<template>
  <div class="playlistItem">
    <b-link
      :to="{
        path: url,
        query: {
          id: model.id,
        },
      }"
      class="left"
      :style="
        'background-image: url(' +
        (model.imageUrl ? model.imageUrl : '/assets/placeholder.png') +
        ')'
      "
    >
      <span class="letter">{{ model.name[0] }}</span>
    </b-link>
    <b-link
      :id="'b' + model.id.replaceAll('-', '')"
      :to="{
        path: url,
        query: {
          id: model.id,
        },
      }"
      class="right focus-smart"
    >
      <h3>{{ model.name }}</h3>
      <p>{{ firstLine }}</p>
      <p>{{ secondLine }}</p>
    </b-link>
    <div class="buttons">
      <b-button
        :title="translations['soundLibrary.EditTitle']"
        @click="edit()"
        ><i class="cil-pencil"></i
      ></b-button>
      <b-button :title="translations['soundLibrary.DeletePlaylist']" @click="remove()"
        ><i class="cil-trash"></i
      ></b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: Object,
    translations: Object,
    url: String,
  },
  computed: {
    time() {
      if (this.model.duration) {
        var hours = Math.floor(this.model.duration / 60);
        var minutes = this.model.duration % 60;
        return `${hours} ${this.translations["soundLibrary.Hour"]} ${minutes} ${this.translations["soundLibrary.Minutes"]}`;
      }
      return `-`;
    },
    firstLine() {
      return `${this.model.count} ${
        this.model.count == 1
          ? this.translations["soundLibrary.Track"]
          : this.translations["soundLibrary.Tracks"]
      } | ${this.time}`;
    },
    secondLine() {
      return `${this.translations["soundLibrary.Create"]} ${this.model.createDate}`;
    },
  },
  methods: {
    remove() {
      this.$emit("delete", this.model.id);
    },
    edit() {
      this.$emit("edit", this.model.id, this.model.name);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.playlistItem {
  width: 100%;
  display: flex;
  background: #515151;
  margin-bottom: 16px;
  border: 2px solid $body-bg;
  .left {
    position: relative;
    background: center / cover no-repeat;
    width: 136px;
    @include media-breakpoint-up(lg) {
      width: 305px;
    }

    .letter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      font-size: em(16);
      @include media-breakpoint-up(lg) {
        font-size: em(44);
      }
    }
  }
  .right {
    flex-grow: 1;
    padding: 26px 16px;
    @include media-breakpoint-up(lg) {
      padding: 44px 22px;
    }
    h3 {
      font-family: "Knile";
      font-style: normal;
      font-weight: 700;
      font-size: em(14, 12);
      line-height: 24px;
      margin: 0;
      @include media-breakpoint-up(lg) {
        font-size: em(18);
        margin-bottom: 12px;
      }
    }
    p {
      display: none;
      font-family: "Wotfard";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .buttons {
    padding: 44px 22px;
    opacity: 0;
    transition: 0.5s all;
    display: none;
    .btn {
      background: transparent;
      border: transparent;
      width: auto;
      height: auto;
      font-size: 40px;
      padding: 0 20px;
      box-shadow: none !important;
      margin: 12px 0px;
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .expandButton {
    padding: 16px;
    .link {
      padding: 15px 12px 10px;
      display: block;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  .expandButton {
    padding: 16px;
    .link {
      padding: 15px 12px 10px;
      display: block;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  &:hover {
    border-color: $color-main;
    .buttons {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div>
    <!-- typ licencji -->
    <radio-buttons
      :header="model.translations['proOrder.TypeLicense']"
      :itemList="model.partLicense"
      :select="currentLicense.licenseType"
      :input="currentLicense.channelName"
      :readonly="readonly"
      name="licenseType"
      @ChangeValue="getValue"
    ></radio-buttons>
    <!-- terrytorium -->
    <transition name="slide-fade">
      <territory
        v-show="
          currentLicense.licenseType &&
          (currentLicense.licenseType != 'usecase.distribution' ||
            (currentLicense.licenseType == 'usecase.distribution' &&
              currentLicense.channelName))
        "
        :header="model.translations['proOrder.Territory']"
        :translations="model.translations"
        :countriesList="model.countries"
        :continentsList="model.continents"
        :selectedContinents="currentLicense.continent"
        :selectedCountries="currentLicense.countries"
        :selectedCheck="currentLicense.checkedFiled"
        :readonly="readonly"
        @ChangeValue="getValue"
      >
      </territory>
    </transition>
    <!-- Pokazy publiczne -->
    <div v-if="currentLicense.licenseType == 'usecase.public_presentation'">
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.PartShowPlace']"
          :itemList="model.partShowPlace"
          :select="currentLicense.partShowPlace"
          name="partShowPlace"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>

      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.partShowPlace"
          :header="model.translations['proOrder.TypeEvent']"
          :itemList="model.partEventType"
          :select="currentLicense.partEventType"
          :readonly="readonly"
          name="partEventType"
          @ChangeValue="getValue"
          ><b-form-group
            v-if="!readonly"
            :label="model.translations['proOrder.EventName']"
            class="short m24"
          >
            <b-input
              :placeholder="model.translations['proOrder.EnterName']"
              v-model="currentLicense.eventName"
              :disabled="!currentLicense.partEventType"
              @blur="getValue"
            >
            </b-input>
          </b-form-group>
          <div v-if="readonly" class="short m24">
            {{ model.translations["proOrder.EventName"] }}:
            <span class="pro-readonly-value">{{
              currentLicense.eventName
            }}</span>
          </div>
        </radio-buttons>
      </transition>
    </div>
    <!-- Emisja w TV -->
    <div v-else-if="currentLicense.licenseType == 'usecase.tv'">
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.ChannelName']"
          :itemList="model.tvProgram"
          :select="currentLicense.tvProgram"
          :readonly="readonly"
          name="tvProgram"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
    </div>
    <!-- Wykorzystanie w produkcji filmowej -->
    <div v-else-if="currentLicense.licenseType == 'usecase.film_production'">
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.MovieType']"
          :itemList="model.movieType"
          :select="currentLicense.movieType"
          :readonly="readonly"
          name="movieType"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
      <transition name="slide-fade">
        <check-box
          v-show="currentLicense.movieType"
          :header="model.translations['proOrder.Distribution']"
          :itemList="model.distribution"
          :select="currentLicense.distribution"
          :readonly="readonly"
          name="distribution"
          @ChangeValue="getValue"
        ></check-box>
      </transition>
    </div>
    <!-- publikacja w internecie -->
    <div v-else-if="currentLicense.licenseType == 'usecase.internet'">
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :itemList="model.accessType"
          name="accessType"
          :header="model.translations['proOrder.Access']"
          :select="currentLicense.accessType"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.accessType"
          :itemList="model.partPublishingType"
          name="publishingType"
          :header="model.translations['proOrder.PartPublishingType']"
          :select="currentLicense.publishingType"
          :input="currentLicense.publishingTypeInput"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.publishingType"
          :itemList="model.publishPlace"
          name="publishPlace"
          :header="model.translations['proOrder.PublishPlace']"
          :select="currentLicense.publishPlace"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
          <inputs
            v-if="!readonly"
            :disabled="!currentLicense.publishPlace"
            :label="model.translations['proOrder.UrlAdress']"
            :placeholder="model.translations['proOrder.EnterAdress']"
            name="urls"
            @ChangeValue="getValue"
            :selected="currentLicense.urls"
            :buttonText="model.translations['proOrder.AddNext']"
          ></inputs>
          <div v-if="readonly">
            <div v-for="(item, index) in currentLicense.urls" :key="index">
              <span class="pro-readonly-value tags">{{ item }}</span>
            </div>
          </div>
        </radio-buttons>
      </transition>
      <transition name="slide-fade">
        <radio-buttons
          v-show="
            currentLicense.publishPlace &&
            currentLicense.urls &&
            currentLicense.urls.find((x) => x)
          "
          :itemList="model.publishSubject"
          name="publishSubject"
          :header="model.translations['proOrder.PublishSubject']"
          :select="currentLicense.publishSubject"
          :input="currentLicense.publishSubjectInput"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
    </div>
    <transition name="slide-fade">
      <b-row
        v-show="
          (currentLicense.licenseType == 'usecase.public_presentation' &&
            currentLicense.eventName) ||
          (currentLicense.licenseType == 'usecase.tv' &&
            currentLicense.tvProgram) ||
          (currentLicense.licenseType == 'usecase.film_production' &&
            currentLicense.distribution &&
            currentLicense.distribution.length > 0) ||
          (currentLicense.licenseType == 'usecase.tv' && isTerritorySelected) ||
          (currentLicense.licenseType == 'usecase.internet' &&
            currentLicense.publishSubject)
        "
        v-if="currentLicense.licenseType && fileFormat && (showPreparingFragments() || showFileFormat())"
      >
        <b-col>
          <div class="white" v-if="!readonly">
            <b-container>
              <b-row class="progressBar">
                <b-col :sm="10">
                  <h2>
                    {{ model.translations["proOrder.FileFormat"] }}
                  </h2>
                </b-col>
              </b-row>
            </b-container>
          </div>          
          <div class="form">
            <transition name="slide-fade">
              <radio-buttons
                v-if="showPreparingFragments()"                
                :header="model.translations['proOrder.PreparingFragment']"
                :itemList="fileFormat.preParingFragment"
                :select="currentLicense.preParingFragment"
                :readonly="readonly"
                name="preParingFragment"
                @ChangeValue="getValue"
              ></radio-buttons>
            </transition>
            <transition>              
              <check-box
                ref="partFileFormat"
                v-show="showFileFormat()"
                :header="model.translations['proOrder.FileFormat']"
                :itemList="fileFormat.filesFormat"
                :select="currentLicense.filesFormat"
                :readonly="readonly"
                name="filesFormat"
                @ChangeValue="getValue"
              >
              </check-box>
            </transition>
          </div>
        </b-col>
      </b-row>
    </transition>
    <transition name="slide-fade">
      <section-header
        :header="model.translations['proOrder.Pending']"
        v-show="
          !readonly && (
          (showFileFormat() && !(currentLicense.filesFormat && currentLicense.filesFormat.length > 0))
          ||
          (!showFileFormat() && showPreparingFragments() && !currentLicense.preParingFragment)
          ||
          (!showFileFormat() && !showPreparingFragments() && !currentLicense.tvProgram))
          "            
      >
      </section-header>
    </transition>
    <transition name="slide-fade" v-if="readonly">
      <div>
        <section-header
          :header="model.translations['proOrder.LicenseNotes']"
        ></section-header>
        <b-container>
          <b-row class="progressBar m40 text-area">
            <b-col :sm="10">
              <div class="notes-margin">
                <b-form-group>
                  <label :for="currentLicense.notes" class="col-form-label">{{
                    model.translations["proOrder.EnterNotes"]
                  }}</label>
                  <b-form-textarea
                    v-model="currentLicense.notes"
                    size="lg"
                    maxlength="1000"
                  ></b-form-textarea>
                  <p class="chars-status text-right">
                    {{
                      currentLicense.notes ? currentLicense.notes.length : 0
                    }}
                    / 1000
                  </p>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </transition>
  </div>
</template>
<script>
import CheckBox from "./checkBox.vue";
import Inputs from "./ProInput.vue";
import RadioButtons from "./RadioButtons.vue";
import Territory from "./Territory.vue";
import SectionHeader from "./SectionHeader.vue";
export default {
  components: {
    Territory,
    RadioButtons,
    CheckBox,
    Inputs,
    SectionHeader,
  },
  props: {
    model: Object,
    current: Object,
    readonly: Boolean,
  },
  data() {
    return {
      currentLicense: {
        licenseType: null,
        countries: [],
        continent: [],
        checkedFiled: [],
        partShowPlace: null,
        partEventType: null,
        eventName: null,
        channelName: null,
        tvProgram: null,
        movieType: null,
        distribution: [],
        accessType: null,
        publishingType: null,
        publishingTypeInput: null,
        publishPlace: null,
        publishSubject: null,
        publishSubjectInput: null,
        urls: [null],
        fileSoundWersion: null,
        fileSpeed: null,
        filesFormat: [],
        preParingFragment: null,
        notes: null,
      },
      fileFormat: {
        fileSoundWersion: [],
        fileSpeed: [],
        filesFormat: [],
        preParingFragment: [],
      },
    };
  },
  methods: {
    showFileFormat() {
      return this.fileFormat.filesFormat &&
            ((this.fileFormat.preParingFragment &&
                this.fileFormat.preParingFragment.length > 0 &&
                this.currentLicense.preParingFragment) ||
              ((!this.fileFormat.preParingFragment ||
                    this.fileFormat.preParingFragment.length <= 0)));
    },
    showPreparingFragments(){
      return  this.currentLicense.licenseType != 'usecase.film_production' &&
              this.fileFormat.preParingFragment &&
              this.fileFormat.preParingFragment.length > 0;
    },
    getValue(value, type) {
      switch (type) {
        case "licenseType":
          switch (value) {
            case "usecase.public_presentation":
              this.fileFormat.fileSoundWersion =
                this.model.partScreenings.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.partScreenings.fileSpeed;
              this.fileFormat.filesFormat =
                this.model.partScreenings.filesFormat;
              this.fileFormat.preParingFragment =
                this.model.partScreenings.preParingFragment;
              break;
            case "usecase.tv":
              this.fileFormat.fileSoundWersion =
                this.model.partTV.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.partTV.fileSpeed;
              this.fileFormat.filesFormat = this.model.partTV.filesFormat;
              break;
            case "usecase.film_production":
              this.fileFormat.fileSoundWersion = this.model.partMovie.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.partMovie.fileSpeed;
              this.fileFormat.filesFormat = this.model.partMovie.filesFormat;      
              break;
            case "usecase.internet":
              this.fileFormat.fileSoundWersion =
                this.model.partInternet.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.partInternet.fileSpeed;
              this.fileFormat.filesFormat = this.model.partInternet.filesFormat;
              break;
          }
          this.currentLicense.licenseType = value;
          break;
        case "licenseTypeinput":
          this.currentLicense.channelName = value;
          break;
        case "continent":
          this.currentLicense.continent = value;
          break;
        case "countries":
          this.currentLicense.countries = value;
          break;
        case "territory":
          this.currentLicense.checkedFiled = value;
          break;
        case "partShowPlace":
          this.currentLicense.partShowPlace = value;
          break;
        case "partEventType":
          this.currentLicense.partEventType = value;
          break;
        case "tvProgram":
          this.currentLicense.tvProgram = value;
          break;
        case "movieType":
          this.currentLicense.movieType = value;
          break;
        case "distribution":
          this.currentLicense.distribution = value;
          break;
        case "accessType":
          this.currentLicense.accessType = value;
          break;
        case "publishingType":
          this.currentLicense.publishingType = value;
          break;
        case "publishingTypeinput":
          this.currentLicense.publishingTypeInput = value;
          break;
        case "publishPlace":
          this.currentLicense.publishPlace = value;
          break;
        case "publishSubjectinput":
          this.currentLicense.publishSubjectInput = value;
          break;
        case "publishSubject":
          this.currentLicense.publishSubject = value;
          break;
        case "urls":
          this.currentLicense.urls = value;
          break;
        case "fileSoundWersion":
          this.currentLicense.fileSoundWersion = value;
          break;

        case "fileSpeed":
          this.currentLicense.fileSpeed = value;
          break;

        case "filesFormat":
          this.currentLicense.filesFormat = value;
          break;

        case "preParingFragment":
          this.currentLicense.preParingFragment = value;
          break;
      }
      this.$emit("ChangeValue", this.currentLicense, "part");
    },
  },
  watch: {
    current: {
      handler(newValue) {
        if (newValue) {
          this.currentLicense.licenseType = newValue.licenseType;
          this.currentLicense.partShowPlace = newValue.partShowPlace;
          this.currentLicense.partEventType = newValue.partEventType;
          this.currentLicense.countries = newValue.countries;
          this.currentLicense.continent = newValue.continent;
          this.currentLicense.checkedFiled = newValue.checkedFiled;
          this.currentLicense.eventName = newValue.eventName;
          this.currentLicense.channelName = newValue.channelName;
          this.currentLicense.tvProgram = newValue.tvProgram;
          this.currentLicense.movieType = newValue.movieType;
          this.currentLicense.distribution = newValue.distribution;
          this.currentLicense.accessType = newValue.accessType;
          this.currentLicense.publishingType = newValue.publishingType;
          this.currentLicense.publishingTypeInput =
            newValue.publishingTypeInput;
          this.currentLicense.publishPlace = newValue.publishPlace;
          this.currentLicense.publishSubject = newValue.publishSubject;
          this.currentLicense.publishSubjectInput =
            newValue.publishSubjectInput;
          this.currentLicense.urls = newValue.urls;
          this.currentLicense.fileSoundWersion = newValue.fileSoundWersion;
          this.currentLicense.fileSpeed = newValue.fileSpeed;
          this.currentLicense.filesFormat = newValue.filesFormat;
          this.currentLicense.preParingFragment = newValue.preParingFragment;
          this.currentLicense.notes = newValue.notes;
        } else {
          this.currentLicense.licenseType = null;
          this.currentLicense.countries = [];
          this.currentLicense.continent = [];
          this.currentLicense.checkedFiled = [];
          this.currentLicense.partShowPlace = null;
          this.currentLicense.partEventType = null;
          this.currentLicense.eventName = null;
          this.currentLicense.channelName = null;
          this.currentLicense.tvProgram = null;
          this.currentLicense.movieType = null;
          this.currentLicense.distribution = [];
          this.currentLicense.accessType = null;
          this.currentLicense.publishingType = null;
          this.currentLicense.publishingTypeInput = null;
          this.currentLicense.publishPlace = null;
          this.currentLicense.publishSubject = null;
          this.currentLicense.publishSubjectInput = null;
          this.currentLicense.urls = [null];
          this.currentLicense.fileSoundWersion = null;
          this.currentLicense.fileSpeed = null;
          this.currentLicense.filesFormat = [];
          this.currentLicense.preParingFragment = null;
          this.currentLicense.notes = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.current) this.currentLicense = this.current;
  },
  computed: {
    isTerritorySelected: function () {
      return this.currentLicense.countries && this.currentLicense.countries.length ? true:false;      
    },
  },
};
</script>
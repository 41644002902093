<template>
  <div class="collection__item background--medium-gray color--black">
    <div class="collection__item__content">
      <div class="collection__item__content--title text-center">
        <h3>{{ model.title }}</h3>
        <div class="collection__item__content--price">
          <span>{{ model.price }}</span>
          <span>{{  model.validTime }}</span>
        </div>
      </div>
      <ul class="collection__item__content--text" v-if="model.features">
        <li v-for="(item, index) in model.features" :key="index">{{ item }}</li>
      </ul>
      <div class="collection__item__content--button text-center">
        <b-button :to="model.link" variant="secondary" class="bottom--button">
          Kup
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: Object,
    translations: Object,
  }, 
};
</script>
<style lang="scss">
.collection {
  &__item {
    &__content {
      &--text {
        li {
          list-style: none;
        }
      }
      &--price {
        display: flex;
        justify-content: center;
        gap: 0.5em;
        font-size: 0.75em;
        :first-child {
          padding-right: 0.5em;
          display: block;
          border-right: 2px solid;
        }
      }
    }
  }
}
</style>
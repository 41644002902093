<template>
  <div class="mx-auto mt-5 color--basic profile-panel">
    <b-container v-if="!isApp && showCard">
      <b-row>
        <b-col class="mx-4 text-center text-uppercase">
          <h1 class="text-center">
            {{ model.translations["user.CardHeader"] }}
          </h1>
        </b-col>
      </b-row>
      <b-row v-if="!cards || cards.length == 0" class="text-center">
        <b-col>
          <p>{{ model.translations["user.NotCard"] }}</p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <accordion
            v-for="(item, index) in cards"
            :key="index"
            accordionName="Cards"
            :tabId="'CardId' + item.id"
          >
            <template v-slot:header>
              <div tabindex="0" class="card-header__button">
                <div>
                  <span>
                    <i class="cil-chevron-bottom-alt"></i>
                    <i class="cil-chevron-left-alt"></i>
                  </span>
                  <span class="hover-left-right">
                    {{ model.translations["user.CardName"] }} {{ item.type }}
                  </span>
                </div>
                <div class="shopping-list__icon">
                  <span v-if="item.status == 1">
                    {{ model.translations["user.Status.CardWaiting"] }}
                    <i class="icoStatus cil-clock"></i
                  ></span>
                  <span v-else-if="item.status == 0">
                    {{ model.translations["user.Status.CardAccept"] }}
                    <i class="icoStatus cil-check-alt"></i
                  ></span>
                  <span v-else-if="item.status == 2">
                    {{ model.translations["user.Status.CardRejected"] }}
                    <i class="icoStatus cil-x"></i
                  ></span>
                  <span v-else>
                    {{ model.translations["order.Status.Unknow"] }}
                    <i class="icoStatus cil-x"></i
                  ></span>
                </div>
              </div>
            </template>
            <template v-slot:main>
              <div>
                <div class="info-card">
                  <h3>{{ model.translations["user.CardDetail"] }}</h3>
                  <div class="property-card text-center" v-if="item.status == 1">
                    {{ model.translations["user.WaitingPayment"] }}
                  </div>
                  <div class="property-card" v-if="item.type">
                    <div class="header">
                      {{ model.translations["user.CardIssuer"] }}
                    </div>
                    <div class="value">
                      {{ item.type }}
                    </div>
                  </div>
                  <div class="property-card" v-if="item.expiration">
                    <div class="header">
                      {{ model.translations["user.CardExpiration"] }}
                    </div>
                    <div class="value">
                      {{ item.expiration.slice(0, 2) }}/{{ item.expiration.slice(2, 6) }}
                    </div>
                  </div>
                  <div class="property-card" v-if="item.created">
                    <div class="header">
                      {{ model.translations["user.CardDateAdd"] }}
                    </div>
                    <div class="value">
                      {{ formatDate(item.created) }}
                    </div>
                  </div>
                </div>
                <div class="buttons">
                  <b-button @click="deleteCard(item.id)">{{
                    model.translations["user.CardDelete"]
                  }}</b-button>
                </div>
              </div>
            </template>
          </accordion>
        </b-col>
      </b-row>
      <b-row class="pt-3 text-center" v-if="showAddButton">
        <b-col>
          <b-button class="focus-smart ml-2" @click="AddCard()" variant="primary">{{
            model.translations["user.AddCard"]
          }}</b-button></b-col
        >
      </b-row>
    </b-container>
    <b-form @submit.stop.prevent="submit">
      <b-container class="mt-5">
        <b-row>
          <b-col class="mx-4 text-center text-uppercase">
            <h1 v-html="model.translations['user.AccountData']"></h1>
          </b-col>
        </b-row>
        <b-row v-if="!isTV">
          <b-col class="text-center">
            <b-link
              class="float-left focus-smart"
              :to="model.deleteAccount.url"
              @click.native="SendAnalitycs('Usuń konto')"
              >{{ model.translations["user.DeleteAccount"] }}</b-link
            >
          </b-col>
        </b-row>

        <b-row class="pt-3" v-if="hasPassword">
          <b-col class="text-center">
            <b-link
              class="float-left focus-smart"
              :to="model.changePassword.url"
              @click.native="SendAnalitycs('Zmień hasło')"
              >{{ model.translations["user.ChangePassword"] }}</b-link
            ><b-link
              class="float-right"
              :to="model.changeEmail.url"
              @click.native="SendAnalitycs('Zmień E-mail')"
              >{{ model.translations["user.ChangeEmail"] }}</b-link
            >
          </b-col>
        </b-row>
        <b-row class="pt-4" v-if="this.state.isOptPro">
          <b-col>
            <label for="institutionName">{{
              model.translations["user.InstitutionName"]
            }}</label>
            <b-form-input
              name="institutionName"
              id="institutionName"
              v-model="$v.state.institutionName.$model"
              :state="validationState($v.state.institutionName)"
              aria-describedby="error-institutionName"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-institutionName"
              v-if="$v.state.institutionName.$dirty && !$v.state.institutionName.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4" v-if="this.state.isOptPro">
          <b-col>
            <label for="institutionPhone">{{
              model.translations["user.InstitutionPhone"]
            }}</label>
            <b-form-input
              name="institutionPhone"
              id="institutionPhone"
              v-model="$v.state.institutionPhone.$model"
              :state="validationState($v.state.institutionPhone)"
              aria-describedby="error-institutionPhone"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-institutionPhone"
              v-if="
                $v.state.institutionPhone.$dirty && !$v.state.institutionPhone.required
              "
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-4" v-if="this.state.isOptPro">
          <b-col>
            <label for="institutionNIP">{{ model.translations["user.NIP"] }}</label>
            <b-form-input
              name="institutionNIP"
              id="institutionNIP"
              v-model="$v.state.institutionNIP.$model"
              :state="validationState($v.state.institutionNIP)"
              aria-describedby="error-institutionNIP"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-institutionName"
              v-if="$v.state.institutionNIP.$dirty && !$v.state.institutionNIP.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-institutionNIP"
              v-else-if="
                $v.state.institutionNIP.$dirty && !$v.state.institutionNIP.nipFormat
              "
              >{{ model.translations["user.InvalidNIP"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-4" v-if="this.state.isOptPro">
          <b-col>
            <label for="institutionRIK">{{ model.translations["user.RIK"] }}</label>
            <b-form-input
              name="institutionRIK"
              id="institutionRIK"
              v-model="$v.state.institutionRIK.$model"
              :state="validationState($v.state.institutionRIK)"
              aria-describedby="error-institutionRIK"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-institutionRIK"
              v-if="$v.state.institutionRIK.$dirty && !$v.state.institutionRIK.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="firstname">{{ model.translations["general.FirstName"] }}</label>
            <b-form-input
              name="firstname"
              id="firstname"
              v-model="$v.state.firstname.$model"
              :state="validationState($v.state.firstname)"
              aria-describedby="error-firstname"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-firstname"
              v-if="$v.state.firstname.$dirty && !$v.state.firstname.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col>
            <label for="lastname">{{ model.translations["general.LastName"] }}</label>
            <b-form-input
              name="lastname"
              id="lastname"
              v-model="$v.state.lastname.$model"
              :state="validationState($v.state.lastname)"
              aria-describedby="error-lastname"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-lastname"
              v-if="$v.state.lastname.$dirty && !$v.state.lastname.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col>
            <label for="username">{{ model.translations["general.Username"] }}</label>
            <b-form-input
              name="username"
              id="username"
              readonly
              v-model="$v.state.username.$model"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <label for="email">{{ model.translations["general.Email"] }}</label>
            <b-form-input
              name="email"
              id="email"
              readonly
              v-model="$v.state.email.$model"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="pt-4" v-if="this.state.isOptPro">
          <b-col>
            <div class="divider"></div>
          </b-col>
        </b-row>

        <b-row class="pt-2" v-show="false">
          <b-col>
            <div
              class="profile-panel__opt-col pl-3 pr-1 d-flex justify-content-between align-items-center"
            >
              <b-form-checkbox
                id="check-optedu"
                name="check-optedu"
                v-model="checkOptEdu"
                value="on"
                unchecked-value="off"
                >{{ model.translations["user.OptionsEdu"] }}
              </b-form-checkbox>
              <b-button role="tab" class="button--toggle" v-b-toggle="'optedu'">
                <b-media center-align vertical-align="center">
                  <template>
                    <div class="arrow"></div>
                  </template>
                  <p class="mb-0 mr-3"></p>
                </b-media>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col>
            <b-collapse
              id="optedu"
              v-model="state.isOptEdu"
              role="tabpanel"
              class="profile-panel__collapsible"
            >
              <slot>
                <b-row class="pt-4">
                  <b-col class="mx-3">
                    <b-form-checkbox
                      id="check-edurole-u"
                      v-model="checkOptPupil"
                      name="check-edurole-u"
                      value="on"
                      unchecked-value="off"
                      >{{ model.translations["user.Pupil"] }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="pt-2">
                  <b-col class="mx-3">
                    <b-form-checkbox
                      id="check-edurole-s"
                      v-model="checkOptStudent"
                      name="check-edurole-s"
                      value="on"
                      unchecked-value="off"
                      >{{ model.translations["user.Student"] }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="pt-2">
                  <b-col>
                    <div
                      class="profile-panel__opt-teacher pl-3 pr-1 d-flex justify-content-between align-items-center"
                    >
                      <b-form-checkbox
                        id="check-edurole-n"
                        v-model="checkOptTeacher"
                        name="check-edurole-n"
                        value="on"
                        unchecked-value="off"
                        >{{ model.translations["user.Teacher"] }}
                      </b-form-checkbox>
                      <b-button
                        role="tab"
                        class="button--toggle"
                        v-b-toggle="'optteacher'"
                      >
                        <b-media center-align vertical-align="center">
                          <template>
                            <div class="arrow"></div>
                          </template>
                          <p class="mb-0 mr-3"></p>
                        </b-media>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse
                      id="optteacher"
                      v-model="state.isOptTeacher"
                      role="tabpanel"
                      class="mb-4 profile-panel__collapsible"
                    >
                      <slot>
                        <b-row class="pt-3">
                          <b-col class="mx-3">
                            <label for="institutionType">{{
                              model.translations["user.InstitutionType"]
                            }}</label>
                            <b-form-select
                              id="institutionType"
                              name="institutionType"
                              v-model="$v.state.institutionType.$model"
                              :options="model.institutionTypes"
                              :state="validationState($v.state.institutionType)"
                              aria-describedby="error-institutionType"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null" disabled>
                                  {{
                                    model.translations["general.Errors.SelectOneOption"]
                                  }}
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <b-form-invalid-feedback
                              id="error-institutionType"
                              v-if="
                                $v.state.institutionType.$dirty &&
                                !$v.state.institutionType.required
                              "
                              >{{ model.translations["general.Errors.Required"] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row class="pt-3">
                          <b-col class="mx-3">
                            <label for="institutionAddress">{{
                              model.translations["user.InstitutionAddress"]
                            }}</label>
                            <b-form-input
                              name="institutionAddress"
                              id="institutionAddress"
                              v-model="$v.state.institutionAddress.$model"
                              :state="validationState($v.state.institutionAddress)"
                              aria-describedby="error-institutionAddress"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              id="error-institutionAddress"
                              v-if="
                                $v.state.institutionAddress.$dirty &&
                                !$v.state.institutionAddress.required
                              "
                              >{{ model.translations["general.Errors.Required"] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row class="pt-3">
                          <b-col class="mx-3">
                            <label for="institutionPhone">{{
                              model.translations["user.InstitutionPhone"]
                            }}</label>
                            <b-form-input
                              name="institutionPhone"
                              id="institutionPhone"
                              v-model="$v.state.institutionPhone.$model"
                              :state="validationState($v.state.institutionPhone)"
                              aria-describedby="error-institutionPhone"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              id="error-institutionPhone"
                              v-if="
                                $v.state.institutionPhone.$dirty &&
                                !$v.state.institutionPhone.required
                              "
                              >{{ model.translations["general.Errors.Required"] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row class="pt-3">
                          <b-col class="mx-3">
                            <label for="province">{{
                              model.translations["user.Province"]
                            }}</label>
                            <b-form-select
                              id="province"
                              name="province"
                              v-model="$v.state.province.$model"
                              :options="model.provinces"
                              :state="validationState($v.state.province)"
                              aria-describedby="error-province"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null" disabled>
                                  {{
                                    model.translations["general.Errors.SelectOneOption"]
                                  }}
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <b-form-invalid-feedback
                              id="error-province"
                              v-if="
                                $v.state.province.$dirty && !$v.state.province.required
                              "
                              >{{ model.translations["general.Errors.Required"] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row class="pt-3">
                          <b-col class="mx-3">
                            <label for="county">{{
                              model.translations["user.County"]
                            }}</label>
                            <b-form-input
                              name="county"
                              id="county"
                              v-model="$v.state.county.$model"
                              :state="validationState($v.state.county)"
                              aria-describedby="error-county"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              id="error-county"
                              v-if="$v.state.county.$dirty && !$v.state.county.required"
                              >{{ model.translations["general.Errors.Required"] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                      </slot>
                    </b-collapse>
                  </b-col>
                </b-row>
              </slot>
            </b-collapse>
          </b-col>
        </b-row>

        <!-- <b-row class="pt-5">
          <b-col>
            <b-form-checkbox
              v-model="$v.state.acceptedCheck.$model"
              name="acceptedCheck"
              value="on"
              unchecked-value=""
              :state="validationState($v.state.acceptedCheck)"
                              aria-describedby="error-acceptedCheck"
            >
              {{ this.acceptance[0] }}
              <b-link
                :to="model.rules.url"
                class="focus-smart"
                :title="model.rules.name"
                >{{ this.acceptance[1] }}</b-link
              >
              {{ this.acceptance[2] }}
              <b-link
                :to="model.privacy.url"
                class="focus-smart"
                :title="model.privacy.name"
                >{{ this.acceptance[3] }}</b-link
              >{{ this.acceptance[4] }}
            </b-form-checkbox>
            <b-form-invalid-feedback
                              id="error-acceptedCheck"
             :state="!$v.state.acceptedCheck.$error">
              {{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row> -->

        <!-- <b-row class="pt-2">
          <b-col>
            <b-form-checkbox
              v-model="$v.state.newsletter.$model"
              name="check-newsl"
              value="on"
              unchecked-value="off"
              >{{ model.translations["user.OptionsNewsletter"] }}
            </b-form-checkbox>
          </b-col>
        </b-row> -->

        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button type="submit" variant="primary" class="button--large button--reg"
              >{{ model.translations["general.Save"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <modal
      header="profile"
      :title="
        this.saveSucceeded
          ? model.translations['general.DataSaved']
          : model.translations['general.Errors.SavingData']
      "
      :show="showModal"
      :closeText="closeText"
      @hidePop="closeModal"
    >
    </modal>
  </div>
</template>

<script>
import ValidationMixin, { nipFormat } from "@/mixins/ValidationMixin";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import modal from "../components/common/modal.vue";
import axios from "axios";
import Accordion from "../components/ListingView/Accordion.vue";
import moment from "moment";

export default {
  components: {
    modal,
    Accordion,
  },
  name: "ProfilePage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
  },
  data() {
    return {
      showCard: process.env.VUE_APP_PAYMENT_ACTIVE == "true",
      showAddButton: false,
      showSaveAlert: false,
      saveSucceeded: false,
      acceptance: [],
      hasPassword: false,
      state: {
        username: null,
        email: null,
        firstname: null,
        lastname: null,
        institutionName: null,
        institutionType: null,
        institutionAddress: null,
        institutionPhone: null,
        institutionNIP: null,
        institutionRIK: null,
        province: null,
        county: null,
        isOptEdu: false,
        isOptTeacher: false,
        isOptPupil: false,
        isOptStudent: false,
        isOptPro: false,
        acceptedCheck: null,
        newsletter: null,
      },
      acceptedCheckCange: false,
      newsletterCange: false,
      showModal: false,
      cards: [],
      isTV: this.$isTizen(this.$browserDetect),
      isApp: this.$browserDetect.isApp,
    };
  },
  computed: {
    checkOptEdu: {
      get() {
        return this.state.isOptEdu ? "on" : "off";
      },
      set(value) {
        this.state.isOptEdu = value == "on";
      },
    },
    checkOptTeacher: {
      get() {
        return this.state.isOptTeacher ? "on" : "off";
      },
      set(value) {
        this.state.isOptTeacher = value == "on";
      },
    },
    checkOptPupil: {
      get() {
        return this.state.isOptPupil ? "on" : "off";
      },
      set(value) {
        this.state.isOptPupil = value == "on";
      },
    },
    checkOptStudent: {
      get() {
        return this.state.isOptStudent ? "on" : "off";
      },
      set(value) {
        this.state.isOptStudent = value == "on";
      },
    },
    checkOptPro: {
      get() {
        return this.state.isOptPro ? "on" : "off";
      },
      set(value) {
        this.state.isOptPro = value == "on";
      },
    },
  },
  mounted() {
    moment.locale(localStorage.getItem("x-language").substr(0, 2));
    this.acceptance = this.model.translations["user.Acceptance"].split(/{|}/);
    this.getProfile();
    this.cards = this.model.cards;
    this.checkAddButton();
    var urlSearchParams = new URLSearchParams(window.location.search);
    var params = Object.fromEntries(urlSearchParams.entries());
    if (params.OrderID) {
      var id = params.OrderID.replace("R_", "");
      var self = this;
      setTimeout(() => {
        self.$root.$emit("bv::toggle::collapse", "CardId" + id);
      }, self);
    }
  },
  validations: {
    state: {
      username: { required },
      email: { required, email },
      firstname: {
        required: requiredIf(function () {
          return this.state.isOptEdu;
        }),
      },
      lastname: {
        required: requiredIf(function () {
          return this.state.isOptEdu;
        }),
      },
      institutionName: {},
      institutionType: {},
      institutionAddress: {},
      institutionPhone: {},
      institutionNIP: {
        nipFormat,
        required: requiredIf(function () {
          return this.state.isOptPro;
        }),
      },
      institutionRIK: {
        required: requiredIf(function () {
          return this.state.isOptPro;
        }),
      },
      province: {},
      county: {},
      acceptedCheck: {},
      newsletter: {},
    },
  },
  methods: {
    formatDate(dt) {
      return moment(String(dt)).format("LLL");
    },
    AddCard() {
      var date = new Date();
      date.setTime(date.getTime() + 3600000);
      localStorage.setItem("redirectUri", location.pathname);
      this.SendAnalitycs("Dodaj kartę");
      axios
        .post(process.env.VUE_APP_BASE_API_URL + this.model.addCard)
        .then(function (response) {
          if (response.status == 200) {
            var data = response.data;
            var url = `${data.url}?ServiceID=${data.bm.serviceID}&OrderID=${data.bm.orderID}&Amount=${data.bm.amount}&GatewayID=${data.bm.gatewayID}&Language=${data.bm.language}&RecurringAcceptanceState=${data.bm.recurringAcceptanceState}&RecurringAction=${data.bm.recurringAction}&Hash=${data.bm.hash}`;
            window.location.href = url;
          } else {
            this.saveSucceeded = false;
            this.showModal = true;
          }
        })
        .catch(() => {
          this.saveSucceeded = false;
          this.showModal = true;
        });
    },
    deleteCard(id) {
      this.$setBusy(true);
      this.$root.$emit("bv::toggle::collapse", "CardId" + id);
      axios
        .delete(
          process.env.VUE_APP_BASE_API_URL + this.model.deleteCard.replace("{id}", id)
        )
        .then(() => {
          var index = this.cards
            .map((x) => {
              return x.id;
            })
            .indexOf(id);

          this.cards.splice(index, 1);
          this.checkAddButton();
          this.$setBusy(false);
        });
    },
    submit() {
      this.showModal = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.saveSucceeded = false;
        this.showModal = true;
        return;
      }
      this.$setBusy(true);
      authService
        .saveProfile(this.state)
        .then(() => {
          this.$setBusy(false);
          this.saveSucceeded = true;
          this.showModal = true;
        })
        .catch(() => {
          this.saveSucceeded = false;
          this.showModal = true;
          this.$setBusy(false);
        });
    },

    closeModal() {
      this.showModal = false;
    },
    getProfile() {
      var data = authService.getProfile();
      this.state.username = data.username;
      this.state.email = data.email;
      this.state.firstname = data.given_name;
      this.state.lastname = data.family_name;
      this.state.institutionName = data.institution_name;
      this.state.institutionType = data.institution_type;
      this.state.institutionAddress = data.institution_address;
      this.state.institutionPhone = data.institution_phone;
      this.state.institutionNIP = data.nip;
      this.state.institutionRIK = data.rik;
      this.state.province = data.province;
      this.state.county = data.district;
      this.checkOptEdu = data.is_teacher | data.is_pupil | data.is_student ? "on" : "off";
      this.state.isOptTeacher = data.is_teacher;
      this.state.isOptPupil = data.is_pupil;
      this.state.isOptStudent = data.is_student;
      this.state.isOptPro = data.is_pro;
      if (!this.isTV) this.hasPassword = data.hasPassword;
    },
    SendAnalitycs(label) {
      this.$setGAFormProfile("Kliknął", label, false);
    },
    checkAddButton() {
      if (this.cards) {
        if (
          this.cards.filter((x) => {
            return x.status == 0;
          }).length > 0
        ) {
          this.showAddButton = false;
        } else {
          this.showAddButton = true;
        }
      } else {
        this.showAddButton = true;
      }
    },
  },
  watch: {
    state: [
      "handle1",
      function handle2() {},
      {
        handler: function handle3() {},
      },
    ],
    "state.acceptedCheck": function () {
      this.acceptedCheckCange = !this.acceptedCheckCange;
    },
    "state.newsletter": function () {
      this.newsletterCange = !this.newsletterCange;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.profile-panel {
  width: 100%;
  max-width: 32em;

  .text {
    &--smaller {
      font-size: $font-size-smaller;
    }
  }

  &__opt-col {
    border-radius: 2px;
    background-color: $color-gray;
    height: 40px;

    .custom-control-label {
      vertical-align: unset;
    }
  }

  &__opt-teacher {
    background-color: $color-gray;
    height: 40px;

    .custom-control-label {
      vertical-align: unset;
    }
  }

  &__collapsible {
    background-color: $color-dark-gray;

    .custom-control {
      margin: 5px 0;
    }

    &.teacher {
      margin-bottom: 20px;
    }
  }

  .divider {
    border-top: 1px solid $color-gray;
  }

  .button {
    &--reg {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }

    &--toggle {
      background-color: inherit;
      border: none;
      height: 40px;
      width: 40px;
      padding: 0 10px;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &:active {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
      }

      .media-aside {
        min-width: 30px;
      }

      &.collapsed {
        .arrow {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $color-main;
        }
      }

      &.not-collapsed {
        .arrow {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $color-main;
          padding-left: 5px;
        }
      }
    }
  }

  > form {
    max-width: 25em;
    margin: auto;

    > .container {
      > .row {
        margin: 0;
      }
    }
  }

  .card {
    border: none;
    background: transparent;
    border-top: 3px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    .card-header {
      padding: 0;
      border: none;
      background: transparent;

      .btn {
        background: transparent;
        border: none;
        text-align: left;
        box-shadow: none;
        padding: 10px 0;
        font-weight: 400;
        background: #373737 !important;

        &.not-collapsed {
          .cil-chevron-bottom-alt {
            display: none;
          }
        }

        &.collapsed {
          .cil-chevron-left-alt {
            display: none;
          }
        }
      }

      &__button {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        align-content: stretch;
        padding: 0 30px;

        .cil-chevron-bottom-alt,
        .cil-chevron-left-alt {
          top: em(3);
          position: relative;
          margin-left: 0;
          margin-right: 20px;
        }
      }

      .cil-clock {
        color: skyblue;
      }

      .cil-check-alt {
        color: #40daa3;
      }

      .cil-x {
        color: red;
      }
    }

    .card-body {
      background: #373737;
      color: white;
      display: flex;
      flex-direction: column;

      .info-card {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .property-card {
          padding: 0.5em 0 !important;
          display: flex;
          flex-wrap: wrap;
          border-top: 1px solid;

          .header,
          .value {
            width: auto !important;
            flex: 0 0 50%;

            span {
              display: contents;
            }
          }
        }
      }

      .buttons {
        display: flex;

        .btn {
          background: white;
          color: black;
          border-color: white;
          margin: auto;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"progressBar details-header"},[_c('b-col',{attrs:{"sm":10}},[_c('b-container',[_c('h3',[_vm._v(" "+_vm._s(_vm.header)+" ")])])],1)],1),_c('b-container',[_c('b-row',{staticClass:"progressBar m40"},[_c('b-col',{attrs:{"sm":10}},[_c('b-form-group',[_c('b-form-checkbox-group',{model:{value:(_vm.checkedValue),callback:function ($$v) {_vm.checkedValue=$$v},expression:"checkedValue"}},[(!_vm.readonly || _vm.checkedValue.includes('A'))?_c('b-form-checkbox',{staticClass:"checkReadonly",attrs:{"name":"some-radios","value":"A","disabled":true}},[_vm._v(_vm._s(_vm.translations["proOrder.Territory.Country"]))]):_vm._e(),(!_vm.readonly)?_c('b-form-tags',{ref:"countryInput",staticClass:"short hide-tags-add",attrs:{"tag-validator":_vm.countryValidator,"invalid-tag-text":_vm.translations['proOrder.InvalidTagText'],"placeholder":_vm.translations['proOrder.AddTagText'],"duplicate-tag-text":_vm.translations['proOrder.DuplicateTagText'],"disabled":!_vm.checkedValue.includes('A')},on:{"focus":function($event){_vm.countryFilters.showSearch = true}},nativeOn:{"keydown":function($event){return _vm.keyDown($event)}},model:{value:(_vm.countryFilters.tags),callback:function ($$v) {_vm.$set(_vm.countryFilters, "tags", $$v)},expression:"countryFilters.tags"}}):_vm._e(),(
                _vm.readonly && _vm.countryFilters.tags && _vm.checkedValue.includes('A')
              )?_c('span',{staticClass:"pro-readonly-value tags"},[_vm._v(_vm._s(_vm.countryFilters.tags.join(", ")))]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.countryFilters.showSearch &&
                _vm.countryFilters.searchText &&
                _vm.countryFilters.items &&
                _vm.countryFilters.items.length > 0
              ),expression:"\n                countryFilters.showSearch &&\n                countryFilters.searchText &&\n                countryFilters.items &&\n                countryFilters.items.length > 0\n              "}],staticClass:"searchResult"},[_c('b-list-group',_vm._l((_vm.countryFilters.items),function(item,index){return _c('b-list-group-item',{key:index,class:{
                    selected: _vm.countryFilters.selected == index,
                  },on:{"click":function($event){return _vm.Additem(item, 0)}}},[_c('p',[_c('span',{staticClass:"hover-left-right"},[_vm._v(_vm._s(item.text))])])])}),1)],1),_vm._t("default")],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.header)?_c('b-row',{staticClass:"progressBar details-header"},[_c('b-col',{attrs:{"sm":10}},[_c('b-container',[_c('h3',[_vm._v(_vm._s(_vm.header))])])],1)],1):_vm._e(),_c('b-container',[_c('b-row',{staticClass:"progressBar m40"},[_c('b-col',{attrs:{"sm":10}},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"radios",class:{ selected: _vm.selectedValue[0] },attrs:{"name":_vm.name},model:{value:(_vm.selectedValue[0]),callback:function ($$v) {_vm.$set(_vm.selectedValue, 0, $$v)},expression:"selectedValue[0]"}},_vm._l((_vm.itemList.filter(
                function (x) { return !_vm.readonly || _vm.selectedValue[0] == x.value; }
              )),function(item,index){return _c('div',{key:index,class:{
                active: item.value == _vm.selectedValue[0],
              }},[_c('b-form-radio',{attrs:{"value":item.value},nativeOn:{"click":function($event){_vm.inputValue = null;
                  _vm.selectedValue[1] = null;}}},[_vm._v(" "+_vm._s(item.text)+" ")]),(item.hint)?_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.value == _vm.selectedValue[0]),expression:"item.value == selectedValue[0]"}],staticClass:"hint"},[_vm._v(" "+_vm._s(item.hint)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(item.haveInput && item.value == _vm.selectedValue[0])?_c('div',{staticClass:"short"},[(!_vm.readonly)?_c('b-form-group',{attrs:{"label":item.inputName}},[_c('b-input',{attrs:{"placeholder":item.inputPlaceHolder,"disabled":item.value != _vm.selectedValue[0],"type":item.inputType},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1):_vm._e(),(_vm.readonly && _vm.inputValue)?_c('span',{staticClass:"pro-readonly-value"},[_vm._v(_vm._s(_vm.inputValue))]):_vm._e()],1):_vm._e(),(item.haveText && item.value == _vm.selectedValue[0])?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item.inputName}},[_c('label',{staticClass:"col-form-label",attrs:{"for":item.inputName}},[_vm._v(_vm._s(item.inputPlaceHolder))]),_c('b-form-textarea',{attrs:{"disabled":item.value != _vm.selectedValue[0],"size":"lg","maxlength":"1000"},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_c('p',{staticClass:"chars-status text-right"},[_vm._v(" "+_vm._s(_vm.inputValue ? _vm.inputValue.length : 0)+" / 1000 ")])],1)],1):(item.haveRadio && item.value == _vm.selectedValue[0])?_c('div',[_c('b-form-radio-group',{staticClass:"radios",class:{ selected: _vm.selectedValue[1] },attrs:{"name":_vm.name + index},model:{value:(_vm.selectedValue[1]),callback:function ($$v) {_vm.$set(_vm.selectedValue, 1, $$v)},expression:"selectedValue[1]"}},_vm._l((item.radioValues.filter(
                        function (x) { return !_vm.readonly || _vm.selectedValue[1] == x.value; }
                      )),function(item2,index2){return _c('div',{key:index2},[_c('b-form-radio',{class:{
                          active: item2.value == _vm.selectedValue[1],
                        },attrs:{"disabled":item.value != _vm.selectedValue[0],"value":item2.value},nativeOn:{"click":function($event){_vm.inputValue = null}}},[_vm._v(" "+_vm._s(item2.text)+" ")]),_c('transition',{attrs:{"name":"slide-fade"}},[(
                            item2.haveInput && item2.value == _vm.selectedValue[1]
                          )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item2.inputName}},[_c('b-input',{attrs:{"placeholder":item2.inputPlaceHolder,"disabled":item2.value != _vm.selectedValue[1],"type":item2.inputType},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)],1):_vm._e(),(
                            item2.haveFile && item2.value == _vm.selectedValue[1]
                          )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item2.inputName}},[_c('b-form-file',{attrs:{"state":Boolean(_vm.inputValue),"browse-text":_vm.translations['proOrder.FileBrowseButton'],"placeholder":_vm.translations['proOrder.FilePlaceholder'],"drop-placeholder":_vm.translations['proOrder.FileDropPlaceholder']},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)],1):_vm._e()])],1)}),0)],1):_vm._e()])],1)}),0),_vm._t("default")],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
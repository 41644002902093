<template>
  <div>
    <div v-if="!isCollapsedVersion" class="d-none d-lg-flex social-uncollapsed share">
      <b-link
        :aria-label="translation['navigation.Share']"
        class="showShare focus-smart"
        :class="{ 'showShare--active': isShare }"
        @keyup="isShare = !isShare"
      >
        <i role="img" :title="translation['navigation.Share']" class="cil-share-alt" />
      </b-link>
      <b-nav :class="[isShare ? 'share__social--keyup' : 'share__social']">
        <b-nav-item
          :key="index"
          v-for="(link, index) in social"
          class="share__social__item"
          :href="link.url"
          target="_blank"
          :title="link.label"
          @click="sendAnalitycs(link.name)"
          :link-attrs="{
            'aria-label': link.label,
          }"
        >
          <i :class="'cib-' + link.icon" :title="link.label" role="img"></i>
          <span class="wcag_hide">{{ link.label }}</span>
        </b-nav-item>
        <b-nav-item
          class="share__social__item"
          v-b-modal.modal-shared-mail
          @click="shareByMail()"
          :link-attrs="{
            'aria-label': translation['general.Share.Email'],
          }"
        >
          <i
            aria-hidden="true"
            :title="translation['general.Share.Email']"
            class="cil-mail"
          ></i>
        </b-nav-item>
      </b-nav>
      <share-by-mail :shareSubmitUrl="shareSubmitUrl" :translation="translation" />
    </div>
    <b-nav v-if="isCollapsedVersion" class="social-collapsed d-lg-none">
      <b-nav-item
        :key="index"
        v-for="(link, index) in social"
        class="social-collapsed__link"
        :href="link.url"
        target="_blank"
        :title="link.label"
        :link-attrs="{
          'aria-label': link.label,
        }"
        @click="sendAnalitycs(link.name)"
      >
        <i
          :class="'cib-' + link.icon"
          class="social-collapsed__icon header-cicon"
          :title="link.label"
          role="img"
        ></i>
      </b-nav-item>
      <b-nav-item
        class="social-collapsed__link"
        v-b-modal.modal-shared-mail
        title="ShareByEmail"
        :link-attrs="{
          'aria-label': translation['general.Share.Email'],
        }"
      >
        <i
          aria-hidden="true"
          :title="translation['general.Share.Email']"
          class="social-collapsed__icon cil-mail header-cicon"
        ></i>
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>
import ShareByMail from "./ShareByMail.vue";
import { EventBus } from "@/services/event-bus.js";

export default {
  components: {
    ShareByMail,
  },
  props: ["shareSubmitUrl", "translation", "isCollapsedVersion", "social"],
  data() {
    return {
      domain: window.location.origin,
      local: this.$route.fullPath,
      isShare: false,
    };
  },
  computed: {
    link() {
      return this.domain + this.local;
    },
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.link}`;
    },
  },
  methods: {
    shareByMail: function () {
      this.sendAnalitycs("E-mail");
      EventBus.$emit("share-content", null);
    },
    sendAnalitycs(label) {
      this.$setGAFormNavigation("Udostępnij", label, false);
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.social-uncollapsed {
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 0px;
    top: 1.25em;
  }
  @include media-breakpoint-down(md) {
    margin-left: 70px;
  }
}
.share {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: 5px;
  color: $color-black;
  background: transparent;
  transform: none;
  padding: 0;
  margin: 0;
  z-index: 5;

  a.showShare {
    background: transparent;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-main;
    font-size: em(25);
    transition: background-color 0.2s ease-in;
    text-decoration: none;
  }
  &__social {
    list-style-type: none;
    flex-direction: column;
    display: none;
    &__item {
      display: list-item;
      opacity: 0;
      padding: 0;
      background: $color-main;
      &:hover {
        background-color: $color-light-gray !important;
      }

      a {
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-black !important;
        font-size: em(25);
      }
    }
  }

  &:hover {
    > a {
      color: $color-black;
      text-decoration: none;
      background-color: $color-light-gray;
    }
    .share__social {
      display: block;
    }
    li.share__social__item a {
      text-decoration: none;
      color: $color-black !important;
    }

    li {
      transform-origin: top center;
    }

    li:nth-child(1) {
      animation: translateX 300ms 250ms ease-in-out forwards;
    }

    li:nth-child(2) {
      animation: translateX 300ms 300ms ease-in-out forwards;
    }

    li:nth-child(3) {
      animation: translateX 300ms 350ms ease-in-out forwards;
    }

    li:nth-child(4) {
      animation: translateX 300ms 400ms ease-in-out forwards;
    }

    li:nth-child(5) {
      animation: translateX 300ms 450ms ease-in-out forwards;
    }

    li:nth-child(6) {
      animation: translateX 300ms 500ms ease-in-out forwards;
    }
  }

  @keyframes translateX {
    0% {
      transform: translateX(60px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  &__social--keyup {
    list-style-type: none;
    flex-direction: column;
    .share__social__item {
      display: list-item;
      opacity: 1;
      padding: 0;
      background: $color-main;
      a {
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-black !important;
        font-size: em(25);
      }
    }
    .share__social__item:hover,
    .share__social__item:focus,
    .share__social__item:active {
      background-color: $color-light-gray !important;
    }
  }
}

.social-collapsed {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  &__link {
    padding-right: 20px;
    margin-right: 30px;
    list-style-type: none;
    .nav-link {
      padding-left: 0;
      padding-right: 0;
    }
    :hover {
      text-decoration: none;
    }
    img {
      position: relative;
      top: -5px;
      height: 24px;
    }
  }
  &__icon {
    line-height: 1;
    margin-top: 2px;
  }
}
</style>

<template>
  <b-navbar-nav v-click-outside="closeDialog">
    <b-nav-item
      @click="searchClick()"
      :class="'search-icon' + [showSearch ? '' : ' zoom-icon']"
      tabindex="-1"
      :link-attrs="{
        'aria-label': GetTitle(),
      }"
      v-if="!showSearch || $isTizen($browserDetect)"
    >
      <i
        role="img"
        :title="GetTitle()"
        class="header-cicon"
        :class="[showSearch ? 'cil-x search-icon__close' : 'cil-magnifying-glass']"
      /> </b-nav-item
    ><b-nav-form @submit.stop.prevent="submit" v-if="$isTizen($browserDetect)">
      <div :class="{ expand: showSearch }" class="search-form">
        <div class="search-form__wrapper">
          <label for="searchItems">{{ model.name }}:</label>
          <b-form-input
            size="sm"
            ref="searchPhrase"
            class="search-form__input"
            :placeholder="model.name"
            v-model="searchPhrase"
            :state="isValid"
            id="searchItems"
            @keydown="keyd"
            aria-describedby="errorSearch"
          >
          </b-form-input>
          <b-form-invalid-feedback aria-live="polite" id="errorSearch" v-if="!isValid">
            {{ translations["general.Error.MinLength"] }} 3
          </b-form-invalid-feedback>
          <b-button
            size="sm"
            class="my-2 my-sm-0 search-form__button"
            type="submit"
            :aria-label="model.name"
          >
            <i aria-hidden="true" class="cil-magnifying-glass header-cicon" />
          </b-button>
        </div>
      </div>
    </b-nav-form>
    <b-nav-form @submit.stop.prevent="submit" v-else>
      <transition name="slide" mode="out-in">
        <div v-show="showSearch" class="search-form">
          <div class="search-form__inner">
            <div class="search-form__inner__c1"></div>
            <div class="search-form__inner__c2">
              <div class="search-form__wrapper">
                <label for="searchItems" class="sr-only">{{ model.name }}:</label>
                <b-form-input
                  size="sm"
                  ref="searchPhrase"
                  class="search-form__input"
                  :placeholder="model.name"
                  v-model="searchPhrase"
                  :state="isValid"
                  id="searchItems"
                  @keydown="keyd"
                  aria-describedby="errorSearch"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  aria-live="polite"
                  id="errorSearch"
                  v-show="!isValid"
                >
                  {{ translations["general.Error.MinLength"] }} 3
                </b-form-invalid-feedback>
                <b-button
                  size="sm"
                  class="my-2 my-sm-0 search-form__button"
                  type="submit"
                  :aria-label="model.name"
                >
                  <i aria-hidden="true" class="cil-magnifying-glass header-cicon" />
                </b-button>
              </div>
            </div>
            <div class="search-form__inner__c3" v-if="isAuthenticated && !isPro">
              <b-link :to="this.model.searchUrl"
                ><span class="uscore">{{ translations["searchPage.SearchBasic"] }}</span>
              </b-link>
            </div>
            <div class="search-form__inner__c3" v-else>
              <b-link class="link" :to="this.model.searchUrlAI"
                ><span class="uscore mr-2">{{
                  translations["searchPage.SearchDetailed"]
                }}</span>
                <span class="grayed" v-if="!isPro">{{
                  translations["searchPage.ForLoggedid"]
                }}</span>
              </b-link>
              <i
                v-b-tooltip.hover="{ customClass: 'advsearch-tooltip' }"
                class="grayed cil-info-circle"
                :title="translations['searchPage.AdvSearchDecription']"
              />
            </div>
          </div>
        </div>
      </transition> </b-nav-form
    ><b-nav-item
      v-if="showSearch && !$isTizen($browserDetect)"
      @click="searchClick()"
      :class="'search-icon' + [showSearch ? '' : ' zoom-icon']"
      tabindex="-1"
      :link-attrs="{
        'aria-label': GetTitle(),
      }"
    >
      <i
        role="img"
        :title="GetTitle()"
        class="header-cicon"
        :class="[showSearch ? 'cil-x search-icon__close' : 'cil-magnifying-glass']"
      />
    </b-nav-item>
  </b-navbar-nav>
</template>
<script>
import { EventBus } from "@/services/event-bus";
import authService from "@/services/authService";
export default {
  props: {
    model: Object,
    translations: Object,
  },
  data() {
    return {
      showSearch: false,
      searchPhrase: null,
      blocked: false,
      isValid: true,
      isAuthenticated: false,
      isPro: false,
    };
  },
  methods: {
    keyd(e) {
      if (e.key === "Select") this.submit();
    },
    closeDialog() {
      if (!this.blocked) this.showSearch = false;
      else this.blocked = false;
    },
    GetTitle() {
      if (!this.showSearch) return this.model.showSearch;
      else return this.model.closeSearch;
    },
    GetIcon() {
      if (!this.showSearch) return "search-outline";
      else return "close-outline";
    },
    submit() {
      if (
        !(
          this.searchPhrase &&
          (this.searchPhrase.length == 0 || this.searchPhrase.length > 2)
        )
      ) {
        return;
      }
      if (this.isAuthenticated && !this.isPro) {
        this.$router.push({
          path: this.model.searchUrlAI,
          query: { phrase: this.searchPhrase },
        });
      } else {
        this.$router.push({
          path: this.model.searchUrl,
          query: { "searchPhrase.3": this.searchPhrase },
        });
      }
    },
    sendAnalitycs(show) {
      this.$setGAFormNavigation(
        "Kliknięcie wyszukiwarki",
        show ? "Otwórz" : "Zamknij",
        false
      );
    },
    searchClick() {
      if (this.isAuthenticated && !this.isPro) {
        this.$router.push(this.model.searchUrlAI);
      } else {
        this.showSearch = !this.showSearch;
        this.sendAnalitycs(this.showSearch);
        if (window.RedgalaxyNav && this.showSearch) {
          window.RedgalaxyNav.focusElement("#searchItems");
        }
      }
    },
    navi(item) {
      if (item == "search") {
        this.blocked = true;
        this.showSearch = true;
      }
    },
  },
  watch: {
    showSearch: function (show) {
      if (show) {
        setTimeout(() => {
          this.$refs.searchPhrase.focus();
        }, 100);
      }
    },
    searchPhrase() {
      this.isValid =
        this.searchPhrase &&
        (this.searchPhrase.length == 0 || this.searchPhrase.length > 2);
    },
  },
  mounted() {
    this.isAuthenticated = authService.getUser() ? true : false;
    if (this.isAuthenticated) this.isPro = authService.getProfile().is_pro;
    EventBus.$on("closeNavEvent", this.closeDialog);
    EventBus.$on("NavigationGoTo", this.navi);
    EventBus.$emit("NavigationRegister", {
      id: 1,
      name: this.translations["navigation.GoTo.Search"],
      key: "search",
    });
  },
  beforeDestroy() {
    EventBus.$off("closeNavEvent", this.closeDialog);
    EventBus.$off("NavigationGoTo", this.navi);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.search-icon {
  position: relative;
  z-index: 4;
  .nav-link {
    // width: 52px;
    // height: 39px;
    padding: 8px 10px;
  }

  &__close {
    // background-color: $color-nero;
    // position: absolute;
    // top: -7px;
    // left: -5px;
    // height: 58px;
    // width: 52px;
    padding-top: 13px;
    padding-left: 0px;
    padding-right: 0px;
    z-index: 4;
    text-align: center;

    @include media-breakpoint-up(lg) {
      height: 64px;
    }
  }
}

.search-form {
  position: absolute;
  left: 0;
  width: calc(100% - 75px);
  z-index: 3;
  background-color: $color-nero;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  bottom: 0px;
  height: 100%;

  @include media-breakpoint-down(md) {
    position: absolute;
    bottom: 0px;
    height: 120px;
    padding-bottom: 5px;

    &__inner {
      &__c1 {
        display: none;
      }
      &__c2 {
        order: 2;
      }
      &__c1 {
        order: 1;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 0px;
    height: 100px;
    transform: translateY(-30px);

    &__inner {
      &__c1 {
        display: none;
      }
      &__c2 {
        order: 2;
      }
      &__c1 {
        order: 1;
      }
    }
  }

  @media (max-width: 1550px) and (min-width: map-get($grid-breakpoints, "lg" )) {
    height: 170%;
  }

  @media (max-width: 1550px) {
    align-items: flex-start;
    &__inner {
      width: auto;
      flex-direction: column;
    }
  }

  @media (min-width: 1551px) {
    align-items: center;
    &__inner {
      flex-direction: row;
      width: 100%;
    }
  }

  width: 100%;
  @media (max-width: 470px) {
    padding-right: 25%;
  }

  @include media-breakpoint-up(md) {
    border-top: 2px solid $color-main;
  }

  @include media-breakpoint-up(lg) {
    top: 68px;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;

    &__c1,
    &__c3 {
      width: 100%;
    }

    &__c3 {
      text-align: center;
      padding: 0.5em 0;
      font-size: em(19);
      i {
        font-size: 1.5em;
        margin-left: 0.5em;
      }
      span,
      i {
        vertical-align: middle;
      }
      .grayed {
        color: gray;
      }
      .uscore {
        text-decoration: underline;
      }
      @media (max-width: 470px) {
        font-size: 0.9em;
      }
    }
  }

  &__container {
    display: flex;
    &__c1 {
      width: 33.3%;
    }
    &__c2 {
      width: 33.3%;
    }
    &__c3 {
      width: 33.3%;
    }
  }

  &__wrapper {
    background-color: $color-gray;
    padding: 1px 10px 3px 5px;
    display: flex;
    flex-direction: row;
    width: auto;
    @include media-breakpoint-up(sm) {
      padding: 1px 10px 1px 5px;
    }
    label {
      color: white;
      font-size: em(17);
      display: none;
      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }

  &__input {
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 0px;
    margin-top: 4px;
    height: 32px;
    max-width: 500px;
    background-color: $color-gray;
    border: none;
    border-bottom: 1px solid $color-main;
    transition: all 0.5s ease-out;
    font-size: $font-size-bigger;

    &:active,
    &:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: $color-gray;
      border: none;
      -webkit-text-fill-color: $color-gray;
      -webkit-box-shadow: 0 0 0 30px $color-gray inset !important;
      -webkit-text-fill-color: $color-main !important;
      border-bottom: 1px solid $color-main;
    }

    @include media-breakpoint-down(xs) {
      // min-width: 240px;
      margin-left: 15px;
      margin-right: 15px;
    }

    @include media-breakpoint-up(sm) {
      min-width: 400px;
      padding-bottom: 2px;
      margin-bottom: 6px;
      margin-top: 2px;
    }

    @include media-breakpoint-up(md) {
      min-width: 500px;
      height: 30px;
      padding-top: 0;
      padding-bottom: 6px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    @include media-breakpoint-up(lg) {
    }

    &::placeholder {
      color: $color-light-gray;
    }
  }

  &__button {
    font-size: 0.8em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 8px;
    padding-bottom: 1px;
    border-radius: 0;
    border: none;
    background-color: $color-gray;

    &:hover {
      background-color: inherit;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.search-form {
  .invalid-feedback {
    position: absolute;
    top: 66px;
  }
}
.wcag-fontboost2 {
  .search-form {
    @include media-breakpoint-up(md) {
      top: 93px;
    }
  }
}
.wcag-fontboost1 {
  .search-form {
    @include media-breakpoint-up(md) {
      top: 80px;
    }
  }
}
</style>

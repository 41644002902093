<template>
  <b-row class="process">
    <b-col>
      <div class="gray">
        <b-container>
          <b-row class="progressBar">
            <b-col :sm="10">
              <span                
                class="etap"
                :class="{ active: current == index }"
                v-for="(item, index) in items"
                :key="index"
              >
                <b-link @click="click(index)">
                  {{ translations["proOrder.License"] }} {{index + 1}}
                </b-link>
                <b-link v-if="items.length > 1" @click="delLicense(index)" :title="translations['proOrder.DeleteLicense']">
                  <i class="cil-trash"></i>
                </b-link>
              </span>
              <b-link
                @click="click(items.length)"
                :disabled="!this.$parent.isCurrentLicenseValid"
                class="etap"
                :class="{
                  active: current == items.length,
                }"
              >
                {{ translations["proOrder.Summary"] }}
              </b-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    current: Number,
    translations: Object,
    items: Array,
  },
  methods: {
    click(value) {
      this.$emit("ChangeValue", value, "progress");
    },
    delLicense(index) {
      this.$emit("ChangeValue", index, "delete");
    }
  },
};
</script>
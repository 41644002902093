<template>
  <div class="mx-auto mt-5 color--basic chpass-panel">
    <b-form @submit.stop.prevent="submit" v-if="!this.saveSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center text-uppercase">
            <h1 v-html="model.translations['user.ChangePassword']"></h1>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="oldPassword">{{
              model.translations["user.ChangePassword.OldPassword"]
            }}</label>
            <b-form-input
              name="oldPassword"
              id="oldPassword"
              type="password"
              v-model="$v.state.oldPassword.$model"
              :state="validationState($v.state.oldPassword)"
              aria-describedby="error-old-pass"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-old-pass"
              v-if="
                $v.state.oldPassword.$dirty && !$v.state.oldPassword.required
              "
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="newPassword">{{
              model.translations["user.ChangePassword.NewPassword"]
            }}</label>
            <b-form-input
              name="newPassword"
              id="newPassword"
              type="password"
              v-model="$v.state.newPassword.$model"
              :state="validationState($v.state.newPassword)"
              aria-describedby="error-new-pass"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-new-pass"
              v-if="
                $v.state.newPassword.$dirty && !$v.state.newPassword.required
              "
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-new-pass"
              v-if="
                $v.state.newPassword.$dirty &&
                !$v.state.newPassword.passwordFormat
              "
              >{{ model.translations["user.PasswordFormat"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="repeatPassword">{{
              model.translations["user.RepeatPassword"]
            }}</label>
            <b-form-input
              name="repeatPassword"
              id="repeatPassword"
              type="password"
              v-model="$v.state.repeatPassword.$model"
              :state="validationState($v.state.repeatPassword)"
              aria-describedby="error-rep-pass"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-rep-pass"
              v-if="
                $v.state.repeatPassword.$dirty &&
                !$v.state.repeatPassword.required
              "
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-rep-pass"
              v-else-if="
                $v.state.repeatPassword.$dirty &&
                !$v.state.repeatPassword.sameAsPassword
              "
              >{{ model.translations["user.ErrorConfirmPassword"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              >{{ model.translations["general.Save"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <modal
      header="changepass"
      :title="alertError"
      :show="showAlert"
      :closeText="closeText"
      @hidePop="closeModal"
    >
    </modal>
    <div class="pt-5" v-if="this.saveSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center">
            {{ model.translations["user.ChangePassword.PasswordChanged"] }}
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              @click="$router.back()"
              >{{ model.translations["user.AccountData"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ValidationMixin, { passwordFormat } from "@/mixins/ValidationMixin";
import { required, sameAs } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import modal from "../components/common/modal.vue";

export default {
  components: { modal },
  name: "ChangePasswordPage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
  },
  data() {
    return {
      showAlert: false,
      saveSucceeded: false,
      alertError: null,
      state: {
        oldPassword: null,
        newPassword: null,
        repeatPassword: null,
      },
    };
  },
  validations: {
    state: {
      oldPassword: { required },
      newPassword: {
        required,
        passwordFormat,
      },
      repeatPassword: {
        sameAsPassword: sameAs("newPassword"),
      },
    },
  },
  methods: {
    closeModal() {
      this.showAlert = false;
    },
    submit() {
      this.showAlert = false;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.showAlert = true;
        this.alertError = this.model.translations["general.Errors.SavingData"];
        return;
      }

      this.$setBusy(true);
      authService
        .changePassword(this.state.oldPassword, this.state.newPassword)
        .then(() => {
          this.saveSucceeded = true;
          this.$setBusy(false);
        })
        .catch((error_code) => {
          let error =
            error_code &&
            this.model.translations["user.SSOErrors." + error_code]
              ? this.model.translations["user.SSOErrors." + error_code]
              : this.model.translations["general.Errors.SavingData"];
          this.alertError = error;
          this.showAlert = true;
          this.$setBusy(false);
        });
      this.$setGAFormProfile("Zmienił hasło", "", false);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.chpass-panel {
  width: 100%;
  max-width: 25em;
  .button {
    &--sendpwd {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }
  }
}
</style>
<template>
  <div id="app" :style="style">
    <div v-if="debug" class="devLog">
      <p>debug mode</p>
      <p v-for="(item, index) in error" :key="index">{{ item }}</p>
    </div>
    <!-- <a href="javascript:void((function(){var objScript=document.createElement('script');objScript.setAttribute('src','https://juicystudio.com/tpg/ARC/aria-usage.js');document.body.appendChild(objScript);})());">WAI-ARIA usage</a> -->
    <busy-indicator></busy-indicator>
    <router-view :model="model" :key="test" />
  </div>
</template>
<script>
import contentService from "@/services/contentService";
import { EventBus } from "@/services/event-bus";
import { getCookie, setCookie } from "tiny-cookie";
import authService from "@/services/authService";
export default {
  components: {
    BusyIndicator: () => import("@/components/common/BusyIndicator"),
  },
  data() {
    return {
      model: null,
      tmp: null,
      seo: {
        metaTitle: "",
        metaData: [],
        metaLinks: [],
      },
      selectors: null,
      oldUrl: "",
      showLog: false,
      test: 0,
      resizeInit: false,
      style: Object,
      debug: false,
      error: [],
      isTV: this.$isTizen(this.$browserDetect),
    };
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach(async (to, from, next) => {
      if (this.$isUnlock()) {
        await this.getSeo(to.fullPath);
        var temp = await this.navigation(to);
        this.oldUrl = from;
        await next(temp);
        this.test = this.test + 1;
      } else {
        this.emmitEvent();
        await next(false);
      }
    });
    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
  mounted() {
    this.CheckIsTV();
    this.CheckIsBrowser();
    this.$Lazyload.$on("loading", this.checkImage);
    setTimeout(() => {
      document.getElementById("start-header").remove();
      this.$setBusy(false);
    }, 1000);
    var urlSearchParams = new URLSearchParams(window.location.search);
    var params = Object.fromEntries(urlSearchParams.entries());
    // if (params.code) {
    //   var self = this;
    //   authService
    //     .logInByCode(params.code)
    //     .then((user) => {
    //       if (user.isLocallyRegistered) {
    //         authService.remoteLogin(this);
    //         var url = getCookie("redirectUrl");
    //         setCookie("redirectUrl", "true", {
    //           expires: new Date().toGMTString(),
    //         });
    //         self.$router.push(url);
    //       } else {
    //         self.$router.push(self.model.compositions.registerExternal);
    //       }
    //     })
    //     .catch(() => {
    //       this.hasValidationError = true;
    //       this.$setBusy(false);
    //     });
    // }
    if (params.redirect) {
      var returnURL = localStorage.getItem("redirectUri");
      var item = { path: returnURL, query: params };
      if (returnURL) this.$router.push(item);
      else this.$router.push("/");
    } else {
      this.getSeo().then(() =>
        this.reloadContent().then((value) => {
          if (typeof value === "string" || value instanceof String) {
            this.$router.push(value);
          }
          if (this.$browserDetect.isApp) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const query = Object.fromEntries(urlSearchParams.entries());
            var date = new Date();
            query.t =
              date.getHours() +
              "" +
              date.getMinutes() +
              "" +
              date.getSeconds() +
              "" +
              date.getMilliseconds();
            this.$router.push({ path: window.location.pathname, query: query });
          }
          this.test++;
        })
      );
    }
    var user = authService.getProfile();
    this.debug = user?.is_debug ? true : false;
    if (this.debug) {
      EventBus.$on("error", (error) => {
        this.error.push(error);
      });
    }
  },
  methods: {
    filtrCookieBot() {
      window.addEventListener(
        "CookiebotOnDialogDisplay",
        () => {
          const detailCategories = [
              "Preference",
              "Statistics",
              "Advertising",
              "Unclassified",
            ],
            toggleCategories = ["Preferences", "Statistics", "Marketing"];
          detailCategories.forEach((e) => {
            // eslint-disable-next-line no-undef
            if (CookieConsentDialog[`cookieTable${e}Count`] === 0)
              document.getElementById(
                `CybotCookiebotDialogDetailBodyContentCookieContainer${e}Card`
              ).parentNode.style.display = "none";
          });
          for (let i = 0; i < 3; i++)
            // eslint-disable-next-line no-undef
            if (CookieConsentDialog[`cookieTable${detailCategories[i]}Count`] === 0)
              document.querySelector(
                `label[for=CybotCookiebotDialogBodyLevelButton${toggleCategories[i]}]`
              ).parentNode.style.display = "none";
        },
        !1
      );
    },
    getA() {
      return JSON.stringify(document.location.ancestorOrigins);
    },
    resize() {
      window.removeEventListener("resize", this.resize, true);
      if (!window.RedgalaxyNav) {
        this.isTV = this.$isTizen(this.$browserDetect);
        this.CheckIsTV();
      }
    },
    reloadContent(route) {
      if (route && route.hash && this.$route.path === route.path) {
        return new Promise(() => {});
      }
      document.activeElement.blur();
      this.$setBusy(true);
      var result = contentService
        .getContent(route?.fullPath)
        .then((response) => {
          if (response.statusCode === 200) {
            localStorage.setItem("x-language", response.culture);
            const html = document.documentElement;
            html.setAttribute("lang", response.culture.substr(0, 2));
            this.tmp = response;
            var aliases = [
              "login",
              "registerProducts",
              "register",
              "forgotPassword",
              "resetPassword",
            ];
            if (authService.getUser() && aliases.includes(this.tmp.content.alias)) {
              return "/";
            }
            this.loadingContent = false;
          } else if (response.statusCode === 307) {
            let gotoRoute = this.$router.resolve(response.content.urlTo).route;
            if (gotoRoute.fullPath === this.$route.fullPath) {
              gotoRoute.query.rdirhash = new Date().getMilliseconds();
            }
            this.$router.push(gotoRoute);
          } else if (response.statusCode === 404) {
            this.tmp = response;
          } else if (response.statusCode === 401) {
            this.tmp = response;
          } else if (response.statusCode == 500) {
            this.tmp = response;
          }
          // this.$setBusy(false);
          return true;
        })
        .catch((error) => {
          console.error(error);
          // this.$setBusy(false);
          return true;
        });
      return result;
    },
    CreateAnalytics(analytics) {
      if (analytics) {
        var temp = getCookie("zxasdeqetf");
        const now = new Date();
        now.setDate(now.getDate() + 360);
        if (temp) {
          if (
            analytics.dimension1 != "brak danych" &&
            temp != this.tmp.compositions.analytics.dimension1
          ) {
            setCookie("zxasdeqetf", analytics.dimension1, {
              expires: now.toGMTString(),
            });
          }
        } else {
          setCookie("zxasdeqetf", null, {
            expires: new Date(),
          });
        }
        var dimenssion = {
          contentGroup1: analytics.contentGroup1,
          contentGroup2: analytics.contentGroup2,
          contentGroup3: analytics.contentGroup3,
          dimension1: analytics.dimension1,
          dimension2: analytics.dimension2,
          dimension3: analytics.dimension3,
          dimension4: analytics.dimension4,
          dimension5: analytics.dimension5,
          dimension6: analytics.dimension6,
          dimension7: analytics.dimension7,
          dimension8: analytics.dimension8,
          dimension9: analytics.dimension9,
          dimension10: analytics.dimension10,
          dimension11: analytics.dimension11,
          dimension12: analytics.dimension12,
          dimension13: analytics.dimension13,
          dimension14: analytics.dimension14,
          dimension15: analytics.dimension15,
          dimension16: analytics.dimension16,
          dimension17: analytics.dimension17,
          dimension18: analytics.dimension18,
          custom_device: this.$browserDetect.source,
        };
        this.$setDataLayer(dimenssion);
      }
    },
    setupMetaData(response) {
      if (response) {
        if (response.seo) {
          document.title = response.seo.metaTitle + " - 35mm.online";
          this.updateTag("og:title", response.seo.metaTitle);
          // this.updateTag("og:title", response.seo.metaTitle);
          //   this.seo.metaTitle = response.seo.metaTitle;
          //   this.seo.metaData = response.seo.metaData;
          //   this.seo.metaLinks = response.seo.metaLinks;
        } else document.title = response.statusCode;
        if (response.analytics) this.CreateAnalytics(response.analytics);
      } else document.title = response.statusCode;
      this.$emit("updateHead");
    },
    emmitEvent() {
      EventBus.$emit("closeNavEvent");
    },
    CheckIsTV() {
      if (this.isTV) {
        this.addScript(process.env.VUE_APP_SMARTTV_JS_URL);
        if (this.$osDetect.isTizen) {
          this.addScript("$WEBAPIS/webapis/webapis.js");
        }
        this.activeNavigation();
      }
      if (this.$osDetect.isAndroid && !this.resizeInit) {
        window.addEventListener("resize", this.resize, true);
        this.resizeInit = true;
      }
    },
    CheckIsBrowser() {
      if (!this.$browserDetect.isApp) {
        this.addScript(
          process.env.VUE_APP_COOKIEBOT,
          "Cookiebot",
          process.env.VUE_APP_CBID
        );
        this.addScript(process.env.VUE_APP_COOKIEDECLARATION, "CookieDeclaration");
        this.filtrCookieBot();
      }
    },
    addScript(scriptLocation, id, cbid) {
      let widgetScript = document.createElement("script");
      widgetScript.setAttribute("src", scriptLocation);
      if (id) {
        widgetScript.async = true;
        widgetScript.setAttribute("id", id);
        widgetScript.setAttribute("type", "text/javascript");
        if (cbid) {
          widgetScript.setAttribute("data-cbid", cbid);
          widgetScript.setAttribute("data-blockingmode", "auto");
        }
      }
      document.head.appendChild(widgetScript);
    },
    activeNavigation() {
      if (!window.RedgalaxyNav && window.RedgalaxySmartTVIframeRemote) {
        this.style = {
          "font-size": "1.82em",
        };
        var selectors = [
          ".footer__element",
          ".link",
          ".button",
          ".rgp-widget__button",
          ".focus-smart",
          ".nav-link",
          ".btn",
          ".form-control",
          ".expandable-image",
          ".galleryBtn",
          ".page-link",
          ".custom-checkbox:not(.disable) > label",
          ".custom-radio:not(.disable) > label",
          ".logo-link",
          ".dropdown-item",
          ".rgp-widget-payments__schedules-option",
          ".rgp-widget-payments__price",
          ".rgp-widget-payments__close",
          ".rgp-widget-payments__input",
          ".close",
        ];

        window.RedgalaxyNav = new window.RedgalaxySmartTVIframeRemote({
          focusSelector: selectors.join(", "),
          focusClassName: "smarttv-focused",
        });
      } else {
        setTimeout(() => {
          this.activeNavigation();
        }, 100);
      }
      return;
    },
    navigation(to) {
      var temp = this.reloadContent(to).then((value) => {
        return value;
      });
      return temp;
    },
    checkImage(a) {
      if (a && a.src) {
        let el = this.findAncestor(a.el, "carouselBig");
        let index = a.src.indexOf("?");
        if (el && index >= 0) {
          a.src = a.src.slice(0, a.src.indexOf("?"));
          this.altText = this.model.alt + " " + this.title + " " + this.textOpen;
        }
      }
    },
    findAncestor(el, cls) {
      let item = el;
      while ((item = item.parentElement) && !item.classList.contains(cls));
      return item;
    },
    updateTag(propertyValue, contentValue) {
      var meta = document.querySelectorAll(`[property="${propertyValue}"]`)[0];
      if (!meta) {
        meta = document.createElement("meta");
        meta.setAttribute("property", propertyValue);
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
      meta.content = contentValue;
    },
    async getSeo(to) {
      var testy = await contentService.getSeo(to);
      this.setupMetaData(testy);
    },
  },
  watch: {
    test() {
      // if (value > 1) this.model = null;
      // else
      this.model = this.tmp;
      this.tmp = null;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>
<style lang="scss">
.devLog {
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  color: red;
  height: 300px;
  width: 300px;
  z-index: 1009;
  overflow: auto;
  font-size: 12px;
}

.footer__element,
.link,
.button,
.rgp-widget__button,
.focus-smart,
.nav-link,
.btn,
.form-control,
.expandable-image,
.galleryBtn,
.page-link,
.custom-checkbox > .custom-control-label,
.logo-link,
.dropdown-item {
  // color: green;
  // background: red;
  // &:not(.lock) {
  //   color: red !important;
  //   background: green !important;
  // }
}
</style>

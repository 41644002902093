<template>
  <div class="quiz text-center">
    <intro
      v-if="!showQuestions"
      :model="model.intro"
      @start="startQuiz"
    ></intro>
    <questions v-if="showQuestions" :model="model.questions"></questions>
  </div>
</template>

<script>
import Intro from "@/components/Quiz/Intro";
import Questions from "@/components/Quiz/Questions";

export default {
    name: "Quiz",
    components: {
        Questions,
        Intro
  },
  props: {
    model: Object,
  },
  data() {
    return {
      showQuestions: false,
    };
  },
  methods: {
    startQuiz(value) {
      this.showQuestions = value;
    },
  },
};
</script>

<style lang="scss">
.quiz {
  padding: 40px 15px;
}
</style>
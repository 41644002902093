<template>
  <div class="proForm">
    <b-container fluid v-if="$isTizen($browserDetect)">
      <b-row>
        <b-col class="header">
          <h1 class="text-center">
            {{ model.translations["proOrder.Title"] }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col class="text-center done">
          <tv-qr-code
            :message="model.translations['general.QrCodeInfo']"
          ></tv-qr-code>
        </b-col>
      </b-row>
    </b-container>
    <div v-else>
      <b-container fluid v-if="done">
        <b-row>
          <b-col class="header">
            <h1 class="text-center">
              {{ model.translations["proOrder.Title"] }}
            </h1>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="text-center done">
            {{ model.translations["proOrder.SendSucceeded"] }}
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--reg"
              @click="$router.push('/')"
              >{{ model.translations["proOrder.GoToAfterSend"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-if="!done">
        <!-- header -->
        <b-row>
          <b-col class="header">
            <h1 class="text-center">
              {{ model.translations["proOrder.Title"] }}
            </h1>
          </b-col>
        </b-row>
        <!-- progresBar -->
        <progres-bar
          ref="scroll"
          :current="current"
          :translations="model.translations"
          :items="submitValue.licenseList"
          @ChangeValue="getValue"
        ></progres-bar>
        <!-- Dane Odbiocy -->
        <licensee-details
          @ChangeValue="getValue"
          :translations="model.translations"
          :readonly="true"
        ></licensee-details>
        <pro-summary
          v-if="showSummary"
          :submitValue="submitValue"
          :translations="model.translations"
          :model="model"
          @sendOk="onSendOk"
          @sendFailed="onSendFailed"
          @ChangeValue="getValue"
        >
        </pro-summary>
        <!-- Wybrany Filmy -->
        <movie-selector
          v-if="!showSummary"
          :translations="model.translations"
          @ChangeValue="getValue"
          :selectedAssets="currentLicense.assets"
        ></movie-selector>
        <!-- Szczegóły licencji -->
        <transition
          name="slide-fade"
          v-if="
            !showSummary &&
            currentLicense.assets &&
            currentLicense.assets.length > 0
          "
        >
          <b-row>
            <b-col>
              <div class="white-two m30">
                <b-container>
                  <b-row class="progressBar">
                    <b-col :sm="10">
                      <h2>
                        {{ model.translations["proOrder.LicenseDetails"] }}
                      </h2>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="form">
                <b-container fluid>
                  <!-- zamawiam -->
                  <radio-buttons
                    :header="model.translations['proOrder.IOrder']"
                    :itemList="model.orderType"
                    :select="currentLicense.orderType"
                    name="orderType"
                    @ChangeValue="getValue"
                  ></radio-buttons>
                  <!-- cały film -->
                  <div
                    v-if="
                      (currentLicense.orderType &&
                        currentLicense.orderType == 'asset.full') ||
                      true
                    "
                  >
                    <transition name="slide-fade">
                      <full-movie
                        v-if="currentLicense.orderType == 'asset.full'"
                        :model="model"
                        @ChangeValue="getValue"
                        :current="currentLicense.full"
                      ></full-movie>
                      <part-movie
                        v-else-if="currentLicense.orderType == 'asset.segments'"
                        :model="model"
                        @ChangeValue="getValue"
                        :current="currentLicense.part"
                      ></part-movie>
                      <frame-movie
                        v-else-if="currentLicense.orderType == 'asset.frames'"
                        :model="model"
                        @ChangeValue="getValue"
                        :current="currentLicense.frame"
                      ></frame-movie>
                      <section-header
                        :header="model.translations['proOrder.Pending']"
                        v-if="!currentLicense.orderType"
                      ></section-header>
                      ></transition
                    >
                  </div>
                </b-container>
              </div>
            </b-col>
          </b-row>
        </transition>
        <!--buttons -->
        <b-row v-if="!showSummary">
          <b-col>
            <div class="form">
              <b-container>
                <b-row class="progressBar buttons">
                  <b-col :sm="9" class="text-center">
                    <!-- <b-button>
                    {{ model.translations["proOrder.SaveOrder"] }}</b-button
                  > -->
                    <b-button
                      @click="addLicense()"
                      :disabled="
                        submitValue.licenseList.length > 2 ||
                        !isCurrentLicenseValid
                      "
                    >
                      {{ model.translations["proOrder.Nextlicense"] }}</b-button
                    >
                    <b-button
                      @click="showSummary = true"
                      :disabled="showSummary || !isCurrentLicenseValid"
                    >
                      {{ model.translations["proOrder.GoSummary"] }}</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-col>
        </b-row>
        <!-- progresBar -->
        <progres-bar
          :current="current"
          :translations="model.translations"
          :items="submitValue.licenseList"
          @ChangeValue="getValue"
        ></progres-bar>
      </b-container>
      <modal
        header="Pro"
        :title="errorMessage"
        :show="hasError"
        :closeText="closeText"
        @hidePop="closeModal"
      >
      </modal>
    </div>
  </div>
</template>
<script>
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
export default {
  components: {
    RadioButtons:()=>import( "../components/ProForm/RadioButtons.vue"),
    ProgresBar:()=>import("../components/ProForm/ProgresBar.vue"),
    LicenseeDetails:()=>import( "../components/ProForm/LicenseeDetails.vue"),
    MovieSelector:()=>import("../components/ProForm/MovieSelector.vue"),
    FullMovie:()=>import("../components/ProForm/FullMovie.vue"),
    PartMovie:()=>import("../components/ProForm/PartMovie.vue"),
    FrameMovie:()=>import("../components/ProForm/FrameMovie.vue"),
    ProSummary:()=>import("@/components/ProForm/ProSummary.vue"),
    SectionHeader:()=>import("@/components/ProForm/SectionHeader.vue"),
    Modal:()=>import("../components/common/modal.vue"),
    TvQrCode:()=>import("../components/common/TvQrCode.vue"),
  },
  mixins: [FixedAlertMixin],
  data() {
    return {
      url: location.href,
      done: false,
      hasError: false,
      errorMessage: " ",
      currentLicense: {
        index: 0,
        orderType: null,
        assets: [],
        full: {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          place: null,
          audience: null,
          countShows: null,
          dates: [null],
          characterShowChecked: null,
          characterShowValue: null,
          typeShow: null,
          showName: null,
          typeEvent: null,
          exclusivity: null,
          EmissionDateBegin: null,
          EmissionDateEnd: null,
          emissionCount: null,
          reEmissionCount: null,
          emisionType: [],
          transmissionModel: [],
          carrier: [],
          carrierInput: [],
          publishing: [],
          collectionName: null,
          characterPublishing: null,
          vodPlatform: [],
          distribution: [],
          fileSpeed: null,
          fileSoundWersion: null,
          fileWcagPl: null,
          langCheck: [],
          movieLang: [],
          filesFormat: [],
          filesFormatInput: [],
          notes: null,
        },
        part: {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          partShowPlace: null,
          partEventType: null,
          eventName: null,
          channelName: null,
          tvProgram: null,
          movieType: null,
          distribution: [],
          accessType: null,
          publishingType: null,
          publishingTypeInput: null,
          publishPlace: null,
          publishSubject: null,
          publishSubjectInput: null,
          urls: [null],
          fileSoundWersion: null,
          fileSpeed: null,
          filesFormat: [],
          preParingFragment: null,
        },
        frame: {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          accessType: null,
          publishingType: null,
          publishingTypeInput: null,
          publishPlace: null,
          publishSubject: null,
          publishSubjectInput: null,
          urls: [null],
          language: [],
          count: null,
          distribution: [],
          publishType: null,
          publishTypeInput: null,
          printType: null,
          destyny: null,
          filesFormat: [],
        },
      },
      submitValue: {
        inputs: {
          companyName: "",
          nIP: "",
          rIK: "",
          firstName: "",
          lastName: "",
          eMail: "",
          phone: "",
        },
        licenseList: [null],
      },
      current: 0,
    };
  },
  props: {
    model: Object,
    closeText: String,
  },
  methods: {
    closeModal() {
      this.hasError = false;
    },
    onSendOk() {
      this.hasError = false;
      this.done = true;
      window.scrollTo(0, 0);
    },
    onSendFailed() {
      this.hasError = true;
      this.errorMessage = this.model.translations["proOrder.SendFailed"];
    },
    delLicense(index) {
      if (!this.showSummary) this.current = index == 0 ? index : index - 1;
      this.submitValue.licenseList.splice(index, 1);
    },
    addLicense() {
      this.current = this.submitValue.licenseList.length;
      this.submitValue.licenseList.push({
        index: 0,
        orderType: null,
        assets: [],
        full: {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          place: null,
          audience: null,
          countShows: null,
          dates: [null],
          characterShowChecked: null,
          characterShowValue: null,
          typeShow: null,
          showName: null,
          typeEvent: null,
          exclusivity: null,
          EmissionDateBegin: null,
          EmissionDateEnd: null,
          emissionCount: null,
          reEmissionCount: null,
          emisionType: [],
          transmissionModel: [],
          carrier: [],
          carrierInput: [],
          publishing: [],
          collectionName: null,
          characterPublishing: null,
          vodPlatform: [],
          distribution: [],
          fileSpeed: null,
          fileSoundWersion: null,
          fileWcagPl: null,
          langCheck: [],
          movieLang: [],
          filesFormat: [],
          filesFormatInput: [],
          notes: null,
        },
        part: {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          partShowPlace: null,
          partEventType: null,
          eventName: null,
          channelName: null,
          tvProgram: null,
          movieType: null,
          distribution: [],
          accessType: null,
          publishingType: null,
          publishingTypeInput: null,
          publishPlace: null,
          publishSubject: null,
          publishSubjectInput: null,
          urls: [null],
          fileSoundWersion: null,
          fileSpeed: null,
          filesFormat: [],
          preParingFragment: null,
        },
        frame: {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          accessType: null,
          publishingType: null,
          publishingTypeInput: null,
          publishPlace: null,
          publishSubject: null,
          publishSubjectInput: null,
          urls: [null],
          language: [],
          count: null,
          distribution: [],
          publishType: null,
          publishTypeInput: null,
          printType: null,
          destyny: null,
          filesFormat: null,
        },
      });
    },
    updateLicense(index, newValue) {
      if (index < this.submitValue.licenseList.length) {
        this.submitValue.licenseList[index] = {
          index: this.currentLicense.index,
          orderType: this.currentLicense.orderType,
          assets: this.currentLicense.assets,
          full: {
            licenseType: this.currentLicense.full.licenseType,
            countries: this.currentLicense.full.countries,
            continent: this.currentLicense.full.continent,
            checkedFiled: this.currentLicense.full.checkedFiled,
            place: this.currentLicense.full.place,
            audience: this.currentLicense.full.audience,
            countShows: this.currentLicense.full.countShows,
            dates: this.currentLicense.full.dates,
            characterShowChecked: this.currentLicense.full.characterShowChecked,
            characterShowValue: this.currentLicense.full.characterShowValue,
            typeShow: this.currentLicense.full.typeShow,
            showName: this.currentLicense.full.showName,
            typeEvent: this.currentLicense.full.typeEvent,
            exclusivity: this.currentLicense.full.exclusivity,
            EmissionDateBegin: this.currentLicense.full.EmissionDateBegin,
            EmissionDateEnd: this.currentLicense.full.EmissionDateEnd,
            emissionCount: this.currentLicense.full.emissionCount,
            reEmissionCount: this.currentLicense.full.reEmissionCount,
            emisionType: this.currentLicense.full.emisionType,
            transmissionModel: this.currentLicense.full.transmissionModel,
            carrier: this.currentLicense.full.carrier,
            carrierInput: this.currentLicense.full.carrierInput,
            publishing: this.currentLicense.full.publishing,
            collectionName: this.currentLicense.full.collectionName,
            characterPublishing: this.currentLicense.full.characterPublishing,
            vodPlatform: this.currentLicense.full.vodPlatform,
            distribution: this.currentLicense.full.distribution,
            fileSpeed: this.currentLicense.full.fileSpeed,
            fileSoundWersion: this.currentLicense.full.fileSoundWersion,
            fileWcagPl: this.currentLicense.full.fileWcagPl,
            langCheck: this.currentLicense.full.langCheck,
            movieLang: this.currentLicense.full.movieLang,
            filesFormat: this.currentLicense.full.filesFormat,
            filesFormatInput: this.currentLicense.full.filesFormatInput,
            notes: this.currentLicense.full.notes,
          },
          part: {
            licenseType: this.currentLicense.part.licenseType,
            countries: this.currentLicense.part.countries,
            continent: this.currentLicense.part.continent,
            checkedFiled: this.currentLicense.part.checkedFiled,
            partShowPlace: this.currentLicense.part.partShowPlace,
            partEventType: this.currentLicense.part.partEventType,
            eventName: this.currentLicense.part.eventName,
            channelName: this.currentLicense.part.channelName,
            tvProgram: this.currentLicense.part.tvProgram,
            movieType: this.currentLicense.part.movieType,
            distribution: this.currentLicense.part.distribution,
            accessType: this.currentLicense.part.accessType,
            publishingType: this.currentLicense.part.publishingType,
            publishingTypeInput: this.currentLicense.part.publishingTypeInput,
            publishPlace: this.currentLicense.part.publishPlace,
            publishSubject: this.currentLicense.part.publishSubject,
            publishSubjectInput: this.currentLicense.part.publishSubjectInput,
            urls: this.currentLicense.part.urls,
            fileSoundWersion: this.currentLicense.part.fileSoundWersion,
            fileSpeed: this.currentLicense.part.fileSpeed,
            filesFormat: this.currentLicense.part.filesFormat,
            preParingFragment: this.currentLicense.part.preParingFragment,
            notes: this.currentLicense.part.notes,
          },
          frame: {
            licenseType: this.currentLicense.frame.licenseType,
            countries: this.currentLicense.frame.countries,
            continent: this.currentLicense.frame.continent,
            checkedFiled: this.currentLicense.frame.checkedFiled,
            accessType: this.currentLicense.frame.accessType,
            publishingType: this.currentLicense.frame.publishingType,
            publishingTypeInput: this.currentLicense.frame.publishingTypeInput,
            publishPlace: this.currentLicense.frame.publishPlace,
            publishSubject: this.currentLicense.frame.publishSubject,
            publishSubjectInput: this.currentLicense.frame.publishSubjectInput,
            urls: this.currentLicense.frame.urls,
            language: this.currentLicense.frame.language,
            count: this.currentLicense.frame.count,
            distribution: this.currentLicense.frame.distribution,
            publishType: this.currentLicense.frame.publishType,
            publishTypeInput: this.currentLicense.frame.publishTypeInput,
            printType: this.currentLicense.frame.printType,
            destyny: this.currentLicense.frame.destyny,
            filesFormat: this.currentLicense.frame.filesFormat,
            notes: this.currentLicense.frame.notes,
          },
        };
      }
      if (this.submitValue.licenseList.length > newValue) {
        var temp = this.submitValue.licenseList[newValue];
        this.currentLicense.index = temp.index;
        this.currentLicense.orderType = temp.orderType;
        this.currentLicense.assets = temp.assets;
        var full = temp.full;
        if (full) {
          this.currentLicense.full = {
            licenseType: full.licenseType,
            countries: full.countries,
            continent: full.continent,
            checkedFiled: full.checkedFiled,
            place: full.place,
            audience: full.audience,
            countShows: full.countShows,
            dates: full.dates,
            characterShowChecked: full.characterShowChecked,
            characterShowValue: full.characterShowValue,
            typeShow: full.typeShow,
            showName: full.showName,
            typeEvent: full.typeEvent,
            exclusivity: full.exclusivity,
            EmissionDateBegin: full.EmissionDateBegin,
            EmissionDateEnd: full.EmissionDateEnd,
            emissionCount: full.emissionCount,
            reEmissionCount: full.reEmissionCount,
            emisionType: full.emisionType,
            transmissionModel: full.transmissionModel,
            carrier: full.carrier,
            carrierInput: full.carrierInput,
            publishing: full.publishing,
            collectionName: full.collectionName,
            characterPublishing: full.characterPublishing,
            vodPlatform: full.vodPlatform,
            distribution: full.distribution,
            fileSpeed: full.fileSpeed,
            fileSoundWersion: full.fileSoundWersion,
            fileWcagPl: full.fileWcagPl,
            langCheck: full.langCheck,
            movieLang: full.movieLang,
            filesFormat: full.filesFormat,
            filesFormatInput: full.filesFormatInput,
            notes: full.notes,
          };
        } else {
          this.currentLicense.full = {
            licenseType: null,
            countries: [],
            continent: [],
            checkedFiled: [],
            place: null,
            audience: null,
            countShows: null,
            dates: [null],
            characterShowChecked: null,
            characterShowValue: null,
            typeShow: null,
            showName: null,
            typeEvent: null,
            exclusivity: null,
            EmissionDateBegin: null,
            EmissionDateEnd: null,
            emissionCount: null,
            reEmissionCount: null,
            emisionType: [],
            transmissionModel: [],
            carrier: [],
            carrierInput: [],
            publishing: [],
            collectionName: null,
            characterPublishing: null,
            vodPlatform: [],
            distribution: [],
            fileSpeed: null,
            fileSoundWersion: null,
            fileWcagPl: null,
            langCheck: [],
            movieLang: [],
            filesFormat: [],
            filesFormatInput: [],
            notes: null,
          };
        }
        var part = temp.part;
        if (part) {
          this.currentLicense.part = {
            licenseType: part.licenseType,
            countries: part.countries,
            continent: part.continent,
            checkedFiled: part.checkedFiled,
            partShowPlace: part.partShowPlace,
            partEventType: part.partEventType,
            eventName: part.eventName,
            channelName: part.channelName,
            tvProgram: part.tvProgram,
            movieType: part.movieType,
            distribution: part.distribution,
            accessType: part.accessType,
            publishingType: part.publishingType,
            publishingTypeInput: part.publishingTypeInput,
            publishPlace: part.publishPlace,
            publishSubject: part.publishSubject,
            publishSubjectInput: part.publishSubjectInput,
            urls: part.urls,
            fileSoundWersion: part.fileSoundWersion,
            fileSpeed: part.fileSpeed,
            filesFormat: part.filesFormat,
            preParingFragment: part.preParingFragment,
            notes: part.notes,
          };
        } else {
          this.currentLicense.part = {
            licenseType: null,
            countries: [],
            continent: [],
            checkedFiled: [],
            partShowPlace: null,
            partEventType: null,
            eventName: null,
            channelName: null,
            tvProgram: null,
            movieType: null,
            distribution: [],
            accessType: null,
            publishingType: null,
            publishingTypeInput: null,
            publishPlace: null,
            publishSubject: null,
            publishSubjectInput: null,
            urls: [null],
            fileSoundWersion: null,
            fileSpeed: null,
            filesFormat: [],
            preParingFragment: null,
            notes: null,
          };
        }
        var frame = temp.frame;
        if (frame) {
          this.currentLicense.frame = {
            licenseType: frame.licenseType,
            countries: frame.countries,
            continent: frame.continent,
            checkedFiled: frame.checkedFiled,
            accessType: frame.accessType,
            publishingType: frame.publishingType,
            publishingTypeInput: frame.publishingTypeInput,
            publishPlace: frame.publishPlace,
            publishSubject: frame.publishSubject,
            publishSubjectInput: frame.publishSubjectInput,
            urls: frame.urls,
            language: frame.language,
            count: frame.count,
            distribution: frame.distribution,
            publishType: frame.publishType,
            publishTypeInput: frame.publishTypeInput,
            printType: frame.printType,
            destyny: frame.destyny,
            filesFormat: frame.filesFormat,
            notes: frame.notes,
          };
        } else {
          this.current.frame = {
            licenseType: null,
            countries: [],
            continent: [],
            checkedFiled: [],
            accessType: null,
            publishingType: null,
            publishingTypeInput: null,
            publishPlace: null,
            publishSubject: null,
            publishSubjectInput: null,
            urls: [null],
            language: [],
            count: null,
            distribution: [],
            publishType: null,
            publishTypeInput: null,
            printType: null,
            destyny: null,
            filesFormat: null,
            notes: null,
          };
        }
      } else {
        this.currentLicense.index = newValue;
        this.currentLicense.orderType = null;
        this.currentLicense.assets = [];
        this.currentLicense.full = {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          place: null,
          audience: null,
          countShows: null,
          dates: [null],
          characterShowChecked: null,
          characterShowValue: null,
          typeShow: null,
          showName: null,
          typeEvent: null,
          exclusivity: null,
          EmissionDateBegin: null,
          EmissionDateEnd: null,
          emissionCount: null,
          reEmissionCount: null,
          emisionType: [],
          transmissionModel: [],
          carrier: [],
          carrierInput: [],
          publishing: [],
          collectionName: null,
          characterPublishing: null,
          vodPlatform: [],
          distribution: [],
          fileSpeed: null,
          fileSoundWersion: null,
          fileWcagPl: null,
          langCheck: [],
          movieLang: [],
          filesFormat: [],
          filesFormatInput: [],
          notes: null,
        };
        this.currentLicense.part = {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          partShowPlace: null,
          partEventType: null,
          eventName: null,
          channelName: null,
          tvProgram: null,
          movieType: null,
          distribution: [],
          accessType: null,
          publishingType: null,
          publishingTypeInput: null,
          publishPlace: null,
          publishSubject: null,
          publishSubjectInput: null,
          urls: [null],
          fileSoundWersion: null,
          fileSpeed: null,
          filesFormat: [],
          preParingFragment: null,
          notes: null,
        };
        this.currentLicense.frame = {
          licenseType: null,
          countries: [],
          continent: [],
          checkedFiled: [],
          accessType: null,
          publishingType: null,
          publishingTypeInput: null,
          publishPlace: null,
          publishSubject: null,
          publishSubjectInput: null,
          urls: [null],
          language: [],
          count: null,
          distribution: [],
          publishType: null,
          publishTypeInput: null,
          printType: null,
          destyny: null,
          filesFormat: null,
          notes: null,
        };
      }
    },
    getValue(value, type) {
      switch (type) {
        case "orderType":
          this.currentLicense.orderType = value;
          break;
        case "inputs":
          this.submitValue.inputs = value;
          break;
        case "delete":
          this.delLicense(value);
          break;
        case "progress":
          this.current = value;
          break;
        case "full":
          this.currentLicense.full = {
            licenseType: value.licenseType,
            countries: value.countries,
            continent: value.continent,
            checkedFiled: value.checkedFiled,
            place: value.place,
            audience: value.audience,
            countShows: value.countShows,
            dates: value.dates,
            characterShowChecked: value.characterShowChecked,
            characterShowValue: value.characterShowValue,
            typeShow: value.typeShow,
            showName: value.showName,
            typeEvent: value.typeEvent,
            exclusivity: value.exclusivity,
            EmissionDateBegin: value.EmissionDateBegin,
            EmissionDateEnd: value.EmissionDateEnd,
            emissionCount: value.emissionCount,
            reEmissionCount: value.reEmissionCount,
            emisionType: value.emisionType,
            transmissionModel: value.transmissionModel,
            carrier: value.carrier,
            carrierInput: value.carrierInput,
            publishing: value.publishing,
            collectionName: value.collectionName,
            characterPublishing: value.characterPublishing,
            vodPlatform: value.vodPlatform,
            distribution: value.distribution,
            fileSpeed: value.fileSpeed,
            fileSoundWersion: value.fileSoundWersion,
            fileWcagPl: value.fileWcagPl,
            langCheck: value.langCheck,
            movieLang: value.movieLang,
            filesFormat: value.filesFormat,
            filesFormatInput: value.filesFormatInput,
            notes: value.notes,
          };
          break;
        case "part":
          this.currentLicense.part = {
            licenseType: value.licenseType,
            countries: value.countries,
            continent: value.continent,
            checkedFiled: value.checkedFiled,
            partShowPlace: value.partShowPlace,
            partEventType: value.partEventType,
            eventName: value.eventName,
            channelName: value.channelName,
            tvProgram: value.tvProgram,
            movieType: value.movieType,
            distribution: value.distribution,
            accessType: value.accessType,
            publishingType: value.publishingType,
            publishingTypeInput: value.publishingTypeInput,
            publishPlace: value.publishPlace,
            publishSubject: value.publishSubject,
            publishSubjectInput: value.publishSubjectInput,
            urls: value.urls,
            fileSoundWersion: value.fileSoundWersion,
            fileSpeed: value.fileSpeed,
            filesFormat: value.filesFormat,
            preParingFragment: value.preParingFragment,
            notes: value.notes,
          };
          break;
        case "assets":
          this.currentLicense.assets = value;
          break;
        case "frame":
          this.currentLicense.frame = {
            licenseType: value.licenseType,
            countries: value.countries,
            continent: value.continent,
            checkedFiled: value.checkedFiled,
            accessType: value.accessType,
            publishingType: value.publishingType,
            publishingTypeInput: value.publishingTypeInput,
            publishPlace: value.publishPlace,
            publishSubject: value.publishSubject,
            publishSubjectInput: value.publishSubjectInput,
            urls: value.urls,
            language: value.language,
            count: value.count,
            distribution: value.distribution,
            publishType: value.publishType,
            publishTypeInput: value.publishTypeInput,
            printType: value.printType,
            destyny: value.destyny,
            filesFormat: value.filesFormat,
            notes: value.notes,
          };
          break;
      }
    },
  },
  watch: {
    current(newValue, oldValue) {
      this.updateLicense(oldValue, newValue);
      if (this.$refs.scroll != undefined)
        this.$scrollTo(this.$refs.scroll, 700, { offset: 0 });
    },
  },
  computed: {
    showSummary: {
      get: function () {
        return this.current >= this.submitValue.licenseList.length;
      },
      set: function () {
        this.current = this.submitValue.licenseList.length;
      },
    },
    isCurrentLicenseValid: function () {
      var ret =
        (this.currentLicense.orderType == "asset.full" &&
          this.currentLicense.full.filesFormat &&
          this.currentLicense.full.filesFormat.length > 0 &&
          this.currentLicense.full.filesFormat[0].length > 0) ||
        (this.currentLicense.orderType == "asset.segments" &&          
          (this.currentLicense.part.licenseType == 'usecase.tv' && this.currentLicense.part.tvProgram)
          || (
            this.currentLicense.part.licenseType != 'usecase.tv' &&
            this.currentLicense.part.filesFormat &&
            this.currentLicense.part.filesFormat.length > 0)) ||          
        (this.currentLicense.orderType == "asset.frames" && (          
          (this.currentLicense.frame.licenseType == 'usecase.internet' &&
            this.currentLicense.frame.accessType) ||
          (this.currentLicense.frame.licenseType == 'usecase.paper' &&
            this.currentLicense.frame.publishSubject) ||
          (this.currentLicense.frame.licenseType == 'usecase.poster' &&
            this.currentLicense.frame.destyny)                    
          ));
      return ret;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.proForm {
  color: $color-main;
  padding-top: 89px;
  font-family: $font-family-wotfard;
  .checkReadonly label {
    color: white !important;
    &::before {
      background-color: white !important;
    }
  }
  .col {
    margin-bottom: 0;
  }
  .header {
    h1 {
      font-size: em(28);
      margin-bottom: 29px;
    }
  }
  .gray {
    background: $color-gray;

    .etap {
      padding: 8px 50px;
      display: inline-block;
      color: $color-black;
      position: relative;
      background: #888888;
      flex: 1 1 0px;
      text-align: center;
      font-weight: em(20);
      a {
        color: $color-black;
      }
      &:after {
        content: "";
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid #888888;
        position: absolute;
        top: 0;
        right: -20px;
        z-index: 1;
      }
      &.active {
        background: $color-main;
        &:after {
          border-left: 20px solid $color-main;
          background: #888888;
        }
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
  .white {
    background: $color-main;
    margin-bottom: 46px;
    h2 {
      color: $color-black;
    }
  }
  .white-two {
    background: $color-light-gray;
    margin-bottom: 46px;
    h2 {
      color: $color-black;
    }
    &.m30 {
      margin-bottom: 30px;
    }
  }
  .white,
  .gray,
  .white-two {
    .progressBar {
      align-items: center;
      justify-content: center;
      h2 {
        font-size: em(20);
        margin: 8px 0;
      }
      .col-sm-10 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
      }
    }
  }
  .process {
    .progressBar {
      margin-bottom: 35px;
    }
  }
  .form {
    .progressBar {
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      padding: 0 30px;
      .col-sm-5,
      .col-sm-10,
      .col-sm-3,
      .col-sm-6,
      .col-sm-9 {
        margin-bottom: 0;
      }
      .card {
        border: none;
        .card-header {
          font-weight: $font-weight-medium;
          .btn {
            border-bottom: 1px solid $color-main;
          }
        }
        .card-text {
          .info {
            font-weight: $font-weight-bold;
            margin-bottom: 15px;
            p {
              font-size: em(14);
              line-height: 1.5;
              &.person {
                :first-child {
                  // margin-right: 5px;
                  &:after {
                    content: " ";
                  }
                }
              }
              &.split {
                :first-child {
                  padding-right: 20px;
                  border-right: 2px solid;
                }
                :last-child {
                  padding-left: 20px;
                }
              }
            }
          }
          .desc {
            font-size: em(16);
            line-height: 1.5;
            font-weight: $font-weight-regular;
          }
        }
      }
      &.buttons {
        .col-sm-9,
        .col-sm-4 {
          display: flex;
          flex-wrap: wrap;
          .btn {
            flex: 1 1 0px;
            margin: 0 5px;
            background: white;
            color: black;
            display: inline-block;
          }
        }
      }
      .form-group {
        margin-bottom: 0;
        .col-form-label {
          font-size: em(14);
        }
      }
      &:last-child {
        margin-bottom: 49px;
      }
      h3 {
        font-size: em(18);
        margin-top: 9px;
        margin-bottom: 9px;
      }
      &.details-header {
        justify-content: flex-end;
        padding: 0;
        .col-sm-10 {
          background: $color-gray;
          padding: 0 50px;
        }
      }
      .hint {
        font-style: italic;
        color: gray;
        padding-left: 3em;
      }
      .chars-status {
        color: gray;
        font-size: em(12);
      }
      .custom-file {
        &.is-valid .custom-file-label {
          color: $color-main;
        }
        &.is-invalid .custom-file-label {
          color: gray;
        }
        .custom-file-label {
          font-size: em(14);
          padding-top: 7px;
          &:after {
            color: black;
            padding-top: 7px;
          }
        }
      }
      .custom-radio .custom-control-label {
        padding-left: 0.9em !important;
      }
      .custom-checkbox {
        padding-left: 0.2em !important;
      }
      .custom-control,
      .custom-radio {
        display: block;
        position: relative;
        margin-bottom: 11px;
        .custom-control-label {
          font-size: em(18);
          font-weight: $font-weight-medium;
          &:before,
          &:after {
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }      
      &.m40 {
        margin-bottom: 40px;
        justify-content: flex-end;
      }
    }
    .selected {
      .custom-radio {
        color: gray;
      }
      .active {
        color: $color-main;
        .custom-radio {
          color: $color-main;
        }
        .selected {
          .custom-radio {
            color: gray;
          }
          .active {
            color: $color-main;
          }
        }
      }
    }
    .short {
      max-width: 400px;
      margin-left: 2.9em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      legend,
      label {
        font-weight: $font-weight-medium;
        font-size: em(18);
      }
      &.m24 {
        margin-top: 24px;
      }
    }
    .b-form-tags {
      &:focus-within {
        box-shadow: inset 0px 0px 0px 1px $color-main;
      }
      input[type="text"] {
        color: $color-main;
      }
    }
    .hide-tags-add {
      .b-form-tags-button {
        display: none;
      }
    }
  }
  .searchResult {
    position: relative;
    .list-group {
      background: $color-gray;
      position: absolute;
      max-height: 30vh;
      overflow: auto;
      z-index: 10;
      width: 100%;
      border: 1px solid $color-main;
      padding: 10px 20px;
      overflow-x: hidden;
      margin-top: 10px;
      .list-group-item {
        background: $color-gray;
        height: 40px;
        padding: 0 5px;
        cursor: pointer;
        border: none;

        &.selected {
          .hover-left-right {
            background-size: 0 2px, 100% 2px;
            text-decoration: none;
          }
        }
      }
    }
  }
  .b-form-tags-list {
    .badge {
      background: transparent;
      border: 1px solid $color-main;
      border-radius: 0;
      padding: 5px 12px;
      margin: 4px 4px 6px;
      .b-form-tag-content {
        position: relative;
        top: -4px;
        padding: 10px 10px 0 0;
      }
      button {
        color: $color-main;
        opacity: 1;
        font-weight: 100;
        position: relative;
        top: -2px;
      }
    }
  }
  .pro-readonly-value {
    font-style: italic;
    font-weight: $font-weight-bold;
    &.tags {
      margin-left: 3em;
    }
  }

  .notes-margin {
    margin-left: 1.5em;
  }
  .button--summary {
    height: 4em;
  }
  .format-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.1.6s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<template>
    <div class="text-block color--basic" v-html="text"></div>
</template>

<script>
export default {
    name: "TextBlock",
    props: {
        text: String
    }
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.text-block {
    // font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-size: em(13,12);
}
</style>
<template>
  <div class="sitemap-level my-2">
    <b-link :to="model.node.url" class="focus-smart">{{model.node.name}}</b-link>
    <div class="ml-5">      
      <div v-for="child in model.children" :key="child.label" :to="child.node.url">        
        <sitemap :model="child"></sitemap>
      </div>      
    </div>             
  </div>
</template>
<script>

export default {
    name: "Sitemap",
    props: {
        model: Object
    },
}
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.article-page .sitemap-level {
  font-size: $font-size-bigger;
}
.sitemap-level .sitemap-level {
  font-size: 0.85em !important;
}
.sitemap-level {  
  text-decoration: underline;
}
</style>
<template>
  <div class="lesson-page color--basic">
    <b-container>
      <b-row>
        <b-col cols="12" class="mt-4 pt-4 text-center">
          <h1>{{ model.title }}</h1>
        </b-col>
      </b-row>
    </b-container>
    <div class="lesson-page__form">
      <b-form>
        <b-container>
          <b-row align-h="center" class="pt-3">
            <b-col cols="12" md="8" lg="6">
              <label for="title">{{
                model.translations["lessons.Form.Title"]
              }}</label>
              <b-form-input
                name="title"
                v-model="$v.state.title.$model"
                :state="validationState($v.state.title)"
                aria-describedby="error-title"
              ></b-form-input>
              <b-form-invalid-feedback
                id="error-title"
                v-if="$v.state.title.$dirty && !$v.state.title.required"
                >{{ model.translations["general.Errors.Required"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pt-5">
            <b-col cols="12" md="8" lg="6">
              <label for="startDate">{{
                model.translations["lessons.Form.StartDate"]
              }}</label>
              <date-picker
                name="startDate"
                prefix-class="vd"
                v-model="$v.state.startDate.$model"
                value-type="format"
                format="YYYY-MM-DD"
              >
                <template v-slot:input>
                  <b-form-input
                    name="startDate"
                    v-model="$v.state.startDate.$model"
                    :state="validationState($v.state.startDate)"
                    aria-describedby="error-startDate"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="error-startDate"
                    v-if="
                      $v.state.startDate.$dirty && !$v.state.startDate.required
                    "
                    >{{ model.translations["general.Errors.Required"] }}
                  </b-form-invalid-feedback>
                </template>
              </date-picker>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pt-5">
            <b-col cols="12" md="8" lg="6">
              <label for="endDate">{{
                model.translations["lessons.Form.EndDate"]
              }}</label>
              <date-picker
                name="startDate"
                prefix-class="vd"
                v-model="$v.state.endDate.$model"
                value-type="format"
                format="YYYY-MM-DD"
              >
                <template v-slot:input>
                  <b-form-input
                    name="endDate"
                    v-model="$v.state.endDate.$model"
                    :state="validationState($v.state.endDate)"
                    aria-describedby="error-enddate"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="error-enddate"
                    v-if="$v.state.endDate.$dirty && !$v.state.endDate.required"
                    >{{ model.translations["general.Errors.Required"] }}
                  </b-form-invalid-feedback>
                </template>
              </date-picker>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pt-5">
            <b-col cols="12" md="8" lg="6">
              <label for="message">{{
                model.translations["lessons.Form.Message"]
              }}</label>
              <b-form-textarea
                name="message"
                rows="4"
                v-model="$v.state.message.$model"
                :state="validationState($v.state.message)"
                aria-describedby="error-message"
              ></b-form-textarea>
              <b-form-invalid-feedback
                id="error-message"
                v-if="$v.state.message.$dirty && !$v.state.message.required"
                >{{ model.translations["general.Errors.Required"] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pt-5 mb-5">
            <b-col cols="12" md="8" lg="6">
              <label>{{ model.translations["lessons.Form.Emails"] }}</label>
              <b-row class="lesson-page__form__emails_container">
                <b-col
                  cols="12"
                  class="lesson-page__form__emails_container__item"
                  :key="index"
                  v-for="(v, index) in $v.state.emails.$each.$iter"
                >
                  <div class="lesson-page__form__emails_container__item--input">
                    <b-form-input
                      v-model="v.email.$model"
                      :state="validationState(v.email)"
                      :aria-describedby="'error-email' + index"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :id="'error-email' + index"
                      v-if="v.email.$dirty && !v.email.required"
                      >{{ model.translations["general.Errors.Required"] }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      id="'error-email'+index"
                      v-else-if="v.email.$dirty && !v.email.email"
                      >{{ model.translations["general.Errors.InvalidEmail"] }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="lesson-page__form__emails_container__item--icon">
                    <i
                      v-if="state.emails.length > 1"
                      aria-hidden="true"
                      class="cil-trash-x"
                      @click="removeEmail(index)"
                    />
                  </div>
                </b-col>
                <b-col cols="12" class="text-center mt-3">
                  <b-button
                    variant="primary"
                    @click="state.emails.push({ email: '' })"
                    >{{ model.translations["lessons.Form.EmailsAdd"] }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6" class="block-center text-center">
              <collapse-item
                :key="category.Key"
                :model="category"
                v-for="category in model.contentCategories"
              >
                <collapse-item
                  :css-class="'collapse-item__secondary'"
                  :key="child.key"
                  :model="child"
                  :default-title="
                    model.translations['lessons.Form.DefaultCategory']
                  "
                  v-for="child in category.categories"
                >
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="state.contentItems"
                      :options="child.items"
                      value-field="item"
                      text-field="name"
                      :aria-describedby="ariaDescribedby"
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </collapse-item>
              </collapse-item>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pt-5">
            <b-col cols="12" lg="6" class="text-center">
              <b-button type="button" variant="primary" @click="submit(false)"
                >{{ submitFormButtonText }}
              </b-button>
            </b-col>
          </b-row>
          <b-row align-h="center" class="pt-5" v-if="isDraft">
            <b-col cols="12" lg="6" class="text-center">
              <b-button type="button" variant="primary" @click="submit(true)"
                >{{ model.translations["lessons.Form.DraftButton"] }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </div>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import CollapseItem from "@/components/common/CollapseItem";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  name: "LessonForm",
  mixins: [ValidationMixin],
  components: {
    CollapseItem,
    DatePicker,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      hasValidationError: false,
      currentLessonId: null,
      isDraft: true,
      state: {
        title: null,
        startDate: null,
        endDate: null,
        message: null,
        emails: [],
        contentItems: [],
      },
    };
  },
  computed: {
    submitFormButtonText() {
      if (this.currentLessonId) {
        return this.model.translations["lessons.Form.EditButton"];
      }
      return this.model.translations["lessons.Form.SubmitButton"];
    },
  },
  mounted() {
    let lessonId = this.$route.query.key;
    if (lessonId) {
      axios({
        method: "GET",
        url: process.env.VUE_APP_BASE_API_URL + this.model.lessonUrl + lessonId,
      })
        .then((response) => {
          if (response.data) {
            this.state.title = response.data.title;
            this.state.startDate = moment(
              String(response.data.startDate)
            ).format("YYYY-MM-DD");
            this.state.endDate = moment(String(response.data.endDate)).format(
              "YYYY-MM-DD"
            );
            this.state.message = response.data.message;
            this.state.emails = response.data.emails;
            this.state.contentItems = response.data.contentItems;
            this.state.contentItems = response.data.contentItems;
            this.currentLessonId = response.data.lessonId;
            this.isDraft = response.data.isDraft;
          } else {
            this.state.emails.push({ email: "" });
          }
          this.$setBusy(false);
        })
        .catch(() => {
          this.state.emails.push({ email: "" });
          this.$setBusy(false);
        });
    } else {
      this.state.emails.push({ email: "" });
    }
  },
  validations: {
    state: {
      title: { required },
      startDate: { required },
      endDate: { required },
      message: { required },
      emails: {
        required,
        $each: {
          email: {
            required,
            email,
          },
        },
      },
      contentItems: {
        required,
      },
    },
  },
  methods: {
    removeEmail(index) {
      this.state.emails.splice(index, 1);
    },
    scrollToFirstInvalidElement() {
      var element = this.$el.getElementsByClassName("is-invalid")[0];
      if (element != undefined) {
        this.$scrollTo(element, 200, { offset: -80 });
      }
    },
    submit(isDraft) {
      this.hasValidationError = false;
      this.$v.state.emails.$touch();
      this.$v.$touch();

      if (this.$v.$anyError || this.$v.state.emails.$anyError) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        this.hasValidationError = true;
        return;
      }
      this.$setBusy(true);

      axios({
        method: "POST",
        url: process.env.VUE_APP_BASE_API_URL + this.model.submitUrl,
        data: {
          ...this.state,
          isDraft: isDraft,
          lessonId: this.currentLessonId,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.$router.push(response.data);
          } else {
            this.hasValidationError = true;
          }
          this.$setBusy(false);
        })
        .catch(() => {
          this.hasValidationError = true;
          this.$setBusy(false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.lesson-page {
  &__form {
    &__emails_container {
      &__item {
        display: flex;
        padding-top: 10px;

        &--input {
          width: calc(100% - 35px);
        }

        &--icon {
          width: 35px;
          height: 40px;
          font-size: 1.25em;
          display: inline-block;
          padding-top: 10px;
          text-align: center;
          background-color: #3f3f3f;
          cursor: pointer;
        }
      }
    }
  }
}

.collapse-item__button {
  padding: {
    left: 1em;
    right: 1em;
  }
}

.invalid-feedback {
  padding: 3px 8px;
  background: $color-light-gray;
  position: relative;
  margin-top: 1em;
  font-weight: 600;
}

.invalid-feedback::before {
  content: "";
  position: absolute;
  border: 6px solid transparent;
  border-bottom-color: $color-light-gray;
  bottom: 100%;
  left: 14px;
}

.custom-checkbox {
  text-align: left;
}

.vd_collapse-tests {
  background: $color-light-gray;
  color: $color-black;
  padding: em(16) em(20) em(10) em(20);
}

.vd_text-more {
  font-size: em(18);
  font-weight: $font-weight-bold;
  cursor: pointer;
  margin-bottom: 2em;
}

.lesson-page {
  .collapse-item__secondary .media {
    text-align: left;
  }

  .collapse-item__secondary fieldset {
    padding-left: 5px;

    label {
      color: black;
    }
  }

  .collapse-item__secondary
    .custom-control-input
    + .custom-control-label::before {
    top: 4px;
  }
}
</style>
<template>
    <div class="mx-auto mt-5 color--basic profile-panel">
      <b-form @submit.stop.prevent="submit" v-if="!this.showSucceeded">
        <b-container>
          <b-row>
            <b-col class="mx-4 text-center text-uppercase">
              <h1 v-html="model.translations['user.DeleteAccount']"></h1>
            </b-col>
          </b-row>        
          <b-row>
              <b-col class="my-3 text-center">
                  {{model.translations['user.DeleteAccountInfo']}}
              </b-col>
          </b-row>                                        
          <b-row>
              <b-col class="my-1 text-center">
                  <b-button
                      type="submit"
                      variant="primary"
                      class="button--large button--loginbtn"
                  >{{ model.translations['user.DeleteAccount'] }}
                  </b-button>
              </b-col>
          </b-row>                    
        </b-container>
      </b-form>              
      <b-alert
          v-model="this.showAlert"
          :class="'position-fixed fixed-top m-0 rounded-0 alert-danger'"
          style="z-index: 2000; text-align: center;"
          variant="primary"
          dismissible
      >
      {{ this.alertError }}
      </b-alert>  
      <div class="pt-2" v-if="this.showSucceeded">
          <b-container>
              <b-row>
                  <b-col class="text-center">
                      {{model.translations['user.AccountDeleted']}}
                  </b-col>
              </b-row>
              <b-row class="pt-5 text-center">
                  <b-col>
                      <b-button
                          type="submit"
                          variant="primary"
                          class="button--large button--loginbtn button--sendpwd"
                          @click="$router.push('/')"
                      >{{ model.translations['general.GoToHomepage'] }}
                      </b-button>
                  </b-col>
              </b-row>
          </b-container>        
      </div>          
    </div>
</template>

<script>
import authService from "@/services/authService";

export default {
    name: "DeleteAccount",    
    props: {
        model: Object
    },
    data() {
        return {            
            showSucceeded: false,
            alertError: false,
            showAlert: false,
        }
    },        
    methods: {
        submit() {    
            this.showAlert = false;                      
            this.$setBusy(true);             
            authService.deleteAccount()
            .then(() => {                                              
                this.showSucceeded = true;
                this.$setBusy(false);
            })     
            .catch((error_code)=>{
                this.alertError = error_code;
                this.showSucceeded = true;
                this.$setBusy(false);
            });                                  
        },
    }    
}
</script>

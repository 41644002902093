<template>
  <b-modal :id="header" :title="title" size="md" centered hide-footer :header-close-label="closeText ? closeText : 'Zamknij'"
  :no-fade="noFade"
  :body-class="{Tizen: $isTizen($browserDetect)}"
  >
    <b-container class="modal-error">
      <b-row class="d-flex justify-content-center">
        <b-col class="text-center">
          <slot name="main"></slot>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center b30" v-if="$slots.btn">
        <b-col class="text-center">
          <slot name="btn"></slot>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center" v-if="!hideClose">
        <b-col :sm="9" class="text-center">
          <b-link
            @click="hideModal"
            class="text-uppercase btn btn-primary focus-smart hideModal"
            >{{ closeText }}
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  props: {
    title: String,
    closeText: String,
    noFade: {
      default: false,
      typeof: Boolean,
    },
    show: {
      default: false,
      typeof: Boolean,
    },
    hideClose: Boolean,
    header: String,
  },
  methods: {
    hideModal() {
      this.$emit("hidePop");
    },
    setNavLock() {
      this.$setNavigationLock(true);
    },
    hideModalEvent(bEvent, bModal) {
      this.$setNavigationLock(false);
      if (bModal == this.header && this.show) this.$emit("hidePop");
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", this.setNavLock);
    this.$root.$on("bv::modal::hide", this.hideModalEvent);
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.setNavLock);
    this.$root.$off("bv::modal::hide", this.hideModalEvent);
  },
  watch: {
    show(newValue) {
      if (newValue) {
        if (this.$isTizen(this.$browserDetect))
          setTimeout(() => {
            window.RedgalaxyNav.focusElement(".hideModal");
          }, 0);
        this.$bvModal.show(this.header);
      } else this.$bvModal.hide(this.header);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.modal-error {
  color: $color-main;
  .btn {
    font-family: $font-family-wotfard;
    width: 100%;
    font-size: em(20);
  }
  .b30 {
    margin-bottom: 30px;
  }
}
.modal-body.Tizen {
  display: inherit !important;
}
</style>

<template>
  <footer class="footer-bar">
    <div class="footer-bar__top" v-if="!this.$browserDetect.isApp">
      <b-container fluid class="containerFixed">
        <b-row class="text-center text-md-left justify-content-md-left">
          <b-col class="footer-bar__top__links">
            <span>{{ model.translations["layout.Footer.FindUs"] }}:</span>
            <b-link
              :key="index"
              v-for="(link, index) in model.social"
              class="footer-bar__top__link d-inline-block focus-smart"
              :href="link.url"
              target="_blank"
              :title="
                link.label + ' ' + model.translations['general.OpenNewTab']
              "
            >
              <i
                :aria-label="
                  link.label + ' ' + model.translations['general.OpenNewTab']
                "
                @click="sendAnalitycs('Kliknięcie social media', link.name)"
                role="img"
                :class="'cib-' + link.icon"
                class="footer-bar__top__icon"
              ></i>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="footer-bar__bottom background--black">
      <b-container fluid class="containerFixed">
        <b-row
          class="text-center justify-content-md-left background--black"
          align-v="center"
        >
          <b-col
            cols="12"
            md="auto"
            :key="index"
            v-for="(link, index) in model.links"
          >
            <b-link
              class="
                footer-bar__bottom__link
                d-inline-block
                hover-left-right
                focus-smart
              "
              @click="sendAnalitycs('Kliknięcie menu', link.name)"
              :to="link.url"
              >{{ link.name }}</b-link
            >
          </b-col>
          <b-col
            class="footer-bar__newsletter"
            v-if="!this.$browserDetect.isApp && model.newsletterSubmitUrl"
          >
            <b-link
              class="footer-bar__newsletter-link focus-smart"
              v-b-modal.modal-newsletter
              @click="sendAnalitycs('Kliknięcie', 'Zapisz się na newsletter')"
              >{{
                model.translations["layout.Footer.Newsletter.Subscribe"]
              }}</b-link
            >
          </b-col>
          <b-col class="d-none d-lg-block footer-bar__bottom__logo">
            <logo :model="model.logo"></logo>
          </b-col>
        </b-row>
        <newsletter-modal
          :newsletterSubmitUrl="model.newsletterSubmitUrl"
          :translation="model.translations"
          v-if="!this.$browserDetect.isApp"
        />
        <div style="color: white" class="copyright">
          Copyright: &copy; {{ model.copyRight }} {{ new Date().getFullYear() }}
        </div>
      </b-container>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/common/Logo";
import NewsletterModal from "./NewsletterModal.vue";
import { EventBus } from "@/services/event-bus";

export default {
  name: "FooterBar",
  components: {
    Logo,
    NewsletterModal,
  },
  props: {
    model: Object,
  },
  methods: {
    sendAnalitycs(action, label) {
      this.$setGAFormFooter(action, label, false);
    },
    navi(item) {
      if (item == "footer") {
        document.getElementsByClassName("footer-bar__bottom__link")[0].focus();
      }
    },
  },
  mounted() {
    EventBus.$on("NavigationGoTo", this.navi);
    EventBus.$emit("NavigationRegister", {
      id: 3,
      name: this.model.translations["navigation.GoTo.Footer"],
      key: "footer",
    });
  },
  beforeDestroy() {
    EventBus.$off("NavigationGoTo", this.navi);
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.footer-bar {
  border-top: 1px solid $primary;
  font-family: $font-family-wotfard;
  .containerFixed {
    .col,
    .col-12 {
      padding-right: 15px;
      padding-left: 15px;
    }
    .footer-bar__top__links {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__top {
    &__links {
      position: static;
      a:last-child {
        margin-right: 0;
      }
      > span {
        font-weight: $font-weight-bold;
        color: $color-main;
        font-size: em(16);
        position: static;
        top: 2px;
        margin-right: 45px;
        @media (max-width: map-get($grid-breakpoints, "lg" ) - 1) {
          display: none;
        }
      }
    }

    &__link {
      margin-top: 10px;
      margin-bottom: 10px;

      @include media-breakpoint-up(sm) {
        margin-right: 45px;
      }

      @include media-breakpoint-up(xs) {
        margin-right: 25px;
      }
      @include media-breakpoint-up(md) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    &__icon {
      font-size: em(30, 12);
      @include media-breakpoint-up(md) {
        font-size: em(30);
      }
      line-height: 1em;
    }
  }

  &__bottom {
    padding-bottom: 10px;
    @include media-breakpoint-up(md) {
      .col-md-auto {
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .footer-bar__bottom__logo {
      text-align: right;
    }
    @include media-breakpoint-down(md) {
      padding-bottom: 20px;
    }
    > div {
      > div {
        > div {
          position: static;
        }
      }
    }
    &__link {
      text-transform: uppercase;
      line-height: em(19, 12);
      font-size: em(14, 12);
      font-weight: $font-weight-bold;
      padding-top: 15px;
      @include media-breakpoint-up(md) {
        font-size: em(14);
        line-height: em(19);
      }
    }

    &__logo {
      padding: 40px 0;
      a {
        display: inline-block;
        height: 30px;
        img {
          height: 30px;
        }
      }
    }
  }
  &__newsletter {
    padding-top: 15px;
  }
  &__newsletter-link {
    text-decoration: underline;
    text-transform: lowercase;
    line-height: 1;
  }
}
.scrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  a {
    padding: 15px 15px;
    // background: $color-gray;
    color: $color-main;
    display: inline-block;
    text-decoration: none;
    // border: 2px solid;
    i {
      font-size: em(30);
      text-shadow: 2px 2px 2px $color-black;
    }
  }
}
.copyright {
  font-size: em(16, 16);
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  @media (min-width: map-get($grid-breakpoints, lg )) {
    margin-top: 0;
  }
}
</style>
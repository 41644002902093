<template>
  <div v-if="model.fake" class="vd_list-item hover-outline item-video fake">
    <div class="vd_list-cover"></div>
  </div>
  <div v-else-if="model.movieType" class="vd_list-item hover-outline item-video fake">
    <player
      :assetId="model.atdId"
      :jumpTime="model.umpTime"
      :translations="translations"
      :imageUrl="model.image.url"
      :lang="model.lang"
    >
    </player>
  </div>
  <div
    class="vd_list-item hover-outline item-video"
    :class="{
      'hover-scale': boxView,
      'hover-social': showSocial,
      selected: isSelected,
    }"
    v-else-if="alternative"
  >
    <b-link
      :to="model.url"
      tabindex="0"
      class="link focus-smart"
      @click.native="sendAnalytics()"
      :aria-label="model.watch + ' ' + model.title"
    >
      <avaliable
        :avaliable="model.avaliable"
        :showOutVod="model.showOutVod"
        :price="priceItem"
        :translations="translations"
      ></avaliable>
      <div
        aria-hidden="true"
        class="vd_list-cover"
        :class="{ 'background--nero': model.defaultIcon }"
        :style="backgroundDefaultIcon"
      >
        <div class="vd_play-icon-wrapper" v-if="!showSocial">
          <div class="vd_video-icon" v-if="model.type == 'Asset'">
            <svg width="21" height="22" viewBox="0 0 21 22">
              <path
                id="Union_5"
                data-name="Union 5"
                d="M1,22a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM1,14a1,1,0,0,1-1-1V10A1,1,0,0,1,1,9H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM9,9A1,1,0,0,1,8,8V5A1,1,0,0,1,9,4h3a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1ZM1,5A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H4A1,1,0,0,1,5,1V4A1,1,0,0,1,4,5Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div :aria-label="model.image ? model.image.title : ''">
          <lazy-image
            v-if="imageUrl"
            :source="imageUrl"
            :alt="model.title"
            style="aspect-ratio: 4/3"
          ></lazy-image>
          <img
            v-else-if="!model.defaultIcon"
            src="/assets/placeholder.png"
            :alt="model.title"
          />
        </div>
      </div>
    </b-link>
    <div class="vd_list-description">
      <div v-if="model.defaultIcon" class="vd_list-description-icon vd_icon-link">
        <i :class="!imageUrl && model.defaultIcon"></i>
      </div>
      <div class="vd_list-description-wrapper">
        <div class="vd_list-title-wrapper">
          <div class="vd_list-title">
            <h3>
              <div>
                {{ ctitle }}
              </div>
            </h3>
          </div>
        </div>
        <h4 v-if="model.showOnBox">
          <span class="showbox">{{ model.showOnBox.roleName }}:</span>&nbsp;<span
            class="showbox"
            v-for="(item, index) in model.showOnBox.persons"
            :key="index"
            >{{ getName(item, model.showOnBox.persons.length == 1)
            }}<span class="showbox" v-if="index < model.showOnBox.persons.length - 1"
              >,&nbsp;</span
            ></span
          >
        </h4>
        <h4 v-else-if="model.lesson">
          <span class="showbox" v-for="(item, index) in model.lesson" :key="index"
            >{{ item
            }}<span class="showbox" v-if="index < model.lesson.length - 1"
              >,&nbsp;</span
            ></span
          >
        </h4>
        <h4 v-if="model.subtitle">{{ model.subtitle }}</h4>
        <div
          class="vd_list-description--text"
          :class="{ sound: model.type == 'Category' }"
          v-if="model.description"
          v-html="model.description"
        ></div>
        <div v-if="model.lead" v-html="model.lead" class="lead"></div>
        <box-social
          :model="model"
          :showSocial="showSocial"
          :section="section"
          :translations="translations"
        ></box-social>
      </div>
    </div>
  </div>
  <b-link
    v-else
    :to="model.url"
    tabindex="0"
    :aria-label="model.watch + ' ' + model.title"
    class="vd_list-item hover-outline item-video focus-smart"
    :class="{
      'hover-scale': boxView,
      'hover-social': showSocial,
      selected: isSelected,
    }"
    @click.native="sendAnalytics()"
  >
    <div
      aria-hidden="true"
      class="vd_list-cover"
      :class="{ 'background--nero': model.defaultIcon }"
      :style="backgroundDefaultIcon"
    >
      <avaliable
        :avaliable="model.avaliable"
        :showOutVod="model.showOutVod"
        :price="priceItem"
        :translations="translations"
      ></avaliable>
      <div class="vd_play-icon-wrapper" v-if="!showSocial">
        <div class="vd_video-icon" v-if="model.type == 'Asset'">
          <svg width="21" height="22" viewBox="0 0 21 22">
            <path
              id="Union_5"
              data-name="Union 5"
              d="M1,22a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM1,14a1,1,0,0,1-1-1V10A1,1,0,0,1,1,9H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM9,9A1,1,0,0,1,8,8V5A1,1,0,0,1,9,4h3a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1ZM1,5A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H4A1,1,0,0,1,5,1V4A1,1,0,0,1,4,5Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div :aria-label="model.image ? model.image.title : ''">
        <lazy-image v-if="imageUrl" :source="imageUrl" :alt="model.title"></lazy-image>
        <img
          v-if="!imageUrl && !model.defaultIcon"
          src="/assets/placeholder.png"
          :alt="model.title"
        />
      </div>
    </div>
    <div class="vd_list-description" aria-hidden="true">
      <div v-if="model.defaultIcon" class="vd_list-description-icon vd_icon-link">
        <i :class="!imageUrl && model.defaultIcon"></i>
      </div>
      <div class="vd_list-description-wrapper">
        <div class="vd_list-title-wrapper">
          <div class="vd_list-title">
            <h3>
              <div>
                {{ ctitle }}
              </div>
            </h3>
          </div>
        </div>
        <h4 v-if="model.showOnBox">
          <span class="showbox">{{ model.showOnBox.roleName }}:</span>&nbsp;<span
            class="showbox"
            v-for="(item, index) in model.showOnBox.persons"
            :key="index"
            >{{ getName(item, model.showOnBox.persons.length == 1)
            }}<span class="showbox" v-if="index < model.showOnBox.persons.length - 1"
              >,&nbsp;</span
            ></span
          >
        </h4>
        <h4 v-if="model.subtitle">{{ model.subtitle }}</h4>
        <div
          class="vd_list-description--text"
          v-if="model.description"
          v-html="model.description"
        ></div>
        <div v-if="model.lead" v-html="model.lead" class="lead"></div>
        <div class="" v-if="showSocial && !this.$browserDetect.isApp">
          <div class="social">
            <favorites-control
              :model="model.favoriteModel"
              css-class="vd_like-btn"
              :section="section"
              :title="model.title"
            ></favorites-control>
            <a
              :href="facebookUrl"
              :aria-label="translations['general.Share.Facebook']"
              target="_blank"
              tabindex="0"
              @click="sendAnalytics('Kliknięcie w ikonkę', 'Udostępnij na facebooku')"
            >
              <i
                :title="translations['general.Share.Facebook']"
                role="img"
                class="cib-facebook icon"
              ></i>
            </a>
            <a
              :aria-label="translations['general.Share.Email']"
              class=""
              @click="
                shareByMail();
                return false;
              "
              tabindex="0"
            >
              <i
                :title="translations['general.Share.Email']"
                class="cil-mail"
                aria-hidden="true"
              ></i>
            </a>
            <a
              :aria-label="model.title"
              class="float-right dissolve"
              :href="model.url"
              tabindex="0"
              @click="sendAnalytics()"
            >
              <img src="/assets/35mm.svg" class="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </b-link>
</template>

<script>
import FavoritesControl from "@/components/common/FavoritesControl";
import BoxSocial from "../common/BoxSocial.vue";
import LazyImage from "../common/LazyImage.vue";
import Avaliable from "../common/Avaliable.vue";
import Player from "../common/Player.vue";

export default {
  name: "ListItem",
  components: {
    FavoritesControl,
    BoxSocial,
    LazyImage,
    Avaliable,
    Player,
  },
  props: {
    model: Object,
    boxView: Boolean,
    isSelected: Boolean,
    section: String,
    alternative: Boolean,
    displayType: String,
    translations: Object,
  },
  data() {
    return {
      domain: location.origin,
    };
  },
  computed: {
    imageUrl() {
      return this.model.image && this.model.image.url
        ? this.model.image.url
        : this.model.categoryImage?.url;
    },
    backgroundDefaultIcon() {
      if (this.model.defaultIcon && !this.model.image) {
        return (
          "background-image: url('/assets/placeholder_" +
          this.model.type.toLowerCase() +
          ".png')"
        );
      }
      return "";
    },
    showSocial() {
      if (this.displayType == "grid") {
        return this.model.type == "Asset";
      }
      return false;
    },
    ctitle() {
      return this.model.additionalInfo
        ? this.model.title + " | " + this.model.additionalInfo
        : this.model.title;
    },
    priceItem() {
      if (this.model.prices?.price) {
        return {
          color: "rgba(0, 0, 0, 0.4) !important",
          text: `${this.model.prices.name} ${this.model.prices.price}`,
          textColor: "white",
          showBasket: true,
        };
      } else {
        return null;
      }
    },
  },
  methods: {
    sendAnalytics(gAction, gLabel) {
      var name = "";
      switch (this.model.type) {
        case "Asset":
          name = "filmu";
          break;
        case "Article":
          name = "Artykułu";
          break;
        case "Quiz":
          name = "Quizu";
          break;
        case "Lesson":
          name = "Lekcji";
          break;
      }
      this.$setGAFormCustom(
        this.section,
        gAction ?? "przejdź na stornę " + name,
        gLabel ?? this.model.title,
        false
      );
    },
    getName(text, full) {
      if (full) return text;
      let splited = text.split(" ");
      return splited[0].substring(0, 1) + ". " + splited[splited.length - 1];
    },
  },
};
</script>
<style lang="scss">
.vd_list-item {
  color: white;
  overflow: hidden;
  display: inline-block;
}

.only-box-view {
  .link {
    width: 100%;
  }
}

.showbox {
  padding: 0 !important;
}

.fake {
  .vd_list-cover {
    padding-top: 75%;
  }
  .Player .Player-background img.image__item {
    min-height: unset;
    min-width: unset;
    height: 100%;
    aspect-ratio: 4 / 3;
  }
}
.modulex2 {
  .fake .Player .Player-background img.image__item {
    aspect-ratio: unset;
  }
}

.vd_list-item .Player {
  max-height: unset;
  height: 100%;
  min-height: unset;
  .Player-background {
    z-index: 0;
  }
}
</style>

<template>
  <b-link :to="model.url" class="returnBanner focus-smart">  
    <lazy-image :source="model.image"></lazy-image>
    <div>
      <b-container fluid class="containerFixed">
        <b-row
          ><div>&lt; {{ model.name }}</div>
          <h1>{{ model.title }}</h1></b-row
        >
      </b-container>
    </div>
  </b-link>
</template>
<script>
import LazyImage from "../common/LazyImage.vue";
export default {
  components: { LazyImage },
  props: {
    model: Object,
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.returnBanner {
  display: flex;
  flex-direction: column;
  > div {
    order: 0;
    h1 {
      margin-top: 0.25em;
      margin-bottom: 0.5em;
    }
  }
  img {
    order: 1;
  }
  @include media-breakpoint-up(sm) {
    display: block;
    position: relative;
    &:hover {
      color: white;
    }
    > div {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      h1 {
        margin-top: 0.5em;
      }
      .containerFixed {
        margin-top: 0.5em;
      }
    }
  }
  img {
    width: 100%;
  }
}
</style>

<template>
  <div class="second-navbar">
    <nav-bar-wcag
      :wcag="secondary.wcag"
      :isCollapsedVersion="false"
      v-if="!this.$browserDetect.isApp"
    />
    <nav-bar-search
      :model="secondary.search"
      :translations="translations"
      v-if="!appView"
    />
    <nav-bar-language :langSetting="secondary.langSetting" :isCollapsedVersion="false" />
    <nav-bar-user :userOption="secondary.userOption" :isCollapsedVersion="false" :playlists="secondary.playlists" />
    <b-nav-item :to="secondary.playlists.url" v-if="isLooged && secondary.playlists.url"
      ><i class="cil-library-music header-cicon mirror" aria-hidden="true"></i>
      <span class="wcag_hide">{{ secondary.playlists.name }}</span></b-nav-item
    >
    <eu-logo :logo="euLogos" class="eu-logo-link" :isCollapsedVersion="false" />
    <nav-bar-socials
      v-if="!this.$browserDetect.isApp"
      :social="social"
      :shareSubmitUrl="shareSubmitUrl"
      :translation="translations"
      :isCollapsedVersion="false"
    />
  </div>
</template>
<script>
import NavBarWcag from "./NavBarWcag.vue";
import NavBarLanguage from "./NavBarLanguage.vue";
import NavBarUser from "./NavBarUser.vue";
import NavBarSearch from "./NavBarSearch.vue";
import NavBarSocials from "./NavBarSocials.vue";
import EuLogo from "./EuLogo.vue";
import authService from "@/services/authService";
export default {
  components: {
    NavBarWcag,
    NavBarLanguage,
    NavBarUser,
    NavBarSearch,
    EuLogo,
    NavBarSocials,
  },
  props: ["secondary", "shareSubmitUrl", "euLogos", "translations", "social", "appView"],
  mounted() {
    this.isLooged = authService.getUser() ? true : false;
  },
  data() {
    return {
      isLooged: false,
    };
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.second-navbar {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  width: 54px;

  @include media-breakpoint-up(sm) {
    width: 54px;
    padding-right: 3.75em;
  }
  @include media-breakpoint-up(md) {
    width: auto;
    justify-content: flex-end;
    padding-right: 0;
  }
  .eulink {
    margin-left: 75px;
  }
  .form-inline {
    position: static;
  }
}
</style>

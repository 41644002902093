<template>
  <div>
    <div class="mx-auto mt-5 color--basic loginpanel">
      <b-form>
        <b-container>
          <b-row>
            <b-col class="mx-4 text-center text-uppercase">
              <h1 v-html="model.translations['general.Login']"></h1>
            </b-col>
          </b-row>
          <b-row class="pt-3">
            <b-col class="mx-4">
              <label for="username">{{
                model.translations["user.UsernameOrEmail"]
              }}</label>
              <b-input
                id="username"
                name="username"
                v-model="$v.state.username.$model"
                :class="{
                  'is-invalid': !validationState($v.state.username) == true,
                  'is-invalid': validationState($v.state.username) == false,
                }"
                aria-describedby="error-username"
                autocomplete="username"
                @keypress="setFocus"
              ></b-input>
              <b-form-invalid-feedback id="error-username"
                ><span v-if="$v.state.username.$dirty && !$v.state.username.required">{{
                  model.translations["general.Errors.Required"]
                }}</span>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="pt-4">
            <b-col class="mx-4">
              <label for="password">{{ model.translations["general.Password"] }}</label>
              <b-input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                v-model="$v.state.password.$model"
                :class="{
                  'is-invalid': !validationState($v.state.password) == true,
                  'is-invalid': validationState($v.state.password) == false,
                }"
                aria-describedby="error-pass"
                ref="password"
                @keypress="setFocus"
              ></b-input>
              <b-form-invalid-feedback id="error-pass"
                ><span v-if="$v.state.password.$dirty && !$v.state.password.required">{{
                  model.translations["general.Errors.Required"]
                }}</span>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col class="mx-4 text-right">
              <b-link
                v-if="!$isTizen($browserDetect)"
                class="forgot focus-smart"
                :to="model.forgotPassword.url"
                >{{ model.translations["user.ForgotPassword"] }}</b-link
              >
            </b-col>
          </b-row>
          <b-row class="pt-4">
            <b-col class="mx-4 text-center">
              <b-button
                ref="btn"
                variant="primary"
                @click="submit"
                class="button--large button--loginbtn"
                >{{ model.translations["general.Login"] }}
              </b-button>
            </b-col>
          </b-row>

          <b-row class="pt-5" v-if="!$isTizen(this.$browserDetect) && showLogin">
            <b-col class="mx-4 text-center">
              <b-link
                :href="getExternalLink('google')"
                class="btn button--large button--google btn-secondary"
                :target="$browserDetect.isApp ? '_blank' : ''"
                >{{ model.translations["user.LoginWithGoogle"] }}
              </b-link>
            </b-col>
          </b-row>

          <b-row class="pt-2" v-if="!$isTizen(this.$browserDetect) && showLogin">
            <b-col class="mx-4 text-center">
              <b-link
                :href="getExternalLink('facebook')"
                class="btn button--large button--fb btn-secondary"
                :target="$browserDetect.isApp ? '_blank' : ''"
                >{{ model.translations["user.LoginWithFacebook"] }}
              </b-link>
            </b-col>
          </b-row>

          <b-row class="pt-5" v-if="!$isTizen($browserDetect)">
            <b-col class="mx-4 pt-5 text-center">
              <b-link class="not-member focus-smart">{{
                model.translations["user.NotMember"]
              }}</b-link>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <modal
        header="login"
        :title="model.translations['user.LoginError']"
        :show="showModal"
        :closeText="closeText"
        @hidePop="hideModal"
        ><template v-slot:main>
          <p class="b-30" style="font-size: 0.75em">{{ errorCode }}</p>
        </template>
      </modal>
    </div>
    <products
      :products="model.products"
      :register="model.register"
      :translations="model.translations"
    ></products>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import Products from "@/components/common/Products";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import { load } from "recaptcha-v3";
import Modal from "../components/common/modal.vue";
import { setCookie } from "tiny-cookie";

export default {
  name: "LoginPage",
  components: {
    Products,
    Modal,
  },
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
    registerExternal: String,
  },
  data() {
    return {
      isFormSubmitted: false,
      state: {
        username: null,
        password: null,
      },
      showModal: false,
      returnLink: "",
      errorCode: String,
      canSend: true,
      showLogin: false,
    };
  },
  validations: {
    state: {
      username: { required },
      password: { required },
    },
  },
  mounted() {
    var queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.returnLink = urlParams.get("returnUrl");
    const now = new Date();
    now.setDate(now.getDate() + 1);
    setCookie("redirectUrl", this.returnLink, { expires: now.toGMTString() });
    load(this.model.reCaptchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
    this.showLogin = process.env.VUE_APP_ENABLE_EXTERNAL_LOGIN === true;
  },
  methods: {
    setFocus() {
      /*
      if (!this.$isTizen(this.$browserDetect)) return;
      if (e.keyCode == 13)
        switch (e.srcElement.id) {
          case "username":
            this.$refs.password.$el.focus();
            break;
          case "password":
            this.$refs.btn.focus();
            break;
        }
        */
    },
    getExternalLink(extprovider) {
      return authService.getExternalLink(
        extprovider,
        this.registerExternal,
        this.returnLink
      );
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.$setBusy(true);
      var self = this;
      if (!this.$browserDetect.isTizen) {
        authService
          .logIn(self.state.username, self.state.password, "Tizen")
          .then((user) => {
            if (!user.isLocallyRegistered) {
              this.$router.push(this.registerExternal);
            } else {
              authService.remoteLogin(this);
              self.$setNavigationLock(false);
              self.$router.go(-1);
            }
          })
          .catch(function (error) {
            self.showModal = true;
            self.$setBusy(false);
            if (error.response.data.error === "invalid_grant") {
              self.errorCode = self.model.translations["user.WrongPassword"];
            } else self.errorCode = error.response.data.error;
          });
      } else {
        this.recaptcha.execute("register").then((token) => {
          authService
            .logIn(self.state.username, self.state.password, token)
            .then((user) => {
              if (!user.isLocallyRegistered) {
                this.$router.push(this.registerExternal);
              } else {
                authService.remoteLogin(this);
                self.$setNavigationLock(false);
                if (this.$route.query.returnUrl)
                  self.$router.push(this.$route.query.returnUrl);
                else self.$router.go(-1);
              }
            })
            .catch(function (error) {
              self.showModal = true;
              self.$setBusy(false);
              if (error.response.data.error === "invalid_grant") {
                self.errorCode = self.model.translations["user.WrongPassword"];
              } else self.errorCode = error.response.data.error;
            });
        });
      }
    },

    hideModal() {
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.loginpanel {
  width: 100%;
  max-width: 28.125em;
  font-family: $font-family-wotfard;

  .not-member {
    font-size: 1.5em;
    text-transform: uppercase;
    display: inline-block;
  }

  .forgot {
    font-size: $font-size-smaller;
  }

  .button {
    &--loginbtn {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }

    &--fb,
    &--google {
      border: none;
      font-weight: $font-weight-semi-bold;
      width: 100%;
      max-width: 360px;
    }

    &--google {
      background-color: $color-google;
    }

    &--fb {
      background-color: $color-fb;
    }
  }
}
</style>

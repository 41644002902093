<template>
  <player
    v-if="model.movieType"
    :assetId="model.atdId"
    :jumpTime="model.umpTime"
    :translations="translations"
    :imageUrl="model.image.url"
    :lang="model.lang"
  >
  </player>
  <b-link :to="model.url" class="module-item focus-smart" tabindex="-1" v-else>
    <div class="module-item-background">
      <lazy-image :source="getImage()" aria-hidden="true"></lazy-image>

    <avaliable
      :avaliable="model.avaliable"
      :showOutVod="model.showOutVod"
      :price="priceItem"
      :translations="translations"
    ></avaliable>
    </div>
    <div class="description">
      <b-container fluid class="containerFixed">
        <b-row>
          <h3>
            <router-link
              class=""
              :to="{ path: model.url, query: this.$route.query }"
              tabindex="0"
            >
              {{ model.title }}
            </router-link>
          </h3>
          <div class="description--text" v-if="model.description">
            <strong>
              <p v-if="model.showOnBox">
                <span class="showbox">{{ model.showOnBox.roleName }}:</span>&nbsp;<span
                  class="showbox"
                  v-for="(item, index) in model.showOnBox.persons"
                  :key="index"
                  >{{ getName(item, model.showOnBox.persons.length == 1)
                  }}<span
                    class="showbox"
                    v-if="index < model.showOnBox.persons.length - 1"
                    >,&nbsp;</span
                  ></span
                >
              </p>
              <p v-if="model.subtitle">{{ model.subtitle }}</p>
            </strong>
            <p v-html="model.description"></p>
          </div>
        </b-row>
      </b-container>
    </div>
  </b-link>
</template>

<script>
import Avaliable from "../common/Avaliable.vue";
import LazyImage from "../common/LazyImage.vue";
import Player from "@/components/common/Player.vue";
export default {
  components: { LazyImage, Avaliable, Player },
  props: {
    model: Object,
    isFluid: Boolean,
    translations: Object,
  },
  methods: {
    getImage() {
      if (this.model.image && this.model.image.url) return this.model.image.url;
      switch (this.model.type) {
        case "Article":
          return "/assets/placeholder_article.png";
        case "Asset":
          return "/assets/placeholder_asset.png";
        case "Quiz":
          return "/assets/placeholder_quiz.png";
        case "Lesson":
          return "/assets/placeholder_lesson.png";
      }
    },
    getName(text, full) {
      if (full) return text;
      let splited = text.split(" ");
      return splited[0].substring(0, 1) + ". " + splited[splited.length - 1];
    },
  },
  computed: {
    priceItem() {      
      if (this.model.prices)
        return {
          color: "rgba(0, 0, 0, 0.4) !important",
          text: `${this.model.prices.name} ${this.model.prices.price}`,
          textColor: "white",
          showBasket: true,
        };
      return null;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.module-item {
  height: 100%;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center;
  .description {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    text-align: left;
    color: $color-main !important;
    padding-top: 6px;
    padding-bottom: 11px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: black;
      opacity: 0.5;
    }
    .container,
    .container-fluid {
      position: relative;
      z-index: 1;
      // font-size: em(16, 12);
      h3 {
        font-family: $font-family-knile;
        font-size: em(28);
        display: block;
        width: 100%;
        margin-bottom: 6px;
        font-weight: 800;
      }
      .description--text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: em(16);
      }
    }
    .description--text {
      p {
        margin-bottom: 0;
      }
    }
  }
  &-background {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    img {
      min-height: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @include media-breakpoint-up(sm) {
    .description {
      padding-top: 8px;
      padding-bottom: 14px;
      .container,
      .container-fluid {
        h3 {
          margin-bottom: 8px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .description {
    }
  }
  @include media-breakpoint-up(lg) {
    .description {
      padding-top: 11px;
      padding-bottom: 22px;
      .container,
      .container-fluid {
        h3 {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>

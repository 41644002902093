<template>
  <div class="carousel" :class="{ big: expanded }">
    <slot name="header" v-if="!expanded"> </slot>
    <div
      class="carouselBig"
      v-show="expanded"
      v-if="galleryId"
      :style="{ width: fullscreenWidth }"
      :class="{
        hideLeft: $refs.primary && $refs.primary.index == 0,
        hideRight: $refs.primary && $refs.primary.index == itemList.length - 1,
      }"
    >
      {{ IndexValue($refs.primary) }}
      <a
        v-if="!$isTizen($browserDetect)"
        class="close-button focus-smart"
        v-on:click="setNewValue"
        :title="translations['carousel.Close']"
        :aria-label="translations['carousel.Close']"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24" aria-hidden="true">
          <path
            fill="#666666"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
          />
        </svg>
      </a>
      <splide class="carousel bigcarousel" :options="optionsFullscreen" ref="primary">
        <slot name="main" :show="expand"></slot>
      </splide>
    </div>
    <splide
      v-show="!expanded"
      :options="optionsInline"
      ref="secondary"
      tabindex="-1"
      :hasSliderWrapper="true"
    >
      <slot name="main"></slot>
    </splide>
  </div>
</template>

<script>
import { Splide } from "@splidejs/vue-splide";
import { EventBus } from "@/services/event-bus.js";
export default {
  components: {
    Splide,
  },
  methods: {
    navi(item) {
      if (item == "galeryItem" + this.header) {
        this.$refs.secondary.$children[0].$el.firstChild.firstChild.focus();
      }
    },
    setNewValue: function () {
      this.expanded = !this.expanded;
      this.fullscreen();
    },
    expandGalleryEvent(value) {
      if (value.id == this.galleryId) {
        this.fullscreenWidth = this.getFullscreenWidth();
        this.expanded = true;
        if (this.$refs.primary) this.$refs.primary.splide.go(value.elementId, true);
        this.fullscreen();
        if (this.alternative && (value.elementId || value.elementId == 0))
          this.sendAnalitycs("Powiększ zdjęcie", "zdjęcia " + value.elementId);
      }
    },
    expandGallery: function (value) {
      this.fullscreenWidth = this.getFullscreenWidth();
      this.expanded = true;
      this.$refs.primary.splide.go(value, true);
      this.fullscreen();
      if (this.alternative && (value || value == 0))
        this.sendAnalitycs("Powiększ zdjęcie", "zdjęcia " + value.elementId);
    },
    getFullscreenWidth() {
      return window.innerWidth / window.innerHeight < 4 / 3 ? "95vw" : "95vw";
    },
    fullscreen() {
      if (this.expanded) {
        document.getElementsByTagName("html")[0].classList.add("fullscreen");
      } else {
        document.getElementsByTagName("html")[0].classList.remove("fullscreen");
        document
          .getElementsByTagName("body")[0]
          .classList.remove("rgpl-fake-fullscreen--body");
      }
    },
    sendAnalitycs(action, label) {
      this.$setGAFormCustom(this.section, action, label ?? "", false);
    },
    closeGallery() {
      this.fullscreen();
      if (this.expanded) this.expanded = false;
    },
    clickItem(elem) {
      let index = elem.realIndex == -1 ? elem.index : elem.realIndex;
      if (this.$refs.primary) this.expandGallery(index);
    },
    sendAnalytics(index) {
      var name = "";
      var item = this.itemList[index];
      switch (item.type) {
        case "Asset":
          name = "filmu";
          break;
        case "Article":
          name = "Artykułu";
          break;
        case "Quiz":
          name = "Quizu";
          break;
        case "Lesson":
          name = "Lekcji";
          break;
      }
      this.$setGAFormCustom(this.section, "przejdź na stornę " + name, item.title, false);
    },
    IndexValue(vale) {
      if (vale)
        this.$setGAFormCustom(this.section, "Otwarto zdjęcię nr" + vale.index, false);
    },
    OnKey(e) {
      if (e.key == "Escape") {
        this.expanded = false;
        this.fullscreen();
      }
    },
    setLock() {
      this.lock = true;
    },
  },
  data() {
    return {
      expanded: false,
      fullscreenWidth: this.getFullscreenWidth(),
      windowWidth: window.innerWidth,
      lock: false,
    };
  },
  props: {
    model: Object,
    translations: Object,
    itemList: Array,
    section: String,
    expand: {
      type: Boolean,
      default: false,
    },
    galleryId: String,
    alternative: Boolean,
    header: String,
  },
  mounted() {
    EventBus.$on("expand", this.expandGalleryEvent);
    EventBus.$on("lockScroll", this.setLock);
    const arrowBtn = document.querySelectorAll("button.splide__arrow");
    arrowBtn.forEach((element) => {
      element.setAttribute("tabindex", "-1");
    });
    const divSplide = document.querySelectorAll("div.splide--slide");
    divSplide.forEach((element) => {
      element.setAttribute("tabindex", "-1");
    });
    EventBus.$on("closeNavEvent", this.closeGallery);
    if (this.header) {
      EventBus.$on("NavigationGoTo", this.navi);
      EventBus.$emit("NavigationRegister", {
        id: 2,
        name: this.header,
        key: "galeryItem" + this.header,
      });
    }
  },
  created() {
    window.addEventListener("keydown", this.OnKey, true);
  },
  beforeDestroy() {
    if (this.$refs.secondary) this.$refs.secondary.$destroy(true);
    if (this.$refs.primary) this.$refs.primary.$destroy(true);
    EventBus.$off("expand", this.expandGalleryEvent);
    EventBus.$off("closeNavEvent", this.closeGallery);
    EventBus.$off("NavigationGoTo", this.navi);
    EventBus.$off("lockScroll", this.setLock);
    this.fullscreen();
    window.removeEventListener("keydown", this.OnKey, true);
  },
  watch: {
    expanded: function (value) {
      if (value) {
        var self = this;
        setTimeout(() => {
          self.$Lazyload.lazyLoadHandler();
        }, 0);
      }
      if (!this.alternative) {
        this.sendAnalitycs(value ? "Przeglądaj galerię zdjęć" : "Schowaj galerię zdjęć");
      }
      setTimeout(() => {
        this.$refs.secondary.splide.refresh();
      });
      this.$setNavigationLock(value);
    },
  },
  computed: {
    optionsFullscreen: function () {
      return {
        i18n: {
          prev: this.translations["carousel.Prev"],
          next: this.translations["carousel.Next"],
          first: this.translations["carousel.First"],
          last: this.translations["carousel.Last"],
          slideX: this.translations["carousel.SlideX"],
          pageX: this.translations["carousel.PageX"],
          play: this.translations["carousel.Play"],
          pause: this.translations["carousel.Pause"],
        },
        type: "fade",
        width: "98%",
        height: "98%",
        perPage: 0,
        pagination: false,
        focus: 0,
        drag: window.innerWidth < 1200,
      };
    },
    optionsInline: function () {
      var type = this.model.type;
      var rewind = this.model.rewind;
      var breakpoints = null,
        fixedWidth = null,
        fixedheight = null,
        height = "";
      if (type == "posters") {
        fixedWidth = "220px";
        fixedheight = "224";
        breakpoints = {
          1024: {
            fixedWidth: "170px",
            fixedheight: "168px",
          },
        };
      } else if (type != "slidebig") {
        if (this.$browserDetect.isApp && this.windowWidth > 1200) {
          type = "slide";
          rewind = false;
        }
        fixedWidth = "543px";
        fixedheight = "407px";
        breakpoints = {
          767: {
            fixedWidth: "222px",
            fixedheight: "166px",
            width: "96vw",
            padding: {
              left: "8px",
            },
            classes: {
              arrows: "hide",
            },
          },
          1024: {
            fixedWidth: "222px",
            fixedheight: "177px",
          },
          1199.5: {
            fixedWidth: "280px",
            fixedheight: "210px",
          },
          1629.5: {
            fixedWidth: "274px",
            fixedheight: "205px",
            gap: 7,
          },
          2129.5: {
            fixedWidth: "375px",
            fixedheight: "281px",
            gap: 8,
          },
          3499.5: {
            fixedWidth: "379px",
            fixedheight: "284px",
            gap: 8,
          },
        };
        if (this.$browserDetect.isApp && this.windowWidth > 1200) {
          type = "slide";
          rewind = false;
          fixedWidth = 375 * 1.23 + "px";
          fixedheight = 281 * 1.23 + "px";
          breakpoints = {};
        }
      } else {
        if (this.$browserDetect.isApp && window.innerWidth > 1500) type = "slide";
        fixedWidth = "543px";
        fixedheight = "857px";
        height = "857px";
        breakpoints = {
          767: {
            fixedWidth: "222px",
            width: "96vw",
            height: "350px",
            padding: {
              left: "8px",
            },
            classes: {
              arrows: "hide",
            },
          },
          1024: {
            fixedWidth: "222px",
            height: "350px",
          },
          1199.5: {
            fixedWidth: "280px",
            height: "422px",
          },
          1629.5: {
            fixedWidth: "274px",
            height: "432px",
            gap: 7,
          },
          2129.5: {
            fixedWidth: "375px",
            height: "592px",
            gap: 8,
          },
          3499.5: {
            fixedWidth: "379px",
            height: "600px",
            gap: 8,
          },
        };
      }
      // type = 'slide'
      return {
        perPage: 0,
        perMove: 1,
        fixedWidth: fixedWidth,
        fixedheight: fixedheight,
        height: height,
        gap: 8,
        cover: false,
        accessibility: true,
        keyboard: "focused",
        slideFocus: false,
        arrows: !this.$browserDetect.isApp,
        focus: 0,
        breakpoints: breakpoints,
        i18n: {
          prev: this.translations["carousel.Prev"],
          next: this.translations["carousel.Next"],
          first: this.translations["carousel.First"],
          last: this.translations["carousel.Last"],
          slideX: this.translations["carousel.SlideX"],
          pageX: this.translations["carousel.PageX"],
          play: this.translations["carousel.Play"],
          pause: this.translations["carousel.Pause"],
        },
        rewind: rewind,
        pagination: this.model.pagination,
        type: type,
        autoplay: this.model.autoplay,
        pauseOnHover: this.model.pauseOnHover,
        lazyLoad: true,
        preloadPages: 10,
        isNavigation: true,
        swipeDistanceThreshold: 150,
        flickVelocityThreshold: 0,
        flickMaxPages: 1,
        flickPower: 0,
      };
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.carousel {
  .vd_list-item {
    width: 100%;
    height: 100%;
    display: inline-block !important;
    overflow: hidden;
    min-width: auto !important;
    min-height: auto !important;
    margin: 0 !important;
    flex-basis: unset !important;
    flex-grow: unset !important;
  }

  .splide__list {
    padding-bottom: 2px !important;
  }
  :not(.Player).hide {
    display: none;
  }

  &.big {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    z-index: 13000;
    text-align: center;
    margin-bottom: 0 !important;

    .carouselBig {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      max-width: 90%;
      max-height: 90%;
      margin-right: auto !important;
      // margin-top: 5%;
      display: block !important;
      overflow: hidden;

      .splide__arrows {
        opacity: 1;
      }

      ul {
        margin: auto !important;
        padding: 0 !important;
      }

      .expandable-image {
        cursor: default;

        .expand-button {
          display: none;
        }

        img {
          transition: none;
          transform: none;
          max-height: 90vh;
        }
      }

      .splide {
        height: 100%;

        &__slide {
          height: auto !important;
        }
      }
    }
  }

  .splide__list {
    padding: 23px 0 !important;
    // margin:-20% 0 !important;
  }
}

.carouselBig {
  .splide__list {
    max-width: 100%;
    transform: none !important;
  }
}

.hideLeft {
  .splide__arrow--prev {
    display: none;
  }
}

.hideRight {
  .splide__arrow--next {
    display: none;
  }
}
</style>

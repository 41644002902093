var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home__collection"},[(_vm.model.title)?_c('h2',{class:{ 'sr-only': _vm.model.hideTitle }},[(_vm.model.link && _vm.model.link.url && _vm.canRoute())?_c('b-link',{staticClass:"focus-smart",attrs:{"to":_vm.model.link.url,"tabindex":"0"},nativeOn:{"click":function($event){return _vm.sendAnalytics('Pokaż więcej')}}},[_c('div',{staticClass:"nameSection"},[_vm._v(_vm._s(_vm.model.title))]),_c('div',[_c('div',{staticClass:"showAll"},[_vm._v(_vm._s(_vm.translations["general.ShowMore"]))]),_c('i',{staticClass:"cil-chevron-right-alt"})])]):_c('div',[_c('div',{staticClass:"nameSection"},[_vm._v(_vm._s(_vm.model.title))])])],1):_vm._e(),(_vm.model.description)?_c('p',{staticClass:"descriptionItems",domProps:{"innerHTML":_vm._s(_vm.model.description)}}):_vm._e(),_c('div',[_c('b-row',{staticStyle:{"display":"flex !important"}},_vm._l((_vm.model.items),function(item,indexT){return _c('b-col',{key:indexT,class:{
          'pr-md-0': indexT == 0,
          'p-md-0': indexT == 1,
          'pl-md-0': indexT == 2,
          invert: _vm.checkInvert(item),
        },attrs:{"cols":"12","md":_vm.model.displayItemsMode[indexT]}},[(item && item.value && item.alias == 'packageItem')?_c('package',{attrs:{"model":item.value,"translations":_vm.translations}}):(item && item.value && item.alias == 'movieElement')?_c('list-item',{attrs:{"model":item.value,"box-view":true,"section":'Sekcja: ' + _vm.model.title,"alternative":true,"displayType":"grid","translations":_vm.translations}}):(item && item.value && item.alias == 'aditionalMaterialGallery')?_c('gallery',{attrs:{"item":item,"translations":_vm.translations,"title":_vm.model.title,"index":_vm.getUniqueId(indexT)}}):(item && item.value && item.alias == 'shortMoviePicker')?_c('div',{staticClass:"vd_list-item hover-outline item-video"},[_c('player',{attrs:{"assetId":item.value.atdId,"jumpTime":item.value.umpTime,"translations":_vm.translations,"imageUrl":item.value.image.url,"lang":item.value.lang}})],1):_vm._e(),(item && item.value && item.alias == 'textElement')?_c('div',{staticClass:"collection__item",class:{
            'background--medium-gray color--black': !item.value.invert,
            'color--main background--dark-gray border2': item.value.invert,
          }},[_c('div',{staticClass:"collection__item__content text-center"},[_c('div',{staticClass:"collection__item__content--title",class:{ invert: item.value.invert }},[_c('h3',[_vm._v(_vm._s(item.value.title))])]),(item.value.description)?_c('div',{staticClass:"collection__item__content--text",domProps:{"innerHTML":_vm._s(item.value.description)}}):_vm._e(),_c('div',{staticClass:"collection__item__content--button"},[(item.value.link)?_c('b-button',{staticClass:"bottom--button",attrs:{"to":item.value.link.url,"variant":item.value.invert ? 'primary' : 'secondary'}},[_vm._v(" "+_vm._s(_vm.translations["general.ShowMore"])+" ")]):_vm._e()],1)])]):_vm._e()],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-row>
    <b-col>
      <div class="white">
        <b-container>
          <b-row class="progressBar">
            <b-col :sm="10">
              <h2>
                {{ translations["proOrder.LicenseeDetails"] }}
              </h2>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="form">
        <b-container>
          <b-row class="progressBar">
            <b-col :sm="10">
              <b-form-group
                :label="translations['proOrder.CompanyName']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.companyName"></b-input>
            </b-col>
          </b-row>
          <b-row class="progressBar">
            <b-col :sm="5">
              <b-form-group
                :label="translations['proOrder.NIP']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.nIP"></b-input>
            </b-col>
            <b-col :sm="5">
              <b-form-group
                :label="translations['proOrder.RIK']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.rIK"></b-input>
            </b-col>
          </b-row>
          <b-row class="progressBar">
            <b-col :sm="5">
              <b-form-group
                :label="translations['general.FirstName']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.firstName"></b-input>
            </b-col>
            <b-col :sm="5">
              <b-form-group
                :label="translations['general.LastName']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.lastName"></b-input>
            </b-col>
          </b-row>
          <b-row class="progressBar">
            <b-col :sm="5">
              <b-form-group
                :label="translations['general.Email']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.eMail"></b-input>
            </b-col>
            <b-col :sm="5">
              <b-form-group
                :label="translations['user.InstitutionPhone']"
              ></b-form-group>
              <b-input readonly v-model="submitValue.phone"></b-input>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import authService from "@/services/authService";
export default {
  props: {
    translations: Object,
    readonly: Boolean,
  },
  data() {
    return {
      submitValue: {
        companyName: "",
        nIP: "",
        rIK: "",
        firstName: "",
        lastName: "",
        eMail: "",
        phone: "",
      },
    };
  },
  watch: {
    submitValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, "inputs");
      },
      deep: true,
    },
  },
  methods: {
    getProfile() {
      var data = authService.getProfile();
      if (!data) return;
      this.submitValue.companyName = data.institution_name;
      this.submitValue.eMail = data.email;
      this.submitValue.firstName = data.given_name;
      this.submitValue.lastName = data.family_name;
      this.submitValue.phone = data.institution_phone;
      this.submitValue.nIP = data.nip;
      this.submitValue.rIK = data.rik;
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>
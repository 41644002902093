import axios from 'axios'
import { EventBus } from './event-bus'
var canSend = true;
export default {
    Send(error) {
        EventBus.$emit("error", error);
        if (canSend) {
            error.ua = window.navigator.userAgent;
            error.date = new Date();
            let url = process.env.VUE_APP_LOG;
            axios.post(url, error).then(() => {
            }).catch(error => {
                canSend = false;
                console.error(error);
            });
        }
    }
}
<template>
  <div class="licItem" v-if="header || value || property || attachments">
    <div class="header" v-if="header">
      <span >{{ header }}</span>
    </div>
    <b-link @click="getFile()" class="hover-left-right" v-if="type === 3">{{
      value.fileName
    }}</b-link>
    <div v-else-if="type === 5">
      <span>{{ value.assetName }}</span>
    </div>
    <div class="value" v-else-if="value">{{ value }}</div>
    <div class="value" v-else-if="!value && property && property.length > 0">
      <div class="LicList another" v-if="property && property.length > 0">
        <licensse-item
          v-for="(item, index) in property"
          :key="index"
          :model="item"
          :translations="translations"
          :type="getType()"
        >
        </licensse-item>
      </div>
    </div>
    <div class="LicList another" v-if="property && property.length > 0 && value">
      <licensse-item
        v-for="(item, index) in property"
        :key="index"
        :model="item"
        :translations="translations"
        :type="getType()"
      >
      </licensse-item>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "LicensseItem",
  props: {
    model: Object,
    translations: Object,
    type: Number,
    attachments: null,
    date: String,
    Title: String,
  },
  data() {
    return {
      header: String,
      value: String,
      property: [],
    };
  },
  mounted() {
    switch (this.type) {
      case 0:
        this.header = this.getName(this.model, true);
        this.value = this.getName(this.model, false);
        this.property = this.model.properties;
        break;
      case 1:
        this.header = this.getUser() + " (" + this.getDate() + ")";
        this.value = this.model.text;
        this.property = null;
        break;
      case 2:
        this.header = this.model["order.Pro.Attachments"];
        this.property = this.attachments;
        this.value = null;
        break;
      case 3:
        this.property = null;
        this.header = null;
        this.value = this.model;
        break;
      case 4:
        this.property = null;
        this.header = this.model["order.Pro.MaterialName"];
        this.value = null;
        this.property = this.attachments;
        break;
      case 5:
        this.property = null;
        this.header = null;
        this.value = this.model;
        break;
      case 6:
        this.property = null;
        this.header = this.Title;
        this.value = this.date;
        break;
      default:
        this.property = null;
        this.header = null;
        this.value = null;
        break;
    }
  },
  methods: {
    getName(value, header) {
      var item;
      if (header) {
        item = this.translations[value.labelKey];
        if (!item) item = value.labelKey;
      } else {
        item = this.translations[value.text];
        if (!item) item = value.text;
        if (!item) item = value.number;
        if (!item) item = value.date;
      }
      if (item) {
        if (item.text) return item.text;
        else if (item.labelKey) return item.labelKey;
        else return item;
      } else if (value.properties && value.properties.length > 0) return;
    },
    getUser() {
      var modifier = this.model.creator;
      if (!modifier) {
        modifier = this.model.modifier;
      }
      if (modifier) {
        if (modifier.properties) {
          var name = [];
          modifier.properties.forEach((element) => {
            if (element.labelKey) {
              if (element.labelKey === "user.firstname") name[0] = element.text;
              if (element.labelKey === "user.lastname") name[1] = element.text;
              if (element.labelKey === "user.mail") name[2] = element.text;
            }
          });
        }
        if (name[0] && name[1]) return name[0] + " " + name[1];
        else if (name[0]) return name[0];
        else if (name[1]) return name[1];
        else if (name[2]) return name[2];
      }
      return "unknow";
    },
    getDate() {
      var date;
      if (this.model.creationDate) date = new Date(this.model.creationDate);
      else if (this.model.modificationDate) date = new Date(this.model.modificationDate);
      if (date) {
        return date.toLocaleString();
      }
      return "unknow";
    },
    getFile() {
      axios({
        method: "GET",
        url:
          process.env.VUE_APP_WIDGET_API_URL +
          "pro-form/attachment?orderId=" +
          this.model.orderId +
          "&attId=" +
          this.model.id,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([atob(response.data.data)]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error(error.data);
        });
    },
    GetMovieName() {
      return this.model.assetName;
    },
    getType() {
      switch (this.type) {
        case 2:
          return 3;
        case 4:
          return 5;
        default:
          return this.type;
      }
    },
    getTranslations(key, type) {
      if (type) return this.translations[key].text;
      else return this.translations[key];
    },
  },
};
</script>
<style lang="scss">
.LicList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  > .licItem {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid;

    .licItem {
      border-left: none;
      border-right: none;
      a {
        color: black;
        font-weight: bold;
        background-image: linear-gradient(transparent, transparent),
          linear-gradient(black, black);
        margin: none;
      }
    }
  }
  .licItem {
    @media (max-width: 500px) {
      flex-direction: column;
    }
    .header,
    .value,
    .another {
      display: flex;
    }
    .header,
    .value {
      padding: 10px;
    }
    .header {
      width: 300px;
      //   border-right: 1px solid;
      span {
        &:last-child {
          &:after {
            content: ": ";
          }
        }
      }
    }
    .value {
      flex: 1;
      .value {
        padding: 0;
      }
    }
    .another {
      flex-basis: 100%;
      border-top: 1px solid;
      border: none;
      :first-child {
        border-top: none;
        border: none;
      }
      .licItem {
        border-bottom: none;
        width: 100%;
        border: none;
      }
    }
  }
  * {
    //   border: none !important;
  }
}
</style>
<template>
  <div class="quiz__questions color--basic">
    <b-container fluid class="containerFixed">
      <b-row>
        <b-col>
          <h1 class="text-left">{{ model.title }}</h1>
          <base-timer
            v-if="model.duration && model.duration != 0 && !showResult"
            :time-limit="model.duration * 60"
            :time-text="model.translations['quizzes.Questions.LeftTime']"
            @end-time="submit"
          ></base-timer>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="!showResult">
      <div class="vd_bg-dark mt-5 pt-4">
        <b-container fluid class="containerFixed">
          <b-row class="text-left">
            <b-col cols="12" class="fix">
              <p>
                {{ model.translations["quizzes.Questions"] }}
                {{ currentQuestionIndex + 1 }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-row>
        <b-col>
          <question
            :model="currentQuestion"
            :show-next="currentQuestionIndex < model.totalQuestions - 1"
            :show-prev="currentQuestionIndex > 0"
            :answer="currentAnswer"
            :translations="model.translations"
            @values="values"
            @next="next"
            @prev="prev"
            @submit="submit"
          ></question>
        </b-col>
      </b-row>
      <b-container fluid class="containerFixed">
        <b-row class="text-left">
          <b-col class="fix">
            <div class="vd_progress-steps">
              <div class="vd_progress-steps-label">
                {{ model.translations["quizzes.Questions.Progress"] }}
                {{ questionProgress }}% <span>|</span>
                {{ model.translations["quizzes.Questions.LeftQuestions"] }}
                {{ questionLeft }}
              </div>
              <div class="vd_progress-steps-bar">
                <div class="vd_progress-line" :style="progressStyles"></div>
                <div class="vd_progress-steps-wrapper">
                  <div class="vd_progress-steps-item save-grid">
                    <!-- this div must be always here-->
                  </div>
                  <div
                    class="vd_progress-steps-item"
                    :class="{ complete: isAnswered(question.key) }"
                    v-for="question in questions"
                    :key="question.key"
                  ></div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <quiz-result
      v-if="showResult"
      :model="quizResult"
      :translations="model.translations"
    ></quiz-result>
  </div>
</template>
<script>
import Question from "@/components/Quiz/Question";
import quizService from "@/services/quizService";
import BaseTimer from "@/components/common/BaseTimer";
import QuizResult from "@/components/Quiz/QuizResult";

export default {
  name: "Questions",
  components: {
    Question,
    BaseTimer,
    QuizResult,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      questions: Array,
      currentQuestionIndex: 0,
      showProgress: false,
      userAnswers: [],
      showResult: false,
      quizResult: Object,
    };
  },
  mounted() {
    let self = this;
    quizService
      .getQuestions(this.model.questionApiEndpoint)
      .then(function (response) {
        self.questions = response;
        self.showProgress = true;
      })
      .catch(function () {});
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    questionLeft() {
      return this.model.totalQuestions - this.currentQuestionIndex - 1;
    },
    currentAnswer() {
      let answers = this.userAnswers.find(
        (ans) => ans.key === this.currentQuestion.key
      );
      if (answers) {
        return answers.answers;
      }
      return [];
    },
    questionProgress() {
      return ((this.currentQuestionIndex + 1) / this.questions.length) * 100;
    },
    progressStyles() {
      return {
        width: this.questionProgress + "%",
      };
    },
  },
  methods: {
    prev() {
      this.currentQuestionIndex = this.currentQuestionIndex - 1;
    },
    next() {
      this.currentQuestionIndex = this.currentQuestionIndex + 1;
    },
    values(answer) {
      let userAnswer = this.userAnswers.find((ans) => ans.key === answer.key);
      if (userAnswer) {
        this.userAnswers.splice(this.userAnswers.indexOf(userAnswer), 1);
      }
      this.userAnswers.push(answer);
    },
    isAnswered(key) {
      return this.userAnswers.find((ans) => ans.key === key) != null;
    },
    submit() {
      let self = this;
      quizService
        .submitAnswers(
          self.model.questionApiEndpoint,
          self.userAnswers,
          self.$route.query.id
        )
        .then(function (response) {
          self.quizResult = response;
          self.showResult = true;
        })
        .catch(function () {});
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.vd_bg-dark {
  background: $color-black;
  margin-left: -15px;
  margin-right: -15px;
}

.vd_text-small {
  font-size: em(14);
}

.quiz__questions__question__answers--item .btn span {
  position: relative;
  padding-left: 10px;
}

.quiz__questions__question__answers--item .btn {
  text-align: left;
  padding-left: 43px;
}

.quiz__questions__question__answers--item .btn span::before {
  content: "";
  position: absolute;
  right: 100%;
  --size: 10px;
  width: var(--size);
  height: var(--size);
  border-radius: 1px;
  border: 1px solid $color-main;
  top: 4px;
  transform: translateX(-10px);
}

.quiz__questions__question__answers--item .btn.active span::before {
  border-color: $color-black;
}

.quiz__questions__question__answers--item .btn.active span::after {
  content: "";
  position: absolute;
  right: 100%;
  --size: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 1px;
  background: $color-black;
  top: 6px;
  transform: translateX(-12px);
}

// progress steps

.vd_progress-steps {
  margin-top: 2em;

  .vd_progress-steps-label {
    font-size: em(12);
    font-weight: $font-weight-semi-bold;
    margin-bottom: 1em;

    span {
      padding: 0 em(20);
    }
  }

  .vd_progress-steps-bar {
    background: $color-gray;
    height: 2px;
    position: relative;

    .vd_progress-line {
      position: absolute;
      height: 100%;
      background: $color-main;
      transition: 0.2s;
    }
  }
}

.vd_progress-steps-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .vd_progress-steps-item {
    background: $body-bg;
    box-shadow: 0 0 0 1.5px $color-main;
    --size: 10px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;

    &.save-grid {
      visibility: hidden;
    }

    &.complete {
      background: $color-main;
      transition: 0.2s 0.2s;
    }
  }
}
</style>
<template>
  <div>
    <b-navbar-nav
      class="wcag-ctrl"
      v-bind:class="{
        'd-none d-lg-flex': !isCollapsedVersion,
        'd-md-none': isCollapsedVersion,
      }"
    >
      <b-nav-item
        class="wcag-ctrl__zoom-on-hover zoom-icon"
        v-on:click="setFontSize(false)"
      >
        <span role="img" :title="wcag.wcagPlus" class="wcag-ctrl__sizing">
          <span aria-hidden="true" class="wcag-ctrl__sizing__ts">T</span>
          <span aria-hidden="true" class="wcag-ctrl__sizing__tl">T</span>
          <span aria-hidden="true" class="wcag-ctrl__sizing__plus">+</span>
        </span>
      </b-nav-item>
      <b-nav-item
        class="second-group-spacing wcag-ctrl__zoom-on-hover zoom-icon"
        v-on:click="setFontSize(true)"
      >
        <span role="img" :title="wcag.wcagMinus" class="wcag-ctrl__sizing">
          <span aria-hidden="true" class="wcag-ctrl__sizing__ts">T</span>
          <span aria-hidden="true" class="wcag-ctrl__sizing__tl">T</span>
          <span aria-hidden="true" class="wcag-ctrl__sizing__plus">-</span>
        </span>
      </b-nav-item>
      <b-nav-item
        class="second-group-spacing zoom-icon wcag-ctrl__contrast"
        v-on:click="toggleContrast"
        :link-attrs="{
          'aria-label': wcag.wcaGcontrast,
        }"
      >
        <span
          role="img"
          :title="wcag.wcaGcontrast"
          class="cil-contrast header-cicon"
        ></span>
      </b-nav-item>
    </b-navbar-nav>
  </div>
</template>

<script>
export default {
  props: {
    wcag: Object,
    isCollapsedVersion: Boolean,
  },
  methods: {
    setFontSize: function (isDown) {
      let fontLevel = 0;
      document.body.classList.forEach((cls) => {
        if (cls.startsWith("wcag-fontboost")) {
          fontLevel = parseInt(cls.substring("wcag-fontboost".length));
        }
      });
      if ((isDown && fontLevel > -2) || (!isDown && fontLevel < 2)) {
        document.body.classList.remove("wcag-fontboost" + fontLevel);
        fontLevel += isDown ? -1 : 1;
        document.body.classList.add("wcag-fontboost" + fontLevel);
      }
      if (isDown) this.sendAnalytics("Mniejsze litery");
      else this.sendAnalytics("Większe litery");
    },
    toggleContrast: function () {
      document.body.classList.toggle("wcag-contrast");
      this.sendAnalytics("Zmień kontrast");
    },
    sendAnalytics(label) {
      this.$setGAFormNavigation("Zmodyfikuj stronę", label, false);
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.wcag-ctrl {
  @include media-breakpoint-between(md, lg) {
    margin-right: 75px;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 7.25rem;
  }

  flex-direction: row;

  &__sizing {
    line-height: 1.45;
    &__ts {
      font-size: 0.75em;
    }
    &__tl {
      font-size: 1.27em;
    }
    &__plus {
      font-size: 1.125em;
    }
  }

  &__contrast {
    margin-top: 0.1em;
  }
}
.wcag-fontboost1 {
  .share {
    top: 0.8125rem;
  }
}
.wcag-fontboost2 {
  .share {
    top: 1.3125rem;
  }
}
</style>

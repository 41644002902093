<template>
  <div class="mx-auto mt-5 color--basic reset-panel">
    <b-form @submit.stop.prevent="submit" v-if="!this.showSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center text-uppercase">
            <h1 v-html="model.translations['user.ResetPassword']"></h1>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <label for="email">{{ model.translations["general.Email"] }}</label>
            <b-form-input
              name="email"
              id="email"
              v-model="$v.state.email.$model"
              :state="validationState($v.state.email)"
              aria-describedby="error-email"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-email"
              v-if="$v.state.email.$dirty && !$v.state.email.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-else-if="$v.state.email.$dirty && !$v.state.email.email"
              id="error-email"
              >{{ model.translations["general.Errors.InvalidEmail"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="password">{{
              model.translations["general.Password"]
            }}</label>
            <b-form-input
              name="password"
              id="password"
              type="password"
              v-model="$v.state.password.$model"
              :state="validationState($v.state.password)"
              aria-describedby="error-pass"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-pass"
              v-if="$v.state.password.$dirty && !$v.state.password.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-pass"
              v-else-if="
                $v.state.password.$dirty && !$v.state.password.passwordFormat
              "
              >{{ model.translations["user.PasswordFormat"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="repeatPassword">{{
              model.translations["user.RepeatPassword"]
            }}</label>
            <b-form-input
              name="repeatPassword"
              id="repeatPassword"
              type="password"
              v-model="$v.state.repeatPassword.$model"
              :state="validationState($v.state.repeatPassword)"
              aria-describedby="error-rep-pass"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-rep-pass"
              v-if="
                $v.state.repeatPassword.$dirty &&
                !$v.state.repeatPassword.required
              "
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-rep-pass"
              v-else-if="
                $v.state.repeatPassword.$dirty &&
                !$v.state.repeatPassword.sameAsPassword
              "
              >{{ model.translations["user.ErrorConfirmPassword"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              >{{ model.translations["general.Save"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <modal
      header="resetPass"
      :title="alertError"
      :show="showAlert"
      :closeText="closeText"
      @hidePop="closeModal"
    >
    </modal>
    <div class="pt-5" v-if="this.showSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center">
            {{ model.translations["user.ResetPassword.Succeeded"] }}
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              @click="$router.push(model.login.url)"
              >{{ model.translations["general.Login"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ValidationMixin, { passwordFormat } from "@/mixins/ValidationMixin";
import { required, email, sameAs } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import { load } from "recaptcha-v3";

export default {
  name: "ResetPasswordPage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
  },
  data() {
    return {
      showAlert: false,
      showSucceeded: false,
      alertError: null,
      state: {
        email: null,
        password: null,
        repeatPassword: null,
      },
    };
  },

  validations: {
    state: {
      email: { required, email },
      password: {
        required,
        passwordFormat,
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    closeModal() {
      this.showAlert = false;
    },
    submit() {
      this.showAlert = false;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.showAlert = true;
        this.alertError = this.model.translations["general.Errors.SavingData"];
        return;
      }

      this.$setBusy(true);
      this.recaptcha.execute("register").then((token) => {
        authService
          .resetPassword(
            this.state.email,
            this.state.password,
            this.$route.query.code,
            token
          )
          .then(() => {
            this.showAlert = false;
            this.showSucceeded = true;
            this.$setBusy(false);
          })
          .catch((error_code) => {
            let error =
              error_code &&
              this.model.translations["user.SSOErrors." + error_code]
                ? this.model.translations["user.SSOErrors." + error_code]
                : this.model.translations["general.Errors.SavingData"];
            this.alertError = error;
            this.showAlert = true;
            this.$setBusy(false);
          });
      });
    },
  },
  mounted() {
    load(this.model.reCaptchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.reset-panel {
  width: 100%;
  max-width: 25em;
  .button {
    &--sendpwd {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }
  }
}
</style>
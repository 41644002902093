import axios from 'axios'

export default {
    async prePare(submitValue) {
        var self = this;
        let promises = submitValue.licenseList.map(async (license) => {
            license.full.filesFormatFlat = license.full.filesFormat.flat();
            if (license.full.filesFormatInput) {
                license.full.certificate = [];
                var temp = [];
                for (const [key, value] of Object.entries(
                    license.full.filesFormatInput
                )) {
                    if (value) {
                        if (value.name) {
                            if (value.size <= 5242880) {
                                temp.push(
                                    await self.getFile(value).then((file) => {
                                        license.full.certificate.push({
                                            value: file,
                                            key: key,
                                            name: value.name,
                                        });
                                    })
                                );
                            }
                        } else {
                            license.full.certificate.push({
                                key: key,
                                name: value,
                            });
                        }
                    }
                }
            }
            return license;
        });
        await Promise.all(promises).then(() => {
        });
        return JSON.parse(JSON.stringify(submitValue));
    },
    async savePro(submitValue) {
        var result = await this.prePare(submitValue);
        return await this.save(result);
    },
    async getTempOrder(id) {
        return await axios({
            method: "GET",
            url:
                `${process.env.VUE_APP_PRO_URL}/SavedOrder?id=${id}`
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
                return false;
            });

    },
    //--------- PRIVATE ----------

    getFile: function (file) {
        return new Promise((resolve) => {
            const fReader = new FileReader();
            fReader.onload = () => {
                let lastCommaIdx = fReader.result.lastIndexOf(",");
                resolve(
                    lastCommaIdx < 0
                        ? fReader.result
                        : fReader.result.substring(lastCommaIdx + 1)
                );
            };
            fReader.readAsDataURL(file);
        });
    },
    async save(payload) {
        return await axios
            .post(`${process.env.VUE_APP_PRO_URL}/save`, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });

    }
}
<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">          
          <b-form-group>
            <b-form-radio-group
              v-model="selectedValue"
              :name="name + selectedValue"
              :class="{ selected: selectedValue }"
              :disabled="disabled"
            >            
              <div v-for="(item, index) in itemList" :key="index">
                <b-form-radio v-if="!readonly || item.value == selectedValue"
                  :disabled="readonly"
                  :value="item.value"
                  :class="{
                    active: item.value == selectedValue,
                    checkReadonly: readonly,
                  }"
                  @click.native="inputValue = null"
                >
                  {{ item.text }}
                </b-form-radio>
                <div
                  class="short"
                  v-if="item.value == selectedValue && disabled"
                >
                  <p>
                    {{ disabledInfo }}
                  </p>
                </div>
                <transition name="slide-fade">
                  <div
                    class="short"
                    v-if="item.haveInput && item.value == selectedValue"
                  >
                    <div v-if="readonly">
                      {{item.inputName ? item.inputName + ': ' : ''}}<span class="pro-readonly-value">{{inputValue}}</span>
                    </div>
                    <b-form-group :label="item.inputName" v-if="!readonly">
                      <b-input
                        :placeholder="item.inputPlaceHolder"
                        :disabled="item.value != selectedValue"
                        :type="item.inputType"
                        v-model="inputValue"
                      ></b-input>
                    </b-form-group>
                  </div>
                </transition>
              </div>
            </b-form-radio-group>
            <slot></slot>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    itemList: Array,
    select: null,
    input: null,
    name: String,
    disabled: Boolean,
    disabledInfo: String,
    readonly: Boolean,
  },
  data() {
    return {
      selectedValue: null,
      inputValue: null,
    };
  },
  watch: {
    selectedValue(newValue) {
      this.$emit("ChangeValue", newValue, this.name);
    },
    select(newValue) {
      if (newValue != this.selectedValue) this.selectedValue = newValue;
    },
    inputValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, this.name + "input");
      },
      deep: true,
    },
    input(newValue) {
      if (newValue != this.inputValue) this.inputValue = newValue;
    },
  },
  mounted() {
    if (this.select) this.selectedValue = this.select;
    if (this.input) this.inputValue = this.input;
  },
};
</script>
<style lang="scss">
</style>
<!------------------------------------------
Komonent - linia z wynikami wyszukiwarki AI
-------------------------------------------->
<template>
  <b-row class="record align-items-center">
    <b-col class="img">
      <b-link :to="itemUrlOld">
        <lazy-image
          :source="item.image ? item.image.url : ''"
          :alt="item.image ? item.image.alt : ''"
          :key="NaN"
        />
      </b-link>
    </b-col>
    <b-col class="meta">
      <b-link :to="itemUrlOld" class="focus-smart">
        <span class="title">{{ item.title }}</span>
        <span class="category">{{ item.category }}</span>
        <span class="director" v-if="item && item.showOnBox && item.showOnBox.length > 0"
          >{{ item.showOnBox.roleName }}: {{ item.showOnBox.persons.join(", ") }}
        </span>
        <span class="country">{{ item.country }} {{ item.year }}</span>
      </b-link>
    </b-col>
    <b-col class="frames pl-4" v-if="item.framesTotal > 0">
      <div class="hdr">
        {{ model.translations["searchPage.FramesFound"] }} ({{ item.framesTotal }})
      </div>
      <b-link
        class="frame focus-smart"
        v-for="(fitem, findex) in item.frames.slice(0, 3)"
        :to="itemUrlFrames"
        :key="findex"
      >
        <lazy-image
          :source="fitem.imageUrl ? fitem.imageUrl : '/assets/35mm.svg'"
          :alt="fitem.frameDescription"
          class="fimg"
        />
        <span class="tc">{{ fitem.frameDescription }}</span>
      </b-link>
      <span class="frames-left" v-if="item.framesTotal > 3">
        +{{ item.framesTotal - 3 }}</span
      >
    </b-col>
  </b-row>
</template>
<script>
import LazyImage from "./LazyImage.vue";
export default {
  components: {
    //-----------------
    //Lazy-loading dla obrazu filmu i stopklatek
    //-----------------
    LazyImage,
  },
  name: "SearchAIResultRow",
  props: {
    //-----------------
    // Model backend: SearchAIViewModel
    //-----------------
    model: Object,

    //-----------------
    // Model backend: AIListItemModel
    //-----------------
    item: Object,

    //-----------------
    // Fraza wyszukiwania dla aktualnych wyników
    //-----------------
    searchPhrase: String,
  },
  computed: {
    itemUrlFrames() {
      let _search = this.searchPhrase
        ? "&search=" + encodeURIComponent(this.searchPhrase)
        : null;
      let _kind = `&aikind=${this.item.kind}`;
      return `${this.item.url}/?ai=1${_search}${_kind}`;
    },
    itemUrlOld() {
      if (this.item.framesTotal > 0)
        return `${this.item.url}/?search=${encodeURIComponent(this.searchPhrase)}&b=ai`;
      else return `${this.item.url}`;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.record {
  border: 1px solid $color-dark-gray;
  margin-bottom: 1em;
  padding: 1em;
  transition: all 0.3s;
  &:hover {
    border-color: white;
  }
  .img {
    flex-grow: 0;
    img {
      @include media-breakpoint-up(md) {
        width: 200px;
        height: 150px;
      }
      width: 133px;
      height: 100px;
      max-height: 200px;
    }
  }
  .meta {
    display: inline-block;
    margin: 1em;
    .title {
      display: block;
      font-weight: bold;
      font-size: 1.2em;
      min-height: 3em;
    }
    .category,
    .director,
    .country {
      display: block;
      font-size: 0.9em;
      color: $color-light-gray;
    }

    .category {
      text-transform: uppercase;
    }
  }
  .frames {
    border-left: 1px solid $color-gray;
    color: $color-light-gray;
    min-width: 475px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 1400px) {
      display: none;
    }
    .hdr {
      font-size: 0.8em;
      font-weight: bold;
      margin-bottom: 0.75em;
      flex-shrink: 0;
      width: 100%;
    }
    .frame {
      display: inline-block;
      margin-right: 1em;
      vertical-align: middle;
      max-width: calc(25% - 0.75em);
      align-self: baseline;
      .fimg {
        height: 80px;
        max-width: 115px;
        display: block;
        border: 1px solid gray;
        margin-bottom: 0.5em;
        width: 100%;
      }
      .tc {
        font-size: 0.68em;
      }
    }
    .frames-left {
      font-size: 1.5em;
    }
  }
}
.Tizen {
  .record .frames {
    min-width: 900px;
    .frame {
      .fimg {
        max-width: unset;
        height: auto;
      }
      display: block;
      text-align: center;
    }
  }
}
</style>

<template>
  <div class="moviePage">
    <b-container class="disableFlex">
      <b-row>
        <player
          v-if="model.assetId"
          :assetId="model.assetId"
          :imageUrl="model.image.url"
          :loginUrl="model.loginUrl"
          :translations="translations"
        >
        </player>
      </b-row>
    </b-container>
  </div>
</template> 
<script>
import Player from "@/components/common/Player.vue";

export default {
  name: "VideoSection",
  components: {
    Player,
  },
  props: {
    model: Object,
    translations: Object,
  },
};
</script> 
<style lang="scss">
.disableFlex {
  .row {
    display: block !important;
    .splide {
      display: block;
    }
  }
}
</style>
let test = document.getElementById("content");
export default {
    install: function (Vue) {
        Vue.prototype.$setNavigationLock = function (value) {
            if (value) document.body.classList.add('lock');
            else document.body.classList.remove('lock');
        };
        Vue.prototype.$isUnlock = function () {
            var navigationLock = document.body.classList.contains("lock");
            if (this.$browserDetect.isApp) {
                if (navigationLock) {
                    document.body.classList.remove('lock');
                    return false;
                }
                else {
                    return true;
                }
            }
            return true;
        }
        Vue.prototype.$isTizen = function (osDetect) {
            if (test == null)
                test = document.getElementById("content");
            if (test != null && test.classList.contains("Tizen"))
                return true;
            return (
                osDetect.isTizen ||
                (osDetect.isApp &&
                    !osDetect.isIOS &&
                    window.innerWidth > 1280)
            );
        }
    }
}
import Vue from 'vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import { Vuelidate } from 'vuelidate'
import vueScrollTo from "vue-scrollto";
import "@/scss/coreui/linear/linear-icons.scss";
import "@/scss/coreui/solid/solid-icons.scss";
import "@/scss/coreui/brand/brand-icons.scss";
import App from "@/App";
import BusyIndicatorPlugin from "@/plugins/BusyIndicator";
import axios from "axios";
import "@/scss/_main.scss";
import browserDetect from "@/plugins/browserDetect";
import osDetect from "@/plugins/osDetect";
import authService from './services/authService';
import "@/scss/fonts.scss";
import ClickOutside from '@/directives/clickOutside';
import VueAnalytics from "vue-analytics";
import VueGtm from 'vue-gtm';
import TabIndex from '@/plugins/TabIndex';
import AppNavigation from '@/plugins/AppNavigation';
import GoogleAnalytics from '@/plugins/GoogleAnalytics';
import ErrorSender from "@/services/errorSender";
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    preLoad: 2,
    error: '/assets/placeholder.png',
    loading: '/assets/placeholder.png',
    attempt: 1,
    throttleWait: 200,
})
Vue.use(GoogleAnalytics);
Vue.use(AppNavigation);
Vue.config.productionTip = false
Vue.use(BusyIndicatorPlugin, { className: "busy" });
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(vueScrollTo)
Vue.use(browserDetect);
Vue.use(osDetect);
Vue.use(TabIndex);

Vue.directive('click-outside', ClickOutside);

Vue.use(VueAnalytics, process.env.VUE_APP_GOOGLE_ANALITICS ? {
    id: process.env.VUE_APP_GOOGLE_ANALITICS
} : null);
const GOOGLE_TAG_MANAGER_ID = process.env.VUE_APP_GOOGLE_TAG_MANAGER;

Vue.use(VueGtm, {
    id: GOOGLE_TAG_MANAGER_ID,
    enabled: true,
    defer: true,
    compatibility: true,
});
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {
    if (!config.url.includes("umbraco/api/")) {
        return config;
    }
    if (!config.headers["x-origin-url"])
        config.headers["x-origin-url"] = window.location.href;
    else config.headers["x-origin-url"] = window.location.origin + config.headers["x-origin-url"];
    config.headers["x-language"] = localStorage.getItem('x-language'); 

    return config;
},
    function (error) {
        ErrorSender.Send(error);
        return Promise.reject(error);
    });
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    ErrorSender.Send(error);
    return Promise.reject(error);
});
// Vue.config.errorHandler = function (err, vm, info) {
//     var error = {
//         errMsg: err.message,
//         errstack: err.stack,
//         // vm: vm,
//         info: info
//     }
//     ErrorSender.Send(error);
// }
// window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
//     ErrorSender.Send({
//         err: errorMsg,
//         url: url,
//         lineNumber: lineNumber
//     })
//     return false;
// }
authService.startTokenRefresh();
new Vue({
    router: router,
    render: h => h(App)
}).$mount('#app')

<template>
  <a
    class="share__icons__icon"
    :class="cssClass"
    v-if="!this.$browserDetect.isApp"
    tabindex="0"
  >
    <div role="img" :title="translations['general.Share']">
      <i class="cil-share-alt"></i>
      <slot></slot>
    </div>
    <ul class="medialist">
      <li>
        <a
          :href="facebookUrl"
          target="_blank"
          @click="sendAnalytics('Udostępnij na facebooku')"
        >
          <i
            role="img"
            :title="
              translations['general.Share.Facebook'] +
              ' ' +
              title +
              ' ' +
              translations['general.OpenNewTab']
            "
            class="cib-facebook"
          ></i>
          <span class="wcag_hide"
            >Facebook {{ translations["general.OpenNewTab"] }}</span
          >
        </a>
      </li>
      <li>
        <a tabindex="0" class="" @click="shareByMail()">
          <i
            :title="translations['general.Share.Email'] + ' ' + title"
            class="cil-mail"
            aria-hidden="true"
          ></i>
          <span class="wcag_hide">Email</span>
        </a>
      </li>
    </ul>
  </a>
</template>

<script>
import { EventBus } from "@/services/event-bus.js";
export default {
  name: "ShareControlV2",  
  props: {
    translations: Object,    
    section: String,
    title: String,
    cssClass: String,
  },
  computed: {
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    },
  },
  methods: {
    shareByMail: function () {
      this.$bvModal.show("modal-shared-mail");
      this.sendAnalytics("Udostępnij przez E-mail");
      EventBus.$emit("share-content", null);
    },
    sendAnalytics(gLabel) {
      this.$setGAFormCustom(this.section, "Kliknięcie w ikonkę", gLabel, false);
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.share {
  &__icons {        
    font-family: $font-family-wotfard;    
    &__icon {
      display: grid;            
      position: relative;      
      p {        
        text-transform: uppercase;       
      }

      .medialist {
        padding-inline-start: 0;      
        overflow: hidden;
        position: absolute;        
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s;
        opacity: 0;
        li {
          display: list-item;
          padding: 0;
          opacity: 1;
          background-color: $body-bg;
          list-style-type: none;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2em;
            height: 2em;
            color: $color-black !important;
            background: #fff;
            &:hover {
              background-color: rgba(255, 255, 255, 0.7) !important;
            }
            transition: background-color 0.3s;
          }
        }
      }
      &:hover,
      &:focus,
      &:focus-within {
        .medialist {
          opacity: 1;
          max-height: 200px;
        }
      }
    }
  }

}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.CanShow() && _vm.item.value)?_c('b-container',{ref:"scrollItem",staticClass:"disableFlex",class:{
    containerFixed: !(
      (_vm.item.alias == 'boxItems' && _vm.item.value.options.type == 'modulex1') ||
      _vm.item.alias == 'parent'
    ),
  },attrs:{"fluid":"","id":_vm.item.value.id}},[_c('b-row',[_c('div',{staticClass:"ListViewer vd_list-grid only-box-view"},[(_vm.item.alias == 'boxItems' && _vm.item.value.options.type == 'modulex2')?_c('home-module-x2',{attrs:{"model":_vm.item.value,"translations":_vm.translations}}):(_vm.item.alias == 'collectionItem')?_c('home-collection',{attrs:{"model":_vm.item.value,"index":_vm.index,"translations":_vm.translations}}):(_vm.item.alias == 'banner')?_c('home-banner',{attrs:{"model":_vm.item.value}}):(_vm.item.alias == 'boxItems' && _vm.item.value.options.type == 'modulex1')?_c('home-module-x-1',{attrs:{"model":_vm.item.value,"translations":_vm.translations}}):(_vm.item.alias == 'parent')?_c('return-banner',{attrs:{"model":_vm.item.value}}):(
          _vm.isTizen ||
          (_vm.item.alias == 'boxItems' &&
            (_vm.item.value.options.type == 'grid' ||
              _vm.item.value.options.type == 'audio' ||
              _vm.item.value.options.type == 'triplegrid'))
        )?_c('home-grid',{attrs:{"model":_vm.item.value,"translations":_vm.translations,"isTriple":_vm.item.value.options.type == 'triplegrid'}}):(
          _vm.item.alias == 'boxItems' &&
          (_vm.item.value.options.type == 'loop' ||
            _vm.item.value.options.type == 'slide' ||
            _vm.item.value.options.type == 'slidebig')
        )?_c('home-carousel',{attrs:{"model":_vm.item.value,"translations":_vm.translations}}):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
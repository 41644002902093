<template>
  <div class="Banner" v-if="model.url">
    <b-link
      class="focus-smart"
      :to="model.url.url"
      :target="model.url.target"
      :title="model.url.name"
      v-if="!model.url.url.includes('http')"
    >
      <lazy-image :source="model.image.url" :alt="model.image.alt"></lazy-image>
    </b-link>
    <a
      class="focus-smart"
      :href="model.url.url"
      :target="model.url.target"
      :title="model.url.name"
      v-else
    >
      <lazy-image :source="model.image.url" :alt="model.image.alt"></lazy-image>
    </a>
  </div>
  <div class="Banner" v-else>
    <lazy-image :source="model.image.url" :alt="model.image.alt"></lazy-image>
  </div>
</template>
<script>
import LazyImage from "../common/LazyImage.vue";
export default {
  components: { LazyImage },
  props: {
    model: Object,
  },
};
</script>
<style lang="scss">
.Banner {
  text-align: center;
  max-width: 100%;
  a {
    display: inline-block;
  }
  * {
    max-width: 100%;
  }
}
</style>
<template>
  <b-container class="mx-auto color--basic" v-if="!$isTizen($browserDetect)">
    <b-row>
      <b-col class="text-center products">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="product mx-4 mt-5 p-4"
        >
          <b-img
            v-if="product.banner"
            :alt="product.title"
            :src="product.banner.url"
            class="product__banner"
          />
          <div class="product__icon mx-auto">
            <b-img
              v-if="!product.banner"
              :alt="product.title"
              :src="'/assets/' + getProductIcon(product.productKey)"
            />
          </div>
          <h2 class="product__title my-5">{{ product.title }}</h2>
          <ul class="px-0 mt-1 text-left">
            <li
              v-for="(feature, findex) in product.features"
              :key="findex"
              class="d-flex product__feature mb-2"
            >
              <b-col class="pl-2 product__feature__col-icon">
                <b-img
                  src="/assets/35_logo_white_minimal.svg"
                  :alt="translations['altText.35mmlogo']"
                />
              </b-col>
              <b-col>
                {{ feature }}
              </b-col>
            </li>
          </ul>
          <b-button
            variant="primary"
            class="button--large button--register"
            :to="register.url + '?pk=' + product.productKey"
            >{{ translations["general.Register"] }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: "Products",
  props: {
    products: Array,
    register: Object,
    translations: Object,
  },
  methods: {
    getProductIcon(pk) {
      switch (pk) {
        case 2:
          return "konto_biznes.svg";
        default:
          return "konto_vod.svg";
      }
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .product {
    display: inline-block;
    vertical-align: top;
    max-width: 90%;
    background-color: $color-dark-gray;
    padding-bottom: 150px !important;
    position: relative;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      max-width: 300px;
      min-height: 680px;
    }
    @include media-breakpoint-up(md) {
      max-width: 388px;
      min-height: 820px;
    }

    &__title {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }
    &__banner {
      height: 80px;
    }
    &__icon {
      border-radius: 10px;
      background-color: $color-gray;
      width: 130px;
      height: 126px;
      position: relative;
      > img {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__feature {
      &__col-icon {
        max-width: 2em;
      }
      &__icon {
        font-size: 1.5em;
      }
    }
    .button {
      &--register {
        white-space: nowrap;
        position: absolute !important;
        bottom: 1.6em;
        transform: translateX(-50%);
        text-transform: uppercase;
      }
    }
  }
}
</style>

<template>
  <div class="text-block-with-title">
    <div class="text-block-with-title__header mb-4">
      <div>{{ model.title }}</div>
    </div>
    <text-block
      :text="model.text"
      class="text-block-with-title__body"
    ></text-block>
  </div>
</template>

<script>
import TextBlock from "@/components/common/TextBlock";

export default {
  name: "TextBlockWithTitle",
  components: {
    TextBlock,
  },
  props: {
    model: Object,
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.text-block-with-title {
  &__header {
    font-size: em(16, 12);
    font-weight: $font-weight-bold;
    line-height: 36px;
    display: flex;
    flex-direction: row;
  }
}
</style>
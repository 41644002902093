<template>
  <div>
    <b-row class="progressBar details-header">
      <b-col :sm="10" class="">
        <b-container>
          <h3>
            {{ header }}
          </h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <b-form-group>
            <b-form-checkbox-group v-model="checkedValue">
              <b-form-checkbox
                name="some-radios"
                value="A"
                v-if="!readonly || checkedValue.includes('A')"
                :disabled="readonly"
                :class="{ checkReadonly: readonly }"
                >{{ polish.text }}
              </b-form-checkbox>
              <b-form-checkbox
                name="some-radios"
                value="B"
                v-if="!readonly || checkedValue.includes('B')"
                :disabled="readonly"
                :class="{ checkReadonly: readonly }"
              >
                {{ translations["proOrder.Other"] }}
              </b-form-checkbox>
              <b-form-tags
                v-if="!readonly"
                class="short hide-tags-add"
                ref="continetsInput"
                @focus="showSearch = true"
                @keydown.native="keyDown"
                :tag-validator="tagValidator"
                :invalid-tag-text="translations['proOrder.InvalidTagText']"
                :placeholder="
                  readonly ? '' : translations['proOrder.AddTagText']
                "
                :duplicate-tag-text="translations['proOrder.DuplicateTagText']"
                v-model="tags"
                :disabled="!checkedValue.includes('B') || readonly"
              ></b-form-tags>
              <span
                v-if="readonly && tags && checkedValue.includes('B')"
                class="pro-readonly-value tags"
                >{{ tags.join(", ") }}</span
              >
              <div
                class="searchResult"
                v-show="showSearch && searchText && items && items.length > 0"
              >
                <b-list-group>
                  <b-list-group-item
                    @click="Additem(item, 1)"
                    v-for="(item, index) in items"
                    :key="index"
                    :class="{
                      selected: selected == index,
                    }"
                  >
                    <p>
                      <span class="hover-left-right">{{ item.text }}</span>
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <slot></slot>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    translations: Object,
    languageList: Array,
    selectedLanguage: Array,
    selectedCheck: Array,
    readonly: Boolean(false),
  },
  data() {
    return {
      checkedValue: [],
      showSearch: false,
      searchText: "",
      items: Array,
      selected: 0,
      tags: [],
      language: [],
      polish: {
        text: String,
        value: String,
      },
    };
  },
  methods: {
    getItems(search) {
      var self = this;

      search = search.toLowerCase();
      var continents = self.languageList.filter((element) =>
        element.text.toLowerCase().startsWith(search)
      );
      if (continents) {
        self.items = continents;
      } else {
        self.items = [];
      }
    },
    Additem(item) {
      if (item) {
        if (!this.language.find((x) => x.value === item.value)) {
          this.language.push(item);
        }
        this.items = [];
        this.searchText = "";                  
        this.$refs.continetsInput.newTag = "";        
      }
    },
    keyDown(key) {
      if (key.key == "ArrowUp") {
        key.preventDefault();

        if (this.selected > 0) this.selected--;
      } else if (key.key == "ArrowDown") {
        key.preventDefault();
        if (this.selected < this.items.length - 1) this.selected++;
      } else if (key.key == "Enter") {
        key.preventDefault();

        this.Additem(this.items[this.selected], 1);
      } else if (!(key.key == "ArrowLeft" || key.key == "ArrowRight")) {
        this.selected = 0;
      }
    },
    tagValidator(tag) {
      this.searchText = tag;
      this.getItems(tag);
      return (
        this.items.length > 0 &&
        !this.language.includes(this.items[this.selected])
      );
    },
    tagFocus(event) {
      var temp = event;
      var time = 0;
      var self = this;
      var value = temp.type == "focus";
      if (!value) {
        time = 500;
      }
      setTimeout(() => {
        self.showSearch = value;
      }, time);
    },
  },
  watch: {
    checkedValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, "langCheck");
      },
      deep: true,
    },

    tags: function (newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        let difference = oldValue.filter((x) => !newValue.includes(x));
        var item = this.language.find(function (element) {
          return difference.includes(element.text);
        });
        var index = this.language.indexOf(item);
        this.language.splice(index, 1);
      }
    },
    language: {
      handler(newValue) {
        this.tags = newValue.map((x) => x.text);
        this.$emit("ChangeValue", newValue, "movieLang");
      },
      deep: true,
    },
    selectedLanguage(newValue) {
      if (newValue != this.language) this.language = newValue;
    },
    selectedCheck(newValue) {
      if (newValue != this.checkedValue) this.checkedValue = newValue;
    },
    languageList: {
      handler(newValue) {
        if (newValue) this.polish = newValue.find((x) => (x.value = "lang.pl"));
      },
      deep: false,
    },
  },
  mounted() {
    if (this.selectedLanguage) this.language = this.selectedLanguage;
    if (this.selectedCheck) this.checkedValue = this.selectedCheck;
    if (this.languageList)
      this.polish = this.languageList.find((x) => x.value == "lang.pl");
    this.tagsElements = document.getElementsByClassName("b-form-tags-input");
    for (var i = 0; i < this.tagsElements.length; i++) {
      var t = this.tagsElements[i];
      t.addEventListener("focus", this.tagFocus, true);
      t.addEventListener("blur", this.tagFocus, true);
    }
  },
  beforeDestroy() {
    for (var i = 0; i < this.tagsElements.length; i++) {
      var t = this.tagsElements[i];
      t.removeEventListener("focus", this.tagFocus, true);
      t.removeEventListener("blur", this.tagFocus, true);
    }
  },
};
</script>
<template>
  <div class="mx-auto mt-5 color--basic forgot-panel">
    <b-form @submit.stop.prevent="submit" v-if="!this.showSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center text-uppercase">
            <h1
              v-html="model.translations['user.ForgotPassword.ResetPassword']"
            ></h1>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col class="mx-4">
            <label for="email">{{ model.translations["general.Email"] }}</label>
            <b-form-input
              name="email"
              id="email"
              v-model="$v.state.email.$model"
              :state="validationState($v.state.email)"
              aria-describedby="error-email"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="$v.state.email.$dirty && !$v.state.email.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-email"
              v-if="$v.state.email.$dirty && !$v.state.email.email"
              >{{ model.translations["general.Errors.InvalidEmail"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              >{{ model.translations["user.ForgotPassword.SendPassword"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <modal
      header="forgotPass"
      :title="alertError"
      :show="showAlert"
      :closeText="closeText"
      @hidePop="closeModal"
    >
    </modal>
    <div class="pt-5" v-if="this.showSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center">
            {{ model.translations["user.ForgotPassword.PasswordSend"] }}
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              @click="$router.back()"
              >{{ model.translations["general.Login"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, email } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import { load } from "recaptcha-v3";
import modal from "../components/common/modal.vue";

export default {
  components: { modal },
  name: "ForgotPasswordPage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
  },
  data() {
    return {
      showAlert: false,
      showSucceeded: false,
      alertError: null,
      state: {
        email: null,
      },
    };
  },
  validations: {
    state: {
      email: { required, email },
    },
  },
  methods: {
    closeModal() {
      this.showAlert = false;
    },
    submit() {
      this.showAlert = false;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.showAlert = true;
        this.alertError =
          this.model.translations["user.ForgotPassword.ErrorSendingPassword"];
        return;
      }

      this.$setBusy(true);
      if (this.$browserDetect.isTizen) {
        authService
          .remindPassword(
            this.state.email,
            this.model.resetPassword.url,
            "Tizen"
          )
          .then(() => {
            this.showAlert = false;
            this.showSucceeded = true;
            this.$setBusy(false);
          })
          .catch((error_code) => {
            let error =
              error_code &&
              this.model.translations["user.SSOErrors." + error_code]
                ? this.model.translations["user.SSOErrors." + error_code]
                : this.model.translations[
                    "user.ForgotPassword.ErrorSendingPassword"
                  ];
            this.alertError = error;
            this.showAlert = true;
            this.$setBusy(false);
          });
      } else {
        this.recaptcha.execute("register").then((token) => {
          authService
            .remindPassword(
              this.state.email,
              this.model.resetPassword.url,
              token
            )
            .then(() => {
              this.showAlert = false;
              this.showSucceeded = true;
              this.$setBusy(false);
            })
            .catch((error_code) => {
              let error =
                error_code &&
                this.model.translations["user.SSOErrors." + error_code]
                  ? this.model.translations["user.SSOErrors." + error_code]
                  : this.model.translations[
                      "user.ForgotPassword.ErrorSendingPassword"
                    ];
              this.alertError = error;
              this.showAlert = true;
              this.$setBusy(false);
            });
        });
      }
    },
  },
  mounted() {
    load(this.model.reCaptchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.forgot-panel {
  width: 100%;
  max-width: 455px;
  .button {
    &--sendpwd {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }
  }
}
</style>
<template>
    <div class="article-image">
        <b-img fluid :src="model.image.url" :alt="model.image.title?model.image.title:model.title"></b-img>
        <div class="article-image__description">
            <p>{{ model.title }}</p>
            <p>{{ model.image.author }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArticleImage",
    props: {
        model: Object
    }
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.article-image {
    text-align: center;
    max-width: 900px;
    margin:auto;
    &__description {
        display: flex;
        justify-content: space-between;
    }
}
</style>
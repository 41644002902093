export const passwordFormat = (value) => {
    if (!value || value.length <= 0)
        return true;
    return value
        && value.length >= 6
        && /[0-9]/.test(value)
        && /[A-Z]/.test(value)
        && /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
        ? true : false;
}
export const loginFormat = (value) => {
    if (!value || value.length <= 0) return true;
    var array = Array.from(value);

    var testy = array.filter((x) => {
        return 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._@+'.indexOf(x) == -1;
    });
    return testy && testy.length == 0;
}
export const nipFormat = (value) => {
    if (!value || value.length <= 0) return true;
    
    const nipWithoutDashes = value.replace(/-/gu, ``);
    const reg = /^[0-9]{10}$/u;
    if (!reg.test(nipWithoutDashes)) {
        return false;
    }

    const dig = String(nipWithoutDashes).split(``);
    const controlValues = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const partialSums = controlValues.map((controlValue, index) => {
        return controlValue * parseInt((dig[index]).toString(), 10);
    });

    let sum = 0;
    partialSums.forEach((partialSum) => {
        sum += partialSum;
    });

    const control = sum % 11;

    if (parseInt((dig[9]).toString(), 10) === control) {
        return true;
    }

    return false;
}
export default {
    methods: {
        validationState(model) {
            if (model.$dirty) return !model.$error;
            return null;
        },
    }
}
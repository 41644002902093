<template>
  <div class="home__collection">
    <h2 v-if="model.title" :class="{ 'sr-only': model.hideTitle }">
      <b-link
        v-if="model.link && model.link.url && canRoute()"
        :to="model.link.url"
        tabindex="0"
        @click.native="sendAnalytics('Pokaż więcej')"
        class="focus-smart"
      >
        <div class="nameSection">{{ model.title }}</div>
        <div>
          <div class="showAll">{{ translations["general.ShowMore"] }}</div>
          <i class="cil-chevron-right-alt"></i>
        </div>
      </b-link>
      <div v-else>
        <div class="nameSection">{{ model.title }}</div>
      </div>
    </h2>
    <p class="descriptionItems" v-if="model.description" v-html="model.description">
    </p>
    <div>
      <b-row style="display: flex !important">
        <b-col
          cols="12"
          :md="model.displayItemsMode[indexT]"
          v-for="(item, indexT) in model.items"
          :key="indexT"
          :class="{
            'pr-md-0': indexT == 0,
            'p-md-0': indexT == 1,
            'pl-md-0': indexT == 2,
            invert: checkInvert(item),
          }"
        >
          <package
            :model="item.value"
            :translations="translations"
            v-if="item && item.value && item.alias == 'packageItem'"
          ></package>
          <list-item
            v-else-if="item && item.value && item.alias == 'movieElement'"
            :model="item.value"
            :box-view="true"
            :section="'Sekcja: ' + model.title"
            :alternative="true"
            displayType="grid"
            :translations="translations"
          ></list-item>
          <gallery
            :item="item"
            :translations="translations"
            :title="model.title"
            :index="getUniqueId(indexT)"
            v-else-if="item && item.value && item.alias == 'aditionalMaterialGallery'"
          >
          </gallery>
          <div
            class="vd_list-item hover-outline item-video"
            v-else-if="item && item.value && item.alias == 'shortMoviePicker'"
          >
            <player
              :assetId="item.value.atdId"
              :jumpTime="item.value.umpTime"
              :translations="translations"
              :imageUrl="item.value.image.url"
              :lang="item.value.lang"
            >
            </player>
          </div>
          <div
            v-if="item && item.value && item.alias == 'textElement'"
            class="collection__item"
            :class="{
              'background--medium-gray color--black': !item.value.invert,
              'color--main background--dark-gray border2': item.value.invert,
            }"
          >
            <div class="collection__item__content text-center">
              <div
                class="collection__item__content--title"
                :class="{ invert: item.value.invert }"
              >
                <h3>{{ item.value.title }}</h3>
              </div>
              <div
                class="collection__item__content--text"
                v-if="item.value.description"
                v-html="item.value.description"
              ></div>
              <div class="collection__item__content--button">
                <b-button
                  v-if="item.value.link"
                  :to="item.value.link.url"
                  :variant="item.value.invert ? 'primary' : 'secondary'"
                  class="bottom--button"
                >
                  {{ translations["general.ShowMore"] }}
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/Lists/ListItem";
import Gallery from "../common/Gallery.vue";
import Package from "../Lists/Package.vue";
import Player from "../common/Player.vue";

export default {
  name: "HomeCollection",
  components: {
    ListItem,
    Gallery,
    Package,
    Player,
  },
  data() {
    return {
      galleryOptions: {
        autoplay: false,
        interval: 5000,
        pagination: false,
        pauseOnHover: false,
        rewind: false,
        type: "loop",
      },
    };
  },
  props: {
    model: Object,
    translations: Object,
    index: Number,
  },
  methods: {
    sendAnalytics(gAction) {
      this.$setGAFormCustom("Sekcja: " + this.model.title, gAction, "", false);
    },
    getUniqueId(index) {
      return this.index * 1000 + index;
    },
    canRoute() {
      return !(
        this.$route.fullPath == this.model.headerUrl ||
        this.$route.fullPath == this.model.headerUrl + "/#"
      );
    },
    checkInvert(item) {
      return item && item.value && item.value.invert;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.home__collection {
  .Player {
    height: 100%;
    max-height: unset;
    min-height: unset;
    margin-left: 4px;
    margin-right: 4px;
  }
  > div {
    padding: 23px 0;
    @include media-breakpoint-down(sm) {
      > .row {
        width: 100%;
        margin: 0 !important;
        > div {
          padding: 0;
          margin-bottom: 15px;
          .vd_list-item {
            margin: 0;
          }
        }
      }
    }
  }
  .btn-secondary {
    background-color: $color-black;
    border-color: $color-black;
  }

  .vd_list-item {
    min-height: 426px;
    height: 100%;
    max-height: 550px;
    display: block;
    .image__item {
      max-height: 100%;
    }

    .link {
      width: 100%;
      height: 100%;
    }
    &.gallery-hover {
      .cil-images {
        position: absolute;
        top: 0;
        right: 0;
        font-size: em(30);
        transform: translate(-50%, 50%);
        padding: 15px;
        transition: all 0.6s;
        pointer-events: none;
        border-radius: 4px;
        z-index: 1;
        &:after {
          background: black;
          opacity: 0.6;
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        &:before {
          z-index: 2;
          position: relative;
        }
      }
      &:hover {
        .cil-images {
          transform: translate(-50%, 50%) scale(1.1);
        }
      }
    }
  }
  .carousel {
    > .splide {
      display: none !important;
    }
  }
  .row {
    @include media-breakpoint-up(md) {
      :first-child {
       &:not(.rgpl-controls__group) > * {
          margin-left: 0;
        }
      }
      :last-child {
        &:not(.rgpl-controls__group) > * {
          margin-right: 0;
        }
      }
    }
  }
  .Player {
    height: 100%;
    max-height: unset;
    min-height: unset;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.collection {
  &__item {
    flex-basis: calc(25% - 8px);
    margin-left: 4px;
    margin-right: 4px;
    min-width: 260px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-grow: 1;
    transition: 0.5s;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100%;
    max-height: 550px;
    min-height: 426px;

    &__content {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--title {
        font-size: em(28);
        font-weight: $font-weight-extra-bold;
        line-height: 1.03;
        padding: 18px 5px;

        text-decoration: none;
        background-image: linear-gradient(transparent, transparent),
          linear-gradient(#000, #000);
        background-size: 0 2px, 100% 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        padding-bottom: 6px !important;

        display: inline-block;
        margin: 0 auto;
        &.invert {
          background-image: linear-gradient(transparent, transparent),
            linear-gradient($color-main, $color-main);
        }
        h3 {
          font-size: 1em;
        }
      }

      &--text {
        padding-top: em(12);
        padding-left: em(35);
        padding-right: em(35);
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &--button {
        margin-top: 16px;
        margin-bottom: 5px;
      }
    }
  }
}
.border2 {
  border: 2px solid;
  top: -2px;
}
</style>

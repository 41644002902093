<template>
  <div
    id="content"
    class="content"
    v-if="model"
    :class="{
      mobileApp: this.$browserDetect.isApp,
      Tizen: $isTizen($browserDetect),
    }"
  >
    <block-avoidance
      v-if="!$isTizen($browserDetect)"
      :alias="model.content.alias"
      :translations="model.compositions.translations"
    ></block-avoidance>

    <navbar-menu
      v-if="model.compositions.menu"
      :model="model.compositions.menu"
      :translations="model.compositions.translations"
    ></navbar-menu>
    <div class="page-content">
      <home-page v-if="displayFor('home')" :model="model.content"></home-page>
      <quiz v-else-if="displayFor('quiz')" :model="model.content"></quiz>
      <contact-page
        v-else-if="displayFor('contact')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></contact-page>
      <faq-page v-else-if="displayFor('faq')" :model="model.content"></faq-page>
      <login-page
        v-else-if="displayFor('login')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
        :registerExternal="model.compositions.registerExternal"
      ></login-page>
      <register-products-page
        v-else-if="displayFor('registerProducts')"
        :model="model.content"
      ></register-products-page>
      <register-page
        v-else-if="displayFor('register')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></register-page>
      <register-external-page
        v-else-if="displayFor('registerExternal')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></register-external-page>
      <profile-page
        v-else-if="displayFor('profile')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></profile-page>
      <delete-account
        v-else-if="displayFor('deleteAccount')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></delete-account>
      <forgot-password-page
        v-else-if="displayFor('forgotPassword')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></forgot-password-page>
      <reset-password-page
        v-else-if="displayFor('resetPassword')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></reset-password-page>
      <change-password-page
        v-else-if="displayFor('changePassword')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></change-password-page>
      <change-email-page
        v-else-if="displayFor('changeEmail')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></change-email-page>
      <orders-page v-else-if="displayFor('orders')" :model="model.content"></orders-page>
      <lgcode-page v-else-if="displayFor('lgcode')" :model="model.content"></lgcode-page>
      <lesson-form
        v-else-if="displayFor('lessonForm')"
        :model="model.content"
      ></lesson-form>
      <movie-page
        v-else-if="displayFor('asset') || displayFor('series')"
        :model="model.content"
      ></movie-page>
      <movie-page-aI
        v-else-if="displayFor('assetAI')"
        :model="model.content"
      ></movie-page-aI>
      <my-lessons v-else-if="displayFor('myLessons')" :model="model.content"></my-lessons>
      <lesson-page v-else-if="displayFor('lesson')" :model="model.content"></lesson-page>
      <article-page
        v-else-if="displayFor('article')"
        :model="model.content"
      ></article-page>
      <search-aI-page
        v-else-if="displayFor('searchAI')"
        :model="model.content"
      ></search-aI-page>
      <b-container fluid class="containerFixed" v-else-if="displayFor('list-view')">
        <b-row>
          <grid-container :model="model.content"></grid-container>
        </b-row>
      </b-container>
      <lesson-result-page
        v-else-if="displayFor('lessonResults')"
        :model="model.content"
      ></lesson-result-page>
      <error-page
        v-else-if="displayFor('error-page')"
        :model="model.content"
        :status-code="model.statusCode"
      ></error-page>
      <sitemap-page
        v-else-if="displayFor('sitemapPage')"
        :model="model.content"
      ></sitemap-page>
      <favorites-page
        v-else-if="displayFor('favorites')"
        :model="model.content"
      ></favorites-page>
      <pro-form
        v-else-if="displayFor('proOrder')"
        :model="model.content"
        :closeText="model.compositions.translations['general.Close']"
      ></pro-form>
      <playlists-page
        v-else-if="displayFor('userPlaylists')"
        :model="model.content"        
      ></playlists-page>
      <playlist-page
        v-else-if="displayFor('playlist')"
        :model="model.content"
        :isUserPlaylist="false"
      ></playlist-page>      
      <playlist-page
        v-else-if="displayFor('userPlaylist')"
        :model="model.content"
        :isUserPlaylist="true"
      ></playlist-page>        
    </div>
    <institution
      v-if="model.compositions.partners"
      :model="model.compositions.partners"
      :translations="model.compositions.footer.translations"
    ></institution>
    <footer-bar
      v-if="model.compositions.footer"
      :model="model.compositions.footer"
    ></footer-bar>
    <institution
      v-if="model.compositions.sponsors"
      :model="model.compositions.sponsors"
      :translations="model.compositions.footer.translations"
    ></institution>
    <modal
      v-if="model.compositions"
      header="favorite"
      :title="model.compositions.translations['general.Favorite.Add']"
      :show="showModal"
      :closeText="model.compositions.translations['general.Close']"
      @hidePop="hideModal"
    >
      <template v-slot:main>
        <p class="b30">
          {{ model.compositions.translations["general.FavoritesMessage"] }}
        </p>
      </template>
      <template v-slot:btn>
        <b-link
          :to="model.compositions.menu.secondary.userOption.login.url"
          class="text-uppercase btn btn-primary focus-smart"
        >
          {{ model.compositions.menu.secondary.userOption.login.name }}
        </b-link>
      </template>
    </modal>
    <welcome
      v-if="!this.$browserDetect.isApp && model.compositions.welcome"
      :model="model.compositions.welcome"
      :translations="model.compositions.translations"
    ></welcome>
  </div>
</template>

<script>
import { EventBus } from "@/services/event-bus";

export default {
  name: "BasePage",
  props: {
    model: Object,
  },
  components: {
    PlaylistsPage:() => import("@/views/PlaylistsPage"),
    PlaylistPage:() => import("@/views/PlaylistPage"),
    NavbarMenu: () => import("@/components/NavBar/NavbarMenu"),
    BlockAvoidance: () => import("@/components/NavBar/BlockAvoidance"),
    Quiz: () => import("@/views/Quiz"),
    HomePage: () => import("@/views/HomePage"),
    FooterBar: () => import("@/components/Footer/FooterBar"),
    ContactPage: () => import("@/views/ContactPage"),
    FaqPage: () => import("@/views/FaqPage"),
    LoginPage: () => import("@/views/LoginPage"),
    RegisterProductsPage: () => import("@/views/RegisterProductsPage"),
    RegisterPage: () => import("@/views/RegisterPage"),
    RegisterExternalPage: () => import("@/views/RegisterExternalPage"),
    ProfilePage: () => import("@/views/ProfilePage"),
    DeleteAccount: () => import("@/views/DeleteAccount"),
    ForgotPasswordPage: () => import("@/views/ForgotPasswordPage"),
    ResetPasswordPage: () => import("@/views/ResetPasswordPage"),
    ChangePasswordPage: () => import("@/views/ChangePasswordPage"),
    ChangeEmailPage: () => import("@/views/ChangeEmailPage"),
    LessonForm: () => import("@/views/LessonForm"),
    LessonPage: () => import("@/views/LessonPage"),
    MoviePage: () => import("./MoviePage.vue"),
    MoviePageAI: () => import("./MoviePageAI.vue"),
    MyLessons: () => import("@/views/MyLessons"),
    Institution: () => import("../components/Footer/Institution.vue"),
    ArticlePage: () => import("@/views/ArticlePage"),
    SearchAIPage: () => import("@/views/SearchAIPage"),
    GridContainer: () => import("@/components/Lists/GridContainer"),
    LessonResultPage: () => import("@/views/LessonResultPage"),
    ErrorPage: () => import("@/views/ErrorPage"),
    SitemapPage: () => import("@/views/SitemapPage"),
    FavoritesPage: () => import("@/views/FavoritesPage"),
    OrdersPage: () => import("@/views/OrdersPage"),
    LgcodePage: () => import("@/views/LgcodePage"),
    ProForm: () => import("@/views/ProForm.vue"),
    Welcome: () => import("../components/common/Welcome.vue"),
    Modal: () => import("../components/common/modal.vue"),
  },
  data() {
    return {
      metaTitle: "",
      showFavoritesAlert: false,
      modalId: null,
      showModal: false,
    };
  },
  mounted() {
    EventBus.$on("ShowFavoritesAlert", this.modalShow);
    this.$root.$on("bv::modal::show", this.modalAddId);
    this.$root.$on("bv::modal::hide", this.modalClear);
  },
  methods: {
    modalShow() {
      this.showModal = true;
    },
    modalAddId(event, id) {
      this.modalId = id;
    },
    modalClear() {
      this.modalId = null;
    },
    displayFor(alias) {
      return this.model.content.alias == alias;
    },
    hideModal() {
      this.showModal = false;
    },
    clearChild(elem) {
      if (elem.$children)
        elem.$children.forEach(function (x) {
          x.$destroy();
        });
      if (this != elem) elem.$destroy();
    },
  },
  beforeDestroy() {
    // this.$el.remove();
    this.clearChild(this);
    // this.$el.parentNode.removeChild(this.$el);
    EventBus.$off("ShowFavoritesAlert", this.modalShow);
    this.$root.$off("bv::modal::show", this.modalAddId);
    this.$root.$off("bv::modal::hide", this.modalClear);
  },
  closeModal() {
    this.$bvModal.hide(this.modalId);
  },
};
</script>
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
.content {
  position: relative;
}
.page-content {
  width: 100%;
  padding-bottom: 20px;
  @include media-breakpoint-up(md) {
    padding-bottom: 44px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 60px;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 62px;
    // margin-top: 70px;
  }
}
</style>

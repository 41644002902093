<template>
  <div class="contact-page color--basic">
    <b-container>
      <b-row>
        <b-col cols="12" class="mt-5 text-center">
          <h1 v-html="model.headlineText"></h1>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="!$browserDetect.isApp">
      <div v-if="!isFormSubmitted" class="contact-page__form">
        <b-form @submit.stop.prevent="submit">
          <b-container>
            <b-row align-h="center" class="pt-3">
              <b-col cols="12" md="8" lg="6">
                <label for="subject">{{
                  model.translations["contact.Subject.Label"]
                }}</label>
                <b-form-select
                  id="subject"
                  name="subject"
                  v-model="$v.state.subject.$model"
                  :options="model.subjects"
                  :state="validationState($v.state.subject)"
                  aria-describedby="error-subject"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>
                      {{ model.translations["general.Errors.SelectOneOption"] }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="error-subject">
                  <span
                    v-if="$v.state.subject.$dirty && !$v.state.subject.required"
                  >
                    {{ model.translations["general.Errors.Required"] }}
                  </span>
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" class="pt-3">
              <b-col cols="12" md="8" lg="6">
                <label for="firstName">{{
                  model.translations["general.FirstName"]
                }}</label>
                <b-form-input
                  name="firstName"
                  id="firstName"
                  v-model="$v.state.firstName.$model"
                  :state="validationState($v.state.firstName)"
                  aria-describedby="error-firstname"
                ></b-form-input>
                <b-form-invalid-feedback id="error-firstname"
                  ><span
                    v-if="
                      $v.state.firstName.$dirty && !$v.state.firstName.required
                    "
                    >{{ model.translations["general.Errors.Required"] }}</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" class="pt-3">
              <b-col cols="12" md="8" lg="6">
                <label for="lastName">{{
                  model.translations["general.LastName"]
                }}</label>
                <b-form-input
                  name="lastName"
                  id="lastName"
                  v-model="$v.state.lastName.$model"
                  :state="validationState($v.state.lastName)"
                  aria-describedby="error-lastname"
                ></b-form-input>
                <b-form-invalid-feedback id="error-lastname"
                  ><span
                    v-if="
                      $v.state.lastName.$dirty && !$v.state.lastName.required
                    "
                    >{{ model.translations["general.Errors.Required"] }}</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" class="pt-3">
              <b-col cols="12" md="8" lg="6">
                <label for="email">{{
                  model.translations["general.Email"]
                }}</label>
                <b-form-input
                  name="email"
                  id="email"
                  v-model="$v.state.email.$model"
                  :state="validationState($v.state.email)"
                  aria-describedby="error-email"
                ></b-form-input>
                <b-form-invalid-feedback id="error-email"
                  ><span
                    v-if="$v.state.email.$dirty && !$v.state.email.required"
                  >
                    {{ model.translations["general.Errors.Required"] }}
                  </span>
                  <span
                    v-else-if="$v.state.email.$dirty && !$v.state.email.email"
                  >
                    >{{ model.translations["general.Errors.InvalidEmail"] }}
                  </span>
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" class="pt-3">
              <b-col cols="12" md="8" lg="6">
                <label for="message">{{
                  model.translations["contact.Message.Label"]
                }}</label>
                <b-form-textarea
                  name="message"
                  id="message"
                  rows="4"
                  v-model="$v.state.message.$model"
                  :state="validationState($v.state.message)"
                  aria-describedby="error-message"
                ></b-form-textarea>
                <b-form-invalid-feedback id="error-message"
                  ><span
                    v-if="$v.state.message.$dirty && !$v.state.message.required"
                    >{{ model.translations["general.Errors.Required"] }}
                  </span>
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" class="pt-3">
              <b-col cols="12" lg="6" class="text-center padding-top">
                <b-button type="submit" variant="primary" class="button--large"
                  >{{ model.translations["contact.Button.Label"] }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="pt-5">
              <b-col
                v-html="model.translations['general.GoogleCaptchaTerms']"
                class="text-center text-muted recaptcha-note"
              >
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </div>
      <div v-if="isFormSubmitted">
        <b-container>
          <b-row>
            <b-col cols="12" class="mt-5 text-center">
              <div v-html="model.confirmationText"></div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <modal
        header="contact"
        :title="model.translations['general.Errors.InvalidForm']"
        :show="hasValidationError"
        :closeText="closeText"
        @hidePop="closeModal"
      >
      </modal>
    </div>
    <div v-else>
      <b-container>
        <b-row>
          <b-col cols="12" class="mt-5 text-center" v-html="model.tvText"> </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import { load } from "recaptcha-v3";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import modal from "../components/common/modal.vue";

export default {
  components: { modal },
  name: "ContactPage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
  },
  mounted() {
    load(this.model.reCaptchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
  data() {
    return {
      isFormSubmitted: false,
      hasValidationError: false,
      state: {
        subject: null,
        firstName: null,
        lastName: null,
        email: null,
        message: null,
      },
    };
  },
  validations: {
    state: {
      subject: { required },
      firstName: { required },
      lastName: { required },
      email: { required, email },
      message: { required },
    },
  },
  methods: {
    closeModal() {
      this.hasValidationError = false;
    },
    scrollToFirstInvalidElement() {
      var element = this.$el.getElementsByClassName("is-invalid")[0];
      if (element != undefined) {
        this.$scrollTo(element, 200, { offset: -80 });
      }
    },
    submit() {
      this.hasValidationError = false;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        // this.hasValidationError = true;
        return;
      }
      this.$setBusy(true);
      if (this.$browserDetect.isTizen) {
        axios({
          method: "POST",
          url: process.env.VUE_APP_BASE_API_URL + this.model.submitFormUrl,
          data: { ...this.state, captchaToken: "Tizen" },
        })
          .then((response) => {
            if (response.status == 200) {
              this.isFormSubmitted = true;
            } else {
              this.hasValidationError = true;
            }
            this.$setBusy(false);
          })
          .catch(() => {
            this.hasValidationError = true;
            this.$setBusy(false);
          });
      } else {
        this.recaptcha.execute("register").then((token) => {
          axios({
            method: "POST",
            url: process.env.VUE_APP_BASE_API_URL + this.model.submitFormUrl,
            data: { ...this.state, captchaToken: token },
          })
            .then((response) => {
              if (response.status == 200) {
                this.isFormSubmitted = true;
              } else {
                this.hasValidationError = true;
              }
              this.$setBusy(false);
            })
            .catch(() => {
              this.hasValidationError = true;
              this.$setBusy(false);
            });
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.recaptcha-note {
  font-size: $font-size-smaller;
  a {
    color: white;
  }
}
</style>
export default {
    install: function (Vue) {
        Vue.prototype.$getTabIndex = function () {
            if (this.$browserDetect.isApp)
                return -1;
            else
                return 0;

        };
    }
}

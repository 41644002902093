<template>
  <div>
    <b-modal
      id="modal-newsletter"
      :title="translation['layout.Footer.Newsletter']"
      size="md"
      centered
      hide-footer
    >
      <div v-if="!isFormSubmitted" class="mx-auto">
        <b-form @submit.stop.prevent="submit">
          <b-container>
            <transition name="fade">
              <b-row v-if="hasValidationError">
                <b-col class="text-center">
                  <b-alert class="modal-alert" show variant="danger">{{
                    translation["layout.Footer.Newsletter.SubscribedError"]
                  }}</b-alert>
                </b-col>
              </b-row>
            </transition>
            <b-row>
              <b-col>
                <label class="color--basic" for="email">
                  {{
                    translation[
                      "layout.Footer.Newsletter.Subscribe.Description"
                    ]
                  }}
                </label>
                <b-form-input
                  name="email"
                  id="email"
                  v-model="$v.state.email.$model"
                  :state="validationState($v.state.email)"
                  aria-describedby="error-email"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  id="error-email"
                  v-if="$v.state.email.$dirty && !$v.state.email.required"
                  >{{ translation["general.Errors.Required"] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  id="error-email"
                  v-else-if="$v.state.email.$dirty && !$v.state.email.emal"
                  >{{ translation["general.Errors.InvalidEmail"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="pt-5 pb-3">
              <b-col class="mx-4 text-center">
                <b-button
                  type="submit"
                  variant="primary"
                  class="text-uppercase"
                >
                  {{ translation["layout.Footer.Newsletter.Subscribe"] }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="isFormSubmitted">
          <b-container>
            <b-row>
              <b-col class="color--basic text-center">
                {{ translation["layout.Footer.Newsletter.Subscribed"] }}
              </b-col>
            </b-row>
            <b-row class="pt-5 pb-3">
              <b-col class="mx-4 text-center">
                <b-link
                  @click="hideModal"
                  class="text-uppercase btn btn-primary focus-smart"
                  >{{ translation["general.Close"] }}
                </b-link>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </transition>
    </b-modal>
  </div>
</template>
<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "NewsleterModal",
  mixins: [ValidationMixin],
  props: {
    newsletterSubmitUrl: String,
    translation: Object,
  },
  data() {
    return {
      hasValidationError: false,
      isFormSubmitted: false,
      confirmationText: "",
      state: {
        email: null,
      },
    };
  },
  validations: {
    state: {
      email: { required, email },
    },
  },
  methods: {
    submit() {
      this.hasValidationError = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.hasValidationError = true;
        return;
      }
      this.$setBusy(true);
      axios({
        method: "POST",
        url: process.env.VUE_APP_BASE_API_URL + this.newsletterSubmitUrl,
        data: { ...this.state },
      })
        .then((response) => {
          if (response.status == 200) {
            this.sendAnalitycs();
            this.isFormSubmitted = true;
          } else {
            this.hasValidationError = true;
          }
          this.$setBusy(false);
        })
        .catch(() => {
          this.hasValidationError = true;
          this.$setBusy(false);
        });
    },
    hideModal() {
      this.$bvModal.hide("modal-newsletter");
    },
    sendAnalitycs() {
      this.$setGAFormFooter("Zapisanie się na newsletter", "", false);
    },
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
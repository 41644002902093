<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <div class="short">
            <b-form-group 
              v-if="!readonly"
              :label="label">
              <b-input
                :disabled="disabled"
                :placeholder="placeholder"
                v-model="value"
                :type="type"
              ></b-input>
            </b-form-group>
            <span v-if="readonly" class="pro-readonly-value">{{value}}</span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: null,
    };
  },
  props: {
    header: String,
    disabled: Boolean,
    placeholder: String,
    label: String,
    input: String,
    name: String,
    type: String,
    readonly: Boolean,
  },
  watch: {
    value(newValue) {
      this.$emit("ChangeValue", newValue, this.name);
    },
    input(newValue) {
      if (newValue != this.value) this.value = newValue;
    },
  },
  mounted() {
    if (this.input) this.value = this.input;
  },
};
</script>
<style lang="scss" scoped>
</style>
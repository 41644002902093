<template>
  <div class="TvInfo">
    <p>
      {{ message }}
      <a :href="url">{{ url }}</a>
    </p>
    <div class="focus-smart qr-code">
      <qr-code :size="500" class="" :text="url"> </qr-code>
    </div>
  </div>
</template>
<script>
import QrCode from "vue-qrcode-component";
export default {
  components: { QrCode },
  props: {
    message: String,
  },
  data() {
    return {
      url: location.href,
    };
  },
};
</script>
<style lang="scss">
.TvInfo {
  max-width: em(600);
  margin: auto;
  a {
    color: #dedede;
    border-bottom: 1px solid;
    word-wrap: break-word;
  }
  .qr-code {
    max-width: 600px;
    margin: auto;
    height: 600px;
    background: white;
    position: relative;
    &.focus-smart {
      &:before {
        // border: none !important;
      }
    }
    img {
      max-width: unset !important;
    }
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
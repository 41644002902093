<template>
  <div class="mx-auto mt-5 color--basic chemail-panel">
    <b-form @submit.stop.prevent="submit" v-if="!this.showSucceeded">
      <b-container>
        <b-row>
          <b-col class="text-center text-uppercase">
            <h1 v-html="model.translations['user.ChangeEmail']"></h1>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="email">{{ model.translations["general.Email"] }}</label>
            <b-form-input
              name="email"
              id="email"
              v-model="$v.state.email.$model"
              :state="validationState($v.state.email)"
              aria-describedby="error-email"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-email"
              v-if="$v.state.email.$dirty && !$v.state.email.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="error-email"
              v-else-if="$v.state.email.$dirty && !$v.state.email.email"
              >{{ model.translations["general.Errors.InvalidEmail"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col class="text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendpwd"
              >{{
                model.translations["user.ChangeEmail.SendVerificationEmail"]
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <modal
      header="changEmail"
      :title="alertError"
      :show="showAlert"
      :closeText="closeText"
      @hidePop="closeModal"
    >
    </modal>
    <div class="pt-5" v-if="this.showSucceeded">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center">
            {{ model.translations["user.ChangeEmail.VerificationEmailSent"] }}
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--sendemail"
              @click="$router.back()"
              >{{ model.translations["user.AccountData"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, email } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import modal from "../components/common/modal.vue";

export default {
  components: { modal },
  name: "ChangeEmailPage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    closeText: String,
  },
  data() {
    return {
      showSucceeded: false,
      alertError: "",
      showAlert: false,
      state: {
        email: null,
      },
    };
  },
  validations: {
    state: {
      email: { required, email },
    },
  },
  methods: {
    closeModal() {
      this.showAlert = false;
    },
    submit() {
      this.showAlert = false;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.showAlert = true;
        this.saveSucceeded = false;
        this.alertError =
          this.model.translations[
            "user.ChangeEmail.ErrorSendingVerificationEmail"
          ];
        return;
      }

      this.$setBusy(true);
      authService
        .changeEmail(this.state.email)
        .then(() => {
          this.showSucceeded = true;
          this.$setBusy(false);
        })
        .catch((error_code) => {
          let error =
            error_code &&
            this.model.translations["user.SSOErrors." + error_code]
              ? this.model.translations["user.SSOErrors." + error_code]
              : this.model.translations[
                  "user.ChangeEmail.ErrorSendingVerificationEmail"
                ];
          this.alertError = error;
          this.showAlert = true;
          this.$setBusy(false);
        });
      this.$setGAFormProfile("Zmienił adres e-mail", "", false);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.chemail-panel {
  width: 100%;
  max-width: 420px;
  .button {
    &--sendemail {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('div',{staticClass:"white-two"},[_c('b-container',[_c('b-row',{staticClass:"progressBar"},[_c('b-col',{attrs:{"sm":10}},[_c('h2',[_vm._v(" "+_vm._s(_vm.translations["proOrder.SelectedMovie"])+" ")])])],1)],1)],1),_c('div',{staticClass:"form"},[_c('b-container',[_c('b-row',{staticClass:"progressBar"},[_c('b-col',{attrs:{"sm":10}},_vm._l((_vm.assets),function(item,index){return _c('accordion',{key:index,attrs:{"tabId":'name' + index,"accordionName":"Movies","role":"tab"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(_vm._s(item.title))]),_c('i',{staticClass:"cil-chevron-bottom-alt"}),_c('i',{staticClass:"cil-chevron-left-alt"})]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"info"},[_c('p',[_vm._v(_vm._s(item.category))]),(
                      item.showOnBox != null &&
                      item.showOnBox.person &&
                      item.showOnBox.person.length > 0
                    )?_c('p',{staticClass:"person"},[_c('span',[_vm._v(_vm._s(item.showOnBox.roleName)+":")]),_c('span',[_vm._v(_vm._s(item.showOnBox.person[0]))])]):_vm._e(),_c('p',{staticClass:"split"},[_c('span',[_vm._v(_vm._s(item.country))]),_c('span',[_vm._v(_vm._s(item.year))])])]),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.lead)+" ")]),_c('b-button',{on:{"click":function($event){return _vm.changeStatus(item.id)}}},[_vm._v(_vm._s(_vm.translations["proOrder.Delete"]))])]},proxy:true}],null,true)})}),1)],1),_c('b-row',{staticClass:"progressBar"},[_c('b-col',{attrs:{"sm":6}},[_c('b-form-group',{attrs:{"label":_vm.translations['proOrder.AddPosition']}}),_c('b-input',{attrs:{"type":"search","placeholder":_vm.translations['proOrder.SearchMovie']},on:{"focus":_vm.tagFocus,"keydown":_vm.keyDown,"blur":_vm.tagFocus},model:{value:(_vm.AssetsFilters.searchText),callback:function ($$v) {_vm.$set(_vm.AssetsFilters, "searchText", $$v)},expression:"AssetsFilters.searchText"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.AssetsFilters.showSearch &&
                _vm.AssetsFilters.searchText &&
                _vm.AssetsFilters.items &&
                _vm.AssetsFilters.items.length > 0
              ),expression:"\n                AssetsFilters.showSearch &&\n                AssetsFilters.searchText &&\n                AssetsFilters.items &&\n                AssetsFilters.items.length > 0\n              "}],staticClass:"searchResult"},[_c('b-list-group',_vm._l((_vm.AssetsFilters.items),function(item,index){return _c('b-list-group-item',{key:index,class:{ selected: _vm.AssetsFilters.selected == index },on:{"click":function($event){return _vm.Additem(item)}}},[(
                      item.showOnBox &&
                      item.showOnBox.persons &&
                      item.showOnBox.persons.length > 0
                    )?_c('p',[_c('span',{staticClass:"hover-left-right"},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('span',[_vm._v("("+_vm._s(item.showOnBox.persons[0])+")")])])]):_c('p',[_c('span',{staticClass:"hover-left-right"},[_vm._v(_vm._s(item.title))])])])}),1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
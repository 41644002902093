<template>
  <div v-click-outside="closeDialog">
    <b-navbar-nav class="user-nav-bar d-none d-lg-block" v-if="$isTizen($browserDetect)">
      <b-nav-item
        :aria-label="userOption.name"
        :title="isLoggedIn ? userOption.loggedInAs + ' ' + username : userOption.name"
        @click="showUserMenu = !showUserMenu"
        class="dropdown-no-caret user-nav-item second-group-spacing"
        :class="[showUserMenu ? 'user-nav-item--close' : 'zoom-icon']"
      >
        <template>
          <i
            role="img"
            :title="userOption.name"
            v-if="!isLoggedIn"
            class="header-cicon"
            :class="[showUserMenu ? 'cil-x' : 'cil-user']"
          />
          <i v-else class="header-cicon" :class="[showUserMenu ? 'cil-x' : 'cis-user']" />
          <span class="wcag_hide">{{ userOption.name }}</span>
        </template>
      </b-nav-item>
      <b-navbar-nav :class="{ expand: showUserMenu }" class="user-menu">
        <b-nav-item
          v-for="(link, index) in isLoggedIn ? userLinks : null"
          :key="link.id"
          :to="link.url"
          @click="sendProfileAnalitycs(link.name)"
          v-show="isUserLinkVisible(index)"
          >{{ link.name }}<i class="user-menu__cicon" :class="getUserLinkIcon(index)"
        /></b-nav-item>
        <b-nav-item
          v-if="!isLoggedIn"
          :to="userOption.login.url + currentUrl"
          @click="sendAnalitycs('zaloguj się')"
          >{{ userOption.login.name }}<i class="cil-key user-menu__cicon" />
        </b-nav-item>
        <b-nav-item
          v-if="!isLoggedIn"
          :to="userOption.register.url"
          @click="sendAnalitycs('zarejestruj się')"
          >{{ userOption.register.name }}<i class="cil-lock-locked user-menu__cicon"
        /></b-nav-item>
        <b-nav-item v-if="isLoggedIn" v-on:click="logout"
          >{{ userOption.logout }}<i class="cil-account-logout user-menu__cicon"
        /></b-nav-item>
      </b-navbar-nav>
    </b-navbar-nav>
    <b-navbar-nav class="user-nav-bar d-none d-lg-block" v-else-if="!isCollapsedVersion">
      <b-nav-item
        :aria-label="userOption.name"
        :title="isLoggedIn ? userOption.loggedInAs + ' ' + username : userOption.name"
        @click="showUserMenu = !showUserMenu"
        class="dropdown-no-caret user-nav-item second-group-spacing"
        :class="[showUserMenu ? 'user-nav-item--close' : 'zoom-icon']"
      >
        <template>
          <i
            role="img"
            :title="userOption.name"
            v-if="!isLoggedIn"
            class="header-cicon"
            :class="[showUserMenu ? 'cil-x' : 'cil-user']"
          />
          <i v-else class="header-cicon" :class="[showUserMenu ? 'cil-x' : 'cis-user']" />
          <span class="wcag_hide">{{ userOption.name }}</span>
        </template>
        <transition name="slide" mode="out-in">
          <b-navbar-nav v-if="showUserMenu" class="user-menu">
            <b-nav-item
              v-for="(link, index) in isLoggedIn ? userLinks : null"
              :key="link.id"
              :to="link.url"
              v-show="isUserLinkVisible(index)"
              @click="sendProfileAnalitycs(link.name)"
              >{{ link.name }}<i class="user-menu__cicon" :class="getUserLinkIcon(index)"
            /></b-nav-item>
            <b-nav-item
              v-if="!isLoggedIn"
              :to="userOption.login.url + currentUrl"
              @click="sendAnalitycs('zaloguj się')"
              >{{ userOption.login.name }}<i class="cil-key user-menu__cicon" />
            </b-nav-item>
            <b-nav-item
              v-if="!isLoggedIn"
              :to="userOption.register.url"
              @click="sendAnalitycs('zarejestruj się')"
              >{{ userOption.register.name }}<i class="cil-lock-locked user-menu__cicon"
            /></b-nav-item>
            <b-nav-item
              v-if="userOption.catalog"
              @click="getCatalog()"
              >{{ userOption.catalog.name
              }}<i class="pl-2 cil-arrow-thick-square-down"></i
            ></b-nav-item>
            <b-nav-item v-if="isLoggedIn" v-on:click="logout"
              >{{ userOption.logout }}<i class="cil-account-logout user-menu__cicon"
            /></b-nav-item>
          </b-navbar-nav>
        </transition>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav v-if="isCollapsedVersion">
      <b-nav-item
        v-for="(link, index) in isLoggedIn ? userLinks : null"
        :key="link.id"
        :to="link.url"
        v-show="isUserLinkVisible(index)"
        class="navitem-hover-left-right"
        @click="sendProfileAnalitycs(link.name)"
        >{{ link.name }}<i class="pl-2 user-menu__cicon" :class="getUserLinkIcon(index)"
      /></b-nav-item>
      <b-nav-item
        v-if="!isLoggedIn"
        class="d-lg-none navitem-hover-left-right"
        :to="userOption.login.url + currentUrl"
        @click="sendAnalitycs('zaloguj się')"
        >{{ userOption.login.name }}</b-nav-item
      >
      <b-nav-item
        :to="playlists.url"
        v-if="isLoggedIn && playlists.url"
        class="d-lg-none navitem-hover-left-right"
        >{{ playlists.name }} <i class="cil-library-music imirror"></i
      ></b-nav-item>
      <b-nav-item
        v-if="!isLoggedIn"
        class="d-lg-none navitem-hover-left-right"
        :to="userOption.register.url"
        @click="sendAnalitycs('zarejestruj się')"
        >{{ userOption.register.name }}</b-nav-item
      >
      <b-nav-item
        v-if="userOption.catalog"
        target="_blank"
        rel="noopener noreferrer"
        class="d-lg-none navitem-hover-left-right"
        @click="getCatalog()"
        >{{ userOption.catalog.name }}<i class="pl-2 cil-arrow-thick-square-down"></i
      ></b-nav-item>
      <b-nav-item v-if="isLoggedIn" v-on:click="logout" class="navitem-hover-left-right"
        >{{ userOption.logout }}<i class="pl-2 cil-account-logout user-menu__cicon"
      /></b-nav-item>
    </b-navbar-nav>
  </div>
</template>
<script>
import authService from "@/services/authService";
import { EventBus } from "@/services/event-bus";
export default {
  props: {
    userOption: Object,
    isCollapsedVersion: Boolean,
    playlists: Object,
  },
  data() {
    return {
      isLoggedIn: false,
      username: String,
      showUserMenu: false,
      isProUser: false,
      currentUrl: "?returnUrl=" + location.pathname,
    };
  },
  mounted() {
    let profile = authService.getProfile();
    if (profile) {
      this.isLoggedIn = true;
      this.username = profile.username;
      this.isProUser = profile.is_pro;
    } else {
      this.isLoggedIn = false;
    }
    EventBus.$on("closeNavEvent", this.closeDialog);
  },
  methods: {
    closeDialog: function () {
      this.showUserMenu = false;
    },
    logout: function () {
      authService.logout();
      this.sendProfileAnalitycs("Wyloguj");
      this.$router.push({
        path: this.userOption.logoutLink,
        query: { logout: new Date().getTime() },
      });
    },
    getUserLinkIcon(index) {
      switch (index) {
        case 0:
          return "cil-user";
        case 1:
          return "cil-heart";
        case 2:
          return "cil-tv";
        case 3:
          return "cil-money";
        case 4:
          return "cil-shop-media";
      }
    },
    sendAnalitycs(action) {
      this.$setGAFormNavigation(action, "", false);
    },
    sendProfileAnalitycs(label) {
      this.$setGAFormProfile("Kliknął", label, false);
    },
    isUserLinkVisible(index) {
      return (
        !this.$isTizen(this.$browserDetect) ||
        !this.userLinks ||
        index < 0 ||
        index >= this.userLinks.length ||
        !this.userLinks[index].name.includes("TV")
      );
    },
    getCatalog() {
      window.open(`${process.env.VUE_APP_BASE_API_URL}umbraco/api/file/movie?lang=${localStorage.getItem("x-language")}`, '_blank');
      this.sendAnalitycs("pobierz katalog");
    },
  },
  beforeDestroy() {
    EventBus.$off("closeNavEvent", this.closeDialog);
  },
  computed: {
    userLinks: function () {
      return this.userOption.links;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.user-nav-bar {
  position: relative;
  .user-menu {
    position: absolute;
    top: 61px;
    background-color: $color-nero;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    transform: translateX(-50%);
    &__cicon {
      font-size: 1.3em;
      padding-left: 15px;
      vertical-align: middle;
    }
    .nav-item {
      width: 13.125em;
      text-align: end;
      padding-right: 10px;
    }
  }
  .user-nav-item {
    z-index: 4;
    // height: 66px;
    // position: absolute;
    // top: -27px;
    // left: -0.625em;
    // width: 52px;
    // text-align: center;
    // padding-top: 6px;
    margin-left: 0;

    &--close {
      background-color: $color-nero;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.imirror {
  display: inline-block;
  transform: scaleX(-1);
}
</style>

const VueBrowserDetect = {
    install: Vue => {
        let standalone = window.navigator.standalone;
        let ios = /iphone|ipod|ipad/.test(navigator.userAgent);
        let ua = window.navigator.userAgent;
        let browserObj = {};
        browserObj.ua = ua;
        browserObj.isMobile =
            (navigator.userAgent.indexOf(" Mobile") >= 0
                || navigator.userAgent.indexOf(" IEMobile") >= 0
                || navigator.userAgent.indexOf("Opera Mobi") >= 0)
            &&
            (window.innerWidth <= 800) && (window.innerHeight <= 600);

        browserObj.isOpera =
            !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

        browserObj.isEdge = /Edge|Edg/.test(navigator.userAgent);
        browserObj.isFirefox = /Firefox/.test(navigator.userAgent);
        /*eslint-disable */
        browserObj.isSafari = (/constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window["safari"] || (safari && safari.pushNotification)))
        /*eslint-ensable */
        browserObj.isSafari2 =
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window["safari"] || safari.pushNotification);
        browserObj.isIE = /*@cc_on!@*/ false || !!document.documentMode;

        browserObj.isChrome = /Google Inc/.test(navigator.vendor);
        browserObj.isChromeIOS = /CriOS/.test(navigator.userAgent);
        browserObj.isTizen = /SMART-TV/.test(navigator.userAgent) || /Tizen/.test(navigator.userAgent);
        // browserObj.isTizen = true;
        browserObj.isApp = (/(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(navigator.userAgent)
            || (ios && !standalone && !browserObj.isSafari)
            || browserObj.isTizen || (/Macintosh/i.test(navigator.userAgent) && (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)));
        // browserObj.isApp = true;
        browserObj.meta = browserSpecs();
        if (ua.indexOf("Firefox") > -1) {
            browserObj.name = "Mozilla Firefox";
            browserObj.maker = "Mozilla";
        } else if (ua.indexOf("SamsungBrowser") > -1) {
            browserObj.name = "Samsung Internet";
            browserObj.maker = "Samsung"
        } else if (ua.indexOf("Opera") > -1 || ua.indexOf("OPR") > -1) {
            browserObj.name = "Opera";
            browserObj.maker = "Opera";
        } else if (ua.indexOf("Trident") > -1) {
            browserObj.name = "Microsoft Internet Explorer";
            browserObj.maker = "Microsoft";
        } else if (ua.indexOf("Edge") > -1) {
            browserObj.name = "Microsoft Edge (Legacy)";
            browserObj.maker = "Microsoft";
        } else if (ua.indexOf("Edg") > -1) {
            browserObj.name = "Microsoft Edge (Chromium)";
            browserObj.maker = "Microsoft";
        } else if (ua.indexOf("CriOS") > -1) {
            browserObj.maker = "Google";
            browserObj.name = "ChromeIOS";
        } else if (ua.indexOf("Chrome") > -1) {
            browserObj.maker = "Google";
            browserObj.name = "Google Chrome";
        }
        else if (ua.indexOf("Tizen") > -1) {
            browserObj.name = "Tizen";
            browserObj.maker = "Samsung";
        } else if (ua.indexOf("Safari") > -1) {
            browserObj.name = "Apple Safari";
            browserObj.maker = "Apple";
        } else {
            browserObj.name = "unknown";
            browserObj.maker = "unknown";
        }
        browserObj.version = browserObj.meta.version;
        function browserSpecs() {
            var tem,
                M =
                    ua.match(
                        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d\.]+)/i
                    ) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: "IE", version: tem[1] || "" };
            }
            if (M[1] === "Chrome") {
                tem = ua.match(/\b(OPR|Edge|Edg)\/([\d\.]+)/);
                if (tem != null) {
                    return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
            if ((tem = ua.match(/version\/([\d\.]+)/i)) != null) M.splice(1, 1, tem[1]);
            return { name: M[0], version: M[1], ua: ua };
        }
        if (browserObj.isTizen) browserObj.source = "TV";
        else if (browserObj.isApp) browserObj.source = "App";
        else browserObj.source = "Browser"

        Vue.prototype.$browserDetect = browserObj;
    }
};

export default VueBrowserDetect;

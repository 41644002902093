<template>
  <div>
    <h2 v-if="model.header" :class="{ 'sr-only': model.hideTitle }">
      <b-link
        v-if="model.headerUrl && canRoute()"
        :to="model.headerUrl"
        tabindex="0"
        @click.native="sendAnalytics('Pokaż więcej')"
        class="focus-smart"
      >
        <div class="nameSection">{{ model.header }}</div>
        <div>
          <div class="showAll">{{ translations["general.ShowMore"] }}</div>
          <i class="cil-chevron-right-alt"></i>
        </div>
      </b-link>
      <div v-else>
        <div class="nameSection">{{ model.header }}</div>
      </div>
    </h2>
    <p class="descriptionItems" v-if="model.description" v-html="model.description"></p>
    <list-container
      :items="model.items"
      :display-type="model.options && model.options.type == 'audio' ? 'audio' : 'grid'"
      :section="'Sekcja: ' + model.header"
      :translations="translations"
      :isTriple="isTriple"
    ></list-container>
  </div>
</template>

<script>
import ListContainer from "@/components/Lists/ListContainer";
export default {
  name: "HomeGrid",
  components: {
    ListContainer,
  },
  props: {
    model: Object,
    translations: Object,
    isTriple: Boolean,
  },
  methods: {
    canRoute() {
      return !(
        this.$route.fullPath == this.model.headerUrl ||
        this.$route.fullPath == this.model.headerUrl + "/#"
      );
    },
    sendAnalytics(gAction) {
      this.$setGAFormCustom("Sekcja: " + this.model.title, gAction, "", false);
    },
  },
};
</script>

<template>
  <b-link
    :class="cssClass"
    class="focus-smart"
    @click="toggleFavorites"
    :aria-label="!isFavorite ? addAlt : remAlt"
  >
    <i
      :class="{ 'cil-heart': !isFavorite, 'cis-heart': isFavorite }"
      :title="!isFavorite ? addAlt : remAlt"
      role="img"
    ></i>
    <slot></slot>
  </b-link>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/services/event-bus";
import authService from "@/services/authService";
import ErrorSender from "@/services/errorSender";

export default {
  name: "FavoritesControl",
  props: {
    cssClass: String,
    model: Object,
    section: String,
    title: String,
  },
  data() {
    return {
      isFavorite: false,
      addAlt: String,
      remAlt: String,
    };
  },
  mounted() {
    this.isFavorite = (this.model && this.model.isFavorite);
    this.addAlt = `${this.model ? this.model.translations["general.Favorite.Add"]:''} - ${this.title}`;
    this.remAlt = `${this.model ? this.model.translations["general.Favorite.Delete"]:''} - ${this.title}`;
  },
  methods: {
    toggleFavorites() {
      EventBus.$emit("lockScroll");
      let user = authService.getUser();
      if (user) {
        let self = this;
        let url =
          process.env.VUE_APP_BASE_API_URL +
          self.model.url +
          "?nodeId=" +
          self.model.id;
        if (self.isFavorite) {
          this.sendAnalytics("Usuń z Ulubionych");
          axios
            .delete(url)
            .then(function (response) {
              if (response.status == 200) {
                self.isFavorite = !self.isFavorite;
              }
            })
            .catch((error) => {
              ErrorSender.Send(error);
            });
        } else {
          this.sendAnalytics("Dodaj do Ulubionych");
          axios.post(url).then(function (response) {
            if (response.status == 200) {
              self.isFavorite = !self.isFavorite;
            }
          });
        }
      } else {
        this.sendAnalytics("Dodaj do Ulubionych");
        EventBus.$emit("ShowFavoritesAlert");
      }
    },
    sendAnalytics(gLabel) {
      this.$setGAFormCustom(this.section, "Kliknięcie w ikonkę", gLabel, false);
    },
  },
};
</script>

<style scoped>
</style>
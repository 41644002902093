<template>
  <div class="Player" id="Player" ref="player" :class="{ hide: isPlaying }">
    <div class="Player-background" aria-hidden="true">
      <lazy-image aria-hidden="true" :source="imageUrl"></lazy-image>
      <avaliable :avaliable="avaliable" :showOutVod="showOutVod" :translations="translations"></avaliable>
    </div>
    <div :id="id"></div>
    <slot> </slot>
    <modal
      header="playerError"
      :title="translations['moviePage.Player.SomethingWrong']"
      :show="showModal"
      :closeText="translations['general.Close']"
      @hidePop="hideModal"
    >
      <template v-slot:main>
        <p class="b30">{{ errorText }}</p>
        <p class="b30" style="font-size: 0.75em" v-if="Error">
          Error code: {{ Error }} <span v-if="ErrorCode">{{ ErrorCode }}</span>
        </p>
      </template>
    </modal>
  </div>
</template>
<script>
import authService from "@/services/authService";
import ErrorSender from "@/services/errorSender";
import modal from "./modal.vue";
import { EventBus } from "@/services/event-bus";
import LazyImage from "./LazyImage.vue";
import Avaliable from "./Avaliable.vue";
export const PlayerStatus = {
  NEXT: 1,
  PREVIOUS: 2,
  PLAYING: 3,
  PAUSED: 4,
  SEEKED: 5,
  STOPPED: 6,
  ENDED: 7,
  ERROR: 8,
};
export default {
  components: {
    modal,
    LazyImage,
    Avaliable,
  },
  props: {
    assetId: Number,
    imageUrl: String,
    loginUrl: String,
    overlay: Object,
    showPreviousButton: Boolean,
    showNextButton: Boolean,
    jumpTime: Number,
    translations: Object,
    lang: String,
    title: String,
    aditionalInfo: String,
    aditionalColor: String,
    showContinuePlaying: {
      type: Boolean,
      default: true,
    },
    avaliable: Object,
    showOutVod: Boolean,
  },
  data() {
    return {
      widget: null,
      errorText: String,
      showModal: false,
      Error: "",
      ErrorCode: "",
      profile: Object,
      isFullScreen: Boolean,
      isPlaying: false,
      isTV: this.$isTizen(this.$browserDetect),
      id: "axxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }),
    };
  },
  mounted: function () {
    this.LoadWidget();
    this.profile = authService.getProfile();
    if (this.assetId) this.prePareWidget();
    EventBus.$on("closeNavEvent", this.closePlayer);
    EventBus.$on("NavigationGoTo", this.navi);
    EventBus.$emit("NavigationRegister", {
      id: 1,
      name: this.translations["navigation.GoTo.Player"],
      key: "player",
    });
  },
  updated() {
    if (this.assetId && !this.widget) this.prePareWidget();
  },
  beforeDestroy: function () {
    if (this.widget) {
      this.widget.$off("login", this.showLogin);
      this.widget.$off("player-next-button-clicked", this.NextClick);
      this.widget.$off("player-previous-button-clicked", this.PreviousClick);
      this.widget.$off("player-initialized", this.PlayerInit);
      this.widget.$off("player-error", this.PlayerError);
      if (this.isTV) {
        this.widget.$off("native-player-ready", this.playerFullScreen);
        this.widget.$off("native-player-released", this.playerFullScreen);
      } else {
        this.widget.$off("player-initialized", this.PlayerInit);
      }
      var player = this.widget.getPlayer();
      if (player) {
        player.off("metadata", this.playerPlaying);
      }
      this.widget.$destroy(true);
      this.widget = null;
    }
    window.removeEventListener("user-changed", this.onUserChanged, true);
    EventBus.$off("closeNavEvent", this.closePlayer);
    EventBus.$off("NavigationGoTo", this.navi);
    var app = document.getElementById("app");
    if (app.classList.length > 0) app.classList.remove(app.classList);
  },
  methods: {
    LoadWidget() {
      if (!window.GuiIntWidget) {
        let widgetScript = document.createElement("script");
        widgetScript.setAttribute("src", process.env.VUE_APP_WIDGET_JS_URL);
        document.head.appendChild(widgetScript);
        let widgetStyle = document.createElement("link");
        widgetStyle.setAttribute("rel", "stylesheet");
        widgetStyle.setAttribute("href", process.env.VUE_APP_WIDGET_CSS_URL);
        document.head.appendChild(widgetStyle);
      }
    },
    hideModal() {
      this.showModal = false;
    },
    prePareWidget() {
      if (!window.GuiIntWidget) {
        setTimeout(() => {
          this.prePareWidget();
        }, 100);
        return;
      }
      // var self = this;
      setTimeout(() => {
        if (!this.widget) {
          var autoPlay = this.$route.query["a"] == "true";
          var lang = localStorage.getItem("x-language").substr(0, 2);
          if (!lang) lang = "pl";
          var user = authService.getUser();
          var settings = {
            propsData: {
              showContinuePlaying: this.showContinuePlaying,
              autoplay: autoPlay,
              productId: this.assetId,
              baseUrl: process.env.VUE_APP_WIDGET_API_URL,
              token: user ? user.access_token : null,
              paymentTest: this.profile?.is_tester,
              paymentOnline: true,
              jumpButtons: true,
              jumpButtonStep: this.jumpTime,
              nextButton: this.showNextButton,
              previousButton: this.showPreviousButton,
              language: lang,
              translations: {
                pl: {
                  BUY: this.translations["moviePage.Player.Buy"],
                  CONFIRM: this.translations["moviePage.Player.Confirm"],
                  DOWNLOAD: this.translations["moviePage.Player.Download"],
                  LISTEN: this.translations["moviePage.Player.Listen"],
                  NO: this.translations["general.No"],
                  RESUME_PLAYBACK: this.translations["moviePage.Player.ResumePlayback"],
                  RESUME_PLAYBACK_QUESTION: this.translations[
                    "moviePage.Player.ResumePlaybackQuestion"
                  ],
                  SIGN_IN: this.translations["general.Login"],
                  TRAILER: this.translations["moviePage.Player.Trailer"],
                  YES: this.translations["general.Yes"],
                  WATCH: this.translations["moviePage.Player.Watch"],
                  WATCH_SLT: this.translations["moviePage.Player.WatchSlt"],
                  PAYMENT_ACCESS_FOR: this.translations[
                    "moviePage.Player.PaymentAccessFor"
                  ],
                  PAYMENT_DAYS: this.translations["moviePage.Player.PaymentDays"],
                  PAYMENT_ONLINE_PAYMENT: this.translations[
                    "moviePage.Player.PaymentOnlinePayment"
                  ],
                  PAYMENT_TEST_PAYMENT: this.translations[
                    "moviePage.Player.PaymentTestPayment"
                  ],
                  ACCESS: this.translations["moviePage.Player.Access"],
                  ADD_VOUCHER: this.translations["moviePage.Player.AddVoucher"],
                  PAYMENT_METHODS: this.translations["moviePage.Player.PaymentMethods"],
                  PAYMENT_TOO_MANY_REQUESTS: this.translations[
                    "moviePage.Player.PaymentMethodsTooMenyRequest"
                  ],
                  ENTER_CODE: this.translations["moviePage.Player.EnterCode"],
                  GO_BACK: this.translations["moviePage.Player.GoBack"],
                  PROMOTION_CODE_NOT_EXISTS: this.translations[
                    "moviePage.Player.PromotionNotExist"
                  ],
                  SEND_VOUCHER: this.translations["moviePage.Player.SendVoucher"],
                  SUBSCRIBER_NOT_EXISTS: this.translations[
                    "moviePage.Player.SubscriberNotExists"
                  ],

                  BROWSE_BUNDLE_CONTENT: this.translations[
                    "moviePage.Player.BrowserBundleContent"
                  ],
                  BROWSE_CONTENT: this.translations[
                    "moviePage.Player.BrowserMovieContent"
                  ],
                  PAYMENT_AGREEMENT_OBLIGATORY: this.translations[
                    "moviePage.Player.PaymentAgreementObligatory"
                  ],
                  PAYMENT_BUNDLE_BROWSE: this.translations[
                    "moviePage.Player.PaymentBundleBrowser"
                  ],
                  PAYMENT_CONFIRMATION: this.translations[
                    "moviePage.Player.PaymentConfirmation"
                  ],
                  PAYMENT_CONTACT_US: this.translations[
                    "moviePage.Player.PaymentContactUs"
                  ],
                  PAYMENT_ERROR: this.translations["moviePage.Player.PaymentError"],
                  PAYMENT_HAPPY_BUNDLE_WATCHING: this.translations[
                    "moviePage.Player.PaymentHappyBundleWatching"
                  ],
                  PAYMENT_TRIAL_ACCESS: this.translations[
                    "moviePage.Player.PaymentTrialAccess"
                  ],
                  PAYMENT_TYPE_INFORMATION: this.translations[
                    "moviePage.Player.PaymentTypeInformation"
                  ],
                  PAYMENT_VOUCHER_SUCCEEDED: this.translations[
                    "moviePage.Player.PaymentVourcherSucceeded"
                  ],
                  RETRY_PAYMENT: this.translations["moviePage.Player.RetryPayment"],
                  SINGLE: this.translations["moviePage.Player.Single"],
                  SINGLE_PAYMENT_DESCRIPTION: this.translations[
                    "moviePage.Player.SinglePaymentDescription"
                  ],
                  SUBSCRIPTION: this.translations["moviePage.Player.Subscription"],
                  SUBSCRIPTION_PAYMENT_DESCRIPTION: this.translations[
                    "moviePage.Player.SubscriptionPaymentDescription"
                  ],
                  VOUCHER_CODE: this.translations["moviePage.Player.VoucherCode"],
                  ADD_CARD: this.translations["moviePage.Player.ADD_CARD"],
                  COMMISSION_INFO: this.translations["moviePage.Player.COMMISSION_INFO"],
                  DELETE_CARD: this.translations["moviePage.Player.DELETE_CARD"],
                  EMPTY_CARD: this.translations["moviePage.Player.EMPTY_CARD"],
                  RATE_PER_MONTH: this.translations["moviePage.Player.RATE_PER_MONTH"],
                  ENTIRE_LIBRARY_ACCESS: this.translations[
                    "moviePage.Player.ENTIRE_LIBRARY_ACCESS"
                  ],
                  COMMISSION_TOTAL: this.translations[
                    "moviePage.Player.COMMISSION_TOTAL"
                  ],
                  GEOLOCATION_INFO: this.translations[
                    "moviePage.Player.GEOLOCATION_INFO"
                  ],
                  ADD_CARD_ERROR: this.translations["moviePage.Player.ADD_CARD_ERROR"],
                },
                en: {
                  BUY: this.translations["moviePage.Player.Buy"],
                  CONFIRM: this.translations["moviePage.Player.Confirm"],
                  DOWNLOAD: this.translations["moviePage.Player.Download"],
                  LISTEN: this.translations["moviePage.Player.Listen"],
                  NO: this.translations["general.No"],
                  RESUME_PLAYBACK: this.translations["moviePage.Player.ResumePlayback"],
                  RESUME_PLAYBACK_QUESTION: this.translations[
                    "moviePage.Player.ResumePlaybackQuestion"
                  ],
                  SIGN_IN: this.translations["general.Login"],
                  TRAILER: this.translations["moviePage.Player.Trailer"],
                  YES: this.translations["general.Yes"],
                  WATCH: this.translations["moviePage.Player.Watch"],
                  WATCH_SLT: this.translations["moviePage.Player.WatchSlt"],
                  PAYMENT_ACCESS_FOR: this.translations[
                    "moviePage.Player.PaymentAccessFor"
                  ],
                  PAYMENT_DAYS: this.translations["moviePage.Player.PaymentDays"],
                  PAYMENT_ONLINE_PAYMENT: this.translations[
                    "moviePage.Player.PaymentOnlinePayment"
                  ],
                  PAYMENT_TEST_PAYMENT: this.translations[
                    "moviePage.Player.PaymentTestPayment"
                  ],
                  ACCESS: this.translations["moviePage.Player.Access"],
                  ADD_VOUCHER: this.translations["moviePage.Player.AddVoucher"],
                  PAYMENT_METHODS: this.translations["moviePage.Player.PaymentMethods"],
                  PAYMENT_TOO_MANY_REQUESTS: this.translations[
                    "moviePage.Player.PaymentMethodsTooMenyRequest"
                  ],
                  ENTER_CODE: this.translations["moviePage.Player.EnterCode"],
                  GO_BACK: this.translations["moviePage.Player.GoBack"],
                  PROMOTION_CODE_NOT_EXISTS: this.translations[
                    "moviePage.Player.PromotionNotExist"
                  ],
                  SEND_VOUCHER: this.translations["moviePage.Player.SendVoucher"],
                  SUBSCRIBER_NOT_EXISTS: this.translations[
                    "moviePage.Player.SubscriberNotExists"
                  ],

                  BROWSE_BUNDLE_CONTENT: this.translations[
                    "moviePage.Player.BrowserBundleContent"
                  ],
                  BROWSE_CONTENT: this.translations[
                    "moviePage.Player.BrowserMovieContent"
                  ],
                  PAYMENT_AGREEMENT_OBLIGATORY: this.translations[
                    "general.PaymentAgreementObligatory"
                  ],
                  PAYMENT_BUNDLE_BROWSE: this.translations[
                    "moviePage.Player.PaymentBundleBrowser"
                  ],
                  PAYMENT_CONFIRMATION: this.translations[
                    "moviePage.Player.PaymentConfirmation"
                  ],
                  PAYMENT_CONTACT_US: this.translations[
                    "moviePage.Player.PaymentContactUs"
                  ],
                  PAYMENT_ERROR: this.translations["moviePage.Player.PaymentError"],
                  PAYMENT_HAPPY_BUNDLE_WATCHING: this.translations[
                    "moviePage.Player.PaymentHappyBundleWatching"
                  ],
                  PAYMENT_TRIAL_ACCESS: this.translations[
                    "moviePage.Player.PaymentTrialAccess"
                  ],
                  PAYMENT_TYPE_INFORMATION: this.translations[
                    "moviePage.Player.PaymentTypeInformation"
                  ],
                  PAYMENT_VOUCHER_SUCCEEDED: this.translations[
                    "moviePage.Player.PaymentVourcherSucceeded"
                  ],
                  RETRY_PAYMENT: this.translations["moviePage.Player.RetryPayment"],
                  SINGLE: this.translations["moviePage.Player.Single"],
                  SINGLE_PAYMENT_DESCRIPTION: this.translations[
                    "moviePage.Player.SinglePaymentDescription"
                  ],
                  SUBSCRIPTION: this.translations["moviePage.Player.Subscription"],
                  SUBSCRIPTION_PAYMENT_DESCRIPTION: this.translations[
                    "moviePage.Player.SubscriptionPaymentDescription"
                  ],
                  VOUCHER_CODE: this.translations["moviePage.Player.VoucherCode"],
                  ADD_CARD: this.translations["moviePage.Player.ADD_CARD"],
                  COMMISSION_INFO: this.translations["moviePage.Player.COMMISSION_INFO"],
                  DELETE_CARD: this.translations["moviePage.Player.DELETE_CARD"],
                  EMPTY_CARD: this.translations["moviePage.Player.EMPTY_CARD"],
                  RATE_PER_MONTH: this.translations["moviePage.Player.RATE_PER_MONTH"],
                  ENTIRE_LIBRARY_ACCESS: this.translations[
                    "moviePage.Player.ENTIRE_LIBRARY_ACCESS"
                  ],
                  COMMISSION_TOTAL: this.translations[
                    "moviePage.Player.COMMISSION_TOTAL"
                  ],
                  GEOLOCATION_INFO: this.translations[
                    "moviePage.Player.GEOLOCATION_INFO"
                  ],
                  ADD_CARD_ERROR: this.translations["moviePage.Player.ADD_CARD_ERROR"],
                },
              },
            },
          };
          if (this.overlay && this.overlay.url)
            settings.propsData.playerConfig = {
              overlay: [
                {
                  image: this.overlay.url,
                  position: "top left",
                  scale: true,
                  width: "5",
                },
              ],
            };
          this.widget = new window.GuiIntWidget(settings);
          this.widget.$mount(`#${this.id}`);
          this.widget.$on("login", this.showLogin);
          this.widget.$on("player-next-button-clicked", this.NextClick);
          this.widget.$on("player-previous-button-clicked", this.PreviousClick);
          this.widget.$on("player-initialized", this.PlayerInit);
          window.addEventListener("user-changed", this.onUserChanged, true);
          this.widget.$on("player-error", this.PlayerError);
          if (this.isTV) {
            this.widget.$on("native-player-ready", this.playerFullScreen);
            this.widget.$on("native-player-released", this.playerFullScreen);
          } else {
            this.widget.$on("player-initialized", this.PlayerInit);
          }
        }
      }, 100);
    },
    PlayerEnd() {
      this.emitPlayerStatus(PlayerStatus.ENDED);
      this.$setNavigationLock(false);
      this.setFocusOnFullScreen("#nav0 .nav-link");
    },
    PlayerError(error) {
      this.showModal = true;
      if (error.data && error.data.code == "GEOIP_FILTER_FAILED")
        this.errorText = this.translations["moviePage.Player.GeoErrorMessage"];
      else if (error.data && error.data.code) {
        this.errorText = this.translations["moviePage.Player.ErrorMessage"];
        this.Error = error.data.code;
      } else if (error.code) {
        this.ErrorCode = error.code;
        this.Error = error.message;
        this.errorText = this.translations["moviePage.Player.ErrorMessage"];
      } else {
        this.errorText = this.translations["moviePage.Player.ErrorMessage"];
      }
      this.emitPlayerStatus(PlayerStatus.ERROR, error);
      ErrorSender.Send(error);
    },
    PlayerInit(player) {
      if (this.isTV) {
        this.setFocusOnFullScreen("#Player .rgpl-btn-pause");
      }
      player.on("metadata", this.playerPlaying);
      player.on("fullscreenChanged", this.playerFullScreen);

      let that = this;
      player.on("ended", function () {
        setTimeout(() => {
          that.PlayerEnd();
        }, 0);
      });
      player.on("playing", function (duration) {
        that.emitPlayerStatus(PlayerStatus.PLAYING, duration);
      });
      player.on("paused", function (currentTime) {
        that.emitPlayerStatus(PlayerStatus.PAUSED, currentTime);
      });
      player.on("seeked", function (currentTime) {
        that.emitPlayerStatus(PlayerStatus.SEEKED, currentTime);
      });
      player.on("stopped", function (currentTime) {
        that.emitPlayerStatus(PlayerStatus.STOPPED, currentTime);
      });
    },
    playerPlaying() {
      this.isPlaying = true;
    },
    playerFullScreen(isFull) {
      if (isFull == undefined) isFull = !this.isFullScreen;
      this.isFullScreen = isFull;
      this.$setNavigationLock(isFull);
    },
    NextClick() {
      this.emitPlayerStatus(PlayerStatus.NEXT);
    },
    PreviousClick() {
      this.emitPlayerStatus(PlayerStatus.PREVIOUS);
    },
    showLogin: function () {
      this.$router.push(this.loginUrl + "?returnUrl=" + location.pathname);
    },
    onUserChanged: function (e) {
      this.widget.token = e.detail.user ? e.detail.user.access_token : null;
    },
    goTo: function (time) {
      var element = this.$refs.player;
      if (element != undefined)
        this.$scrollTo(element, this.isTV ? 0 : 700, {
          offset: this.isTV ? 0 : -100,
          // onDone: this.startPlayer(time),
        });
      let self = this;
      setTimeout(() => {
        self.startPlayer(time);
      }, 1000);
    },
    startPlayer(time) {
      if (!this.widget.getPlayer()) {
        this.widget.playAvailable(time);
      } else {
        this.widget.getPlayer().seekTo(time);
      }
    },
    emitPlayerStatus: function (status, param) {
      this.$emit("player-status", status, param);
    },
    closePlayer() {
      if (this.isFullScreen) {
        if (this.widget) {
          let player = this.widget.getPlayer();
          if (player) player.setFullscreen(false);
        }
      }
    },
    setFocusOnFullScreen(className) {
      if (window.RedgalaxyNav) {
        let self = this;
        if (document.querySelector(className))
          window.RedgalaxyNav.focusElement(className);
        else
          setTimeout(function () {
            self.setFocusOnFullScreen(className);
          }, 0);
      }
    },
    navi(item) {
      if (item == "player") {
        var player = this.widget.getPlayer();
        if (player) {
          document.getElementsByClassName("rgpl-btn-play-pause")[0].focus();
        } else {
          document.getElementsByClassName("rgp-widget__button")[0].focus();
        }
      }
    },
    setProductID(assetId) {
      if (this.widget.productId != assetId) {
        this.widget.productId = assetId;
      }
      setTimeout(() => {
        this.widget.playAvailable();
      }, 600);
    },
    setNextVisible(b) {
      this.widget.nextButton = b;
    },
    setPrevVisible(b) {
      this.widget.previousButton = b;
    },
    togglePlay: function (doPlay) {
      let player = this.widget.getPlayer();
      if (player) {
        if (doPlay) {
          player.play();
        } else {
          player.pause();
        }
      } else {
        this.widget.playAvailable();
      }
    },
    getCurrentTime: function () {
      let player = this.widget.getPlayer();
      if (player) {
        return {
          time: player.getCurrentTime(),
          duration: player.getDuration(),
        };
      } else {
        return null;
      }
    },
    getProductId() {
      return this.widget ? this.widget.productId : this.assetId;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.Player {
  position: relative;
  width: 100%;
  height: 56.26vw;
  max-height: 65vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 35vh;

  .rgp-widget:has(.rgp-widget-payments) {
    overflow: visible;
  }

  .rgp-widget {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    top: 0;
    bottom: 0;

    input {
      background-color: #515151;
    }

    .mode-fullscreen {
      z-index: 9999 !important;
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
    }

    .rgp-widget__player-wrapper {
      height: 100% !important;
      width: 100% !important;
    }

    .rgp-widget__button {
      background: $color-black;
      opacity: 0.8;
      position: relative;
      padding: 1em 2.25em 1em 2.25em;
      font-size: em(15);
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      text-wrap: nowrap;

      &:after {
        font-family: "CoreUI-Icons-Solid" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-size: em(24, 15);
        line-height: 1.5em;
        left: 0.45em;
        position: relative;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &--play,
      &--play-audio,
      &--play-trailer {
        order: 1;
        height: auto;
        padding-left: 4em;

        &:after {
          content: "";
          width: em(12);
          height: em(23);
          background-image: url("/assets/35mm.svg");
          background-repeat: no-repeat;
          background-position: center;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &--play-trailer {
        order: 0;
        padding-left: 4em;
      }

      &--download {
        &:after {
          content: "\eb25";
        }
      }

      &--login {
        padding-left: 4em;

        &:after {
          content: "\ec46";
        }
      }

      &--buy {
        padding-left: 3em;
        padding-top: 1em;
        padding-right: 1em;
        padding-bottom: 1em;

        span {
          display: inline-block;
          color: #fff;

          font-family: Wotfard;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 133.333% */
          letter-spacing: 0.36px;
          text-transform: uppercase;
          font-size: em(18);
          line-height: unset;

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            // font-style: normal;
            // font-weight: 700;
            margin-right: 5px;
          }
          &:nth-child(3) {
            font-weight: normal;
          }
          // &:last-child {
          //   font-size: em(18, 15);
          //   font-style: normal;
          //   font-weight: 700;
          // }
        }

        &:after {
          content: "\ead9";
          font-family: "CoreUI-Icons-Linear" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          left: 0.5em;
        }
      }
    }

    .rgp-widget__player-wrapper
      .rgp-widget__player-confirm
      .rgp-widget__player-confirm-prompt
      .rgp-widget__button {
      border: 2px solid;
      padding: 1em 3em 1em 1em;

      &:first-child:after {
        content: "\eaf3";
      }

      &:last-child:after {
        content: "\ee52";
      }

      &:after {
        left: auto;
        right: 0.5em;
      }
    }

    .rgpl-overlay {
      top: 5% !important;
      left: 3% !important;
      right: unset !important;
      bottom: unset !important;
      width: calc(100% / (16 / 9));

      > img {
        width: 8% !important;
      }
    }

    .ui-toast-wrapper {
      font-size: 1em;
      background: $body-bg;
      color: $color-main;
      max-width: 28.75em;
      width: 100%;
      padding: 1em 2.125em !important;
      border: none;
      text-align: center;

      &__text {
        color: $color-main;
        font-size: 1em;
      }

      &__title {
        font-size: 1.625em;
      }
    }

    .rgpl-subtitle {
      font-size: em(23);
      bottom: em(14);
    }

    &__buttons {
      display: flex !important;
      > div:last-child {
        display: none;
      }
    }
  }

  .rgp-widget__player {
    &.rgpl-pip-active {
      font-size: 0.5em;
    }
  }

  &-background {
    position: absolute;
    z-index: -1;
    height: 100%;
    overflow: hidden;
    width: 100%;
    pointer-events: none;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
    }
  }

  .title {
    position: absolute;
    bottom: 0px;
    left: 0;
    text-shadow: 0 0px 20px black;
    font-weight: 800;
    margin-bottom: 10px;
    font-size: 1.33333em;
    text-transform: uppercase;
    pointer-events: none;
    transition: all 1s;
    transition-delay: 5s;
    z-index: 9999;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0.8) 55%
    );
    width: 100%;
    margin: 0;
    padding: 5%;

    @include media-breakpoint-up(sm) {
      font-size: 2.58333em;
    }
  }

  &.hide {
    .title,
    .aditional-info {
      opacity: 0;
    }
  }

  .aditional-info {
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 99999;
    pointer-events: none;
    transition: all 1s;
    transition-delay: 5s;
    font-size: 2.5em;
  }

  &:hover {
    .title,
    .aditional-info {
      transition-delay: 0s !important;
      opacity: 1 !important;
    }
  }
}

.rgpl-btn-volume-progress {
  &:focus-visible {
    width: 70px !important;
  }
}

.rgp-widget .rgp-widget-payments {
  top: 0;
  margin-top: 2.5%;
  &:has(.rgp-widget-payments__schedules-options) {
  }
  width: 100vw;
  max-width: 100vw;
  min-width: unset;
  // max-width: 599px;
  border: 2px solid #000;
  background: linear-gradient(
    144deg,
    rgba(45, 45, 45, 1) 3.93%,
    rgba(45, 45, 45, 1) 97.73%
  ) !important;
  padding: 24px;

  &__wrapper {
    overflow: hidden !important;
    max-width: 100%;
    margin: auto;
  }

  .rgp-widget-payments__success {
    max-width: 500px;
    margin: auto;

    .rgp-widget-payments__success-title {
      font-size: em(24);
      margin-bottom: em(16, 24);
      padding-bottom: 0;
    }

    .rgp-widget-payments__success-first-section {
      margin: em(24) 0;
      padding: 0;
      font-size: em(12);

      .rgp-widget-payments__success-strong {
        margin-bottom: em(12);
        font-size: em(16, 12);
      }
    }

    .rgp-widget-payments__success-second-section {
      font-size: em(12);
      padding: 0;
      margin-bottom: em(40, 12);
    }

    .rgp-widget-payments__success-fourth-section {
      font-size: em(20);
      margin: em(24, 20) 0;
      padding: 0;
    }

    .rgp-widget__button--browse {
      text-align: center;
      padding: 1em 6em;
      background-color: white;
      opacity: 1;
      color: black;
    }
  }

  .rgp-widget-payments__close {
    color: white;
  }

  .rgp-widget-payments__title {
    font-size: em(20, 12);
    line-height: 150%;
    font-family: Knile;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 24px;
  }

  .rgp-widget-payments__schedules {
    margin-bottom: 24px;
    .rgp-widget-payments__schedules-option--schedule-type {
      font-size: em(16, 20);
      font-family: Knile;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
      text-align: left;
    }
    .rgp-widget-payments__schedules-option--schedule-description {
      color: #d0d0d0;
      font-family: Wotfard;
      font-size: em(14, 20);
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 25.6px */
      letter-spacing: 0.32px;
    }

    .rgp-widget-payments__schedules-option--schedule-price {
      white-space: nowrap;
      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: Knile;
      font-size: em(20, 20);
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 42px */
      margin-left: 20px;
    }
  }
  .rgp-widget-payments__schedules-options--subscription-headline {
    color: #fff;

    font-family: Knile;
    font-size: em(16, 12);
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    text-transform: uppercase;
  }

  .rgp-widget__button--add-card {
    color: #1d1d1d;

    font-family: Wotfard;
    font-size: em(16);
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    text-transform: uppercase;
  }
  .rgp-widget-payments__commission_info {
    margin-bottom: 24px;
    .rgp-widget-payments__commission_info--title {
      color: #fff;
      font-family: Knile;
      font-size: em(16, 12);
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
    .rgp-widget-payments__commission_info--metadata {
      padding-bottom: 8px;
      margin-bottom: 24px;
      .rgp-widget-payments__commission_info--description {
        color: #d0d0d0;
        font-family: Knile;
        font-size: em(13, 12);
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 25.6px */
        letter-spacing: 0.32px;
        padding-right: 29px;
      }
      .rgp-widget-payments__commission_info--price {
        color: white;
        font-family: Knile;
        font-size: em(24, 12);
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }

  .rgp-widget__button--add-card-autopay-img {
    padding-left: 12px;
  }
  .rgp-widget-payments__schedules-option {
    padding: 20px;
    height: unset;
    opacity: 1;
    .rgp-widget-payments__schedules-option--user-card {
      margin: 0;
      .rgp-widget-payments__schedules-option--user-card-empty {
        color: #fff;
        font-family: Wotfard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.32px;
        display: block;
      }
      .rgp-widget-payments__schedules-option--user-card-empty-text {
        color: #fff;
        display: block;

        font-family: Wotfard;
        font-size: em(16);
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.32px;
        margin-bottom: 12px;
      }
    }
    .rgp-widget-payments__schedules-option--user-card {
      .rgp-widget-payments__schedules-option--user-has-card {
        padding-top: 20px;
      }
      .rgp-widget-payments__schedules-option--user-has-card-image {
        background-color: white;
        max-height: 28px;
        max-width: 43px;
        padding: 6px;
        img {
          height: auto;
        }
      }
      .rgp-widget-payments__schedules-option--user-has-card-metadata {
        color: #fff;

        font-family: Wotfard;
        font-size: em(16, 20);
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.32px;
      }
      .rgp-widget__button--delete-card {
        color: #fff;

        text-align: right;
        font-family: Wotfard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        letter-spacing: 0.32px;
        text-decoration-line: underline;
      }
    }

    .rgp-widget-payments__schedules-option--trial-schedule {
      margin-bottom: 10px;
    }
  }

  .rgp-widget__button--add-card-text {
    font-size: em(14);
  }
  .gp-widget-payments__schedules-option--user-error {
    color: #ff7272;
    text-align: center;
    font-family: Wotfard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
  }
  .rgp-widget-payments__payment-info {
    font-size: em(11);
    font-weight: 400;
    line-height: em(18);
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0;
  }

  .rgp-widget-payments__voucher-wrapper {
    padding: 0;
    min-height: unset;
    overflow: initial;
    margin-bottom: 24px;
  }

  .rgp-widget-payments__button-wrapper {
    color: #1d1d1d;
    font-family: Wotfard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
    max-width: unset !important;
    display: block;
    .rgp-widget__button--payment-img {
      position: static !important;
    }
  }

  .rgp-widget-payments__voucher {
    margin: 0;
    max-height: unset;

    .rgp-widget-payments__voucher-title {
      color: #fff;

      font-family: Knile;
      font-size: em(16);
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      margin-bottom: 24px;
    }

    .rgp-widget-payments__error {
      font-size: 0.75em;
      padding: 0;
    }

    .rgp-widget-payments__voucher-form {
      .input--component {
        align-content: center;
        align-items: baseline;
        justify-content: center;
      }
      .rgp-widget-payments__input {
        width: 100%;
        overflow: hidden;
        color: #fff;

        text-overflow: ellipsis;
        font-family: Wotfard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.32px;
        border: none;
        padding: 19px 16px;
      }

      .rgp-widget__button {
        height: em(28, 12) !important;
        font-size: em(12);
        opacity: 1 !important;
        position: static;
        border: 1px solid #fff !important;
        opacity: 0.5;
        background: rgba(255, 255, 255, 0.1) !important;
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        color: #fff;
        font-family: Wotfard;
        font-size: em(16);
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
  }

  .rgp-widget-payments__card-info {
    color: #fff;

    font-family: Knile;
    font-size: em(16);
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    padding-left: 10px;
  }
  .rgp-widget-payments__confirm {
    background: white !important;
    opacity: 1 !important;
    max-width: 263px;
    padding: 6.5px 15.25px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
    border-radius: 2px;
    border: 1px solid #fff;
    opacity: 0.3;
    background: #fff;
    min-width: 100%;
    &.rgp-widget-payments__confirm--disabled {
      opacity: 0.3 !important;
    }
    .rgp-widget__button--payment-img {
      margin: 0;
      height: 24px;
      width: auto;
      top: 8px;
      position: relative;
    }

    .rgp-widget__button--payment-text {
      color: #1d1d1d;
      font-family: Wotfard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      text-transform: uppercase;
    }

    .rgp-widget__button--blue-media-img {
      height: 32px;
      margin: 0;
      position: relative;
      top: 1px;
      width: auto;
    }
  }

  .rgp-widget-payments__input {
    font-size: em(12) !important;
    height: em(30, 12) !important;
    border: 2px solid #fff;
    background: #474747 !important;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    margin-right: 20px;
  }
  .rgp-widget-payments__agreement {
    .rgp-widget-payments__agreement-content {
      color: #fff;
      font-family: Knile;
      font-size: em(14);
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: 0.28px;
      margin-left: 8px;
      p {
        font-size: em(16, 9);
        color: #d0d0d0;

        font-family: Knile;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 25.6px */
        letter-spacing: 0.32px;
      }
      .rgp-widget-payments__agreement-content--agreement-obligatory {
        display: none;
      }
    }
  }

  .rgp-widget-payments__loading-wrapper {
    min-height: 100px;
    width: 100%;
    position: relative;
    max-height: 90%;
    height: 100%;
  }

  .rgp-widget-payments__wrapper + .rgp-widget-payments__loading-wrapper {
    position: absolute !important;
    max-height: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: map-get($grid-breakpoints, "sm")) {
  .rgp-widget .rgp-widget-payments {
    max-width: 640px;
  }
  .rgp-widget-payments__schedules-option--user-card-empty {
    display: flex !important;
  }
  .rgp-widget-payments__agreement-content {
    p {
      font-size: em(16, 10.5) !important;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, "sm")) {
  .rgp-widget-payments__commission_info--description {
    font-size: em(16, 16) !important;
  }
  .rgp-widget-payments__button-wrapper .rgp-widget__button {
    height: em(30, 12) !important;
  }
  .rgp-widget-payments__schedules-option--user-card-empty-text {
    margin: 0 !important;
    display: flex !important;
  }
}
.ListViewer .Player {
  max-height: 100% !important;
  aspect-ratio: 4 / 3;
}
</style>

<template>
  <div class="busy-indicator">
    <b-spinner></b-spinner>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  name: "BusyIndicator",
  components: { BSpinner },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

body {
  .busy-indicator {
    display: none;
  }
}
html {
  .busy {
    overflow: hidden;
    .busy-indicator {
      top: 0;
      left: 0;
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: $body-bg;
      opacity: 1;
      z-index: 1001;

      .spinner-border {
        width: 5rem;
        height: 5rem;
        opacity: 1;
        color: $primary;
      }
    }
  }
}
</style>
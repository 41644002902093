import axios from 'axios'
import qs from 'qs';

const GRANT_PASSWORD = 'password';
const GRANT_REFRESH_TOKEN = 'refresh_token';
const GRANT_AUTHORIZATION_CODE = 'authorization_code';

const ROLE_TEACHER = 'teacher';
const ROLE_PUPIL = 'pupil';
const ROLE_STUDENT = 'student';
const ROLE_PRO = 'pro';
const ROLE_TEST = 'tester';
const ROLE_DEBUG = "debug";

const AGREEMENT_KEY = 'rules35mm';

export default {

    data() {
        return {
            renewTimer: Number,
        }
    },

    logIn: function (login, passwd, token) {

        const payload = {
            client_id: process.env.VUE_APP_SSO_CLIENT_ID,
            grant_type: GRANT_PASSWORD,
            username: login,
            password: passwd,
            token: token,
        };
        return new Promise((resolve, reject) => {
            this.postForm(process.env.VUE_APP_SSO_TOKEN_ENDPOINT, payload)
                .then(response => {
                    resolve(this.evalLoginResponse(response));
                })
                .catch(error => {
                    reject(error);
                })
        });
    },

    logInByCode: function (code) {
        let redirect_uri = window.location.protocol + "//" + window.location.host;
        const payload = {
            client_id: process.env.VUE_APP_SSO_CLIENT_ID,
            grant_type: GRANT_AUTHORIZATION_CODE,
            code: code,
            redirect_uri: redirect_uri,
        };

        return new Promise((resolve, reject) => {
            this.postForm(process.env.VUE_APP_SSO_TOKEN_ENDPOINT, payload)
                .then(response => {
                    resolve(this.evalLoginResponse(response));
                })
                .catch(error => {
                    reject(error);
                })
        });
    },

    getExternalLink: function (extprovider, registerExtUrl) {
        let redirect_uri = encodeURIComponent(process.env.VUE_APP_ABSOLUTE_URL);
        let scopes = 'openid%20profile%20offline_access%20email%20roles%20IdentityServerApi%20rules35mm';
        let registerext_uri = (registerExtUrl ? '&registerext=' + encodeURIComponent(process.env.VUE_APP_ABSOLUTE_URL) + registerExtUrl : '');
        return `${process.env.VUE_APP_SSO_AUTHORIZE_ENDPOINT}?client_id=${process.env.VUE_APP_SSO_CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=${scopes}&nonce=x&external=${extprovider}&prompt=login${registerext_uri}`;
    },

    startTokenRefresh: function () {
        let user = this.readUser();
        if (user) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
            this.setupRenewing(user.access_token);
        }
    },

    getUser: function () {
        let user = this.readUser();
        return user && user.isLocallyRegistered ? user : null;
    },

    getUserUnregistered: function () {
        return this.readUser();
    },

    logout: function () {
        clearTimeout(this.renewTimer);
        localStorage.removeItem('user');
        delete axios.defaults.headers.common['Authorization'];
        window.dispatchEvent(new CustomEvent('user-changed', {
            detail: {
                user: null
            }
        }));
    },

    remoteLogin: function (vm) {
        axios
            .post(process.env.VUE_APP_REMOTE_LOGIN_URL,
                {
                    agent: vm.$browserDetect.name,
                    agentVersion: vm.$browserDetect.version,
                    maker: vm.$browserDetect.maker,
                    os: vm.$osDetect.os,
                    osVersion: vm.$osDetect.version,
                    userAgent: vm.$browserDetect.ua,
                });
    },

    register: function (model) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_REGISTER_ENDPOINT}?returnUrl=${encodeURI(process.env.VUE_APP_ABSOLUTE_URL)}`,
                {
                    ClientID: process.env.VUE_APP_SSO_CLIENT_ID,
                    email: model.email,
                    userName: model.username,
                    password: model.password,
                    firstName: model.firstname,
                    lastName: model.lastname,
                    institutionName: model.institutionName,
                    institutionPhone: model.institutionPhone,
                    nip: model.institutionNIP,
                    rik: model.institutionRIK,
                    isPro: model.isOptPro,
                    token: model.token,
                    agreements: { items: [{ claim: AGREEMENT_KEY, active: true }] },
                })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    registerExt: function (model) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_REGISTEREXT_ENDPOINT}?returnUrl=${encodeURI(process.env.VUE_APP_ABSOLUTE_URL)}`,
                {
                    email: model.email,
                    firstName: model.firstname,
                    lastName: model.lastname,
                    isPro: model.isPro,
                    institutionName: model.institutionName,
                    institutionPhone: model.institutionPhone,
                    nip: model.institutionNIP,
                    rik: model.institutionRIK,
                    token: model.token,
                    provider: model.provider,
                    providerKey: model.providerKey,
                    ClientID: process.env.VUE_APP_SSO_CLIENT_ID,
                    agreements: { items: [{ claim: AGREEMENT_KEY, active: true }] },
                })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    saveProfile: function (model) {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_SSO_PROFILE_ENDPOINT,
                {
                    firstName: model.firstname,
                    lastName: model.lastname,
                    isPro: model.isOptPro,
                    institutionName: model.institutionName,
                    institutionPhone: model.institutionPhone,
                    nip: model.institutionNIP,
                    rik: model.institutionRIK,
                    token: model.token,
                    agreements: { items: [{ claim: AGREEMENT_KEY, active: true }] },
                })
                .then(() => {
                    this.renewToken();
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                });

        })
    },

    getProfile: function (includeUnregistered) {
        var user = includeUnregistered ? this.getUserUnregistered() : this.getUser();
        if (user) {
            let jwt = this.parseJwt(user.access_token);
            return {
                username: user.username,
                email: jwt.email,
                given_name: jwt.given_name,
                family_name: jwt.family_name,
                institution_name: jwt.institution_name,
                institution_type: jwt.institution_type,
                institution_address: jwt.institution_address,
                institution_phone: jwt.institution_phone,
                province: jwt.province,
                district: jwt.district,
                nip: jwt.institution_nip,                
                rik: jwt.institution_rik,
                is_teacher: (jwt.role && jwt.role.includes(ROLE_TEACHER)) ? true : false,
                is_student: jwt.role && jwt.role.includes(ROLE_STUDENT) ? true : false,
                is_pupil: jwt.role && jwt.role.includes(ROLE_PUPIL) ? true : false,
                is_pro: jwt.role && jwt.role.includes(ROLE_PRO) ? true : false,
                is_tester: jwt.role && jwt.role.includes(ROLE_TEST) ? true : false,
                is_debug: jwt.role && jwt.role.includes(ROLE_DEBUG) ? true : false,
                accepted: jwt.roles35mm,
                acceptedCheck: jwt.acceptedCheck === "True",
                newsletter: jwt.newsletter === "True",
                hasPassword: jwt.hasPassword != "0",
            }
        }
    },

    remindPassword: function (email, returnUrl, token) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_FORGOT_ENDPOINT}?returnUrl=${encodeURI(process.env.VUE_APP_ABSOLUTE_URL + returnUrl)}`,
                {
                    ClientID: process.env.VUE_APP_SSO_CLIENT_ID,
                    email: email,
                    token: token,
                })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    resetPassword: function (email, password, code, token) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_RESET_ENDPOINT}`,
                {
                    ClientID: process.env.VUE_APP_SSO_CLIENT_ID,
                    email: email,
                    password: password,
                    code: code,
                    token: token,
                })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    changePassword: function (oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_PASSWORD_ENDPOINT}`,
                {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    changeEmail: function (email) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_CHANGEEMAIL_ENDPOINT}?returnUrl=${encodeURI(process.env.VUE_APP_ABSOLUTE_URL)}`,
                {
                    email: email,
                })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    deleteAccount: function () {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_SSO_DELETEACCOUNT_ENDPOINT}`)
                .then(() => {
                    this.logout();
                    resolve();
                })
                .catch(error => {
                    reject(this.parseError(error));
                })
        })
    },

    //--------- PRIVATE ----------

    parseError: function (error) {
        if (error.response
            && error.response.status
            && error.response.status == 400
            && error.response.data.error) {
            return error.response.data.error;
        }
        else {
            return null;
        }
    },

    setupRenewing: function (access_token) {
        let jwt = this.parseJwt(access_token);
        let expires_in = new Date(jwt.exp * 1000) - (new Date()) - 1000 * process.env.VUE_APP_TOKEN_RENEW_OVERLAP;
        clearTimeout(this.renewTimer);
        this.renewTimer = setTimeout(() => this.renewToken(), expires_in);
    },

    renewToken: function () {
        let user = this.readUser();
        if (!user)
            return;
        const payload = {
            client_id: process.env.VUE_APP_SSO_CLIENT_ID,
            grant_type: GRANT_REFRESH_TOKEN,
            refresh_token: user.refresh_token,
        };
        this.postForm(process.env.VUE_APP_SSO_TOKEN_ENDPOINT, payload)
            .then(response => {
                this.evalLoginResponse(response);
            })
            .catch(() => {
                this.logout();
            })
    },

    parseJwt: function (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    },

    evalLoginResponse: function (response) {
        if (!response.data.access_token
            || !response.data.refresh_token
            || !response.data.token_type
            || response.data.token_type != "Bearer") {
            throw Error("Invalid response");
        }
        let jwt = this.parseJwt(response.data.access_token);
        if (!jwt.sub || !jwt.email || !jwt.exp || !jwt.preferred_username) {
            throw Error("Invalid response");
        }

        let isLocallyRegistered = (jwt.rules35mm && (jwt.rules35mm === process.env.VUE_APP_SSO_CLIENT_ID || jwt.rules35mm.includes(process.env.VUE_APP_SSO_CLIENT_ID)) ? true : false);
        let user = this.createUser(response.data.access_token,
            response.data.refresh_token,
            jwt.preferred_username,
            isLocallyRegistered);

        this.saveUser(user);

        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;

        this.setupRenewing(response.data.access_token);

        window.dispatchEvent(new CustomEvent('user-changed', {
            detail: {
                user: user
            }
        }));

        return user;
    },

    createUser: function (access_token, refresh_token, username, isLocallyRegistered) {
        var user = {
            access_token: access_token,
            refresh_token: refresh_token,
            username: username,
            isLocallyRegistered: isLocallyRegistered
        };
        return user;
    },

    saveUser: function (user) {
        localStorage.setItem('user', JSON.stringify(user));
    },

    readUser: function () {
        try {
            return JSON.parse(localStorage.getItem('user'));
        }
        catch {
            return null;
        }
    },

    postForm: function (url, payload) {
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(payload),
            url: url,
        };
        let axiosNoDefs = axios.create();
        axiosNoDefs.defaults.headers.common = {};
        return axiosNoDefs(options);
    },
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.header)?_c('b-row',{staticClass:"progressBar details-header"},[_c('b-col',{attrs:{"sm":10}},[_c('b-container',[_c('h3',[_vm._v(_vm._s(_vm.header))])])],1)],1):_vm._e(),_c('b-container',[_c('b-row',{staticClass:"progressBar m40"},[_c('b-col',{attrs:{"sm":10}},[_c('b-form-group',[_c('b-form-checkbox-group',{staticClass:"radios",class:{ selected: _vm.selectedValue[0] },attrs:{"name":_vm.name},model:{value:(_vm.selectedValue[0]),callback:function ($$v) {_vm.$set(_vm.selectedValue, 0, $$v)},expression:"selectedValue[0]"}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:{
                active:
                  _vm.selectedValue[0] && _vm.selectedValue[0].includes(item.value),
              }},[_c('b-form-checkbox',{attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")]),(!_vm.readonly && item.hint)?_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.selectedValue[0] && _vm.selectedValue[0].includes(item.value)
                ),expression:"\n                  selectedValue[0] && selectedValue[0].includes(item.value)\n                "}],staticClass:"hint"},[_vm._v(" "+_vm._s(item.hint)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(
                    item.haveInput &&
                    _vm.selectedValue[0] &&
                    _vm.selectedValue[0].includes(item.value)
                  )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item.inputName}},[_c('b-input',{attrs:{"placeholder":item.inputPlaceHolder,"disabled":item.value != _vm.selectedValue[0],"type":item.inputType},model:{value:(_vm.inputValue[item.value]),callback:function ($$v) {_vm.$set(_vm.inputValue, item.value, $$v)},expression:"inputValue[item.value]"}})],1)],1):_vm._e(),(
                    item.haveText &&
                    _vm.selectedValue[0] &&
                    _vm.selectedValue[0].includes(item.value)
                  )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item.inputName}},[_c('label',{staticClass:"col-form-label",attrs:{"for":item.inputName}},[_vm._v(_vm._s(item.inputPlaceHolder))]),_c('b-form-textarea',{attrs:{"disabled":!(
                          _vm.selectedValue[0] &&
                          _vm.selectedValue[0].includes(item.value)
                        ),"size":"lg","maxlength":"1000"},model:{value:(_vm.inputValue[item.value]),callback:function ($$v) {_vm.$set(_vm.inputValue, item.value, $$v)},expression:"inputValue[item.value]"}}),_c('p',{staticClass:"chars-status text-right"},[_vm._v(" "+_vm._s(_vm.inputValue[item.value] ? _vm.inputValue[item.value].length : 0)+" / 1000 ")])],1)],1):(
                    item.haveCheckBox &&
                    _vm.selectedValue[0] &&
                    _vm.selectedValue[0].includes(item.value)
                  )?_c('div',[_c('b-form-checkbox-group',{staticClass:"radios",attrs:{"name":_vm.name + index},model:{value:(_vm.selectedValue[1]),callback:function ($$v) {_vm.$set(_vm.selectedValue, 1, $$v)},expression:"selectedValue[1]"}},_vm._l((item.radioValues.filter(
                        function (x) { return !_vm.readonly ||
                          _vm.selectedValue[1].includes(item.value + x.value); }
                      )),function(item2,index2){return _c('div',{key:index2},[_c('b-form-checkbox',{attrs:{"value":item.value + ';' + item2.value}},[_vm._v(" "+_vm._s(item2.text)+" ")]),_c('transition',{attrs:{"name":"slide-fade"}},[(
                            item2.haveInput &&
                            _vm.selectedValue[1] &&
                            _vm.selectedValue[1].includes(
                              item.value + ';' + item2.value
                            )
                          )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item2.inputName}},[_c('b-input',{attrs:{"placeholder":item2.inputPlaceHolder,"type":item2.inputType},model:{value:(
                                _vm.inputValue[item.value + ';' + item2.value]
                              ),callback:function ($$v) {_vm.$set(_vm.inputValue, item.value + ';' + item2.value, $$v)},expression:"\n                                inputValue[item.value + ';' + item2.value]\n                              "}})],1)],1):_vm._e(),(
                            !_vm.readonly &&
                            item2.haveFile &&
                            _vm.selectedValue[1] &&
                            _vm.selectedValue[1].includes(
                              item.value + ';' + item2.value
                            )
                          )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item2.inputName}},[_c('b-form-file',{attrs:{"state":_vm.chechFile(
                                  _vm.inputValue[item.value + ';' + item2.value]
                                ),"browse-text":_vm.translations['proOrder.FileBrowseButton'],"placeholder":_vm.translations['proOrder.FilePlaceholder'],"drop-placeholder":_vm.translations['proOrder.FileDropPlaceholder']},model:{value:(
                                _vm.inputValue[item.value + ';' + item2.value]
                              ),callback:function ($$v) {_vm.$set(_vm.inputValue, item.value + ';' + item2.value, $$v)},expression:"\n                                inputValue[item.value + ';' + item2.value]\n                              "}}),(
                                _vm.toBig &&
                                _vm.inputValue[item.value + ';' + item2.value]
                              )?_c('span',[_vm._v("plik jest za duży")]):_vm._e()],1),(_vm.readonly)?_c('div',[_c('span',{staticClass:"pro-readonly-value"},[_vm._v(_vm._s(_vm.inputValue[item.value + ";" + item2.value].name))])]):_vm._e()],1):_vm._e()])],1)}),0)],1):(
                    item.haveRadio &&
                    _vm.selectedValue[0] &&
                    _vm.selectedValue[0].includes(item.value)
                  )?_c('div',[_c('b-form-checkbox-group',{staticClass:"radios",attrs:{"name":_vm.name + index},model:{value:(_vm.selectedValue[1]),callback:function ($$v) {_vm.$set(_vm.selectedValue, 1, $$v)},expression:"selectedValue[1]"}},_vm._l((item.radioValues.filter(
                        function (x) { return !_vm.readonly ||
                          _vm.selectedValue[1].includes(item.value + x.value); }
                      )),function(item2,index2){return _c('div',{key:index2},[_c('b-form-checkbox',{attrs:{"value":item.value + ';' + item2.value},nativeOn:{"click":function($event){return _vm.filterValues(item.value + ';' + item2.value)}}},[_vm._v(" "+_vm._s(item2.text)+" ")]),_c('transition',{attrs:{"name":"slide-fade"}},[(
                            item2.haveInput &&
                            _vm.selectedValue[1] &&
                            _vm.selectedValue[1].includes(
                              item.value + ';' + item2.value
                            )
                          )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item2.inputName}},[_c('b-input',{attrs:{"placeholder":item2.inputPlaceHolder,"type":item2.inputType},model:{value:(
                                _vm.inputValue[item.value + ';' + item2.value]
                              ),callback:function ($$v) {_vm.$set(_vm.inputValue, item.value + ';' + item2.value, $$v)},expression:"\n                                inputValue[item.value + ';' + item2.value]\n                              "}})],1)],1):_vm._e(),(
                            !_vm.readonly &&
                            item2.haveFile &&
                            _vm.selectedValue[1] &&
                            _vm.selectedValue[1].includes(
                              item.value + ';' + item2.value
                            )
                          )?_c('div',{staticClass:"short"},[_c('b-form-group',{attrs:{"label":item2.inputName}},[_c('b-form-file',{attrs:{"state":_vm.chechFile(
                                  _vm.inputValue[item.value + ';' + item2.value]
                                ),"browse-text":_vm.translations['proOrder.FileBrowseButton'],"placeholder":_vm.translations['proOrder.FilePlaceholder'],"drop-placeholder":_vm.translations['proOrder.FileDropPlaceholder']},model:{value:(
                                _vm.inputValue[item.value + ';' + item2.value]
                              ),callback:function ($$v) {_vm.$set(_vm.inputValue, item.value + ';' + item2.value, $$v)},expression:"\n                                inputValue[item.value + ';' + item2.value]\n                              "}}),(_vm.toBig)?_c('span',[_vm._v("plik jest za duży")]):_vm._e()],1),(_vm.readonly)?_c('div',[_c('span',{staticClass:"pro-readonly-value"},[_vm._v(_vm._s(_vm.inputValue[item.value + ";" + item2.value].name))])]):_vm._e()],1):_vm._e()])],1)}),0)],1):_vm._e()])],1)}),0),_vm._t("default")],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
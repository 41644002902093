<template>
  <div :class="'institution inst' + model.length">
    <b-container>
      <b-row>
        <div class="institutionContent">
          <div v-for="(item, index) in model" :key="index" class="picture">
            <a
              v-if="item.redirect"
              class="image"
              :href="item.redirect"
              target="_blank"
              @click="sendAnalitycs(item.redirect)"
              :aria-label="
                item.title + ' ' + translations['general.OpenNewTab']
              "
              tabindex="0"
              :title="item.title + ' ' + translations['general.OpenNewTab']"
            >
              <lazy-image
                :source="item.url"
                :alt="item.title + ' ' + translations['general.OpenNewTab']"
                style="height: 50px"
              >
              </lazy-image
            ></a>
            <div class="image" v-else>
              <lazy-image
                :source="item.url"
                :alt="item.title"
                style="height: 50px"
              >
              </lazy-image>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import LazyImage from "../common/LazyImage.vue";
export default {
  components: {
    LazyImage,
  },
  props: {
    model: Array,
    translations: Object,
  },
  methods: {
    sendAnalitycs(label) {
      this.$setGAFormFooter("Kliknięcie w powiązane strony", label, false);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.institution {
  background: white;
  padding: 7px;

  .institutionContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .picture {
      margin: 15px;
      flex: 1 1 auto;
      .image {
        display: block;
        text-align: center;
        flex: 1 1 auto;
        color: white;
        img {
          // max-width : 80%;
          max-height: 50px;
          margin: auto;
          display: inherit;
        }
      }
    }
  }
}
</style>
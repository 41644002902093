<template>
  <div>
    <div class="collapsed-separator space-user d-lg-none"></div>
    <nav-bar-user
      :userOption="secondary.userOption"
      :playlists="secondary.playlists"
      :isCollapsedVersion="true"
      class="container"
    />
    <nav-bar-language
      :langSetting="secondary.langSetting"
      :isCollapsedVersion="true"
      class="container"
    />
    <div
      class="collapsed-separator space-wcag d-md-none"
      v-if="!this.$browserDetect.isApp"
    ></div>
    <nav-bar-wcag
      v-if="!this.$browserDetect.isApp"
      :wcag="secondary.wcag"
      :isCollapsedVersion="true"
      class="container"
    />
    <div
      class="collapsed-separator space-social d-lg-none"
      v-if="!this.$browserDetect.isApp"
    ></div>
    <nav-bar-socials
      v-if="!this.$browserDetect.isApp"
      :social="social"
      :isCollapsedVersion="true"
      class="container d-lg-none"
      :shareSubmitUrl="shareSubmitUrl"
      :translation="translations"
    />
    <div class="collapsed-separator space-social d-lg-none"></div>
    <div class="container d-lg-none">
      <div class="nav social-collapsed d-lg-none">
        <eu-logo :logo="euLogo" class="eu-logo-link" :isCollapsedVersion="true" />
      </div>
    </div>
  </div>
</template>
<script>
import NavBarWcag from "./NavBarWcag.vue";
import NavBarSocials from "./NavBarSocials.vue";
import NavBarLanguage from "./NavBarLanguage.vue";
import NavBarUser from "./NavBarUser.vue";
import EuLogo from "./EuLogo.vue";
export default {
  components: { NavBarSocials, NavBarWcag, NavBarLanguage, NavBarUser, EuLogo },
  props: ["secondary", "shareSubmitUrl", "euLogo", "translations", "social"],
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.collapsed-separator {
  width: 100%;
  border-top: 2px solid $color-divider;

  &.space-user {
    margin-top: 4px;
    padding-top: 13px;
  }

  &.space-wcag {
    margin-top: 17px;
    padding-top: 17px;
  }

  &.space-social {
    margin-top: 20px;
    padding-top: 19px;
  }
}
.second-collapsed {
  .nav-item {
    padding-left: 0px !important;
  }
  .eulink {
    margin-bottom: 20px;
    .eulogo-txt {
      order: 10;
      @include media-breakpoint-down(md) {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }
}
</style>

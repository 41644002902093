<template>
  <modal
    :title="translations['soundLibrary.EditTitle']"
    :hideClose="true"
    :show="show"
    header="editTitle"
    @hidePop="close"
  >
    <template v-slot:main
      ><div class="text-left create-playlist">
        <label for="playlistName">{{ translations["soundLibrary.Title"] }}</label>
        <b-input id="playlistName" name="playlistName" v-model="localName"></b-input>
      </div>
    </template>
    <template v-slot:btn>
      <div class="flex-buttons">
        <b-button @click="close">{{ translations["general.Close"] }}</b-button>
        <b-button variant="primary" @click="save">{{
          translations["general.Save"]
        }}</b-button>
      </div>
    </template>
  </modal>
</template>
<script>
import modal from "./modal.vue";
import PlaylistService from "../../services/playlistService";
export default {
  components: { modal },
  props: {
    translations: Object,   
    editId: String,
    name: String,
  },
  data() {
    return { 
      show: false,
      localName: '',
    };
  },
  mounted() {
    this.localName = this.name;
    this.show = true;
  },
  methods: {
    close() {
      this.$emit("close", this.localName);
    },
    save() {
      PlaylistService.edit(this.editId, this.localName).then(() => {
        this.close();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.create-playlist {
  margin-bottom: 24px;
  label {
    font-family: "Wotfard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
}
</style>

<template>
  <div class="mx-auto mt-5 color--basic regpanel">
    <b-container class="mb-5" v-if="done">
      <b-row class="pt-5">
        <b-col class="text-center">
          {{ model.translations["user.RegistrationSucceeded"] }}
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col class="mx-4 text-center">
          <b-button type="submit" variant="primary" class="button--large button--reg"
            @click="$router.push(model.login.url)">{{ model.translations["general.Login"] }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="$browserDetect.isApp && width > 1500"><b-row>
        <b-col class="mx-4 text-center text-uppercase">
          <h1 v-html="model.translations['general.Register']"></h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <p v-html="model.translations['general.Error.RegisterNoAvaliable']"></p>
        </b-col>
      </b-row>
    </b-container>
    <b-form @submit.stop.prevent="submit" v-else>
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center text-uppercase">
            <h1 v-html="model.translations['general.Register']"></h1>
          </b-col> </b-row><b-row v-if="!this.$isTizen(this.$browserDetect) && !state.isOptPro && showLogin">
          <b-col class="mx-4 text-center">
            <b-link :href="getExternalLink('google')" class="btn button--large button--google btn-secondary"
              :target="$browserDetect.isApp ? '_blank' : ''">{{ model.translations["user.LoginWithGoogle"] }}
            </b-link>
          </b-col>
        </b-row>
        <b-row class="pt-2" v-if="!this.$isTizen(this.$browserDetect) && !state.isOptPro && showLogin">
          <b-col class="mx-4 text-center">
            <b-link :href="getExternalLink('facebook')" class="btn button--large button--fb btn-secondary"
              :target="$browserDetect.isApp ? '_blank' : ''">{{ model.translations["user.LoginWithFacebook"] }}
            </b-link>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col class="text-center text--smaller">{{ this.loginIfRegistered[0] }}
            <b-link :to="model.login.url" class="focus-smart" :title="model.login.name">{{
              this.loginIfRegistered[1]
            }}</b-link>
          </b-col>
        </b-row>
        <b-row class="pt-3" v-if="model.productKey == 2">
          <b-col>
            <label for="institutionName">{{
              model.translations["user.InstitutionName"]
            }}</label>
            <b-form-input id="institutionName" name="institutionName" v-model="$v.state.institutionName.$model"
              :state="validationState($v.state.institutionName)" aria-describedby="error-institution-name"></b-form-input>
            <b-form-invalid-feedback id="error-institution-name"><span v-if="$v.state.institutionName.$dirty && !$v.state.institutionName.required
              ">{{ model.translations["general.Errors.Required"] }}
              </span></b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-3" v-if="model.productKey == 2">
          <b-col>
            <label for="institutionPhone">{{
              model.translations["user.InstitutionPhone"]
            }}</label>
            <b-form-input name="institutionPhone" id="institutionPhone" v-model="$v.state.institutionPhone.$model"
              :state="validationState($v.state.institutionPhone)"
              aria-describedby="error-institution-phone"></b-form-input>
            <b-form-invalid-feedback id="error-institution-phone"><span v-if="$v.state.institutionPhone.$dirty && !$v.state.institutionPhone.required
              ">{{ model.translations["general.Errors.Required"] }}
              </span></b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-3" v-if="model.productKey == 2">
          <b-col>
            <label for="institutionNIP">{{ model.translations["user.NIP"] }}</label>
            <b-form-input name="institutionNIP" id="institutionNIP" v-model="$v.state.institutionNIP.$model"
              :state="validationState($v.state.institutionNIP)" aria-describedby="error-institution-nip"></b-form-input>
            <b-form-invalid-feedback id="error-institutionName"
              v-if="$v.state.institutionNIP.$dirty && !$v.state.institutionNIP.required">{{
                model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback id="error-institutionNIP"
              v-else-if="$v.state.institutionNIP.$dirty && !$v.state.institutionNIP.nipFormat">{{
                model.translations["user.InvalidNIP"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-3" v-if="model.productKey == 2">
          <b-col>
            <label for="institutionRIK">{{ model.translations["user.RIK"] }}</label>
            <b-form-input name="institutionRIK" id="institutionRIK" v-model="$v.state.institutionRIK.$model"
              :state="validationState($v.state.institutionRIK)" aria-describedby="error-institution-rik"></b-form-input>
            <b-form-invalid-feedback id="error-institution-rik"><span
                v-if="$v.state.institutionRIK.$dirty && !$v.state.institutionRIK.required">{{
                  model.translations["general.Errors.Required"] }}
              </span></b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <label for="firstname">{{ model.translations["general.FirstName"] }}</label>
            <b-form-input name="firstname" id="firstname" v-model="$v.state.firstname.$model"
              :state="validationState($v.state.firstname)" aria-describedby="error-firstname"></b-form-input>
            <b-form-invalid-feedback id="error-firstname"><span
                v-if="$v.state.firstname.$dirty && !$v.state.firstname.required">{{
                  model.translations["general.Errors.Required"] }}
              </span></b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col>
            <label for="lastname">{{ model.translations["general.LastName"] }}</label>
            <b-form-input name="lastname" id="lastname" v-model="$v.state.lastname.$model"
              :state="validationState($v.state.lastname)" aria-describedby="error-lastname"></b-form-input>
            <b-form-invalid-feedback id="error-lastname"><span
                v-if="$v.state.lastname.$dirty && !$v.state.lastname.required">{{
                  model.translations["general.Errors.Required"] }}
              </span></b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col>
            <label for="username">{{ model.translations["general.Username"] }}</label>
            <b-form-input name="username" id="username" v-model="$v.state.username.$model"
              :state="validationState($v.state.username)" aria-describedby="error-username"></b-form-input>
            <b-form-invalid-feedback id="error-username"><span
                v-if="$v.state.username.$dirty && !$v.state.username.required">{{
                  model.translations["general.Errors.Required"] }}
              </span>
              <span v-if="$v.state.username.$dirty &&
                $v.state.username.required &&
                !$v.state.username.loginFormat
                ">{{ model.translations["general.Errors.InvalidUserName"] }}</span></b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <label for="email">{{ model.translations["general.Email"] }}</label>
            <b-form-input name="email" id="email" v-model="$v.state.email.$model" :state="validationState($v.state.email)"
              aria-describedby="error-email"></b-form-input>
            <b-form-invalid-feedback id="error-email"><span v-if="$v.state.email.$dirty && !$v.state.email.required">{{
              model.translations["general.Errors.Required"] }}
              </span>
              <span v-else-if="$v.state.email.$dirty && !$v.state.email.email">{{
                model.translations["general.Errors.InvalidEmail"] }}
              </span>
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col>
            <label for="password">{{ model.translations["general.Password"] }}</label>
            <b-form-input name="password" id="password" type="password" v-model="$v.state.password.$model"
              :state="validationState($v.state.password)" aria-describedby="error-password"></b-form-input>
            <b-form-invalid-feedback id="error-password"><span
                v-if="$v.state.password.$dirty && !$v.state.password.required">{{
                  model.translations["general.Errors.Required"] }}
              </span>
              <span v-else-if="$v.state.password.$dirty && !$v.state.password.passwordFormat">{{
                model.translations["user.PasswordFormat"] }}
              </span>
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <label for="repeatPassword">{{
              model.translations["user.RepeatPassword"]
            }}</label>
            <b-form-input name="repeatPassword" id="repeatPassword" type="password"
              v-model="$v.state.repeatPassword.$model" :state="validationState($v.state.repeatPassword)"
              aria-describedby="error-repeatPassword"></b-form-input>
            <b-form-invalid-feedback id="error-repeatPassword"><span
                v-if="$v.state.repeatPassword.$dirty && !$v.state.repeatPassword.required">{{
                  model.translations["general.Errors.Required"] }}
              </span>
              <span id="error-repeatPassword" v-else-if="$v.state.repeatPassword.$dirty &&
                !$v.state.repeatPassword.sameAsPassword
                ">{{ model.translations["user.ErrorConfirmPassword"] }}
              </span>
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-4" v-if="model.productKey == 2">
          <b-col>
            <div class="divider"></div>
          </b-col>
        </b-row>

        <b-row class="pt-4" v-if="state.isOptEdu">
          <b-col class="text--smaller">
            {{ model.translations["user.Optional"] }}
          </b-col>
        </b-row>

        <b-row class="pt-2" v-if="false">
          <b-col>
            <div class="regpanel__opt-col pl-3 pr-1 d-flex justify-content-between align-items-center">
              <b-form-checkbox id="check-optedu" name="check-optedu" v-model="checkOptEdu" value="on"
                unchecked-value="off">{{ model.translations["user.OptionsEdu"] }}
              </b-form-checkbox>
              <b-button role="tab" class="button--toggle" v-b-toggle="'optedu'">
                <b-media center-align vertical-align="center">
                  <template>
                    <div class="arrow"></div>
                  </template>
                  <p class="mb-0 mr-3"></p>
                </b-media>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="state.isOptEdu">
          <b-col>
            <b-collapse id="optedu" v-model="state.isOptEdu" role="tabpanel" class="regpanel__collapsible">
              <slot>
                <b-form-checkbox id="check-edurole-u" v-model="checkOptPupil" name="check-edurole-u" value="on"
                  unchecked-value="off">{{ model.translations["user.Pupil"] }}
                </b-form-checkbox>
                <b-form-checkbox id="check-edurole-s" v-model="checkOptStudent" name="check-edurole-s" value="on"
                  unchecked-value="off">{{ model.translations["user.Student"] }}
                </b-form-checkbox>
                <b-form-checkbox id="check-edurole-n" v-model="checkOptTeacher" name="check-edurole-n" value="on"
                  unchecked-value="off">{{ model.translations["user.Teacher"] }}
                </b-form-checkbox>
                <!-- <b-button
                  role="tab"
                  class="button--toggle"
                  v-b-toggle="'optteacher'"
                >
                  <b-media center-align vertical-align="center">
                    <template>
                      <div class="arrow"></div>
                    </template>
                    <p class="mb-0 mr-3"></p>
                  </b-media>
                </b-button> -->
                <b-collapse id="optteacher" v-model="state.isOptTeacher" role="tabpanel"
                  class="mb-4 regpanel__collapsible">
                  <slot>
                    <b-row class="pt-3">
                      <b-col class="mx-3">
                        <label for="institutionName">{{
                          model.translations["user.InstitutionName"]
                        }}</label>
                        <b-form-input name="institutionName" id="institutionName"
                          v-model="$v.state.institutionName.$model" :state="validationState($v.state.institutionName)"
                          aria-describedby="error-institution-name"></b-form-input>
                        <b-form-invalid-feedback id="error-institution-name"><span v-if="$v.state.institutionName.$dirty &&
                          !$v.state.institutionName.required
                          ">{{ model.translations["general.Errors.Required"] }}
                          </span></b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3">
                      <b-col class="mx-3">
                        <label for="institutionType">{{
                          model.translations["user.InstitutionType"]
                        }}</label>
                        <b-form-select name="institutionType" id="institutionType"
                          v-model="$v.state.institutionType.$model" :options="model.institutionTypes"
                          :state="validationState($v.state.institutionType)" aria-describedby="error-institution-type">
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                              {{ model.translations["general.Errors.SelectOneOption"] }}
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback id="error-institution-type"><span v-if="$v.state.institutionType.$dirty &&
                          !$v.state.institutionType.required
                          ">{{ model.translations["general.Errors.Required"] }}
                          </span></b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3">
                      <b-col class="mx-3">
                        <label for="institutionAddress">{{
                          model.translations["user.InstitutionAddress"]
                        }}</label>
                        <b-form-input name="institutionAddress" id="institutionAddress"
                          v-model="$v.state.institutionAddress.$model"
                          :state="validationState($v.state.institutionAddress)"
                          aria-describedby="error-institution-address"></b-form-input>
                        <b-form-invalid-feedback id="error-institution-address"><span v-if="$v.state.institutionAddress.$dirty &&
                          !$v.state.institutionAddress.required
                          ">{{ model.translations["general.Errors.Required"] }}
                          </span></b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3">
                      <b-col class="mx-3">
                        <label for="institutionPhone">{{
                          model.translations["user.InstitutionPhone"]
                        }}</label>
                        <b-form-input name="institutionPhone" id="institutionPhone"
                          v-model="$v.state.institutionPhone.$model" :state="validationState($v.state.institutionPhone)"
                          aria-describedby="error-institution-phone"></b-form-input>
                        <b-form-invalid-feedback id="error-institution-phone"><span v-if="$v.state.institutionPhone.$dirty &&
                          !$v.state.institutionPhone.required
                          ">{{ model.translations["general.Errors.Required"] }}
                          </span></b-form-invalid-feedback>
                      </b-col>
                    </b-row>

                    <b-row class="pt-3">
                      <b-col class="mx-3">
                        <label for="province">{{
                          model.translations["user.Province"]
                        }}</label>
                        <b-form-select name="province" id="province" v-model="$v.state.province.$model"
                          :options="model.provinces" :state="validationState($v.state.province)"
                          aria-describedby="error-province">
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                              {{ model.translations["general.Errors.SelectOneOption"] }}
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback id="error-province"><span
                            v-if="$v.state.province.$dirty && !$v.state.province.required">{{
                              model.translations["general.Errors.Required"] }}
                          </span></b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3">
                      <b-col class="mx-3">
                        <label for="county">{{
                          model.translations["user.County"]
                        }}</label>
                        <b-form-input name="county" id="county" v-model="$v.state.county.$model"
                          :state="validationState($v.state.county)" aria-describedby="error-country"></b-form-input>
                        <b-form-invalid-feedback id="error-country"><span
                            v-if="$v.state.county.$dirty && !$v.state.county.required">{{
                              model.translations["general.Errors.Required"] }}
                          </span></b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                  </slot>
                </b-collapse>
              </slot>
            </b-collapse>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col>
            <b-form-checkbox v-model="$v.state.acceptedCheck.$model" name="acceptedCheck" value="on" unchecked-value=""
              class="accept" :state="validationState($v.state.acceptedCheck)" aria-describedby="error-acceptedCheck">
              {{ this.acceptance[0] }}
              <b-link :to="model.rules.url" class="focus-smart" :title="model.rules.name">{{ this.acceptance[1]
              }}</b-link>
              {{ this.acceptance[2] }}
              <b-link :to="model.privacy.url" class="focus-smart" :title="model.privacy.name">{{ this.acceptance[3]
              }}</b-link>{{ this.acceptance[4] }}
            </b-form-checkbox>
            <b-form-invalid-feedback id="error-acceptedCheck" :state="!$v.state.acceptedCheck.$error">
              <span>
                {{ model.translations["general.Errors.Required"] }}
              </span></b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-2" v-if="false">
          <b-col>
            <b-form-checkbox v-model="$v.state.newsletter.$model" name="check-newsl" value="on" unchecked-value="off">{{
              model.translations["user.OptionsNewsletter"] }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button type="submit" variant="primary" class="button--large button--reg">{{
              model.translations["general.Register"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <modal header="register" :title="registrationError" :show="showModal" :closeText="closeText" @hidePop="hideModal">
    </modal>
  </div>
</template>

<script>
import ValidationMixin, { passwordFormat, loginFormat, nipFormat } from "@/mixins/ValidationMixin";
import FixedAlertMixin from "@/mixins/FixedAlertMixin";
import { required, requiredIf, email, sameAs } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import { load } from "recaptcha-v3";
import modal from "../components/common/modal.vue";

export default {
  components: { modal },
  name: "RegisterPage",
  mixins: [ValidationMixin, FixedAlertMixin],
  props: {
    model: Object,
    translations: Object,
    closeText: String,
  },
  data() {
    return {
      hasValidationError: false,
      done: false,
      acceptance: [],
      loginIfRegistered: [],
      registrationError: null,
      state: {
        username: null,
        email: null,
        password: null,
        repeatPassword: null,
        firstname: null,
        lastname: null,
        institutionName: null,
        institutionType: null,
        institutionAddress: null,
        institutionPhone: null,
        institutionNIP: null,
        institutionRIK: null,
        province: null,
        county: null,
        isOptEdu: this.model.productKey == 3,
        isOptTeacher: false,
        isOptPupil: false,
        isOptStudent: false,
        isOptPro: this.model.productKey == 2,
        acceptedCheck: null,
        newsletter: null,
        token: null,
      },
      width: window.innerWidth,
      showModal: Boolean,
      showLogin: false,
    };
  },
  computed: {
    checkOptEdu: {
      get() {
        return this.state.isOptEdu ? "on" : "off";
      },
      set(value) {
        this.state.isOptEdu = value == "on";
      },
    },
    checkOptTeacher: {
      get() {
        return this.state.isOptTeacher ? "on" : "off";
      },
      set(value) {
        this.state.isOptTeacher = value == "on";
      },
    },
    checkOptPupil: {
      get() {
        return this.state.isOptPupil ? "on" : "off";
      },
      set(value) {
        this.state.isOptPupil = value == "on";
      },
    },
    checkOptStudent: {
      get() {
        return this.state.isOptStudent ? "on" : "off";
      },
      set(value) {
        this.state.isOptStudent = value == "on";
      },
    },
  },
  mounted() {
    this.showLogin = process.env.VUE_APP_ENABLE_EXTERNAL_LOGIN === true;
    this.acceptance = this.model.translations["user.Acceptance"].split(/{|}/);
    this.loginIfRegistered = this.model.translations["user.LoginIfRegistered"].split(
      /{|}/
    );
    load(this.model.reCaptchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
  validations: {
    state: {
      username: { required, loginFormat },
      email: { required, email },
      password: {
        required,
        passwordFormat,
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
      firstname: {
        required: requiredIf(function () {
          return this.state.isOptEdu;
        }),
      },
      lastname: {
        required: requiredIf(function () {
          return this.state.isOptEdu;
        }),
      },
      institutionName: {
        required: requiredIf(function () {
          return (
            this.model.productKey == 2 || (this.state.isOptTeacher && this.state.isOptEdu)
          );
        }),
      },
      institutionType: {
        required: requiredIf(function () {
          return this.state.isOptTeacher && this.state.isOptEdu;
        }),
      },
      institutionAddress: {
        required: requiredIf(function () {
          return this.state.isOptTeacher && this.state.isOptEdu;
        }),
      },
      institutionPhone: {
        required: requiredIf(function () {
          return this.state.isOptTeacher && this.state.isOptEdu;
        }),
      },
      institutionNIP: {
        nipFormat,
        required: requiredIf(function () {
          return this.state.isOptPro;
        }),
      },
      institutionRIK: {
        required: requiredIf(function () {
          return this.state.isOptPro;
        }),
      },
      province: {
        required: requiredIf(function () {
          return this.state.isOptTeacher && this.state.isOptEdu;
        }),
      },
      county: {},
      acceptedCheck: {
        required,
      },
      newsletter: {},
    },
  },
  methods: {
    getExternalLink(extprovider) {
      return authService.getExternalLink(extprovider, this.model.registerExternal.url);
    },
    hideModal() {
      this.showModal = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.registrationError = this.model.translations["user.RegisterError"];
        this.show = true;
        return;
      }

      this.$setBusy(true);
      this.recaptcha.execute("register").then((token) => {
        this.state.token = token;
        authService
          .register(this.state)
          .then(() => {
            this.$setBusy(false);
            this.done = true;
          })
          .catch((error_code) => {
            let error =
              error_code && this.model.translations["user.SSOErrors." + error_code]
                ? this.model.translations["user.SSOErrors." + error_code]
                : this.model.translations["user.RegisterError"];
            this.registrationError = error;
            this.showModal = true;
            this.$setBusy(false);
            window.scrollTo(0, 0);
          });
      });
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.regpanel {
  width: 100%;
  max-width: 500px;
  font-family: $font-family-wotfard;

  .text {
    &--smaller {
      font-size: $font-size-smaller;
    }
  }

  .divider {
    border-top: 1px solid $color-gray;
  }

  &__opt-col {
    border-radius: 2px;
    background-color: $color-gray;
    height: 40px;

    .custom-control-label {
      vertical-align: unset;
    }
  }

  &__opt-teacher {
    // background-color: $color-gray;
    height: 40px;

    .custom-control-label {
      vertical-align: unset;
    }
  }

  &__collapsible {

    // background-color: $color-dark-gray;
    .custom-control {
      margin: 5px 0;
    }

    &.teacher {
      margin-bottom: 20px;
    }
  }

  .button {
    &--reg {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }

    &--toggle {
      background-color: inherit;
      border: none;
      height: 40px;
      width: 40px;
      padding: 0 10px;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &:active {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
      }

      .media-aside {
        min-width: 30px;
      }

      &.collapsed {
        .arrow {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $color-main;
        }
      }

      &.not-collapsed {
        .arrow {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $color-main;
          padding-left: 5px;
        }
      }
    }

    &--fb,
    &--google {
      border: none;
      font-weight: $font-weight-semi-bold;
      width: 100%;
      max-width: 360px;
    }

    &--google {
      background-color: $color-google;
    }

    &--fb {
      background-color: $color-fb;
    }
  }

  .accept {
    a {
      text-decoration: underline;
    }
  }

  >form {
    >.container {
      >.row {
        margin: 0;
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main-nav-bar"},[_c('div',{staticClass:"main-nav-bar__first-level"},[_c('b-container',{staticClass:"containerFixed",attrs:{"fluid":""}},[_c('b-row',[_c('div',{staticClass:"row m-0 w-100"},[_c('b-navbar-nav',{ref:"scroll",staticClass:"main-nav ml-0 mr-0",attrs:{"id":"TVScroll"}},[_vm._l((_vm.mainLinks),function(main,index){return [(!main.subPages)?_c('b-nav-item',{key:index,staticClass:"navitem-hover-left-right",attrs:{"id":'nav' + index,"to":main.url,"exact-active-class":"active-link","exact":""},on:{"click":function($event){return _vm.sendAnalitycs(main.name)},"focus":_vm.focus}},[_vm._v(_vm._s(main.name))]):(!_vm.$isTizen(_vm.$browserDetect))?_c('b-nav-item',{key:index,staticClass:"main-nav__dropdown",class:[_vm.openSubmenuIndex == index ? 'active' : ''],attrs:{"exact-active-class":"active-link"},on:{"click":function($event){$event.stopPropagation();return _vm.showSubmenu(index)}}},[_c('span',{staticClass:"submenu-toggle-text"},[_vm._v(_vm._s(main.name))]),_c('i',{staticClass:"main-nav__icon",class:[
                    _vm.openSubmenuIndex != index
                      ? 'cil-chevron-bottom-alt'
                      : 'cil-chevron-left-alt' ]}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.openSubmenuIndex == index)?_c('b-navbar-nav',{staticClass:"submenu"},[(!_vm.user)?_c('b-navbar-nav',_vm._l((main.subPages.filter(
                          function (subPage) { return !subPage.requireAuthorization; }
                        )),function(subPage,i){return _c('b-nav-item',{key:i,staticClass:"submenu-hover-left-right",attrs:{"exact-active-class":"active-link-submenu","to":subPage.url},on:{"click":function($event){return _vm.sendAnalitycs(subPage.name)}}},[_vm._v(_vm._s(subPage.name))])}),1):_vm._e(),(_vm.user)?_c('b-navbar-nav',_vm._l((main.subPages),function(subPage,i){return _c('b-nav-item',{key:i,staticClass:"submenu-hover-left-right",attrs:{"exact-active-class":"active-link-submenu","to":subPage.url},on:{"click":function($event){return _vm.sendAnalitycs(subPage.name)}}},[_vm._v(_vm._s(subPage.name))])}),1):_vm._e()],1):_vm._e()],1)],1):_c('b-navbar-nav',{key:index,staticClass:"submenu",class:[_vm.openSubmenuIndex == index ? 'active' : '']},[_c('b-nav-item',{staticClass:"main-nav__dropdown",attrs:{"exact-active-class":"active-link"},on:{"click":function($event){$event.stopPropagation();return _vm.showSubmenu(index)},"focus":_vm.focus}},[_c('span',{staticClass:"submenu-toggle-text"},[_vm._v(_vm._s(main.name))]),_c('i',{staticClass:"main-nav__icon",class:[
                      _vm.openSubmenuIndex != index
                        ? 'cil-chevron-bottom-alt'
                        : 'cil-chevron-left-alt' ]})]),(!_vm.user)?_c('b-navbar-nav',_vm._l((main.subPages.filter(
                      function (subPage) { return !subPage.requireAuthorization; }
                    )),function(subPage,i){return _c('b-nav-item',{key:i,staticClass:"submenu-hover-left-right",attrs:{"exact-active-class":"active-link-submenu","to":subPage.url},on:{"click":function($event){return _vm.sendAnalitycs(subPage.name)},"focus":_vm.focus}},[_vm._v(_vm._s(subPage.name))])}),1):_c('b-navbar-nav',_vm._l((main.subPages),function(subPage,i){return _c('b-nav-item',{key:i,staticClass:"submenu-hover-left-right",attrs:{"exact-active-class":"active-link-submenu","to":subPage.url},on:{"click":function($event){return _vm.sendAnalitycs(subPage.name)},"focus":_vm.focus}},[_vm._v(_vm._s(subPage.name))])}),1)],1)]})],2)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">          
          <div v-if="readonly">
            <div>
              {{translations['proOrder.NumberShows']}}: <span class="pro-readonly-value">{{countShows}}</span>
            </div>
            <div>
              {{translations['proOrder.ShowDates']}}: 
              <span v-for="(date, index) in dates" :key="index" class="pro-readonly-value">
                <span v-if="index > 0">, </span>{{date}}</span>
            </div>
          </div>
          <b-form-group v-if="!readonly">            
            <b-form-group
              :label="translations['proOrder.NumberShows']"
              class="short"
            >
              <b-input
                v-model="countShows"
                type="number"
                :placeholder="translations['proOrder.EnterNumber']"
              >
              </b-input>
              <label for="startDate" class="col-form-label">{{
                translations["proOrder.ShowDates"]
              }}</label>
              <date-picker
                class="date"
                v-for="(date, index) in dates"
                :key="index"
                v-model="dates[index]"
                name="startDate"
                prefix-class="vd"
                value-type="format"
                format="YYYY-MM-DD"
              >
                <template v-slot:input>
                  <b-input
                    type="text"
                    v-model="dates[index]"
                    name="startDate"
                    :placeholder="translations['proOrder.EnterDate']"
                  ></b-input>
                </template>
              </date-picker>
              <b-link @click="dates.push(null)">{{
                translations["proOrder.AddDate"]
              }}</b-link>
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  data() {
    return {      
      countShows: null,
      dates: [null],
    };
  },
  props: {
    readonly: Boolean(false),
    header: String,
    translations: Object,
    count: String,
    selectDates: Array,
  },
  watch: {
    countShows(newValue) {
      this.$emit("ChangeValue", newValue, "countShows");
    },
    dates(newValue) {
      this.$emit("ChangeValue", newValue, "dates");
    },
    count(newValue) {
      if (newValue != this.countShows) this.countShows = newValue;
    },
    selectDates: {
      handler(newValue) {
        if (this.dates != newValue) this.dates = newValue;
      },
    },
  },
  mounted() {
    if (this.count) this.countShows = this.count;
    if (this.selectDates) this.dates = this.selectDates;
  },
};
</script>
<style lang="scss">
.progressBar {
  .vd-input-wrapper div.invalid-feedback ~ i {
    top: 50%;
  }
  .date {
    margin-bottom: 15px;
  }
}
</style>
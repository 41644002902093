<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <b-form-group>
            <b-form-radio-group
              class="radios"
              v-model="selectedValue[0]"
              :name="name"
              :class="{ selected: selectedValue[0] }"
            >
              <div
                v-for="(item, index) in itemList.filter(
                  (x) => !readonly || selectedValue[0] == x.value
                )"
                :key="index"
                :class="{
                  active: item.value == selectedValue[0],
                }"
              >
                <b-form-radio
                  :value="item.value"
                  @click.native="
                    inputValue = null;
                    selectedValue[1] = null;
                  "
                >
                  {{ item.text }}
                </b-form-radio>
                <p
                  class="hint"
                  v-if="item.hint"
                  v-show="item.value == selectedValue[0]"
                >
                  {{ item.hint }}
                </p>
                <transition name="slide-fade">
                  <div
                    class="short"
                    v-if="item.haveInput && item.value == selectedValue[0]"
                  >
                    <b-form-group :label="item.inputName" v-if="!readonly">
                      <b-input
                        :placeholder="item.inputPlaceHolder"
                        :disabled="item.value != selectedValue[0]"
                        :type="item.inputType"
                        v-model="inputValue"
                      ></b-input>
                    </b-form-group>
                    <span
                      v-if="readonly && inputValue"
                      class="pro-readonly-value"
                      >{{ inputValue }}</span
                    >
                  </div>
                  <div
                    class="short"
                    v-if="item.haveText && item.value == selectedValue[0]"
                  >
                    <b-form-group :label="item.inputName">
                      <label :for="item.inputName" class="col-form-label">{{
                        item.inputPlaceHolder
                      }}</label>
                      <b-form-textarea
                        :disabled="item.value != selectedValue[0]"
                        v-model="inputValue"
                        size="lg"
                        maxlength="1000"
                      ></b-form-textarea>
                      <p class="chars-status text-right">
                        {{ inputValue ? inputValue.length : 0 }} / 1000
                      </p>
                    </b-form-group>
                  </div>
                  <div
                    v-else-if="item.haveRadio && item.value == selectedValue[0]"
                  >
                    <b-form-radio-group
                      class="radios"
                      :name="name + index"
                      v-model="selectedValue[1]"
                      :class="{ selected: selectedValue[1] }"
                    >
                      <div
                        v-for="(item2, index2) in item.radioValues.filter(
                          (x) => !readonly || selectedValue[1] == x.value
                        )"
                        :key="index2"
                      >
                        <b-form-radio
                          :disabled="item.value != selectedValue[0]"
                          :value="item2.value"
                          :class="{
                            active: item2.value == selectedValue[1],
                          }"
                          @click.native="inputValue = null"
                        >
                          {{ item2.text }}
                        </b-form-radio>
                        <transition name="slide-fade">
                          <div
                            class="short"
                            v-if="
                              item2.haveInput && item2.value == selectedValue[1]
                            "
                          >
                            <b-form-group :label="item2.inputName">
                              <b-input
                                :placeholder="item2.inputPlaceHolder"
                                :disabled="item2.value != selectedValue[1]"
                                :type="item2.inputType"
                                v-model="inputValue"
                              ></b-input>
                            </b-form-group>
                          </div>
                          <div
                            class="short"
                            v-if="
                              item2.haveFile && item2.value == selectedValue[1]
                            "
                          >
                            <b-form-group :label="item2.inputName">
                              <b-form-file
                                v-model="inputValue"
                                :state="Boolean(inputValue)"
                                :browse-text="
                                  translations['proOrder.FileBrowseButton']
                                "
                                :placeholder="
                                  translations['proOrder.FilePlaceholder']
                                "
                                :drop-placeholder="
                                  translations['proOrder.FileDropPlaceholder']
                                "
                              ></b-form-file>
                            </b-form-group>
                          </div>
                        </transition>
                      </div>
                    </b-form-radio-group>
                  </div>
                </transition>
              </div>
            </b-form-radio-group>
            <slot></slot>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    itemList: Array,
    select: null,
    input: null,
    name: String,
    translations: null,
    readonly: Boolean,
  },
  data() {
    return {
      selectedValue: [],
      inputValue: null,
    };
  },
  watch: {
    selectedValue(newValue) {
      this.$emit("ChangeValue", newValue, this.name);
    },
    select: {
      handler(newValue) {
        if (newValue != this.selectedValue) this.selectedValue = newValue;
      },
      deep: true,
    },
    inputValue(newValue) {
      this.$emit("ChangeValue", newValue, this.name + "input");
    },

    input(newValue) {
      if (this.inputValue != newValue) this.inputValue = newValue;
    },
  },
  mounted() {
    if (this.select) this.selectedValue = this.select;
    if (this.input) this.inputValue = this.input;
  },
};
</script>
<style lang="scss">
.radios {
  .radios {
    margin-left: 20px;
  }
}
</style>
import axios from 'axios'

export default {
    getQuestions: function (endpoint) {
        return axios
            .get(process.env.VUE_APP_BASE_API_URL + endpoint, {})
            .then(function (response) {
                return response.data;
            }).catch(function () {
            });
    },
    submitAnswers: function (endpoint, userAnswers, uniqueKey) {
        return axios
            .post(process.env.VUE_APP_BASE_API_URL + endpoint,
                {
                    uniqueKey : uniqueKey,
                    userAnswers: userAnswers
                })
            .then(function (response) {
                return response.data;
            })
            .catch(function () {
            });
    }
}
<template>
    <div class="quiz__intro">
        <b-container fluid class="containerFixed">
            <b-row class="color--basic">
                <b-col cols="12">
                    <h1>{{ model.title }}</h1>
                </b-col>
                <b-col cols="12 mb-3">
                    <div v-html="model.description"></div>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col cols="12" md="8" lg="6" class="mt-3 fix">
                    <div class="vd_list-group sm">
                        <div class="vd_list-item">
                            {{ model.translations["quizzes.Intro.TotalQuestions"] }}
                            <div class="vd_list-badge">
                                {{ model.totalQuestions }}
                            </div>
                        </div>

                        <div class="vd_list-item">
                            {{ model.translations["quizzes.Intro.AvailableScore"] }}
                            <div class="vd_list-badge">
                                {{ model.availableScore }}
                            </div>
                        </div>

                        <div class="vd_list-item" v-if="model.duration">
                            {{ model.translations["quizzes.Intro.DurationTime"] }}
                            <div class="vd_list-badge">
                                {{ model.duration }}
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" align-self="center">
                    <b-button @click="startQuiz" class="mt-5" variant="primary">
                        {{ model.translations["quizzes.Intro.Button"] }}
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "Intro",
    props: {
        model: Object
    },
    methods: {
        startQuiz() {
            this.$emit("start", true);
        }
    }
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.vd_list-group {
    margin: auto;

    &.sm {
        max-width: em(360);
    }

    &.md {
        max-width: em(600);
    }

    &.adaptive {
        display: inline-block;
    }

    .vd_list-item {
        background: $color-gray;
        color: $color-main;
        font-size: em(16);
        font-weight: $font-weight-extra-bold;
        padding: em(12) em(39) em(12) em(15);
        display: flex;
        text-align: left;
    }

    .vd_list-item:not(:last-child) {
        margin-bottom: em(20);
    }

    .vd_list-badge {
        margin-left: auto;
        padding-left: em(15);
    }
}
</style>
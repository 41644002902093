<template>
  <div class="short">
    <b-form-group :label="label">
      <b-input
        v-for="(item, index) in values"
        :key="index"
        :disabled="disabled"
        :placeholder="placeholder"
        v-model="values[index]"
      ></b-input>
    </b-form-group>
    <b-link @click="values.push(null)" v-show="!disabled">
      {{ buttonText }}</b-link
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      values: [null],
    };
  },
  props: {
    disabled: Boolean,
    placeholder: String,
    label: String,
    selected: Array,
    buttonText: String,
    name: String,
  },
  watch: {
    values: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, this.name);
      },
      deep: true,
    },
    selected: {
      handler(newValue) {
        if (newValue != this.values) this.values = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.selected) this.values = this.selected;
  },
};
</script>
<style lang="scss" scoped>
input {
  margin-bottom: 15px;
}
</style>
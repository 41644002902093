<template>
  <div class="mainheader">
    <header
      :class="{ mobileExpanded: isMenuExpanded }"
      @mouseover="mouseOverTopMenu = true"
      @mouseleave="mouseOverTopMenu = false"
    >
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
        class="mainheader__App-belt background--black"
      >
        <b-container fluid class="containerFixed">
          <b-row style="width: 100%">
            <b-link
              :aria-label="translations['general.Back']"
              @click="
                $setNavigationLock(false);
                $router.go(-1);
              "
              class="arrow focus-smart"
              v-if="ShowArrowBack()"
            >
              <i class="cil-chevron-circle-left-alt"></i>
            </b-link>
            <logo :model="model.logo" class="logo-link"></logo>
          </b-row> </b-container
      ></b-navbar>
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
        class="mainheader__top-navbar"
      >
        <div
          class="mainheader__belt-top background--black"
          ref="mainheaderbelttop"
        >
          <b-container fluid class="containerFixed">
            <b-row>
              <div>
                <b-navbar-toggle
                  target="nav-collapse-main"
                  class="mainheader__toggler"
                  :label="
                    !isMenuExpanded
                      ? translations['general.ShowMenu']
                      : translations['general.HideMenu']
                  "
                >
                  <template>
                    <span id="hamburger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                    <span id="cross">
                      <span></span>
                      <span></span>
                    </span>
                  </template>
                </b-navbar-toggle>
                <logo
                  :model="model.logo"
                  :class="{ hide: this.$browserDetect.isApp }"
                  class="logo-link"
                ></logo>
              </div>
              <div>
                <nav-bar-second
                  :secondary="model.secondary"
                  :euLogos="model.euLogo"
                  :shareSubmitUrl="model.secondary.shareSubmitUrl"
                  :translations="model.secondary.translations"
                  :social="model.secondary.social"
                />
              </div>
            </b-row>
          </b-container>
        </div>
        <div
          class="mainheader__belt-bottom"
          :class="{ hidden: !scrollOnTop && !mouseOverTopMenu }"
        >
          <b-collapse
            id="nav-collapse-main"
            is-nav
            class="mainheader__collapse-panel"
            v-model="isMenuExpanded"
            ref="navcollapsemain"
          >
            <nav-bar-main :mainLinks="model.mainLinks" />
            <nav-bar-second-collapsed
              :secondary="model.secondary"
              class="second-collapsed d-lg-none"
              :euLogo="model.euLogo"
              :shareSubmitUrl="model.secondary.shareSubmitUrl"
              :translations="model.secondary.translations"
              :social="model.secondary.social"
            />
          </b-collapse>
        </div>
      </b-navbar>
    </header>
  </div>
</template>
<script>
import Logo from "@/components/common/Logo";
import NavBarMain from "./NavBarMain.vue";
import NavBarSecond from "./NavBarSecond.vue";
import NavBarSecondCollapsed from "./NavBarSecondCollapsed.vue";

export default {
  components: {
    Logo,
    NavBarMain,
    NavBarSecond,
    NavBarSecondCollapsed,
  },
  props: {
    model: Object,
    translations: Object,
  },
  data() {
    return {
      showSearch: false,
      scrollOnTop: true,
      mouseOverTopMenu: false,
      isMenuExpanded: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
    document.body.classList.remove("faded");
  },
  methods: {
    onScroll: function () {
      let scrollOffset =
        window.pageYOffset || document.documentElement.scrollTop;
      if (this.scrollOnTop) {
        this.scrollOnTop = scrollOffset < 100;
      } else {
        this.scrollOnTop = scrollOffset == 0;
      }
    },
    ShowArrowBack() {
      return window.location.pathname != "/";
    },
  },
  watch: {
    isMenuExpanded: function (v) {
      v
        ? document.body.classList.add("faded")
        : document.body.classList.remove("faded");
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.wcag-fontboost2 {
  @include media-breakpoint-only(md) {
    .second-group-spacing {
      // margin-left: 3px !important;
    }
    .wcag-ctrl {
      margin-right: 0 !important;
    }
    .social-uncollapsed {
      margin-left: 0 !important;
    }
  }
}

.wcag-fontboost1 {
  @include media-breakpoint-only(md) {
    .second-group-spacing {
      // margin-left: 5px !important;
    }
    .social-uncollapsed {
      margin-left: 0 !important;
    }
  }
}

.logo-link {
  img {
    height: 30px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 33px 0 0;
  }
  @include media-breakpoint-down(md) {
    img {
      height: 25px;
    }
    margin: auto 33px auto 15px;
  }

  @include media-breakpoint-only(md) {
    margin: 0 59px 0 0;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 102px 0 0;
  }
  &.hide {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.dropdown-no-caret {
  .dropdown-toggle::after {
    display: none;
  }
}

.second-group-spacing {
  @include media-breakpoint-up(lg) {
    margin-left: 24px;
  }
  @include media-breakpoint-down(md) {
    margin-left: 30px;
  }
}

.header-cicon {
  font-size: 1.5em;
  line-height: 1;
  &.mirror::before {
    display: inline-block;    
    transform: scaleX(-1);
  }
}

.mainheader {
  position: fixed;
  z-index: 10;
  width: 100%;
  left: 0;
  max-width: 100vw;
  &::before {
    position: absolute;
    content: " ";
    background-color: black;
    top: -300px;
    left: 0;
    right: 0;
    height: 300px;
  }
  &.mobileExpanded {
    top: 0;
    overflow-y: auto;
  }
  .nav-link {
    color: rgba(255, 255, 255, 0.7) !important;
    transition: 0.2s;
    &:hover {
      color: white !important;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.33em;
    }
    @include media-breakpoint-up(md) {
      color: white !important;
      &:hover {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  .zoom-icon {
    transition: 0.2s;
    &:hover {
      transform: scale(1.15);
    }
  }

  &__top-navbar {
    padding: 0;
    flex-flow: wrap;
  }

  &__belt-top {
    width: 100%;
    position: relative;
    z-index: 20;
    @include media-breakpoint-down(sm) {
      padding: 0.6em 0;
    }
    @include media-breakpoint-only(md) {
      padding: 0.6em 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.6em 0;
    }
  }

  &__belt-bottom {
    @include media-breakpoint-up(lg) {
      position: static;
      transition: 1s all ease-out;
      max-height: 1000px;
      transition-delay: 0s;
      overflow: hidden;
      &.hidden {
        max-height: 0;
      }
    }
    width: 100%;
    border-bottom: 2px solid white;
    background-color: $body-bg;
    @include media-breakpoint-down(md) {
      .showShare {
        display: none !important;
      }
    }
  }

  &__toggler {
    @include media-breakpoint-down(sm) {
      margin: 0 25px 0 0;
    }

    @include media-breakpoint-up(md) {
      margin: 0 48px 0 0;
    }

    border: none;
    padding: 5px;
    width: 49px;
    height: 50px;
    .bar1,
    .bar2,
    .bar3 {
      width: 39px;
      height: 3px;
      background-color: white;
      transition: 0.3s;
    }

    &.collapsed {
      .bar1 {
        margin: 0 0 12px;
      }
      .bar2 {
        margin: 12px 0;
      }
      .bar3 {
        margin: 12px 0 0;
      }
    }

    &.not-collapsed {
      .bar1 {
        transform: rotate(-45deg) translate(-4.5px, 0);
        width: 45px;
        margin: 0;
      }

      .bar2 {
        opacity: 0;
      }

      .bar3 {
        transform: rotate(45deg) translate(-4px, 0);
        width: 45px;
        margin: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 37px;
      height: 37px;
      padding: 3px;
      .bar1,
      .bar2,
      .bar3 {
        width: 31px;
      }
      &.collapsed {
        .bar1 {
          margin: 0 0 9px;
        }
        .bar2 {
          margin: 9px 0;
        }
        .bar3 {
          margin: 9px 0 0;
        }
      }

      &.not-collapsed {
        .bar1 {
          transform: rotate(-45deg) translate(-5.5px, -1px);
          width: 40px;
          margin: 0;
        }

        .bar3 {
          transform: rotate(45deg) translate(-5px, 1px);
          width: 40px;
          margin: 0;
        }
      }
    }
  }

  &__collapse-panel {
    @include media-breakpoint-down(md) {
      transition: height 0.5s;
      font-weight: $font-weight-regular;
      text-transform: uppercase;
      padding-top: 24px;

      .nav-item {
        padding: 2px 0 2px 0px;
      }
    }
  }
  &__belt-top {
    .containerFixed {
      display: block;
      .row {
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 0;
  }
}
.modulex2 {
  .vd_list-item {
    min-width: unset;
    min-height: unset;
  }
}

.mainheader__toggler {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  transform: scale(0.9);
  span {
    display: block;
    border-radius: 2px;
    background: white;
  }
  #hamburger {
    position: absolute;
    height: 37px;
    width: 37px;
    top: 0;
    background: transparent;
    span {
      width: 26px;
      height: 2px;
      position: relative;
      top: 0px;
      left: 1px;
      margin: 8px auto;
      &:nth-child(1) {
        transition-delay: 0.5s;
      }
      &:nth-child(2) {
        transition-delay: 0.625s;
      }
      &:nth-child(3) {
        transition-delay: 0.75s;
      }
    }
  }
  #cross {
    position: absolute;
    height: 36px;
    width: 36px;
    transform: rotate(45deg);
    background: transparent;
    top: 0;
    // background: red;
    span {
      &:nth-child(1) {
        height: 0%;
        width: 2px;
        position: absolute;
        margin: 3px 17px;
        transition-delay: 0s;
      }
      &:nth-child(2) {
        width: 0%;
        height: 2px;
        position: absolute;
        margin: 17px 3px;
        transition-delay: 0.25s;
      }
    }
  }
  * {
    transition: 0.25s ease-in-out;
    box-sizing: border-box;
  }

  &.not-collapsed {
    #hamburger {
      span {
        width: 0%;
        &:nth-child(1) {
          transition-delay: 0s;
        }
        &:nth-child(2) {
          transition-delay: 0.125s;
        }
        &:nth-child(3) {
          transition-delay: 0.25s;
        }
      }
    }
    #cross {
      span {
        &:nth-child(1) {
          height: 30px;
          transition-delay: 0.625s;
        }
        &:nth-child(2) {
          width: 30px;
          transition-delay: 0.375s;
        }
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints,md )) {
    #hamburger {
      width: 50px;
      height: 49px;
      span {
        width: 45px;
        margin: 11px 0;
      }
    }
    #cross {
      width: 50px;
      height: 50px;
      span {
        &:nth-child(1) {
          margin: 3px 24px;
        }
        &:nth-child(2) {
          margin: 24px 3px;
        }
      }
    }
    &.not-collapsed {
      #cross {
        span {
          &:nth-child(1) {
            height: 45px;
          }
          &:nth-child(2) {
            width: 45px;
          }
        }
      }
    }
  }
}
.mainheader {
  @include media-breakpoint-down(md) {
    bottom: 0;
    height: 100vh;
    overflow-y: hidden !important;
    pointer-events: none;
    * {
      pointer-events: all;
    }
    .second-navbar > .nav-item{
      display: none;
    }
    .mainheader__top-navbar {
      transition: height 0.5s;
      position: absolute;
      width: 100%;
      bottom: 0;
      // transform: translateY(-100%);
      background-color: #1d1d1d !important;
      max-height: calc(100vh - 5.9em);      
      height: 5em;
    }
    &__belt-top {
      > .container {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      @include media-breakpoint-down(md) {
        .logo-link {
          display: none;
        }
      }
    }
    &__belt-bottom {
      overflow: auto;
      // height: 0;
      // max-height: 0;
      transition: height 0.5s;
      // transition-delay: 0s;
      // div{
      //   display: none !important;
      // }
      max-height: 0;
      height: 100%;
      > div {
        // display: block !important;
        transition: height 0.5s;
      }
    }

    .mobileExpanded {
      .mainheader__top-navbar {
        transition-delay: 0s;
        // top: 0;
        height: 100%;
        max-height: calc(100% - 70px) !important;
      }
      .mainheader__belt-bottom {
        max-height: calc(100vh - 3.75em - 70px) !important;
        // height: 100% !important;
        transition-delay: 0.8s;
        .show {
          position: static;
          border-top: 2px solid white;
        }
      }
    }

    &__App-belt {
      position: fixed;
      top: 0;
      width: 100%;
      background: $black !important;
      height: 70px;

      .container {
        padding-right: 15px;
        padding-left: 15px;

        .second-navbar {
          width: auto;

          .user-nav-bar {
            display: block !important;
            margin-left: 0 !important;
            margin-right: 0 !important;

            .user-nav-item {
              margin-left: 0;
              margin-right: 30px;
              position: static;
              height: auto;

              .user-menu {
                top: 53px;

                .nav-item {
                  white-space: nowrap;
                  width: auto;
                  padding: 5px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &__App-belt {
      display: none;
    }
  }
}
</style>

<template>
  <b-container
    fluid
    class="containerFixed userPlaylist"
    :class="{
      loaded: items && items.length > 0,
    }"
  >
    <b-row>
      <b-col
        ><h1>{{ model.name }}</h1></b-col
      >
    </b-row>
    <b-row class="empty" v-if="!items || items == 0">
      <b-col cols="12">
        <i class="cil-library-music"></i>
        <h2>{{ model.translations["soundLibrary.EmptyPlaylistHeader"] }}</h2>
        <p>{{ model.translations["soundLibrary.EmptyPlaylist"] }}</p>
        <b-button
          id="baddPlaylist"
          @click="createPlaylist('Moja playlista')"
          variant="primary "
          >{{ model.translations["soundLibrary.GotoSoundLibrary"] }}</b-button
        >
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="button-list">
        <b-button
          id="baddPlaylist"
          @click="createPlaylist('Moja playlista')"
          variant="primary"
          >{{ model.translations["soundLibrary.GotoSoundLibrary"] }}</b-button
        ></b-col
      >
      <playlist-item
        v-for="(item, index) in items"
        :key="index"
        :model="item"
        :translations="model.translations"
        :url="model.url"
        @delete="deletePlaylist"
        @edit="editPlaylist"
      ></playlist-item>
    </b-row>
    <create-playlist
      v-if="showCreate"
      :translations="model.translations"
      :show="showCreate"
      @close="refresh"
    ></create-playlist>
    <delete-playlist
      v-if="showDelete"
      :translations="model.translations"
      :deleteId="currentId"
      @close="
        showDelete = false;
        getPlaylist();
      "
    ></delete-playlist>
    <edit-playlist
      v-if="showEdit"
      :translations="model.translations"
      :editId="currentId"
      :name="currentName"
      @close="
        showEdit = false;
        getPlaylist();
      "
    ></edit-playlist>
  </b-container>
</template>
<script>
import PlaylistService from "@/services/playlistService";
import PlaylistItem from "../components/Lists/PlaylistItem.vue";
import CreatePlaylist from "../components/common/CreatePlaylist.vue";
import DeletePlaylist from "../components/common/DeletePlaylist.vue";
import EditPlaylist from "../components/common/EditPlaylist.vue";
export default {
  components: {
    PlaylistItem,
    CreatePlaylist,
    DeletePlaylist,
    EditPlaylist,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      items: [],
      showCreate: false,
      showDelete: false,
      showEdit: false,
      currentId: "",
      currentName: "",
      errorMessage: "",
    };
  },
  beforeMount() {
    this.getPlaylist();
  },
  methods: {
    createPlaylist() {
      this.showCreate = true;
    },
    getPlaylist(name) {
      const name1 = name;
      PlaylistService.getAll().then((response) => {
        this.items = response;
        var item;
        if (name && this.items.length > 0) {
          this.items?.forEach((element) => {
            element.id = element.id.replaceAll("-", "");
          });
          if (name) item = this.items.find((x) => x.name == name1)?.id;
          if (!item) item = this.items[0]?.id;
        } else item =  "addPlaylist";
        var selector = "#b" + item;
        setTimeout(() => {
          window.RedgalaxyNav.focusElement(selector);
        }, 100);
      });
    },
    refresh(name) {
      this.showCreate = false;
      this.getPlaylist(name);
    },
    deletePlaylist(id) {
      this.currentId = id;
      this.showDelete = true;
    },
    editPlaylist(id, name) {
      this.currentId = id;
      this.currentName = name;
      this.showEdit = true;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.userPlaylist {
  padding-top: 64px;
  color: $color-main;
  display: flex;
  flex-wrap: wrap;
  &.loaded {
    display: block;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  h1 {
    font-family: "Knile";
    font-style: normal;
    font-weight: 600;
    font-size: em(36);
    line-height: em(24);
    text-align: center;
  }
  .empty {
    text-align: center;
    .cil-library-music {
      font-size: em(64);
      margin: auto;
      display: block;
      margin-bottom: 42px;
      &:before {
        display: inline-block;
        transform: scaleX(-1);
      }
    }
    h2 {
      font-family: "Knile";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    p {
      font-family: "Wotfard";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
  .button-list {
    text-align: center;
    padding-bottom: 1.5em;
  }
}
</style>

<template>
  <div>
    <b-row class="progressBar details-header">
      <b-col :sm="10" class="">
        <b-container>
          <h3>
            {{ header }}
          </h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <b-form-group>
            <b-form-checkbox-group v-model="checkedValue">
              <b-form-checkbox
                name="some-radios"
                value="A"
                :disabled="true"
                v-if="!readonly || checkedValue.includes('A')"
                class="checkReadonly"
                >{{
                  translations["proOrder.Territory.Country"]
                }}</b-form-checkbox
              >              
              <b-form-tags
                v-if="!readonly"
                class="short hide-tags-add"
                ref="countryInput"
                @focus="countryFilters.showSearch = true"
                @keydown.native="keyDown"
                :tag-validator="countryValidator"
                :invalid-tag-text="translations['proOrder.InvalidTagText']"
                :placeholder="translations['proOrder.AddTagText']"
                :duplicate-tag-text="translations['proOrder.DuplicateTagText']"
                v-model="countryFilters.tags"
                :disabled="!checkedValue.includes('A')"
              >
              </b-form-tags>
              <span
                v-if="
                  readonly && countryFilters.tags && checkedValue.includes('A')
                "
                class="pro-readonly-value tags"
                >{{ countryFilters.tags.join(", ") }}</span
              >
              <div
                class="searchResult"
                v-show="
                  countryFilters.showSearch &&
                  countryFilters.searchText &&
                  countryFilters.items &&
                  countryFilters.items.length > 0
                "
              >
                <b-list-group>
                  <b-list-group-item
                    @click="Additem(item, 0)"
                    v-for="(item, index) in countryFilters.items"
                    :key="index"
                    :class="{
                      selected: countryFilters.selected == index,
                    }"
                  >
                    <p>
                      <span class="hover-left-right">{{ item.text }}</span>
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <slot></slot>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    translations: Object,
    countriesList: Array,
    continentsList: Array,
    selectedCountries: Array,
    selectedContinents: Array,
    selectedCheck: Array,
    readonly: Boolean,
  },
  data() {
    return {
      checkedValue: ['A'],
      countryFilters: {
        showSearch: false,
        searchText: "",
        items: Array,
        selected: 0,
        tags: [],
      },
      continetsFilters: {
        showSearch: false,
        searchText: "",
        items: Array,
        selected: 0,
        tags: [],
      },
      countries: [],
      continent: [],
    };
  },
  methods: {
    getItems(search) {
      var self = this;
      if (self.countryFilters.showSearch) {
        search = search.toLowerCase();
        var countries = self.countriesList.filter((element) =>
          element.text.toLowerCase().startsWith(search)
        );
        if (countries) {
          self.countryFilters.items = countries;
        } else {
          self.countryFilters.items = [];
        }
      } else if (self.continetsFilters.showSearch) {
        search = search.toLowerCase();
        var continents = self.continentsList.filter((element) =>
          element.text.toLowerCase().startsWith(search)
        );
        if (continents) {
          self.continetsFilters.items = continents;
        } else {
          self.continetsFilters.items = [];
        }
      }
    },
    Additem(item, index) {
      if (item) {
        if (index == 0) {
          if (!this.countries.find((x) => x.value === item.value)) {
            this.countries.push(item);
          }
          this.countryFilters.items = [];
          this.countryFilters.searchText = "";           
          this.$refs.countryInput.newTag = "";         
        } else if (index == 1) {
          if (!this.continent.find((x) => x.value === item.value)) {
            this.continent.push(item);
          }
          this.continetsFilters.items = [];
          this.continetsFilters.searchText = "";
        }
      }
    },
    keyDown(key) {
      if (key.key == "ArrowUp") {
        key.preventDefault();
        if (this.countryFilters.showSearch) {
          if (this.countryFilters.selected > 0) this.countryFilters.selected--;
        } else if (this.continetsFilters.showSearch) {
          if (this.continetsFilters.selected > 0)
            this.continetsFilters.selected--;
        }
      } else if (key.key == "ArrowDown") {
        key.preventDefault();
        if (this.countryFilters.showSearch) {
          if (
            this.countryFilters.selected <
            this.countryFilters.items.length - 1
          )
            this.countryFilters.selected++;
        } else if (this.continetsFilters.showSearch) {
          if (
            this.continetsFilters.selected <
            this.continetsFilters.items.length - 1
          )
            this.continetsFilters.selected++;
        }
      } else if (key.key == "Enter") {
        key.preventDefault();
        if (this.countryFilters.showSearch) {
          this.Additem(
            this.countryFilters.items[this.countryFilters.selected],
            0
          );
        } else if (this.continetsFilters.showSearch) {
          this.Additem(
            this.continetsFilters.items[this.continetsFilters.selected],
            1
          );
        }
      } else if (!(key.key == "ArrowLeft" || key.key == "ArrowRight")) {
        if (this.countryFilters.showSearch) this.countryFilters.selected = 0;
        else if (this.continetsFilters.showSearch)
          this.continetsFilters.selected = 0;
      }
    },
    countryValidator(tag) {
      this.countryFilters.searchText = tag;
      this.getItems(tag);
      return (
        this.countryFilters.items.length > 0 &&
        !this.countries.includes(
          this.countryFilters.items[this.countryFilters.selected]
        )
      );
    },
    tagValidator(tag) {
      this.continetsFilters.searchText = tag;
      this.getItems(tag);
      return (
        this.continetsFilters.items.length > 0 &&
        !this.continent.includes(
          this.continetsFilters.items[this.continetsFilters.selected]
        )
      );
    },
    tagFocus(event) {
      var temp = event;
      var time = 0;
      var self = this;
      var value = temp.type == "focus";
      if (!value) {
        time = 500;
      }
      setTimeout(() => {
        var countryInput;
        var continetsInput;
        if (self.$refs.countryInput)
          countryInput = self.$refs.countryInput.$el.id + "___input__";
        if (self.$refs.continetsInput)
          continetsInput = self.$refs.continetsInput.$el.id + "___input__";
        if (countryInput && temp.target.id === countryInput) {
          self.countryFilters.showSearch = value;
        } else if (continetsInput && temp.target.id === continetsInput) {
          self.continetsFilters.showSearch = value;
        }
      }, time);
    },
  },
  watch: {
    checkedValue(newValue) {
      this.$emit("ChangeValue", newValue, "territory");
    },
    countryFilters: [
      "handle1",
      function handle2() {},
      {
        handler: function handle3() {},
      },
    ],
    "countryFilters.tags": function (newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        let difference = oldValue.filter((x) => !newValue.includes(x));
        var item = this.countries.find(function (element) {
          return difference.includes(element.text);
        });
        var index = this.countries.indexOf(item);
        this.countries.splice(index, 1);
      }
    },
    continetsFilters: [
      "handle1",
      function handle2() {},
      {
        handler: function handle3() {},
      },
    ],
    "continetsFilters.tags": function (newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        let difference = oldValue.filter((x) => !newValue.includes(x));
        var item = this.continent.find(function (element) {
          return difference.includes(element.text);
        });
        var index = this.continent.indexOf(item);
        this.continent.splice(index, 1);
      }
    },
    countries: function (newValue) {
      this.countryFilters.tags = newValue.map((x) => x.text);
      this.$emit("ChangeValue", newValue, "countries");
    },
    continent: function (newValue) {
      this.continetsFilters.tags = newValue.map((x) => x.text);
      this.$emit("ChangeValue", newValue, "continent");
    },
    selectedCountries: {
      handler(newValue) {
        if (newValue != this.countries) this.countries = newValue;
      },
      deep: true,
    },
    selectedContinents: {
      handler(newValue) {
        if (newValue != this.continent) this.continent = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.selectedCountries && this.selectedCountries.length > 0)
      this.countries = this.selectedCountries;
    if (this.selectedContinents && this.selectedContinents.length > 0)
      this.continent = this.selectedContinents;
    this.tagsElements = document.getElementsByClassName("b-form-tags-input");
    if (this.tagsElements)
      for (var i = 0; i < this.tagsElements.length; i++) {
        var t = this.tagsElements[i];
        t.addEventListener("focus", this.tagFocus, true);
        t.addEventListener("blur", this.tagFocus, true);
      }
  },
  beforeDestroy() {
    for (var i = 0; i < this.tagsElements.length; i++) {
      var t = this.tagsElements[i];
      t.removeEventListener("focus", this.tagFocus, true);
      t.removeEventListener("blur", this.tagFocus, true);
    }
  },
};
</script>
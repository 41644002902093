<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    header: String, 
  },
};
</script>
<template>
  <div class="item image">
    <image-box :model="model" />
  </div>
</template>
<script>
import ImageBox from "./ImageBox.vue";
export default {
  components: {
    ImageBox,
  },
  props: {
    model: Object,
  },
};
</script>
<style>
.item.image{
  border: none;
}
</style>
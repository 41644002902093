<template>
    <div class="base-timer text-left">
        <div class="vd_time-left">{{ timeText }} {{ formattedTimeLeft }}</div>
        <b-progress :max="timeLimit" show-progress animated height="24px" :variant="progressVariant">
            <b-progress-bar :value="timeLeft"></b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
const WARNING_THRESHOLD = 60;
const ALERT_THRESHOLD = 30;

const COLOR_CODES = {
    info: {
        variant: "success"
    },
    warning: {
        variant: "warning",
        threshold: WARNING_THRESHOLD
    },
    alert: {
        variant: "danger",
        threshold: ALERT_THRESHOLD
    }
};
export default {
    data() {
        return {
            timePassed: 0,
            timerInterval: null
        };
    },
    props: {
        timeLimit: {
            type: Number,
            default: 10000
        },
        timeText: String
    },
    computed: {
        formattedTimeLeft() {
            const timeLeft = this.timeLeft;
            const minutes = Math.floor(timeLeft / 60);
            let seconds = timeLeft % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${minutes}:${seconds}`;
        },
        timeLeft() {
            return this.timeLimit - this.timePassed;
        },
        progressVariant() {
            const {alert, warning, info} = COLOR_CODES;

            if (this.timeLeft <= alert.threshold) {
                return alert.variant;
            } else if (this.timeLeft <= warning.threshold) {
                return warning.variant;
            } else {
                return info.variant;
            }
        }
    },
    watch: {
        timeLeft(newValue) {
            if (newValue === 0) {
                this.onTimesUp();
            }
        }
    },
    mounted() {
        this.startTimer();
    },
    methods: {
        onTimesUp() {
            clearInterval(this.timerInterval);
            this.$emit("end-time");
        },
        startTimer() {
            this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
        }
    },
    beforeDestroy(){
        clearInterval(this.timerInterval);
    }
    
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.vd_time-left {
    font-size: em(12);
    font-weight: 600;
    margin-bottom: 1em;
}

.progress {
    height: 2px !important;
    font-size: 0;
    background: $color-light-gray;
    overflow: visible !important;

    .progress-bar {
        background: $color-main !important;
        position: relative;
        overflow: visible;
        transition: 1s linear;

        &::after {
            content: '';
            --size: 11px;
            width: var(--size);
            height: var(--size);
            background: #fff;
            position: absolute;
            display: block;
            left: 100%;
            transform: translateX(-50%);
            border-radius: 50%;
        }
    }
}

</style>
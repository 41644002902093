<template>
  <div>
    <div class="moviePage">
      <b-container fluid class="disableFlex containerFixed">
        <b-row class="top-row-spacer position-relative">
          <player
            ref="player"
            :assetId="model.items && model.items.length > 0 ? model.items[0].atdId : null"
            :imageUrl="model.poster ? model.poster : null"
            :loginUrl="model.loginUrl"
            :lang="model.lang"
            :showPreviousButton="false"
            :showNextButton="model.items && model.items.length > 1"
            :translations="model.translations"
            :title="model.name"
            :showContinuePlaying="false"
            :overlay="
              model.items && model.items.length > 0 ? model.items[0].overlay : null
            "
          >
          </player>
          <div class="episodeTitle">{{ episodeTitle }}</div>
          <div class="movieInfo">
            <div class="descriptionBox">
              <div class="movieHeader focus-smart">
                <div class="description">
                  <h1>{{ localName }}</h1>
                  <p
                    v-if="
                      model.genre || model.author || model.signature || model.createYear
                    "
                  >
                    <span v-if="model.genre" class="category">
                      {{ getStringFromArray(model.genre, 2) }}
                    </span>
                    <br />
                    <span v-if="model.author" class="Director">
                      <span class="showbox">{{ model.author }}</span>
                    </span>
                    <br />
                    <span v-if="model.signature" class="country"> WFDiF </span>&nbsp;
                    <span v-if="model.createYear" class="year">{{
                      model.createYear
                    }}</span>
                  </p>
                </div>
                <div>
                  <div class="moreInfo" v-if="!this.$browserDetect.isApp">
                    <div
                      class="additional hiding2"
                      role="img"
                      :title="
                        model.translations['soundLibrary.NumberOfTracks'] +
                        ': ' +
                        localEpisodes.length
                      "
                    >
                      <span class="time"
                        ><i class="cil-music-note mirror"></i>
                        {{ model.translations["soundLibrary.NumberOfTracks"] }}:
                        {{ localEpisodes.length }}</span
                      >
                    </div>
                    <div v-if="model.time" class="additional hiding2">
                      <div
                        v-b-tooltip.hover
                        :title="
                          model.translations['moviePage.DurationTitle'] + ' ' + plduration
                        "
                        role="img"
                      >
                        <img
                          aria-hidden="true"
                          class="icon"
                          src="/assets/clock.svg"
                          :alt="model.translations['moviePage.DurationTitle']"
                        />
                        <span class="time">
                          {{ plduration }}
                        </span>
                      </div>
                    </div>
                    <div class="additional audiobtn">
                      <b-button
                        @click="audioPlShuffle = !audioPlShuffle"
                        :pressed="audioPlShuffle"
                        variant="secondary"
                      >
                        <i class="cil-random"></i>
                        {{ model.translations["soundLibrary.Shuffle"] }}
                      </b-button>
                    </div>
                    <div class="additional audiobtn">
                      <b-button
                        @click="audioPlLoop = !audioPlLoop"
                        :pressed="audioPlLoop"
                        variant="secondary"
                      >
                        <i class="cil-loop"></i>
                        {{ model.translations["soundLibrary.LoopPlaylist"] }}
                      </b-button>
                    </div>
                  </div>
                  <div class="moreInfo" v-else>
                    <div class="additional hiding2">
                      <span class="time"
                        ><i class="cil-music-note mirror"></i>
                        {{ model.translations["soundLibrary.NumberOfTracks"] }}:
                        {{ localEpisodes.length }}</span
                      >
                    </div>
                    <div v-if="model.time" class="additional hiding2">
                      <div
                        :tabindex="this.$getTabIndex()"
                        v-b-tooltip.hover
                        :title="
                          model.translations['moviePage.DurationTitle'] + ' ' + plduration
                        "
                      >
                        <img class="icon" src="/assets/clock.svg" />
                        <span class="time">
                          {{ plduration }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="lead focus-smart"
              v-if="model.description"
              v-html="model.description"
            ></div>
            <share-control
              v-if="!isUserPlaylist"
              :translations="model.translations"
              :favorite-model="model.favoriteModel"
              :title="model.name"
              section="Sekcje: podstawowe informacje o playliscie"
            ></share-control>
            <div v-if="isUserPlaylist">
              <div class="share-control">
                <b-row>
                  <b-col cols="12">
                    <div class="share-control__icons">
                      <a
                        class="share-control__icons__icon cursor-pointer focus-smart"
                        @click="showEdit = true"
                      >
                        <div
                          role="img"
                          :title="model.translations['soundLibrary.EditTitle']"
                        >
                          <i class="cil-pencil"> </i>
                          <p>{{ model.translations["soundLibrary.EditTitle"] }}</p>
                        </div>
                      </a>
                      <a
                        class="share-control__icons__icon cursor-pointer focus-smart"
                        @click="showDelete = true"
                      >
                        <div
                          role="img"
                          :title="model.translations['soundLibrary.DeletePlaylist']"
                        >
                          <i class="cil-trash"> </i>
                          <p>{{ model.translations["soundLibrary.DeletePlaylist"] }}</p>
                        </div>
                      </a>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="basicInfo">
              <div class="cast">
                <div class="fe-item section second" v-if="model.genre">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Genre"] }}:
                  </span>
                  <span class="fe-value"> {{ getStringFromArray(model.genre, 1) }}</span>
                </div>
                <div class="fe-item section second" v-if="model.signature">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Signature"] }}:
                  </span>
                  <span class="fe-value"> {{ model.signature }}</span>
                </div>
                <div class="fe-item section second" v-if="model.tags">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Tags"] }}:
                  </span>
                  <span class="fe-value">
                    <span v-for="(item, index) in model.tags" :key="index"
                      >{{ index > 0 ? ", " : "" }}
                      <b-link
                        :to="
                          model.searchUrl + '?searchPhrase.3=' + encodeURIComponent(item)
                        "
                        class="hover-left-right focus-smart"
                        @click="sendAnalytics(model.translations['moviePage.Tags'], item)"
                        >{{ item }}</b-link
                      >
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="movieMoreInfo"
            v-if="model.accordions && model.accordions.length > 0"
          >
            <accordion
              v-for="(item, index) in getHonorde(model.accordions)"
              :key="index"
              :tabId="item.name"
              accordionName="main"
              :ref="item.name"
              :collapsed="!expended"
            >
              <template v-slot:header>
                <span> {{ item.name }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <div class="focus-smart customText">
                  <p v-html="item.text"></p>
                </div>
              </template>
            </accordion>
          </div>
          <audio-playlist
            v-if="localEpisodes && localEpisodes.length > 0"
            ref="aplaylist"
            :player="$refs.player"
            :translations="model.translations"
            :episodes="localEpisodes"
            :headerText="model.translations['moviePage.PlaybackList']"
            :shuffle="audioPlShuffle"
            :loopPlaylist="audioPlLoop"
            :showIndex="true"
            :showTrash="isUserPlaylist"
            :showMove="isUserPlaylist"
            :playlistId="model.id"
            :isUserPlaylist="isUserPlaylist"
            :showSort="true"
            @updateTitle="updateTitle"
          ></audio-playlist>
          <div>
            <div v-if="!isTv">
              <home-sections
                v-for="(item, index) in model.list"
                :key="index"
                :item="item"
                :index="index"
                :translations="model.translations"
              ></home-sections>
            </div>
            <div class="ListViewer carousel" v-if="model.listView">
              <h2 class="">{{ model.translations["soundLibrary.SearchInFonoteka"] }}</h2>
            </div>
            <grid-container
              v-if="model.listView"
              :model="model.listView"
            ></grid-container>
          </div>
        </b-row>
      </b-container>
    </div>
    <delete-playlist
      v-if="showDelete"
      :translations="model.translations"
      :deleteId="model.id"
      @close="onDeletePLClose"
    ></delete-playlist>
    <edit-playlist
      v-if="showEdit"
      :translations="model.translations"
      :editId="model.id"
      :name="localName"
      @close="onEditClose"
    ></edit-playlist>
  </div>
</template>
<script>
import Player from "@/components/common/Player.vue";
import Accordion from "../components/ListingView/Accordion.vue";
import ShareControl from "../components/common/ShareControl.vue";
import AudioPlaylist from "../components/common/AudioPlaylist.vue";
import HomeSections from "../components/HomePage/HomeSections.vue";
import GridContainer from "../components/Lists/GridContainer.vue";
import DeletePlaylist from "../components/common/DeletePlaylist.vue";
import EditPlaylist from "../components/common/EditPlaylist.vue";
import { EventBus } from "@/services/event-bus";
import PlaylistService from "@/services/playlistService";

export default {
  components: {
    Accordion,
    Player,
    ShareControl,
    AudioPlaylist,
    HomeSections,
    GridContainer,
    DeletePlaylist,
    EditPlaylist,
  },
  data() {
    return {
      events: [],
      expended: false,
      audioPlShuffle: false,
      audioPlLoop: false,
      showDelete: false,
      showEdit: false,
      localName: "",
      localEpisodes: [],
      isTv: this.$isTizen(this.$browserDetect),
      episodeTitle: "",
    };
  },
  props: {
    model: Object,
    isUserPlaylist: Boolean,
  },
  methods: {
    updateTitle(item) {
      this.episodeTitle = `${item.position + 1}. ${item.title}`;
    },
    onPlaylistModified(playlistId) {
      if (playlistId == this.model.id) {
        PlaylistService.get(playlistId).then((r) => {
          this.localEpisodes = r.items;
        });
      }
    },
    isCollapsed() {
      this.expended = this.$isTizen(this.$browserDetect);
    },
    getStringFromArray: function (array, type) {
      if (array) {
        if (type == 0) {
          var value = array.slice(0, 2).join(" ");
          if (array.length > 2) {
            value = value + " +" + (array.length - 2);
          }
          return value;
        } else if (type == 1) return array.join(", ");
        else if (type == 2) return array.slice(0, 1).join(" ");
      }
    },
    getHonorde: function (array) {
      if (array && array.length > 0) return array;
      return [];
    },
    sendAnalytics(gAction, gLabel) {
      this.$setGAFormCustom(
        "Sekcja: podstawowe informacje o playliscie",
        "Kliknął element: " + gAction,
        gLabel,
        false
      );
    },
    onEditClose(newName) {
      this.showEdit = false;
      this.localName = newName;
    },
    onDeletePLClose(isDeleted) {
      this.showDelete = false;
      if (isDeleted) this.$router.push(this.model.playlistsUrl);
    },
  },
  mounted() {
    this.isCollapsed();
    this.localName = this.model.name;
    this.localEpisodes = this.model.items;
    EventBus.$on("pl-added", this.onPlaylistModified);
  },
  computed: {
    plduration() {
      if (this.model.time) {
        var hours = Math.floor(this.model.time / 60);
        var minutes = this.model.time % 60;
        return `${hours} ${this.model.translations["soundLibrary.Hour"]} ${minutes} ${this.model.translations["soundLibrary.Minutes"]}`;
      }
      return `-`;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.top-row-spacer {
  > .movieInfo {
    margin-top: 2em;
  }
}

h3 {
  font-size: em(26);
}
.subject {
  display: table-row;
  * > {
    display: table-cell;
  }
  .tclink {
    white-space: nowrap !important;
    color: inherit !important;
    > i,
    > span {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      margin-right: 3px;
    }
    .full {
      display: none !important;
    }
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      .short {
        display: none !important;
      }
      .full {
        display: inline-block !important;
      }
    }
  }
}
.card-text {
  font-family: $font-family-wotfard;
  .subjects {
    border-collapse: collapse;
    width: 100%;
    margin-left: 0;
    a {
      cursor: pointer;
    }
  }
}
.Player {
  .card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: em(500);
    transform: translate(-50%, -50%);
    z-index: 2002;
    &:before {
      content: " ";
      background: black;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    .btn {
      margin: auto;
    }
    .buttons {
      display: flex;
      .btn {
        margin-right: 0;
      }
      .custom-control {
        margin: auto;
        margin-left: 0;
        .custom-control-label {
          margin-left: 3.5em !important;
          position: relative;
        }
      }
    }
  }
}

.moreInfo {
  .mirror::before {
    display: inline-block;
    transform: scaleX(-1);
  }
  .additional.audiobtn {
    .btn {
      font-weight: normal;
      @include media-breakpoint-down(sm) {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
    i {
      font-size: 1.2em;
      position: relative;
      top: 0.2em;
    }
    .active {
      background-color: $color-dark-gray;
      i {
        font-weight: 800;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
.episodeTitle {
  position: absolute;
  top: 25px;
  right: 25px;
  font-family: "Knile";
  font-style: normal;
  font-weight: 600;
  font-size: em(24);
  line-height: 150%;
  z-index: 9999;
  color: #ffffff;
}
</style>

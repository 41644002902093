<template>
  <div>
    <b-container fluid class="containerFixed x1">
      <b-row>
        <h2 v-if="model.header" :class="{ 'sr-only': model.hideTitle }">
          <b-link
            v-if="model.headerUrl && canRoute()"
            :to="model.headerUrl"
            tabindex="0"
            @click.native="sendAnalytics('Pokaż więcej')"
            class="focus-smart"
          >
            <div class="nameSection">{{ model.header }}</div>
            <div>
              <div class="showAll">{{ translations["general.ShowMore"] }}</div>
              <i class="cil-chevron-right-alt"></i>
            </div>
          </b-link>
          <div v-else>
            <div class="nameSection">{{ model.header }}</div>
          </div>
        </h2>
        <p
          class="descriptionItems"
          v-if="model.description"
          v-html="model.description"
        ></p>
      </b-row>
    </b-container>
    <b-carousel
      id="carousel-1"
      :interval="model.options.interval"
      :indicators="indicator()"
      :controls="model.items.length > 1"
      :autoplay="model.options.autoplay"
      :no-hover-pause="!model.options.pauseOnHover"
      :keyboard="true"
      :no-wrap="!model.options.rewind"
      :label-goto-slide="translations['carousel.SlideX']"
      :label-indicators="translations['carousel.PageX']"
      :label-next="translations['carousel.Next']"
      :label-prev="translations['carousel.Prev']"
      ref="modulx1"
    >
      <b-carousel-slide v-for="(item, i) in model.items" :key="i" @keydown="keyDown">
        <module-x-1-item
          :model="item"
          isFluid
          :translations="translations"
        ></module-x-1-item>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
import ModuleX1Item from "../Lists/ModuleX1Item.vue";
export default {
  components: { ModuleX1Item },
  props: {
    model: Object,
    translations: Object,
  },
  methods: {
    sendAnalytics(gAction) {
      this.$setGAFormCustom("Sekcja: " + this.model.header, gAction, "", false);
    },
    indicator() {
      var item =
        (this.model.options.pagination || this.$isTizen(this.$browserDetect)) &&
        this.model.items.length > 1;
      return item;
    },
    keyDown(a) {
      if (
        this.$refs.modulx1 &&
        this.$refs.modulx1.$el.getElementsByClassName("smarttv-focused").length > 0
      ) {
        if (a.keyCode == 37) {
          this.$refs.modulx1.prev();
          a.stopPropagation();
        } else if (a.keyCode == 39) {
          this.$refs.modulx1.next();
          a.stopPropagation();
        }
      }
    },
    canRoute() {
      return !(
        this.$route.fullPath == this.model.headerUrl ||
        this.$route.fullPath == this.model.headerUrl + "/#"
      );
    },
  },
  mounted() {
    document.addEventListener("keydown", this.keyDown, true);
    var prev = this.$refs.modulx1.$el.getElementsByClassName("carousel-indicators")[0]
      .children;
    setTimeout(() => {
      prev.forEach((element) => {
        element.removeAttribute("role");
      });
    }, 0);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyDown, true);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.ListViewer {
  .carousel-indicators {
    // display: none;
  }
  .carousel.slide {
    height: 40vw;
    max-height: 700px;
    min-height: 300px;
    &.item1 {
      .carousel-control-prev,
      .carousel-control-next,
      .carousel-indicators {
        display: none !important;
      }
    }
    .carousel-inner {
      height: 100%;
      .carousel-item {
        height: 100%;
        .carousel-caption {
          height: 100%;
          position: relative;
          right: 0;
          left: 0;
          bottom: 0;
          top: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    .carousel-indicators {
      top: 20px;
      bottom: unset;
      margin: 0;
      li {
        width: 6px;
        height: 6px;
        background: transparent;
        border: 1px solid $color-main;
        margin: 0 4px;
        flex: none;
        box-sizing: border-box;
        opacity: 1;
        &.active {
          background: $color-main;
        }
      }
      @include media-breakpoint-up(sm) {
        li {
          width: 10px;
          height: 10px;
          border: 1px solid $color-main;
          margin: 0 10px;
        }
      }
      @include media-breakpoint-up(md) {
        li {
          width: 14px;
          height: 14px;
          border: 2px solid $color-main;
          margin: 0 13px;
        }
      }
    }
    .carousel-control-prev {
      span {
        &:before {
          content: "\eb0b";
        }
      }
      &:hover {
        span {
          left: 35%;
        }
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      z-index: 11;
      position: absolute;
      top: 0%;
      bottom: 0;
      width: auto;
      height: auto;
      transition: all 0.5s;
      padding: 30px 30px;
      width: 10%;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: "CoreUI-Icons-Solid" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #fff;
        font-size: em(28);
        background: none;
        transition: all 0.5s;
      }
    }
    .carousel-control-next {
      span {
        &:before {
          content: "\eb0d";
        }
      }
      &:hover {
        span {
          left: 65%;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      // height: 353px;
      .carousel-indicators {
        top: 30px;
      }
    }
  }
}
.x1 {
  .descriptionItems {
    margin-bottom: calc(23px + 1rem);
  }
}
</style>

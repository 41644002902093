<template>
  <div class="grid-container" ref="scroll">
    <div
      v-if="model.displayType == 'grid' || model.displayType == 'audio'"
      class="filters background--black color--basic"
      :class="model.customCssClass"
    >
      <b-container fluid class="containerFixed">
        <b-row>
          <b-col cols="12">
            <div class="filters__container d-flex">
              <div class="filters__container__block filters__container--flexwrap">
                <div class="filters__container__block__filter details-mobile">
                  <b-link class="link" :to="model.searchUrlAI">{{
                    model.translations["searchPage.DetailedView"]
                  }}</b-link>
                </div>

                <div
                  :class="{
                    filters__container__block__filter:
                      !(
                        isApp() &&
                        (filter.type === 1 || filter.type === 2 || filter.type === 5)
                      ) || !isApp(),
                  }"
                  v-for="(filter, index) in filterList"
                  :key="index"
                >
                  <label
                    v-if="
                      (!isApp() && [1, 2, 5].includes(filter.type)) ||
                      ![1, 2, 5].includes(filter.type)
                    "
                    class="filters__container__block__filter__label"
                    :for="filter.uniqueKey"
                    >{{ filter.label }}</label
                  >
                  <b-dropdown
                    no-flip
                    @show="showFilterF"
                    @hide="showFilter = null"
                    v-if="
                      !isApp() &&
                      (filter.type === 1 || filter.type === 2 || filter.type === 5)
                    "
                    :ref="filter.uniqueKey"
                    :id="filter.uniqueKey"
                    :name="filter.uniqueKey"
                    variant="none"
                    :text="filter.placeholder"
                    class="filters__container__block__dropdown"
                  >
                    <b-dropdown-item-button
                      v-for="value in filter.value"
                      :key="value.key"
                      :active="value.selected"
                      @click="selectFilter(filter, value)"
                    >
                      {{ value.value }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <div v-if="filter.type === 3" class="filters__container__search">
                    <b-form-input
                      :id="filter.uniqueKey"
                      :name="filter.uniqueKey"
                      :placeholder="filter.placeholder"
                      :debounce="100"
                      @change="filterRecords()"
                      v-model="filter.value"
                      class="filters__container__search__input"
                      tabindex="0"
                      :state="
                        filter.value &&
                        (filter.value.length == 0 || filter.value.length > 2)
                      "
                      :aria-describedby="'error' + filter.uniqueKey"
                    ></b-form-input>
                    <i
                      aria-hidden="true"
                      class="header-cicon cil-magnifying-glass filters__container__search__icon"
                    />
                    <b-form-invalid-feedback
                      :id="'error' + filter.uniqueKey"
                      :state="
                        filter.value &&
                        (filter.value.length == 0 || filter.value.length > 2)
                      "
                    >
                      {{ model.translations["general.Error.MinLength"] }} 3
                    </b-form-invalid-feedback>
                  </div>
                  <div
                    v-if="filter.type === 4 && !checkIsApp()"
                    class="filters__container__range"
                  >
                    <vue-slider
                      :ref="filter.uniqueKey"
                      :id="filter.uniqueKey"
                      :name="filter.uniqueKey"
                      v-model="filter.value.value"
                      :enable-cross="false"
                      :min="filter.value.min"
                      :max="filter.value.max"
                      tooltip="always"
                      tooltip-placement="bottom"
                      :height="2"
                      :dot-size="10"
                      @change="filterRecords()"
                    ></vue-slider>
                  </div>
                  <div
                    v-if="filter.type === 4 && checkIsApp()"
                    class="filters__container__search range"
                  >
                    <b-form-input
                      :id="filter.uniqueKey + 'min'"
                      :name="filter.uniqueKey + 'min'"
                      :min="filter.value.min"
                      :max="filter.value.value[1]"
                      type="number"
                      placeholder="min"
                      class="filters__container__search__input"
                      v-model="filter.value.value[0]"
                      @change="
                        filterRecords();
                        sendAnalytics();
                      "
                      :debounce="100"
                      tabindex="0"
                    />
                    <b-form-input
                      :id="filter.uniqueKey + 'max'"
                      :name="filter.uniqueKey + 'max'"
                      :max="filter.value.max"
                      :min="filter.value.value[0]"
                      type="number"
                      placeholder="max"
                      class="filters__container__search__input"
                      v-model="filter.value.value[1]"
                      @change="filterRecords()"
                      :debounce="100"
                      tabindex="0"
                    />
                  </div>
                </div>
              </div>

              <div class="filters__container__block filters__container__button">
                <div>
                  <div class="detailed">
                    <b-link :to="model.searchUrlAI" class="link">{{
                      model.translations["searchPage.DetailedView"]
                    }}</b-link>
                  </div>
                  <view-selector
                    :showDropdown="displayType != 'audio'"
                    :translations="model.translations"
                    :displayType="displayType"
                    @displayTypeUpdate="displayTypeUpdate"
                  />
                  <b-button variant="primary" @click="clearFilters()">{{
                    model.translations["searchPage.ClearFilter"]
                  }}</b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div>
      <b-row class="padding-top color--basic">
        <b-col cols="12">
          <list-container
            v-if="records && records.length > 0"
            :items="records"
            :displayType="displayType"
            :section="'Sekcja: ' + (model.header ? model.header : header)"
            :translations="model.translations"
            :key="item"
          ></list-container>
          <div class="emptyList" v-else>
            <h2>
              {{ model.translations["searchPage.EmptyList"] }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      fluid
      class="disableFlex containerFixed"
      ref="paginationItem"
      v-if="pagination.totalItems > pagination.itemsPerPage"
    >
      <b-row align-h="center">
        <b-col cols="12" class="text-center padding-top">
          <b-pagination
            :value="pagination.pageNumber"
            :total-rows="pagination.totalItems"
            :per-page="pagination.itemsPerPage"
            @change="GotoPage"
            :aria-label="'Stronicowanie'"
            :label-next-page="'Przejdź do następnej'"
            :label-page="'Przejdź do'"
            :label-prev-page="'Przejdź do poprzedniej'"
            :label-last-page="'Przejdź do ostatniej'"
            :label-first-page="'Przjdź do pierwszej'"
            first-class="cis-chevron-double-left-alt custom"
            last-class="cis-chevron-double-right-alt custom"
            prev-class="cis-chevron-left-alt custom"
            next-class="cis-chevron-right-alt custom"
            :limit="pagination.count"
            align="center"
            :pills="true"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ListContainer from "@/components/Lists/ListContainer";
import ViewSelector from "@/components/common/ViewSelector";
import VueSlider from "vue-slider-component";
import { EventBus } from "@/services/event-bus";

export default {
  name: "GridContainer",
  components: {
    ListContainer,
    VueSlider,
    ViewSelector,
  },
  props: {
    header: { type: String, default: "wyniki wyszukiwania" },
    model: Object,
  },
  data() {
    return {
      records: [],
      pagination: {
        pageNumber: 1,
        totalItems: 0,
        pageCount: 1,
        itemsPerPage:
          this.$isTizen(this.$browserDetect) && this.model.displayType == "audio"
            ? 25
            : 48,
        count: 5,
        load: false,
      },
      item: 0,
      waiting: null,
      filterState: [],
      showFilter: null,
      first: true,
      displayType: this.model.displayType,
    };
  },
  computed: {
    canLoadMore() {
      return this.pagination.pageNumber < this.pagination.pageCount;
    },
    filterList() {
      return this.filterState.filter((x) => x.type !== 0 && x.visible);
    },
    filtersQuery() {
      var t = this.filterState.filter((x) => {
        var temp =
          x && x.type === 3 && x.value && x.value.length > 0 && x.value.length < 3;
        return temp;
      });
      if (t.length > 0) {
        this.$setBusy(false);
        return false;
      }
      let constantFilterValues = this.filterState
        .filter((x) => (x.type === 0 || x.type === 3) && x.value)
        .map((filter) => {
          return {
            field: filter.field,
            value: filter.value.replaceAll('"', '\\"'),
            type: filter.type,
          };
        });

      let dropDownFilterValues = this.filterState
        .filter((x) => (x.type === 1 || x.type === 2 || x.type === 5) && x.value)
        .map((filter) => {
          return {
            field: filter.field,
            value: filter.value
              .filter((value) => value.selected)
              .map((value) => value.key)
              .join(),
            type: filter.type,
          };
        })
        .filter((filter) => filter.value);

      let rangeFilterValues = this.filterState
        .filter((x) => x.type === 4 && x.value)
        .map((filter) => {
          return {
            field: filter.field,
            value: filter.value.value,
            type: filter.type,
          };
        });

      let allSelectedFilters = constantFilterValues
        .concat(dropDownFilterValues, rangeFilterValues)
        .map((filter) => {
          return `"${filter.field}.${filter.type}":"${filter.value}"`;
        });

      if (this.$route.query.id) {
        allSelectedFilters.push(`"id":"${this.$route.query.id}"`);
      }

      return JSON.parse(`{${allSelectedFilters.join()}}`);
    },
  },
  methods: {
    closeDialog: function () {
      if (this.showFilter) {
        this.showFilter.hide();
      }
    },
    reloadPage() {
      this.filterState = JSON.parse(JSON.stringify(this.model.filters));
      this.setupFiltersByQS();
      var qs = this.filtersQuery;
      if (qs) {
        this.addHashToLocation(qs);
      }
      this.load();
    },
    showFilterF(filter) {
      this.showFilter = filter.vueTarget;
    },
    setupFiltersByQS() {
      var page = this.$route.query["page"];
      this.pagination.pageNumber = page ?? 1;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.filterState.forEach((filter) => {
        if (filter.visible) {
          let qsVal = params[`${filter.field}.${filter.type}`];
          if (qsVal) {
            switch (filter.type) {
              case 3:
                filter.value = qsVal;
                break;
              case 4:
                filter.value.value = qsVal.split(",");
                break;
              case 1:
              case 2:
              case 5:
                filter.value.forEach((e) => {
                  if (e.key == qsVal) {
                    this.selectFilter(filter, e);
                  }
                });
                break;
            }
          }
        }
      });
    },
    selectFilter(filter, selectedValue) {
      this.sendAnalytics(
        (!selectedValue.selected ? "Włączył " : "Wyłączył ") +
          (filter.field == "sort_field" ? "sortowanie" : "filtr"),
        selectedValue.value,
        false
      );
      this.pagination.pageNumber = 1;
      let isMultiple = filter.type === 2;
      if (!isMultiple) {
        filter.value = filter.value.map((value) => {
          return {
            key: value.key,
            value: value.value,
            selected: false,
          };
        });
      }

      let value = filter.value.filter((value) => value.key === selectedValue.key)[0];

      if (!isMultiple) {
        value.selected = true;
        filter.placeholder = selectedValue.value;
      } else {
        value.selected = !value.selected;
        let selectedValues = filter.value.filter((value) => value.selected == true);
        filter.placeholder = `${filter.name}${
          selectedValues.length > 0 ? " (" + selectedValues.length + ")" : ""
        }`;
      }

      this._filterRecords();
    },
    serialize(params) {
      if (params) {
        var queryString = Object.keys(params)
          .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
          })
          .join("&");
        return queryString;
      }
      return "";
    },
    addHashToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?page=" +
          this.pagination.pageNumber +
          "&" +
          this.serialize(params)
      );
    },
    clearFilters() {
      this.$setGAFormCustom(
        "Sekcja: " + (this.model.header ? this.model.header : this.header),
        "Wyczyść filtry",
        "",
        false
      );
      this.addHashToLocation(null);
      this.reloadPage();
    },
    _filterRecords() {
      var qs = this.filtersQuery;
      if (qs) {
        this.addHashToLocation(qs);
        this.loadRecords();
      }
    },
    filterRecords() {
      this.pagination.pageNumber = 1;
      if (this.waiting != null) clearInterval(this.waiting);
      this.waiting = setTimeout(() => this._filterRecords(), 1000);
    },
    loadRecords() {
      if (this.waiting != null) clearInterval(this.waiting);
      this.waiting = setTimeout(() => this.load(), 1000);
    },
    GotoPage(page) {
      this.pagination.pageNumber = page;
      var qs = this.filtersQuery;
      this.addHashToLocation(qs);
      this.$setGAFormCustom(
        "Sekcja: " + (this.model.header ? this.model.header : this.header),
        "Paginacja | Kliknięcie w numer strony",
        page,
        false
      );
      let self = this;

      self.$setBusy(true);

      var test = this.filtersQuery;
      if (!test) return;
      axios
        .get(process.env.VUE_APP_BASE_API_URL + this.model.loadItemsEndpoint, {
          params: {
            page: page,
            limit: self.pagination.itemsPerPage,
            ...this.filtersQuery,
          },
        })
        .then(function (response) {
          self.records = response.data.records;
          self.item++;
          self.pagination.pageNumber = response.data.pageNumber;
          self.pagination.pageCount = response.data.pageCount;
          self.pagination.totalItems = response.data.totalItems;
          self.$setBusy(false);
          self.options = response.data.options;
          self.$scrollTo(self.$refs.scroll, 1000, { offset: -120 });
        })
        .catch(function () {
          self.$setBusy(false);
        });
    },
    getWindowWidth() {
      var element = this.$refs.paginationItem;
      if (element != null) {
        var width = element.offsetWidth;
        if (this.$isTizen(this.$browserDetect))
          this.pagination.count = ((width / 80) | 0) - 4;
        else this.pagination.count = ((width / 50) | 0) - 4;
      } else if (this.pagination.load) {
        setTimeout(this.getWindowWidth, 0);
      }
    },
    load: function () {
      let self = this;
      if (!self.first) self.$setBusy(true);
      var test = this.filtersQuery;
      if (!test) return;
      axios
        .get(process.env.VUE_APP_BASE_API_URL + this.model.loadItemsEndpoint, {
          params: {
            page: this.pagination.pageNumber,
            limit: self.pagination.itemsPerPage,
          },
          headers: {
            TvStatus: this.isApp(),
          },
        })
        .then(function (response) {
          self.records = response.data.records;
          self.item++;
          self.pagination.pageNumber = response.data.pageNumber;
          self.pagination.pageCount = response.data.pageCount;
          self.pagination.totalItems = response.data.totalItems;
          self.pagination.load = true;
          if (!self.first) {
            self.$setBusy(false);
          }
          self.first = false;
        })
        .catch(function () {
          if (!self.first) self.$setBusy(false);
        });
    },
    checkIsApp() {
      return this.$browserDetect.isApp;
    },
    sendAnalytics(action, label, interaction) {
      this.$setGAFormCustom(
        "Sekcja: " + (this.model.header ? this.model.header : this.header),
        action,
        label,
        interaction
      );
    },
    isApp() {
      return this.$isTizen(this.$browserDetect);
    },
    displayTypeUpdate(type) {
      this.displayType = type;
    },
  },
  watch: {
    model: function () {
      this.reloadPage();
    },
    showFilter(value) {
      this.$setNavigationLock(value ? true : false);
    },
  },
  beforeMount() {
    if (this.displayType != "audio") {
      for (var i = 0; i < this.pagination.itemsPerPage; i++) {
        this.records.push({ fake: true });
      }
    }
  },
  async mounted() {
    await this.reloadPage();
    window.addEventListener("resize", this.getWindowWidth, true);
    setTimeout(this.getWindowWidth, 0);
    EventBus.$on("closeNavEvent", this.closeDialog);
  },
  beforeDestroy() {
    EventBus.$off("closeNavEvent", this.closeDialog);
    window.removeEventListener("resize", this.getWindowWidth, true);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.filters {
  border-bottom: 2px solid $color-main;
  min-height: 165px;
  font-family: $font-family-wotfard;
  padding-top: 5px;

  &.education {
    @include media-breakpoint-up(lg) {
      margin-top: 48px;
      border-top: 1px solid $color-main;
    }
  }

  &.assets {
    border-top: 0;
  }

  &__container {
    justify-content: space-between;
    min-height: 165px;
    flex-direction: row;
    margin-top: 5px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &--flexwrap {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
      @include media-breakpoint-down(md) {
        justify-content: center;
        width: 100%;
      }
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__range {
      height: 40px;
      padding: 0 20px;
      display: grid;
      align-items: center;
      margin-top: -7px;
    }

    &__block {
      align-self: center;

      label {
        font-size: 1em;
      }

      &__filter {
        width: 100%;
        margin-bottom: 16px !important;
        max-width: 300px;

        &.details-mobile {
          text-decoration: underline;
        }
        @include media-breakpoint-down(xs) {
          margin: auto;
        }
        @include media-breakpoint-up(sm) {
          margin-right: 55px;
        }

        @media (min-width: 820px) {
          &.details-mobile {
            width: 650px;
          }
        }

        @include media-breakpoint-up(lg) {
          &.details-mobile {
            display: none;
          }
        }

        &__label {
          display: block;
          height: 24px;
        }
      }

      &__dropdown {
        text-transform: uppercase;

        .btn {
          color: $color-main;
          width: 100%;
          text-align: left;
          padding-left: 1rem;
          font-weight: $font-weight-medium;
          @include media-breakpoint-down(sm) {
            padding-top: 0.75rem;
          }
        }

        .dropdown-toggle[aria-expanded="true"]:after {
          transform: rotate(90deg);
        }

        .dropdown-toggle:after {
          position: absolute;
          right: 14px;
          top: 7px;
          font-size: em(24);
          margin-left: 1em;
          transition: 0.2s;
          vertical-align: 0.255em;
          content: "\eaf9";
          font-family: "CoreUI-Icons-Linear" !important;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          border-top: 0;
          border-right: 0;
          border-bottom: 0;
          border-left: 0;
        }

        .dropdown-menu {
          .dropdown-item {
            color: $color-black;
            font-weight: $font-weight-medium;
            margin-left: calc(1rem - 5px);
            padding-left: 5px;
            width: auto;
          }

          .dropdown-item.active {
            width: fit-content;
            border-bottom: 1px solid $color-black;
          }

          border: 0;
          border-radius: 0;
          min-width: 100%;
        }

        display: block;
        background: $color-gray;

        height: 40px;
      }
    }

    &__search {
      position: relative;
      .form-control {
        &.is-valid,
        &.is-invalid {
          background-image: none;
        }
      }
      .invalid-feedback {
        position: absolute;
      }
      &__icon {
        position: absolute;
        width: 22px;
        height: 22px;
        right: 14px;
        top: 7px;
      }
      &.range {
        > input {
          max-width: 45%;
          display: inline-block;
          &:first-child {
            margin-right: 3%;
          }
        }
      }
    }

    &__button {
      min-width: 308px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      > div {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
          gap: 5px;
        }
      }
      .detailed {
        text-align: right;
        position: absolute;
        top: -3em;
        right: 0;
        a {
          text-decoration: underline;
        }
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
        .detailed {
          display: none;
        }
      }
    }
  }
  .vue-slider-dot-tooltip-bottom {
    bottom: unset !important;
    top: 0;
    transform: translate(-50%, -100%);
  }
  .filters__container__range {
    margin-top: 0;
  }
}
.emptyList {
  font-family: "wotfard";
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
  }
}
.grid-container {
  margin: auto;
  width: 100%;
}
.Tizen {
  .detailed {
    position: static;
    padding-bottom: 0.7em;
  }
}
</style>

<template>
  <div>
    <b-row class="progressBar details-header">
      <b-col :sm="10" class="">
        <b-container>
          <h3>
            {{ header }}
          </h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <span v-if="readonly && tags" class="pro-readonly-value tags">{{
            tags.join(", ")
          }}</span>
          <b-form-group v-if="!readonly">
            <b-form-tags
              class="short hide-tags-add"
              ref="tagFilterInput"
              @focus="showSearch = true"
              @keydown.native="keyDown"
              :tag-validator="countryValidator"
              :invalid-tag-text="translations['proOrder.InvalidTagText']"
              :placeholder="translations['proOrder.AddTagText']"
              :duplicate-tag-text="translations['proOrder.DuplicateTagText']"
              v-model="tags"
            ></b-form-tags>
            <div
              class="searchResult"
              v-show="showSearch && searchText && items && items.length > 0"
            >
              <b-list-group>
                <b-list-group-item
                  @click="Additem(item, 0)"
                  v-for="(item, index) in items"
                  :key="index"
                  :class="{
                    selected: selected == index,
                  }"
                >
                  <p>
                    <span class="hover-left-right">{{ item.text }}</span>
                  </p>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    translations: Object,
    tagList: Array,
    name: String,
    selectedTag: Array,
    readonly: Boolean,
  },
  data() {
    return {
      showSearch: false,
      searchText: "",
      items: Array,
      selected: 0,
      tags: [],
      countries: [],
    };
  },
  methods: {
    getItems(search) {
      search = search.toLowerCase();
      var countries = this.tagList.filter((element) =>
        element.text.toLowerCase().startsWith(search)
      );
      if (countries) {
        this.items = countries;
      } else {
        this.items = [];
      }
    },
    Additem(item) {
      if (item) {
        if (!this.countries.find((x) => x.value === item.value)) {
          this.countries.push(item);
        }
        this.items = [];
        this.searchText = "";
        this.$refs.tagFilterInput.newTag = "";   
      }
    },
    keyDown(key) {
      if (key.key == "ArrowUp") {
        key.preventDefault();
        if (this.selected > 0) this.selected--;
      } else if (key.key == "ArrowDown") {
        key.preventDefault();

        if (this.selected < this.items.length - 1) this.selected++;
      } else if (key.key == "Enter") {
        key.preventDefault();
        this.Additem(this.items[this.selected], 0);
      } else if (!(key.key == "ArrowLeft" || key.key == "ArrowRight")) {
        this.selected = 0;
      }
    },
    countryValidator(tag) {
      this.searchText = tag;
      this.getItems(tag);
      return (
        this.items.length > 0 &&
        !this.countries.includes(this.items[this.selected])
      );
    },
    tagFocus(event) {
      var temp = event;
      var time = 0;
      var self = this;
      var value = temp.type == "focus";
      if (!value) {
        time = 500;
      }
      setTimeout(() => {
        self.showSearch = value;
      }, time);
    },
  },
  watch: {
    tags: function (newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        let difference = oldValue.filter((x) => !newValue.includes(x));
        var item = this.countries.find(function (element) {
          return difference.includes(element.text);
        });
        var index = this.countries.indexOf(item);
        this.countries.splice(index, 1);
      }
    },
    countries: function (newValue) {
      this.tags = newValue.map((x) => x.text);
      this.$emit("ChangeValue", newValue, this.name);
    },
    selectedTag(newValue) {
      if (newValue != this.countries) this.countries = newValue;
    },
  },
  mounted() {
    if (this.selectedTag) this.countries = this.selectedTag;

    this.tagsElements = document.getElementsByClassName("b-form-tags-input");
    for (var i = 0; i < this.tagsElements.length; i++) {
      var t = this.tagsElements[i];
      t.addEventListener("focus", this.tagFocus, true);
      t.addEventListener("blur", this.tagFocus, true);
    }
  },
  beforeDestroy() {
    for (var i = 0; i < this.tagsElements.length; i++) {
      var t = this.tagsElements[i];
      t.removeEventListener("focus", this.tagFocus, true);
      t.removeEventListener("blur", this.tagFocus, true);
      this.tagsElements
    }
  },
};
</script>
<template>
  <modal
    header="welcome"
    :title="model.title"
    :show="showModal"
    :closeText="translations['general.Close']"
    @hidePop="hideModal"
  >
    <template v-slot:main>
      <p class="b30" v-html="model.text"></p>
    </template>
  </modal>
</template>
<script>
import { get, set } from "tiny-cookie";
import Modal from "./modal.vue";
export default {
  components: { Modal },
  props: {
    model: Object,
    translations: Object,
  },
  data() {
    return {
      showModal: false,
      accepted: null,
    };
  },
  methods: {
    hideModal: function () {
      const now = new Date();
      now.setYear(now.getFullYear() + 1);
      set("vedu-welcome", "true", { expires: now.toGMTString() });
      this.showModal = false;
    },
  },
  mounted() {
    this.accepted = get("vedu-welcome");
    if (!this.accepted) {
      this.showModal = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.b30 {
  color: white;
}
</style>
<template>
  <b-container class="mb-5 mx-auto mt-5 color--basic">
    <b-row>
      <b-col class="mx-4 text-center text-uppercase">
        <h1 v-html="model.translations['general.Register']"></h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center text--smaller"
        >{{ this.loginIfRegistered[0] }}
        <b-link
          :to="model.login.url"
          class="focus-smart"
          :title="model.login.name"
          >{{ this.loginIfRegistered[1] }}</b-link
        >
      </b-col>
    </b-row>
    <b-row v-if="$browserDetect.isApp && width > 1500">
      <b-col class="text-center">
        <p v-html="model.translations['general.Error.RegisterNoAvaliable']"></p>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <products
          :products="model.products"
          :register="model.register"
          :translations="model.translations"
        ></products>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Products from "@/components/common/Products";
export default {
  name: "RegisterProductsPage",
  components: {
    Products,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      hasValidationError: false,
      done: false,
      acceptance: [],
      loginIfRegistered: [],
      width: window.innerWidth,
    };
  },
  mounted() {
    this.loginIfRegistered =
      this.model.translations["user.LoginIfRegistered"].split(/{|}/);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
</style>
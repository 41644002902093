<template>
  <div>
    <!-- typ licencji -->
    <radio-buttons
      :header="model.translations['proOrder.TypeLicense']"
      :itemList="model.licenseType"
      :select="currentLicense.licenseType"
      :readonly="readonly"
      name="licenseType"
      @ChangeValue="getValue"
    ></radio-buttons>
    <!-- terrytorium -->
    <transition name="slide-fade">
      <territory
        v-show="currentLicense.licenseType"
        :header="model.translations['proOrder.Territory']"
        :translations="model.translations"
        :countriesList="model.countries"
        :continentsList="model.continents"
        :selectedContinents="currentLicense.continent"
        :selectedCountries="currentLicense.countries"
        :selectedCheck="currentLicense.checkedFiled"
        :readonly="readonly"
        @ChangeValue="getValue"
      ></territory>
    </transition>
    <!-- Pokaz filmowy -->
    <div v-if="currentLicense.licenseType == 'usecase.show'">
      <!-- Miejsce pokazu -->
      <transition name="slide-fade">
        <div v-show="currentLicense.licenseType == 'usecase.show' && isTerritorySelected">
          <radio-buttons
            :header="model.translations['proOrder.PlaceShow']"
            :itemList="model.showPlace"
            :select="currentLicense.place"
            :readonly="readonly"
            name="place"
            @ChangeValue="getValue"
          >
            <b-form-group
              :label="model.translations['proOrder.NumberSeats']"
              class="short m24"
              v-if="!readonly"
            >
              <b-input
                :placeholder="model.translations['proOrder.EnterNumber']"
                v-model="currentLicense.audience"
                :disabled="!currentLicense.place"
              ></b-input>
            </b-form-group>
            <div v-if="readonly" class="short m24">
              {{ model.translations["proOrder.NumberSeats"] }}:
              <span class="pro-readonly-value">{{ currentLicense.audience }}</span>
            </div>
          </radio-buttons>
        </div>
      </transition>
      <!-- liczba i data pokazu -->
      <transition name="slide-fade">
        <date-show
          v-show="
            currentLicense.licenseType == 'usecase.show' &&
            currentLicense.place &&
            currentLicense.audience
          "
          :header="model.translations['proOrder.NumberDateShows']"
          @ChangeValue="getValue"
          :translations="model.translations"
          :count="currentLicense.countShows"
          :selectDates="currentLicense.dates"
          :readonly="readonly"
        ></date-show>
      </transition>
      <!-- Charakter pokazu -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="
            currentLicense.licenseType == 'usecase.show' &&
            currentLicense.countShows > 0 &&
            currentLicense.dates.find((elem) => elem)
          "
          :header="model.translations['proOrder.CharacterShow']"
          :itemList="model.characterShow"
          :select="currentLicense.characterShowChecked"
          :input="currentLicense.characterShowValue"
          :readonly="readonly"
          name="characterShow"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
      <!-- Rodzaj pokazu -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="
            currentLicense.licenseType == 'usecase.show' &&
            currentLicense.characterShowChecked &&
            currentLicense.characterShowValue
          "
          :header="model.translations['proOrder.TypeShow']"
          :itemList="model.typeShow"
          :select="currentLicense.typeShow"
          :input="currentLicense.showName"
          :readonly="readonly"
          name="typeShow"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
      <!-- Rodzaj wydarzenia -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="
            currentLicense.licenseType == 'usecase.show' &&
            currentLicense.characterShowChecked &&
            currentLicense.characterShowValue &&
            currentLicense.typeShow == 'show.kind.event'
          "
          :header="model.translations['proOrder.TypeEvent']"
          :itemList="model.typeEvent"
          :select="currentLicense.typeEvent"
          :readonly="readonly"
          name="typeEvent"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
      <!-- formaty -->
    </div>
    <!-- Emisja w TV -->
    <div v-else-if="currentLicense.licenseType == 'usecase.tv'">
      <!-- Wyłączność Emisji -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.licenseType == 'usecase.tv' && isTerritorySelected"
          :header="model.translations['proOrder.LicenseExclusivity']"
          :itemList="model.exclusivity"
          :select="currentLicense.exclusivity"
          :readonly="readonly"
          name="exclusivity"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
      <!-- Czas Trwania Licencji -->
      <transition name="slide-fade">
        <emission-type
          v-show="currentLicense.exclusivity"
          :header="model.translations['proOrder.DurationLicense']"
          :translations="model.translations"
          :selectedDateBegin="currentLicense.EmissionDateBegin"
          :selectedDateEnd="currentLicense.EmissionDateEnd"
          :readonly="readonly"
          @ChangeValue="getValue"
          name="emmssion"
        ></emission-type>
      </transition>
      <!-- Liczba nadań -->
      <transition name="slide-fade">
        <emission-count
          v-show="
            currentLicense.EmissionDateBegin &&
            currentLicense.EmissionDateEnd &&
            currentLicense.EmissionDateBegin < currentLicense.EmissionDateEnd
          "
          :header="model.translations['proOrder.NumberBroadcasts']"
          name="emmssion"
          @ChangeValue="getValue"
          :selectedEmission="currentLicense.emissionCount"
          :selectedReEmission="currentLicense.reEmissionCount"
          :translations="model.translations"
          :readonly="readonly"
        ></emission-count>
      </transition>
      <!-- Rodzaj emisji -->
      <transition name="slide-fade">
        <check-box
          v-show="currentLicense.emissionCount > 0"
          :header="model.translations['proOrder.EmissionType']"
          name="emissionType"
          :itemList="model.emisionType"
          :select="currentLicense.emisionType"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></check-box>
      </transition>
      <!-- Model transmisji -->
      <transition name="slide-fade">
        <check-box
          v-show="currentLicense.emisionType && currentLicense.emisionType.length > 0"
          :header="model.translations['proOrder.TransmissionModel']"
          name="transmissionModel"
          :itemList="model.transmissionModel"
          :select="currentLicense.transmissionModel"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></check-box>
      </transition>
    </div>
    <!-- VOD -->
    <div v-else-if="currentLicense.licenseType == 'usecase.vod'">
      <!-- Wyłączność Emisji -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.LicenseExclusivity']"
          :itemList="model.exclusivity"
          :select="currentLicense.exclusivity"
          :readonly="readonly"
          name="exclusivity"
          @ChangeValue="getValue"
          disabled
          :disabledInfo="model.translations['proOrder.NonExclusive']"
        ></radio-buttons>
      </transition>
      <transition name="slide-fade">
        <check-box
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.VodPlatform']"
          name="vodPlatform"
          :itemList="model.vodPlatform"
          :select="currentLicense.vodPlatform"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></check-box>
      </transition>
    </div>
    <!-- Wydawnictwo Płytowe -->
    <div v-else-if="currentLicense.licenseType == 'usecase.cd'">
      <!-- Wyłączność Emisji -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.LicenseExclusivity']"
          :itemList="model.exclusivity"
          :select="currentLicense.exclusivity"
          :readonly="readonly"
          name="exclusivity"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
      <!-- nośnik -->
      <transition name="slide-fade">
        <check-box
          :header="model.translations['proOrder.Carrier']"
          v-show="currentLicense.exclusivity"
          name="carrier"
          :itemList="model.carrier"
          :select="currentLicense.carrier"
          :input="currentLicense.carrierInput"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></check-box>
      </transition>
      <!-- Czas Trwania Licencji -->
      <transition name="slide-fade">
        <emission-type
          v-show="
            currentLicense.carrier.length > 0 && currentLicense.carrierInput.length > 0
          "
          :header="model.translations['proOrder.DurationLicense']"
          :translations="model.translations"
          :selectedDateBegin="currentLicense.EmissionDateBegin"
          :selectedDateEnd="currentLicense.EmissionDateEnd"
          :readonly="readonly"
          @ChangeValue="getValue"
          name="emmssion"
        ></emission-type>
      </transition>
      <!-- rodzaj wydawnictwa -->
      <transition name="slide-fade">
        <radio-buttons-extended
          v-show="
            currentLicense.EmissionDateBegin &&
            currentLicense.EmissionDateEnd &&
            currentLicense.EmissionDateBegin < currentLicense.EmissionDateEnd
          "
          name="publishing"
          :header="model.translations['proOrder.PublishingType']"
          :itemList="model.publishingType"
          :select="currentLicense.publishing"
          :input="currentLicense.collectionName"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></radio-buttons-extended>
      </transition>
      <!-- charakter wydawnictwa -->
      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.publishing.find((x) => x)"
          :header="model.translations['proOrder.CharacterPublishing']"
          :itemList="model.characterPublishing"
          :select="currentLicense.characterPublishing"
          :readonly="readonly"
          name="characterPublishing"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
    </div>
    <div v-else-if="currentLicense.licenseType == 'usecase.distribution'">
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :header="model.translations['proOrder.LicenseExclusivity']"
          :itemList="model.exclusivity"
          :select="currentLicense.exclusivity"
          :readonly="readonly"
          name="exclusivity"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
      <transition name="slide-fade">
        <check-box
          v-show="currentLicense.exclusivity"
          :header="model.translations['proOrder.Distribution']"
          :itemList="model.distribution"
          :select="currentLicense.distribution"
          :readonly="readonly"
          name="distribution"
          @ChangeValue="getValue"
        ></check-box>
      </transition>
    </div>
    <!-- formaty -->
    <transition name="slide-fade">
      <b-row
        class="format-row"
        v-show="
          (currentLicense.licenseType == 'usecase.show' &&
            (currentLicense.typeEvent ||
              currentLicense.typeShow == 'show.kind.repertoire')) ||
          (currentLicense.licenseType == 'usecase.distribution' &&
            currentLicense.distribution &&
            currentLicense.distribution.length > 0) ||
          (currentLicense.licenseType == 'usecase.tv' &&
            currentLicense.transmissionModel.length > 0) ||
          (currentLicense.licenseType == 'usecase.vod' &&
            currentLicense.vodPlatform.length > 0) ||
          (currentLicense.licenseType == 'usecase.cd' &&
            currentLicense.characterPublishing &&
            currentLicense.characterPublishing.length > 0)
        "
        v-if="currentLicense.licenseType && fileFormat"
      >
        <b-col>
          <div class="white" v-if="!readonly">
            <b-container>
              <b-row class="progressBar">
                <b-col :sm="10">
                  <h2>
                    {{ model.translations["proOrder.FileFormat"] }}
                  </h2>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="form">
            <transition name="slide-fade">
              <file-language
                :translations="model.translations"
                :languageList="model.lanugages"
                :selectedLanguage="currentLicense.movieLang"
                :selectedCheck="currentLicense.langCheck"
                :header="model.translations['proOrder.MuvieLanguage']"
                :readonly="readonly"
                @ChangeValue="getValue"
              ></file-language
            ></transition>
            <transition name="slide-fade">
              <check-buttons-extended
                v-show="
                  currentLicense.langCheck &&
                  (currentLicense.langCheck.find((x) => x == 'A') ||
                    currentLicense.movieLang.length > 0)
                "
                :header="model.translations['proOrder.FileFormat']"
                :itemList="fileFormat.filesFormat"
                :select="currentLicense.filesFormat"
                :input="currentLicense.filesFormatInput"
                :readonly="readonly"
                name="filesFormat"
                @ChangeValue="getValue"
                :translations="model.translations"
              ></check-buttons-extended>
            </transition>
          </div>
        </b-col>
      </b-row>
    </transition>
    <transition name="slide-fade">
      <section-header
        :header="model.translations['proOrder.Pending']"
        v-show="
          !readonly &&
          !currentLicense.langCheck
        "
      >
      </section-header>
    </transition>
    <transition name="slide-fade" v-if="readonly">
      <div>
        <section-header
          :header="model.translations['proOrder.LicenseNotes']"
        ></section-header>
        <b-container>
          <b-row class="progressBar m40 text-area">
            <b-col :sm="10">
              <div class="notes-margin">
                <b-form-group>
                  <label :for="currentLicense.notes" class="col-form-label">{{
                    model.translations["proOrder.EnterNotes"]
                  }}</label>
                  <b-form-textarea
                    v-model="currentLicense.notes"
                    size="lg"
                    maxlength="1000"
                  ></b-form-textarea>
                  <p class="chars-status text-right">
                    {{ currentLicense.notes ? currentLicense.notes.length : 0 }}
                    / 1000
                  </p>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </transition>
  </div>
</template>
<script>
import CheckBox from "./checkBox.vue";
import DateShow from "./DateShow.vue";
import EmissionCount from "./EmissionCount.vue";
import EmissionType from "./EmissionType.vue";
import FileLanguage from "./FileLanguage.vue";
import RadioButtons from "./RadioButtons.vue";
import RadioButtonsExtended from "./RadioButtonsExtended.vue";
import CheckButtonsExtended from "./CheckButtonsExtended.vue";
import Territory from "./Territory.vue";
import SectionHeader from "./SectionHeader.vue";
export default {
  components: {
    Territory,
    RadioButtons,
    DateShow,
    EmissionType,
    EmissionCount,
    CheckBox,
    RadioButtonsExtended,
    FileLanguage,
    SectionHeader,
    CheckButtonsExtended,
  },
  props: {
    model: Object,
    current: Object,
    readonly: Boolean,
  },
  data() {
    return {
      currentLicense: {
        licenseType: null,
        countries: [],
        continent: [],
        checkedFiled: [],
        place: null,
        audience: null,
        countShows: null,
        dates: [null],
        characterShowChecked: null,
        characterShowValue: null,
        typeShow: null,
        showName: null,
        typeEvent: null,
        exclusivity: null,
        EmissionDateBegin: null,
        EmissionDateEnd: null,
        EmissionStart: null,
        emissionCount: null,
        reEmissionCount: null,
        emisionType: [],
        transmissionModel: [],
        carrier: [],
        carrierInput: [],
        publishing: [],
        collectionName: null,
        characterPublishing: null,
        vodPlatform: [],
        distribution: [],
        fileSpeed: null,
        fileSoundWersion: null,
        fileWcagPl: null,
        langCheck: [],
        movieLang: [],
        filesFormat: [],
        filesFormatInput: [],
        notes: null,
      },
      fileFormat: {
        fileSoundWersion: [],
        fileSpeed: [],
        fileWcagPl: [],
        filesFormat: [],
      },
    };
  },
  methods: {
    getValue(value, type) {
      switch (type) {
        case "licenseType":
          switch (value) {
            case "usecase.show":
              this.fileFormat.fileSoundWersion = this.model.fullScreenings.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.fullScreenings.fileSpeed;
              this.fileFormat.fileWcagPl = this.model.fullScreenings.fileWcagPl;
              this.fileFormat.filesFormat = this.model.fullScreenings.filesFormat;
              break;
            case "usecase.distribution":
              this.fileFormat.fileSoundWersion = this.model.fullDistributtion.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.fullDistributtion.fileSpeed;
              this.fileFormat.fileWcagPl = this.model.fullDistributtion.fileWcagPl;
              this.fileFormat.filesFormat = this.model.fullDistributtion.filesFormat;
              break;
            case "usecase.tv":
              this.fileFormat.fileSoundWersion = this.model.fullTV.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.fullTV.fileSpeed;
              this.fileFormat.fileWcagPl = this.model.fullTV.fileWcagPl;
              this.fileFormat.filesFormat = this.model.fullTV.filesFormat;
              break;
            case "usecase.vod":
              this.fileFormat.fileSoundWersion = this.model.fullVod.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.fullVod.fileSpeed;
              this.fileFormat.fileWcagPl = this.model.fullVod.fileWcagPl;
              this.fileFormat.filesFormat = this.model.fullVod.filesFormat;
              this.getValue(this.model.exclusivity[1].value, "exclusivity");
              break;
            case "usecase.cd":
              this.fileFormat.fileSoundWersion = this.model.fullCarrier.fileSoundWersion;
              this.fileFormat.fileSpeed = this.model.fullCarrier.fileSpeed;
              this.fileFormat.fileWcagPl = this.model.fullCarrier.fileWcagPl;
              this.fileFormat.filesFormat = this.model.fullCarrier.filesFormat;
              break;
          }
          this.currentLicense.licenseType = value;
          break;
        case "continent":
          this.currentLicense.continent = value;
          break;
        case "countries":
          this.currentLicense.countries = value;
          break;
        case "territory":
          this.currentLicense.checkedFiled = value;
          break;
        case "place":
          this.currentLicense.place = value;
          break;
        case "countShows":
          this.currentLicense.countShows = value;
          break;
        case "dates":
          this.currentLicense.dates = value;
          break;
        case "characterShow":
          this.currentLicense.characterShowChecked = value;
          break;
        case "characterShowinput":
          this.currentLicense.characterShowValue = value;
          break;
        case "typeShow":
          this.currentLicense.typeShow = value;
          break;
        case "typeShowinput":
          this.currentLicense.showName = value;
          break;
        case "typeEvent":
          this.currentLicense.typeEvent = value;
          break;
        case "exclusivity":
          this.currentLicense.exclusivity = value;
          break;
        case "emmssion_dateBegin":
          this.currentLicense.EmissionDateBegin = value;
          break;
        case "emmssion_dateEnd":
          this.currentLicense.EmissionDateEnd = value;
          break;
        case "emmssion_Ecount":
          this.currentLicense.emissionCount = value;
          break;
        case "emmssion_reCount":
          this.currentLicense.reEmissionCount = value;
          break;
        case "emissionType":
          this.currentLicense.emisionType = value;
          break;
        case "transmissionModel":
          this.currentLicense.transmissionModel = value;
          break;
        case "carrier":
          this.currentLicense.carrier = value;
          break;
        case "carrierinput":
          this.currentLicense.carrierInput = value;
          break;
        case "publishing":
          this.currentLicense.publishing = value;
          break;
        case "publishinginput":
          this.currentLicense.collectionName = value;
          break;
        case "characterPublishing":
          this.currentLicense.characterPublishing = value;
          break;
        case "vodPlatform":
          this.currentLicense.vodPlatform = value;
          break;
        case "distribution":
          this.currentLicense.distribution = value;
          break;
        case "fileSpeed":
          this.currentLicense.fileSpeed = value;
          break;
        case "fileSoundWersion":
          this.currentLicense.fileSoundWersion = value;
          break;
        case "fileWcagPl":
          this.currentLicense.fileWcagPl = value;
          break;
        case "langCheck":
          this.currentLicense.langCheck = value;
          break;
        case "movieLang":
          this.currentLicense.movieLang = value;
          break;
        case "filesFormat":
          this.currentLicense.filesFormat = value;
          break;
        case "filesFormatinput":
          this.currentLicense.filesFormatInput = value;
          break;
      }
      this.$emit("ChangeValue", this.currentLicense, "full");
    },
  },
  watch: {
    current: {
      handler(newValue) {
        if (newValue) {
          this.currentLicense.licenseType = newValue.licenseType;
          this.currentLicense.countries = newValue.countries;
          this.currentLicense.continent = newValue.continent;
          this.currentLicense.checkedFiled = newValue.checkedFiled;
          this.currentLicense.place = newValue.place;
          this.currentLicense.audience = newValue.audience;
          this.currentLicense.countShows = newValue.countShows;
          this.currentLicense.dates = newValue.dates;
          this.currentLicense.characterShowChecked = newValue.characterShowChecked;
          this.currentLicense.characterShowValue = newValue.characterShowValue;
          this.currentLicense.typeShow = newValue.typeShow;
          this.currentLicense.showName = newValue.showName;
          this.currentLicense.typeEvent = newValue.typeEvent;
          this.currentLicense.exclusivity = newValue.exclusivity;
          this.currentLicense.EmissionDateBegin = newValue.EmissionDateBegin;
          this.currentLicense.EmissionDateEnd = newValue.EmissionDateEnd;
          this.currentLicense.emissionCount = newValue.emissionCount;
          this.currentLicense.reEmissionCount = newValue.reEmissionCount;
          this.currentLicense.emisionType = newValue.emisionType;
          this.currentLicense.transmissionModel = newValue.transmissionModel;
          this.currentLicense.carrier = newValue.carrier;
          this.currentLicense.carrierInput = newValue.carrierInput;
          this.currentLicense.publishing = newValue.publishing;
          this.currentLicense.collectionName = newValue.collectionName;
          this.currentLicense.characterPublishing = newValue.characterPublishing;
          this.currentLicense.vodPlatform = newValue.vodPlatform;
          this.currentLicense.distribution = newValue.distribution;
          this.currentLicense.fileSpeed = newValue.fileSpeed;
          this.currentLicense.fileSoundWersion = newValue.fileSoundWersion;
          this.currentLicense.fileWcagPl = newValue.fileWcagPl;
          this.currentLicense.langCheck = newValue.langCheck;
          this.currentLicense.movieLang = newValue.movieLang;
          this.currentLicense.filesFormat = newValue.filesFormat;
          this.currentLicense.filesFormatInput = newValue.filesFormatInput;
          this.currentLicense.notes = newValue.notes;
        } else {
          this.currentLicense.licenseType = null;
          this.currentLicense.countries = [];
          this.currentLicense.continent = [];
          this.currentLicense.checkedFiled = [];
          this.currentLicense.place = null;
          this.currentLicense.audience = null;
          this.currentLicense.countShows = null;
          this.currentLicense.dates = [null];
          this.currentLicense.characterShowChecked = null;
          this.currentLicense.characterShowValue = null;
          this.currentLicense.typeShow = null;
          this.currentLicense.showName = null;
          this.currentLicense.typeEvent = null;
          this.currentLicense.exclusivity = null;
          this.currentLicense.EmissionDateBegin = null;
          this.currentLicense.EmissionDateEnd = null;
          this.currentLicense.emissionCount = null;
          this.currentLicense.reEmissionCount = null;
          this.currentLicense.emisionType = [];
          this.currentLicense.transmissionModel = [];
          this.currentLicense.carrier = [];
          this.currentLicense.carrierInput = [];
          this.currentLicense.publishing = [];
          this.currentLicense.collectionName = null;
          this.currentLicense.characterPublishing = null;
          this.currentLicense.vodPlatform = [];
          this.currentLicense.distribution = [];
          this.currentLicense.fileSpeed = null;
          this.currentLicense.fileSoundWersion = null;
          this.currentLicense.fileWcagPl = null;
          this.currentLicense.langCheck = [];
          this.currentLicense.movieLang = [];
          this.currentLicense.filesFormat = [];
          this.currentLicense.filesFormatInput = [];
          this.currentLicense.notes = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.current) this.currentLicense = this.current;
  },
  computed: {
    isTerritorySelected: function () {
      return (
        this.currentLicense.checkedFiled &&
        (this.currentLicense.checkedFiled.find((x) => x == "C") ||
          (this.currentLicense.countries && this.currentLicense.countries.length > 0) ||
          (this.currentLicense.continent && this.currentLicense.continent.length > 0))
      );
    },
  },
};
</script>

export default {
    install: function (Vue) {
        var send = function (event, category, action, label, interaction) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: event,
                GAcategory: category,
                GAaction: action,
                GAlabel: label,
                GAinteraction: interaction,
            });
        };
        Vue.prototype.$setDataLayer = function (dataLayer) {
            window.dataLayer.push(dataLayer);
        };
        Vue.prototype.$setGAFormCustom = function (category, action, label, interaction) {
            send("GAEventCustom", category, action, label, interaction);

        };
        Vue.prototype.$setGAFormNavigation = function (action, label, interaction) {
            send("GAEventCustom", "Pasek nawigacji", action, label, interaction);
        }
        Vue.prototype.$setGAFormFooter = function (action, label, interaction) {
            send("GAEventCustom", "Stopka", action, label, interaction);
        }
        Vue.prototype.$setGAFormProfile = function (action, label, interaction) {
            send("GAEventCustom", "Sekcja: Profil użytkownika", action, label, interaction);
        }

    }
}
<template>
  <div class="article-page color--basic">
    <b-container fluid class="containerFixed">
      <b-row v-if="model.banner && !(model.stream && model.stream.enableStream)">
        <b-col cols="12" class="article-page__banner fix">
          <b-img
            fluid
            :src="model.banner.url"
            :alt="model.banner.title ? model.banner.title : model.title"
            class="article-page__banner__image"
          ></b-img>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12" class="fix">
          <h1>{{ model.title }}</h1>
        </b-col>
        <b-col cols="12" class="article-page__author fix">
          <div class="article-info">
            <span v-if="model.author">{{ model.author }} </span>
            <span v-if="model.author && model.date" class="break">|</span>
            <span v-if="model.date"> {{ model.date }}</span>
          </div>
          <div class="underscore"></div>
        </b-col>
      </b-row>
      <b-row class="lead">
        <b-col cols="12" class="fix">
          <text-block :text="model.shortDescription"></text-block>
        </b-col>
      </b-row>
      <tv-qr-code
        v-if="$isTizen($browserDetect)"
        :message="model.translations['general.QrCodeInfo']"
      ></tv-qr-code>
      <div v-else>
        <share-control
          v-if="!model.hideShare"
          :translations="model.translations"
          :favorite-model="model.favoriteModel"
          :title="model.title"
        ></share-control>
        <b-row
          align-h="center"
          class="mt-4"
          :key="index"
          v-for="(section, index) in model.sections"
        >
          <b-col cols="12" class="fix" :sm="getColumnSize(section.alias)">
            <text-block
              v-if="canSee(section, 'textSection')"
              :text="section.value.text"
            ></text-block>
            <text-block-with-title
              v-else-if="canSee(section, 'textWithTitleSection')"
              :model="section.value"
            ></text-block-with-title>
            <article-image
              v-else-if="canSee(section, 'imageSection')"
              :model="section.value"
            ></article-image>
            <download-control
              v-else-if="canSee(section, 'downloadSection')"
              :model="section.value"
            ></download-control>
            <video-section
              v-else-if="canSee(section, 'videoSection')"
              :model="section.value"
              :translations="model.translations"
            ></video-section>
            <grid-container
              :model="section.value"
              v-else-if="canSee(section, 'listingSection')"
              :header="model.title"
            ></grid-container>
            <gallery-alternative
              :options="section.value.options"
              :translations="model.translations"
              :items="section.value.images"
              v-else-if="canSee(section, 'aditionalMaterialGallery')"
            >
            </gallery-alternative>
            <player
              v-else-if="canSee(section,'shortMoviePicker')"
              :assetId="section.value.atdId"
              :jumpTime="section.value.umpTime"
              :translations="model.translations"
              :imageUrl="section.value.image.url"
              :lang="section.value.lang"
            >
            </player>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import TextBlock from "@/components/common/TextBlock";
import TextBlockWithTitle from "@/components/common/TextBlockWithTitle";
import ArticleImage from "@/components/common/ArticleImage";
import ShareControl from "@/components/common/ShareControl";
import DownloadControl from "@/components/common/DownloadControl";
import VideoSection from "@/components/common/VideoSection";
import GridContainer from "@/components/Lists/GridContainer";
import GalleryAlternative from "../components/common/GalleryAlternative.vue";
import TvQrCode from "../components/common/TvQrCode.vue";
import Player from "../components/common/Player.vue";

export default {
  name: "ArticlePage",
  components: {
    TextBlock,
    TextBlockWithTitle,
    ArticleImage,
    ShareControl,
    DownloadControl,
    VideoSection,
    GridContainer,
    GalleryAlternative,
    TvQrCode,
    Player
},
  props: {
    model: Object,
  },
  data() {
    return {
      url: location.href,
    };
  },
  mounted() {
    var section = this.$route.hash.replace("#", "");
    if (section)
      this.$scrollTo(window.document.getElementById(section), { offset: -100 });
  },
  methods: {
    getColumnSize(alias) {
      if (
        alias == "textSection" ||
        alias == "textWithTitleSection" ||
        alias == "imageSection"
      ) {
        return 12;
      }
      if (alias == "downloadSection") {
        return 6;
      }
    },
    getUniqueId(index) {
      return this.index * 1000 + index;
    },
    canSee(section, alias) {
      return section.alias == alias && section.value;
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.article-page {
  // font-family: $font-family-alternative;
  &__banner {
    aspect-ratio: 16/12;
    max-height: 400px;
    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  h1 {
    margin-bottom: 5px;
  }

  &__author {
    font-size: em(14);
    font-weight: $font-weight-semi-bold;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    .article-info{
      span:last-child{
        margin-right: 40px;
      }
    }
    .break {
      padding: 0 10px;
    }
  }
  .underscore {
    margin-top: 11px;
    margin-bottom: 11px;
    margin-left: 0;
  }
  .ListViewer {
    .splide__slider {
      overflow: hidden;
    }
  }
}
</style>

<template>
  <b-row>
    <b-col>
      <div class="white-two">
        <b-container>
          <b-row class="progressBar">
            <b-col :sm="10">
              <h2>
                {{ translations["proOrder.SelectedMovie"] }}
              </h2>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="form">
        <b-container>
          <b-row class="progressBar">
            <b-col :sm="10">
              <accordion
                :tabId="'name' + index"
                accordionName="Movies"
                v-for="(item, index) in assets"
                :key="index"
                role="tab"
              >
                <template v-slot:header>
                  <span>{{ item.title }}</span>
                  <i class="cil-chevron-bottom-alt"></i>
                  <i class="cil-chevron-left-alt"></i>
                </template>
                <template v-slot:main>
                  <div class="info">
                    <p>{{ item.category }}</p>
                    <p
                      class="person"
                      v-if="
                        item.showOnBox != null &&
                        item.showOnBox.person &&
                        item.showOnBox.person.length > 0
                      "
                    >
                      <span>{{ item.showOnBox.roleName }}:</span
                      ><span>{{ item.showOnBox.person[0] }}</span>
                    </p>
                    <p class="split">
                      <span>{{ item.country }}</span
                      ><span>{{ item.year }}</span>
                    </p>
                  </div>
                  <p class="desc">
                    {{ item.lead }}
                  </p>
                  <b-button @click="changeStatus(item.id)">{{
                    translations["proOrder.Delete"]
                  }}</b-button>
                </template>
              </accordion>
            </b-col>
          </b-row>
          <b-row class="progressBar">
            <b-col :sm="6">
              <b-form-group
                :label="translations['proOrder.AddPosition']"
              ></b-form-group>
              <b-input
                type="search"
                :placeholder="translations['proOrder.SearchMovie']"
                v-model="AssetsFilters.searchText"
                @focus="tagFocus"
                @keydown="keyDown"
                @blur="tagFocus"
              ></b-input>
              <div
                class="searchResult"
                v-show="
                  AssetsFilters.showSearch &&
                  AssetsFilters.searchText &&
                  AssetsFilters.items &&
                  AssetsFilters.items.length > 0
                "
              >
                <b-list-group>
                  <b-list-group-item
                    @click="Additem(item)"
                    v-for="(item, index) in AssetsFilters.items"
                    :key="index"
                    :class="{ selected: AssetsFilters.selected == index }"
                  >
                    <p
                      v-if="
                        item.showOnBox &&
                        item.showOnBox.persons &&
                        item.showOnBox.persons.length > 0
                      "
                    >
                      <span class="hover-left-right">
                        <span>{{ item.title }}</span
                        >&nbsp;
                        <span>({{ item.showOnBox.persons[0] }})</span>
                      </span>
                    </p>
                    <p v-else>
                      <span class="hover-left-right">{{ item.title }}</span>
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import Accordion from "../ListingView/Accordion.vue";
export default {
  components: {
    Accordion,
  },
  props: { translations: Object, selectedAssets: Array },
  data() {
    return {
      assets: [],
      AssetsFilters: {
        showSearch: false,
        searchText: "",
        item: [],
        selected: 0,
        items: [],
      },
    };
  },
  mounted() {
    if (this.assets != this.selectedAssets) this.assets = this.selectedAssets;
  },
  methods: {
    getItems(search) {
      var self = this;
      if (this.AssetsFilters.showSearch && search.length > 2) {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_PRO_URL}/Find?search=${search}`,
        })
          .then((response) => {
            if (response.status == 200) {
              self.AssetsFilters.items = response.data;
              self.AssetsFilters.selected = 0;
            }
          })
          .catch(() => {});
      } else self.AssetsFilters.items = [];
    },
    Additem(item) {
      if (item) {
        if (!this.assets.find((x) => x.id === item.id)) {
          this.assets.push(item);
          this.AssetsFilters.items = [];
          this.AssetsFilters.searchText = "";
        }
      }
    },
    keyDown(key) {
      if (key.key == "ArrowUp") {
        key.preventDefault();
        if (this.AssetsFilters.showSearch) {
          if (this.AssetsFilters.selected > 0) this.AssetsFilters.selected--;
        }
      } else if (key.key == "ArrowDown") {
        key.preventDefault();
        if (this.AssetsFilters.showSearch) {
          if (this.AssetsFilters.selected < this.AssetsFilters.items.length - 1)
            this.AssetsFilters.selected++;
        }
      } else if (key.key == "Enter") {
        key.preventDefault();
        if (this.AssetsFilters.showSearch)
          this.Additem(this.AssetsFilters.items[this.AssetsFilters.selected]);
      } else if (!(key.key == "ArrowLeft" || key.key == "ArrowRight")) {
        if (this.AssetsFilters.showSearch) this.AssetsFilters.selected = 0;
      }
    },
    tagFocus(event) {
      var temp = event;
      var time = 0;
      var self = this;
      var value = temp.type == "focus";
      if (!value) {
        time = 500;
      }
      setTimeout(() => {
        self.AssetsFilters.showSearch = value;
      }, time);
    },
    changeStatus(id) {
      var tmp = this.assets.find((x) => {
        return x.id !== id;
      });
      if (tmp) this.assets = tmp;
      else this.assets = [];
    },
  },
  watch: {
    AssetsFilters: [
      "handle1",
      function handle2() {},
      {
        handler: function handle3() {},
      },
    ],
    "AssetsFilters.searchText": function (newValue) {
      if (newValue.length > 0) this.getItems(newValue);
    },
    assets(newValue) {
      this.$emit("ChangeValue", newValue, "assets");
    },
    selectedAssets: {
      handler(newValue) {
        if (this.assets != newValue) this.assets = newValue;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.desc {
  margin-bottom: 1em !important;
}
</style>
<template>
    <div class="download-control__list" v-if="!$browserDetect.isApp">
        <a target="_blank" :href="file.url" :key="index" v-for="(file, index) in model.files" class="focus-smart">
            <div class="download-control__list__item">
                {{ file.name }}
                <div class="download-control__list__item--badge">
                    <i class="cil-data-transfer-down"></i>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "DownloadControl",
    props: {
        model: Object
    }
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.download-control__list {
    margin: auto;

    &.sm {
        max-width: em(360);
    }

    &.md {
        max-width: em(600);
    }

    &.adaptive {
        display: inline-block;
    }

    &__item {
        background: $color-light-gray;
        color: $color-black;
        font-size: em(16);
        font-weight: 800;
        padding: em(12) em(39) em(12) em(15);
        display: flex;
        text-align: left;
        margin-bottom: 10px;

        &--badge {
            margin-left: auto;
            padding-left: em(15);
        }
    }

    .download-control__list__item:not(:last-child) {
        margin-bottom: em(20);
    }
    a{
        display: block;
    }
}
</style>
<!------------------------------------------
Komponent - strona wyszukiwania AI w filmie, 'Znalezione przez AI'.
-------------------------------------------->
<template>
  <div>
    <movie-tabs :model="model" />
    <b-container fluid class="containerFixed movieAI mt-4">
      <b-row class="movieAI__top">
        <b-col class="mobile-title d-md-none" md="12">
          <movie-meta-title :model="model" />
        </b-col>
        <b-col class="col-player" md="7">
          <movie-time-line
            :translations="model.translations"
            :model="model"
            :frames="frames"
            :showSpinner="showSpinner"
          ></movie-time-line>
        </b-col>
        <b-col md="5" class="col-meta pl-md-5">
          <movie-meta :model="model" @on-data="onData" @set-busy="onBusy" />
        </b-col>
      </b-row>
      <b-row
        class="disableFlex"
        v-if="model.reletedContent && model.reletedContent.items"
      >
        <b-col>
          <div class="ListViewer vd_list-grid only-box-view">
            <carousel-container
              :model="options"
              :translations="model.translations"
              :itemList="model.reletedContent.items"
              :header="model.translations['moviePageAI.AnnotherMaterial']"
            >
              <template v-slot:header>
                <h2 v-if="isSearch">
                  {{ model.translations["moviePageAI.AnnotherMaterial"] }}
                </h2>
                <h2 v-else>{{ model.translations["moviePage.Related"] }}</h2>
              </template>
              <template v-slot:main>
                <splide-slide
                  v-for="(item, index) in model.reletedContent.items"
                  :key="index"
                >
                  <carousel-item
                    :alternative="true"
                    :model="item"
                    :box-view="true"
                    section="Sekcje: podobne filmy"
                    displayType="grid"
                    :translations="model.translations"
                  ></carousel-item>
                </splide-slide>
              </template>
            </carousel-container>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import MovieTabs from "../components/common/MovieTabs.vue";
import MovieMeta from "../components/common/MovieMeta.vue";
import MovieTimeLine from "../components/common/MovieTimeLine.vue";
import CarouselContainer from "../components/ListingView/CarouselContainer.vue";
import CarouselItem from "../components/ListingView/CarouselItem.vue";
import { SplideSlide } from "@splidejs/vue-splide";
import MovieMetaTitle from "../components/common/MovieMetaTitle.vue";
export default {
  components: {
    //----------------
    //Slider "polecane"
    //----------------
    SplideSlide,

    //----------------
    //Nagłówek strone z linkami do strony w starej wersji i strony wyszukiwania AI w filmie
    //----------------
    MovieTabs,

    //----------------
    //Metadane filmu (tytul, rezyser, filtry), sekcje po lewej stronie (dla rozdzielczosci desktop)
    //----------------
    MovieMeta,

    //----------------
    //Player i stopklatki filmu
    //----------------
    MovieTimeLine,

    //----------------
    //Naglowek z tyułem, frazą, rezyserem itd dla wersji mobile
    //----------------
    MovieMetaTitle,

    //----------------
    //Części slidera 'polecane'
    //----------------
    CarouselItem,
    CarouselContainer,
  },
  props: {
    //----------------
    //Model z backend: AssetViewModelAI
    //----------------
    model: Object,
  },
  data() {
    return {
      //opcje slidera 'polecane'
      options: {
        rewind: false,
        pagination: false,
        type: "slide",
        autoplay: false,
        pauseOnHover: false,
        interval: 5000,
      },

      //lista klatek filmu
      frames: [],

      //zmienna sterująca spinnerem w sekcji stopklatek
      showSpinner: false,
    };
  },
  mounted() {
    this.frames = this.updateFrame(this.model.frames);
  },
  methods: {
    onData(response) {
      this.frames = this.updateFrame(response.data);
    },
    onBusy(on) {
      this.showSpinner = on;
    },
    updateFrame(frames) {
      frames.forEach((element, index) => {
        element.id = index;
      });
      return frames;
    },
  },
  computed: {
    isSearch() {
      return this.model.searchPhrase ? true : false;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.movieAI {
  color: $color-main;
  &__top {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  .col-player,
  .mobile-title {
    margin-bottom: em(16, 12);
  }
}
.Tizen {
  .mobile-title {
    display: block !important;
  }
  .metatitle {
    &__hdrright {
      display: none;
    }
  }
  .meta {
    .metatitle {
      display: none !important;
    }
  }
  .movieAI__top {
    > div {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .Player {
      max-height: 94vh;
    }.meta__filter__postercheck .plabel{
      width: em(140);
    }
  }
}
</style>

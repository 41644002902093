<template>
    <b-container>
        <b-row align-h="center" v-if="model">
            <b-col cols="12" lg="6" class="text-center">
                <b-button :to="model.url" variant="primary" class="bottom--button">{{ model.name }}</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "BottomButton",
    props: {
        model: Object
    }
}
</script>

<style lang="scss">
.bottom--button {
    min-width: 240px;
    margin-bottom: 10px;
}
</style>
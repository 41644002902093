<template>
  <b-link    
    :to="model.url"
    :title="model.name"
    :tabindex="this.$getTabIndex()"
    @click.native="logoEvent()"
  >
    <img :src="model.urlImg" :alt="model.alt" />
  </b-link>
</template>

<script>
export default {
  name: "Logo",
  props: {
    model: Object,
  },
  methods: {
    logoEvent() {
      this.$setGAFormNavigation("Kliknięcie loga", "", false);
    },
  },
};
</script>
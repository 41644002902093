<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <div class="short">
            <b-form-group :label="translations['proOrder.MYStart']"
              ><date-picker
                class="date"
                v-model="dateBegin"
                :name="name"
                prefix-class="vd"
                value-type="format"
                format="YYYY-MM-DD"
                type="month"
              >
                <template v-slot:input>
                  <b-input
                    type="text"
                    v-model="dateBegin"
                    :name="name"
                    :placeholder="translations['proOrder.EnterDate']"
                  ></b-input>
                </template>
              </date-picker>
            </b-form-group>

            <b-form-group :label="translations['proOrder.MYEnd']"
              ><date-picker
                class="date"
                v-model="dateEnd"
                :name="name"
                prefix-class="vd"
                value-type="format"
                format="YYYY-MM-DD"
                type="month"
              >
                <template v-slot:input>
                  <b-input
                    type="text"
                    v-model="dateEnd"
                    :name="name"
                    :placeholder="translations['proOrder.EnterDate']"
                  ></b-input>
                </template>
              </date-picker>
              <span
                v-if="dateBegin && dateEnd && new Date(dateEnd) < new Date(dateBegin)"
                v-html="translations['proOrder.DateValidation']"
                class="invalid-feedback"
                style="display: block;"
              ></span>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      dateBegin: null,
      dateEnd: null,
    };
  },
  props: {
    header: String,
    translations: Object,
    name: String,
    selectedDateBegin: String,
    selectedDateEnd: String,
  },
  watch: {
    dateBegin(newValue) {
      this.$emit("ChangeValue", newValue, this.name + "_dateBegin");
    },
    dateEnd(newValue) {
      this.$emit("ChangeValue", newValue, this.name + "_dateEnd");
    },
    selectedDateBegin(newValue) {
      if (this.dateBegin != newValue) this.dateBegin = newValue;
    },
    selectedDateEnd(newValue) {
      if (this.dateEnd != newValue) this.dateEnd = newValue;
    },
  },
  mounted() {
    if (this.selectedDateBegin) this.dateBegin = this.selectedDateBegin;
    if (this.selectedDateEnd) this.dateEnd = this.selectedDateEnd;
  },
};
</script>

<template>
  <b-container
    v-if="CanShow() && item.value"
    fluid
    class="disableFlex"
    :class="{
      containerFixed: !(
        (item.alias == 'boxItems' && item.value.options.type == 'modulex1') ||
        item.alias == 'parent'
      ),
    }"
    :id="item.value.id"
    ref="scrollItem"
  >
    <b-row>
      <div class="ListViewer vd_list-grid only-box-view">
        <home-module-x2
          v-if="item.alias == 'boxItems' && item.value.options.type == 'modulex2'"
          :model="item.value"
          :translations="translations"
        ></home-module-x2>
        <home-collection
          v-else-if="item.alias == 'collectionItem'"
          :model="item.value"
          :index="index"
          :translations="translations"
        ></home-collection>
        <home-banner v-else-if="item.alias == 'banner'" :model="item.value"></home-banner>
        <home-module-x-1
          v-else-if="item.alias == 'boxItems' && item.value.options.type == 'modulex1'"
          :model="item.value"
          :translations="translations"
        ></home-module-x-1>
        <return-banner
          v-else-if="item.alias == 'parent'"
          :model="item.value"
        ></return-banner>
        <home-grid
          v-else-if="
            isTizen ||
            (item.alias == 'boxItems' &&
              (item.value.options.type == 'grid' ||
                item.value.options.type == 'audio' ||
                item.value.options.type == 'triplegrid'))
          "
          :model="item.value"
          :translations="translations"
          :isTriple="item.value.options.type == 'triplegrid'"
        ></home-grid>
        <home-carousel
          v-else-if="
            item.alias == 'boxItems' &&
            (item.value.options.type == 'loop' ||
              item.value.options.type == 'slide' ||
              item.value.options.type == 'slidebig')
          "
          :model="item.value"
          :translations="translations"
        ></home-carousel>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import HomeCarousel from "@/components/HomePage/HomeCarousel";
import HomeModuleX1 from "@/components/HomePage/HomeModuleX1";
import HomeModuleX2 from "@/components/HomePage/HomeModuleX2";
import HomeGrid from "@/components/HomePage/HomeGrid";
import HomeCollection from "@/components/HomePage/HomeCollection";
import HomeBanner from "./HomeBanner.vue";
import ReturnBanner from "./ReturnBanner.vue";
export default {
  props: {
    item: Object,
    translations: Object,
    index: Number,
    isFluid: Boolean,
  },
  data() {
    return {
      arr2: ["Android", "IOS", "Desktop", "App", "Tizen"],
      isTizen: this.$isTizen(this.$browserDetect),
    };
  },
  components: {
    HomeCarousel,
    HomeModuleX1,
    HomeModuleX2,
    HomeGrid,
    HomeCollection,
    HomeBanner,
    ReturnBanner,
  },
  methods: {
    CanShow() {
      if (this.item && this.item.alias == "banner") {
        if (this.item.value.allowedDevices && this.item.value.allowedDevices.length > 0) {
          if (
            (this.$osDetect.isWindows ||
              this.$osDetect.isLinux ||
              this.$osDetect.isMac) &&
            this.item.value.allowedDevices.includes("Desktop") //desktop
          ) {
            return true;
          } else if (
            this.$osDetect.isAndroid &&
            !this.$browserDetect.isApp &&
            this.item.value.allowedDevices.includes("Android Web") //show on Android Browser
          ) {
            return true;
          } else if (
            this.$osDetect.isAndroid &&
            this.$browserDetect.isApp &&
            this.item.value.allowedDevices.includes("Android Mobile") &&
            !this.isTizen //show on Android App
          ) {
            return true;
          } else if (
            this.$osDetect.isAndroid &&
            this.isTizen &&
            this.item.value.allowedDevices.includes("Android TV") //show on Android TV
          ) {
            return true;
          } else if (
            this.$osDetect.isIOS &&
            !this.$browserDetect.isApp &&
            this.item.value.allowedDevices.includes("IOS Web") //show on IOS browser
          ) {
            return true;
          } else if (
            this.$osDetect.isIOS &&
            this.$browserDetect.isApp &&
            this.item.value.allowedDevices.includes("IOS Mobile") //show on IOS browser
          ) {
            return true;
          } else if (
            this.$browserDetect.isTizen &&
            this.item.value.allowedDevices.includes("Tizen") //show on TizenApp
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  mounted() {
    if (this.$route.query.id && this.$route.query.id == this.item.value.id) {
      setTimeout(() => this.$scrollTo(this.$refs.scrollItem, 700, { offset: -100 }), 0);
    }
  },
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/payments/return/page',
        redirect: '/'
    },
    {
        path: '*',
        component: () => import('../views/BasePage'),
        pathToRegexpOptions: { strict: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {            
            setTimeout(() => {
                if (!to.hash) {                    
                    if (savedPosition) {
                        resolve(savedPosition);
                    } else {
                        resolve({ x: 0, y: 0 });
                    }
                }
                if (to.fullPath !== from.fullPath) {
                    setTimeout(() => {
                        document.body.classList.remove("busy");
                    }, 0);
                }

            }, 1000)            
        })
    }
})

export default router

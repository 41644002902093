<template>
  <carousel-container
    :model="model.options"
    :translations="translations"
    :itemList="model.items"
    :header="model.header"
  >
    <template v-slot:header>
      <h2 v-if="model.header" :class="{ 'sr-only': model.hideTitle }">
        <b-link
          v-if="model.headerUrl && canRoute()"
          :to="model.headerUrl"
          tabindex="0"
          @click.native="sendAnalytics('Pokaż więcej')"
          class="focus-smart"
        >
          <div class="nameSection">{{ model.header }}</div>
          <div>
            <div class="showAll">{{ translations["general.ShowMore"] }}</div>
            <i class="cil-chevron-right-alt"></i>
          </div>
        </b-link>
        <div v-else>
          <div class="nameSection">{{ model.header }}</div>
        </div>
      </h2>
      <p class="descriptionItems" v-if="model.description" v-html="model.description"></p>
    </template>
    <template v-slot:main>
      <splide-slide v-for="(item, index) in model.items" :key="index">
        <carousel-item
          :model="item"
          :box-view="true"
          :alternative="!$browserDetect.isApp"
          :section="'Sekcja: ' + model.header"
          displayType="grid"
          :translations="translations"
        ></carousel-item>
      </splide-slide>
    </template>
  </carousel-container>
</template>

<script>
import { SplideSlide } from "@splidejs/vue-splide";
import CarouselContainer from "@/components/ListingView/CarouselContainer";
import CarouselItem from "../ListingView/CarouselItem.vue";
export default {
  name: "HomeCarousel",
  components: {
    CarouselContainer,
    CarouselItem,
    SplideSlide,
  },
  props: {
    model: Object,
    translations: Object,
  },
  methods: {
    sendAnalytics(gAction) {
      this.$setGAFormCustom("Sekcja: " + this.model.header, gAction, "", false);
    },
    canRoute() {
      return !(
        this.$route.fullPath == this.model.headerUrl ||
        this.$route.fullPath == this.model.headerUrl + "/#"
      );
    },
  },
};
</script>

<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <div class="short">
            <b-form-group :label="translations['proOrder.EmissionCount']">
              <b-input
                type="number"
                v-model="emission"
                :placeholder="translations['proOrder.EnterNumber']"
              ></b-input>
            </b-form-group>
            <b-form-group :label="translations['proOrder.ReEmissionCount']">
              <b-input
                type="number"
                :placeholder="translations['proOrder.EnterNumber']"
                v-model="reEmssion"
              ></b-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      emission: null,
      reEmssion: null,
    };
  },
  props: {
    header: String,
    name: String,
    selectedEmission: null,
    selectedReEmission: null,
    translations: Object,
  },
  watch: {
    emission(newValue) {
      this.$emit("ChangeValue", newValue, this.name + "_Ecount");
    },
    reEmssion(newValue) {
      this.$emit("ChangeValue", newValue, this.name + "_reCount");
    },
    selectedEmission(newValue) {
      if (this.emission != newValue) this.emission = newValue;
    },
    selectedReEmission(newValue) {
      if (this.reEmssion != newValue) this.reEmssion = newValue;
    },
  },
  mounted() {
    if (this.selectedEmission) this.emission = this.selectedEmission;
    if (this.selectedReEmission) this.reEmssion = this.selectedReEmission;
  },
};
</script>
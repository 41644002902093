<template>
  <div class="lessons color--basic">
    <b-container fluid class="containerFixed">
      <b-row class="mt-5">
        <b-col cols="12">
          <h1>{{ model.title }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <list-container :items="model.items"></list-container>
        </b-col>
      </b-row>
    </b-container>
    <bottom-button
      class="padding-top"
      :model="model.editLessonLink"
    ></bottom-button>
    <bottom-button :model="model.showResultLink"></bottom-button>
  </div>
</template>

<script>
import ListContainer from "@/components/Lists/ListContainer";
import BottomButton from "@/components/common/BottomButton";

export default {
  name: "LessonPage",
  components: {
    ListContainer,
    BottomButton,
  },
  props: {
    model: Object,
  },
};
</script>

<style scoped>
</style>
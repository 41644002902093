<template>
  <div class="faq-page color--basic">
    <b-container fluid class="containerFixed">
      <b-row>
        <b-col cols="12" class="mt-5 text-center">
          <h1>FAQ</h1>
          <div v-html="model.headlineText"></div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="containerFixed collapse-content" role="tablist">
      <b-row>
        <b-col cols="12" lg="10" class="block-center">
          <collapse-item
            :key="item.title"
            accordion-name="main-accordion"
            :model="item"
            v-for="item in model.items"
          >
            <collapse-item
              :css-class="'collapse-item__secondary'"
              :key="child.title"
              :accordion-name="item.key"
              :model="child"
              v-for="child in item.items"
            >
              <text-block
                class="color--black"
                :text="child.description"
              ></text-block>
            </collapse-item>
          </collapse-item>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="disableFlex containerFixed">
      <b-row>
        <b-col cols="12" class="mt-5 text-center">
          <div v-html="model.descriptionText"></div>
        </b-col>
      </b-row>
      <b-row align-h="center" class="pt-3" v-if="model.contactLink">
        <b-col cols="12" lg="6" class="text-center">
          <b-button variant="primary" :to="model.contactLink.url">{{
            model.contactLink.name
          }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CollapseItem from "@/components/common/CollapseItem";
import TextBlock from "@/components/common/TextBlock";

export default {
  name: "FaqPage",
  components: { CollapseItem, TextBlock },
  props: {
    model: Object,
  },
};
</script>

<style lang="scss">
.block-center {
  margin: {
    left: auto;
    right: auto;
  }
}
</style>
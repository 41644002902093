var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playlistItem"},[_c('b-link',{staticClass:"left",style:('background-image: url(' +
      (_vm.model.imageUrl ? _vm.model.imageUrl : '/assets/placeholder.png') +
      ')'),attrs:{"to":{
      path: _vm.url,
      query: {
        id: _vm.model.id,
      },
    }}},[_c('span',{staticClass:"letter"},[_vm._v(_vm._s(_vm.model.name[0]))])]),_c('b-link',{staticClass:"right focus-smart",attrs:{"id":'b' + _vm.model.id.replaceAll('-', ''),"to":{
      path: _vm.url,
      query: {
        id: _vm.model.id,
      },
    }}},[_c('h3',[_vm._v(_vm._s(_vm.model.name))]),_c('p',[_vm._v(_vm._s(_vm.firstLine))]),_c('p',[_vm._v(_vm._s(_vm.secondLine))])]),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"title":_vm.translations['soundLibrary.EditTitle']},on:{"click":function($event){return _vm.edit()}}},[_c('i',{staticClass:"cil-pencil"})]),_c('b-button',{attrs:{"title":_vm.translations['soundLibrary.DeletePlaylist']},on:{"click":function($event){return _vm.remove()}}},[_c('i',{staticClass:"cil-trash"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
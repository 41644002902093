import axios from 'axios'
const PlaylistUrl = `${process.env.VUE_APP_BASE_API_URL}umbraco/api/playlist`
const PlaylistItemUrl = `${process.env.VUE_APP_BASE_API_URL}umbraco/api/playlistitem`
const PlayliststUrl = `${process.env.VUE_APP_BASE_API_URL}umbraco/api/playlists`
export default {
    create: function (name) {
        let playlistName = name;        
        return axios.post(`${PlaylistUrl}?&name=${name}`)
            .then((response) => {
                if (response.status == 200) {
                    return {
                        name: playlistName,
                        id: response.data,

                    }
                }
                return null;
            }).catch(() => {
                return "error";
            });
    },
    edit: function (id,name) {
        let playlistName = name;
        return axios.post(`${PlaylistUrl}?id=${id}&name=${name}`)
            .then((response) => {
                if (response.status == 200) {
                    return {
                        name: playlistName,
                        id: response.data,
                    }
                }
                return null;
            }).catch(() => {
                return "error";
            });
    },        
    add: function (plalistId, nodeId) {
        return axios.post(`${PlaylistItemUrl}?plalistId=${plalistId}&nodeId=${nodeId}`)
            .then((response) => {
                return response.status == 200;
            }).catch(() => {
                return "error";
            });
    },
    deleteItem: function (plalistId, nodeId) {
        return axios.delete(`${PlaylistItemUrl}?plalistId=${plalistId}&nodeId=${nodeId}`)
            .then((response) => {
                return response.status == 200;
            }).catch(() => {
                return "error";
            });
    },
    copy: function (name, items) {
        var requestValue = {
            name: name,
            items: items,
        }
        return axios.post(PlaylistUrl, requestValue)
            .then((response) => {
                if (response.status == 200) {
                    return {
                        name: requestValue.name,
                        id: response.data,
                    }
                }
                return null;
            }).catch(() => {
                return "error";
            });
    },
    delete: function (id) {
        return axios.delete(`${PlaylistUrl}?id=${id}`)
            .then((response) => {
                return response.status == 200;
            }).catch(() => {
                return "error";
            });
    },
    update: function (id, items) {
        var requestValue = {
            id: id,
            items: items,
        }
        return axios.put(PlaylistUrl, requestValue)
            .then((response) => {
                return response.status == 200;
            }).catch(() => {
                return "error";
            });
    },
    get: function (id) {
        return axios.get(`${PlaylistUrl}?id=${id}`)
            .then((response) => {
                if (response.status == 200) {
                    return response.data;
                }
                return null;
            }).catch(() => {
                return "error";
            });
    },
    getAll: function () {
        return axios.get(`${PlayliststUrl}`)
            .then((response) => {
                if (response.status == 200) {
                    return response.data;
                }
                return null;
            }).catch(() => {
                return "error";
            });
    },
}
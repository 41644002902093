<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <!--  -->
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <b-form-group>            
            <b-form-checkbox-group v-model="selectedValue" :name="name" v-if="itemList">
              <div v-for="(item, index) in itemList.filter(x=>!readonly || selectedValue.includes(x.value))" :key="index">
                <b-form-checkbox                  
                  :value="item.value"
                  :disabled="readonly"
                  :class="{checkReadonly: readonly}"
                  @click.native="inputValue[index] = null"
                >
                  {{ item.text }}
                </b-form-checkbox>
                <div class="short" v-if="item.haveInput">
                  <b-form-group :label="item.inputName">
                    <b-input
                      :placeholder="item.inputPlaceHolder"
                      :disabled="selectedValue.includes((x) => x == item.value)"
                      :type="item.inputType"
                      v-model="inputValue[index]"
                    ></b-input>
                  </b-form-group>
                </div>
              </div>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    itemList: Array,
    select: Array,
    name: String,
    input: Array,
    readonly: Boolean,
  },
  data() {
    return {
      selectedValue: [],
      inputValue: [],
    };
  },
  watch: {
    selectedValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, this.name);
      },
      deep: true,
    },
    select: {
      handler(newValue) {
        if (newValue != this.selectedValue) this.selectedValue = newValue;
      },
      deep: true,
    },
    inputValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, this.name + "input");
      },
      deep: true,
    },
    input(newValue) {
      if (newValue != this.inputValue) this.inputValue = newValue;
    },
  },
  mounted() {
    if (this.select) this.selectedValue = this.select;
    if (this.input) this.inputValue = this.input;
  },
};
</script>
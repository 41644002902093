<template>
  <a class="expandable-image" v-on:click="onClickButton" :title="altText">
    <i class="expand-button" aria-hidden="true">
      <svg style="width: 35px; height: 35px" viewBox="0 0 35 35">
        <path
          fill="#000000"
          d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z"
        />
      </svg>
    </i>
    <lazy-image ref="img" :source="model.url" :title="altText"></lazy-image>
    <div class="autor" v-if="model.title">
      <p>{{ model.title }}</p>
    </div>
  </a>
</template>

<script>
import { EventBus } from "@/services/event-bus.js";
import LazyImage from "../common/LazyImage.vue";
export default {
  components: {
    LazyImage,
  },
  props: {
    closeOnBackgroundClick: {
      type: Boolean,
      default: false,
    },
    title: String,
    model: Object,
    galleryId: String,
    elementId: Number,
    text: String,
    textOpen: String,
  },
  data() {
    return {
      altText: "",
    };
  },
  methods: {
    onClickButton: function () {
      if (this.galleryId) {
        EventBus.$emit("expand", {
          value: true,
          id: this.galleryId,
          elementId: this.elementId,
        });
      }
      return false;
    },
  },
  mounted() {
    this.altText = this.model.alt + " " + this.title;
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.expandable-image {
  display: block;
  position: relative;
  transition: 0.25s opacity;
  top: 2px;
  .autor {
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
    color: white !important;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: black;
      z-index: 1;
      opacity: 0.4;
    }
    p {
      position: relative;
      z-index: 1;
      padding: 5px 10px;
      margin: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    cursor: zoom-in;
  }
}
body > .expandable-image.expanded {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  opacity: 0;
  padding-bottom: 0 !important;
  cursor: default;
}
body > .expandable-image.expanded > img {
  width: 100%;
  max-width: 1200px;
  max-height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
body > .expandable-image.expanded > .close-button {
  display: block;
}
.close-button {
  position: fixed;
  cursor: pointer;
  z-index: 1;
  padding: 6px;
  top: 0px;
  right: 0;
  // @include media-breakpoint-up(md) {
  // top: -40px;
  // right: -6px;
  // }
}
.expand-button svg,
.close-button svg {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}
.expand-button svg path,
.close-button svg path {
  fill: #fff;
}
.expand-button {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 10px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  opacity: 0;
  transition: 0.2s opacity;
}
.expandable-image:hover .expand-button {
  opacity: 1;
}
.expand-button svg {
  width: 20px;
  height: 20px;
}
.expand-button path {
  fill: #fff;
}
.expandable-image img {
  // width: 100%;
}
.left-button,
.right-button {
  font-size: em(40);
  color: white;
  position: absolute;
  text-decoration: none !important;
  cursor: pointer;
}
.left-button {
  left: 10px;
}
.right-button {
  right: 10px;
}
</style>

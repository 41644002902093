<template>
  <ul class="block_avoidance">
    <li v-for="(item, index) in items" :key="index">
      <b-button @click="Navigate(item)">{{translations["navigation.GoTo"]}} {{ item.name }}</b-button>
    </li>
  </ul>
</template>
<script>
import { EventBus } from "@/services/event-bus";
export default {
  name: "BlockAvoidance",
  props: {
    alias: String,
    translations: Object,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    Navigate(el) {
      EventBus.$emit("NavigationGoTo", el.key);
    },
    RegisterNav(item) {
      this.items.push(item);
      this.items.sort((a, b) => a.id - b.id);
    },
  },
  beforeMount() {
    EventBus.$on("NavigationRegister", this.RegisterNav);
  },
  beforeDestroy() {
    EventBus.$off("NavigationRegister", this.RegisterNav);
  },
};
</script>
<style lang="scss" scoped>
.block_avoidance {
    margin: 0;
  li {
    position: absolute;
    top: -100%;
    .btn {
      background: white;
      color: black;
      border-color: white;
    }
    &:focus-within {
      top: 1em;
      left: 50%;
      z-index: 100;
      transform: translateX(-50%);
    }
  }
}
</style>
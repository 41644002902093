<template>
  <div class="sitemap-page color--basic">
    <b-container>
      <b-row>
        <b-col cols="12" class="mt-5 text-center">
          <h1>{{ model.title }}</h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="12" class="mt-5">
          <sitemap :model="model.siteMap"></sitemap>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Sitemap from "@/components/Footer/Sitemap";

export default {
  name: "SitemapPage",
  components: { Sitemap },
  props: {
    model: Object,
  },
};
</script>
<!------------------------------------------
Komponent - strona wyszukiwania AI
-------------------------------------------->
<template>
  <div class="searchai color--basic" ref="scroll">
    <div class="background--black mb-4">
      <b-container
        fluid
        class="containerFixed searchai__hdr"
        :class="isSearchDone ? 'd-none d-sm-block' : ''"
      >
        <b-row>
          <b-col class="searchai__hdr__c1">
            <b-nav-form @submit.stop.prevent="submit" class="searchai__hdr__form">
              <label for="searchItemsAi">
                <span class="mr-4">
                  <i
                    class="grayed cil-info-circle align-middle mr-1"
                    v-b-tooltip.hover="{ customClass: 'advsearch-tooltip' }"
                    :title="model.translations['searchPage.AdvSearchDecription']"
                  />
                  <b-link :to="model.aIhelpPage.url" class="grayed align-middle">{{
                    model.aIhelpPage.name
                  }}</b-link>
                </span>
                {{ model.translations["searchPage.SearchTo"] }}:</label
              >
              <div class="searchai__hdr__outerinput">
                <b-form-input
                  size="sm"
                  ref="searchPhrase"
                  class="searchai__hdr__input"
                  :placeholder="model.translations['searchPage.SearchPlaceholer']"
                  v-model="currentSearchPhrase"
                  :state="isValid"
                  id="searchItemsAi"
                  maxlength="100"
                  @keydown="keyd"
                  @keyup="keyup"
                  aria-describedby="errorSearch"
                  autocomplete="off"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  aria-live="polite"
                  id="errorSearch"
                  v-show="!isValid"
                >
                  <span v-if="!isTv">
                    {{
                      model.translations["searchPage.AIMinLength"].replace(
                        "{0}",
                        this.minSearchPhrase
                      )
                    }}
                  </span>
                  <span v-else
                    >{{ model.translations["general.Error.MinLength"] }} 3</span
                  >
                </b-form-invalid-feedback>
                <div
                  class="searchai__hdr__hints"
                  v-show="showHints && hints && hints.length > 0 && !isTv"
                  v-click-outside="closeHints"
                >
                  <div v-for="(item, index) in hints" :key="index">
                    <a class="searchai__hdr__hints__hint" @click="apllyHint(item)">{{
                      item
                    }}</a>
                  </div>
                </div>
              </div>
              <b-button
                variant="primary"
                class="searchai__hdr__button"
                type="submit"
                :aria-label="model.translations['searchPage.Search']"
              >
                <span>{{ model.translations["searchPage.Search"] }}</span>
                <i aria-hidden="true" class="cil-magnifying-glass header-cicon ml-4" />
              </b-button>
            </b-nav-form>
          </b-col>
          <b-col class="searchai__hdr__viewc">
            <b-link :to="model.searchUrlBasic" class="link">
              {{ model.translations["searchPage.SearchBasic"] }}
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="!isSearchDone">
      <home-sections
        v-for="(item, index) in model.items"
        :key="index"
        :item="item"
        :index="index"
        :translations="model.translations"
        :isFluid="model.highlighted && model.highlighted.url !== null"
      ></home-sections>
    </div>
    <div v-if="isSearchDone">
      <b-container fluid class="containerFixed searchai__mobilehdr d-sm-none">
        <b-row class="py-1">
          <b-container fluid>
            <b-row class="align-items-center">
              <b-col>
                <span class="results"
                  >{{ model.translations["searchPage.ResultsFor"] }}
                  <span class="phrase">"{{ lastSearchPhrase }}"</span></span
                >
              </b-col>
              <b-col class="flex-grow-0">
                <a
                  class="cursor-pointer focus-smart"
                  tabindex="0"
                  @click="showFiltersModal"
                >
                  <span class="mobile-hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </a>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
        <b-row>
          <b-col class="mobile-kinds">
            <a
              v-for="(item, index) in kinds.value"
              :key="index"
              :class="kindSelected == item.key ? 'selected' : ''"
              @click="
                kindSelected = item.key;
                filterChange(true);
              "
              tabindex="0"
              class="focus-smart"
            >
              {{ item.value }}
            </a>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid class="containerFixed">
        <b-row>
          <b-col class="mt-3 mr-5 aifilters d-none d-sm-block">
            <div class="position-relative">
              <div class="aifilters__spinner" :class="{ show: showFilterSpinner }">
                <b-spinner></b-spinner>
              </div>
              <div class="aifilters__header">
                <div class="aifilters__section strike mt-4">
                  <span>{{ model.translations["searchPage.Filters"] }}</span>
                  <span>
                    <b-button
                      variant="secondary"
                      type="button"
                      @click="clearFilters()"
                      :aria-label="model.translations['searchPage.Clear']"
                      >{{ model.translations["searchPage.Clear"] }}
                    </b-button>
                    <b-button
                      variant="primary"
                      type="button"
                      :class="{ filterAnim: filterAnim }"
                      @click="loadRecords()"
                      :aria-label="model.translations['searchPage.Filter']"
                      >{{ model.translations["searchPage.Filter"] }}
                    </b-button>
                  </span>
                </div>
              </div>
              <div class="aifilters__section strike" v-if="kinds">
                <span>{{ kinds.name }}</span>
              </div>
              <b-form-checkbox-group
                button-variant="outline-primary"
                v-model="kindSelected"
                v-if="kinds"
              >
                <b-form-checkbox
                  v-for="(item, index) in kinds.value"
                  :key="index"
                  :value="item.key"
                  @change="_filterChange(true, item)"
                  class="custom-radio"
                >
                  {{ item.value }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <div
                class="clear-both"
                v-for="(item, index) in filters.slice(2)"
                :key="index"
              >
                <div v-if="index > 0" class="aifilters__fdivider"></div>
                <accordion
                  class="acordionAI"
                  :tabId="item.field"
                  v-if="item.type == 2"
                  :collapsed="!isTv"
                  :expandedByDefault="true"
                >
                  <template v-slot:header>
                    <span class="aifilters__fname">{{ item.name }}</span>
                    <i class="cil-chevron-bottom-alt"></i>
                    <i class="cil-chevron-left-alt"></i>
                  </template>
                  <template v-slot:main>
                    <b-form-checkbox
                      v-for="(fitem, findex) in item.value.slice(
                        0,
                        getFilterItemCount(item)
                      )"
                      v-model="fitem.selected"
                      :key="findex"
                      :disabled="!fitem.enabled"
                      @change="_filterChange(false, fitem)"
                    >
                      {{ fitem.value }}
                    </b-form-checkbox>
                    <a
                      class="cursor-pointer aifilters__show-more focus-smart"
                      @click="expandFilter(item)"
                      v-show="item.value.length > getFilterItemCount(item)"
                    >
                      {{ model.translations["general.ShowMore"] }}
                    </a>
                  </template>
                </accordion>
                <div v-if="item.type == 4">
                  <div class="aifilters__fname mb-1">{{ item.name }}</div>
                  <label :for="'rangeFrom' + index" class="mr-2"
                    >{{ model.translations["general.From"] }}:</label
                  >
                  <b-form-input
                    class="range-from mr-3"
                    maxlength="4"
                    :formatter="formatterInt"
                    :name="'rangeFrom' + index"
                    :id="'rangeFrom' + index"
                    v-model="item.value.value[0]"
                    @change="filterChange(false)"
                  ></b-form-input>
                  <label :for="'rangeTo' + index" class="mr-2"
                    >{{ model.translations["general.To"] }}:</label
                  >
                  <b-form-input
                    class="range-to"
                    maxlength="4"
                    :formatter="formatterInt"
                    :name="'rangeTo' + index"
                    :id="'rangeTo' + index"
                    v-model="item.value.value[1]"
                    @change="filterChange(false)"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="records">
            <div>
              <div class="records-header d-none d-sm-flex">
                <div class="resultBox">
                  <span class="results"
                    >{{ model.translations["searchPage.SearchResultsFor"] }}
                    <span class="phrase">"{{ lastSearchPhrase }}"</span></span
                  >
                </div>
                <div v-if="!isTv">
                  <b-dropdown
                    no-flip
                    class="sortdropdown"
                    v-model="sortSelected.key"
                    v-if="sorts"
                    :text="sorts.name + ': ' + sortSelected.value"
                  >
                    <b-dropdown-item
                      v-for="(item, index) in sorts.value"
                      :key="index"
                      :value="item.key"
                      @click="
                        sortSelected = item;
                        loadRecords();
                      "
                    >
                      {{ item.value }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="" v-if="pagination.totalItems <= 0">
                {{ model.translations["searchPage.EmptyList"] }}
              </div>
              <div class="mt-3">
                <search-AI-result-row
                  v-for="(item, index) in pagination.records"
                  :key="index"
                  :item="item"
                  :model="model"
                  :searchPhrase="lastSearchPhrase"
                ></search-AI-result-row>

                <div
                  fluid
                  class="disableFlex containerFixed"
                  ref="paginationItem"
                  v-if="pagination.totalItems > pagination.itemsPerPage"
                >
                  <b-row align-h="center">
                    <b-col cols="12" class="text-center padding-top">
                      <b-pagination
                        :value="pagination.pageNumber"
                        :total-rows="pagination.totalItems"
                        :per-page="pagination.itemsPerPage"
                        @change="gotoPage"
                        :aria-label="'Stronicowanie'"
                        :label-next-page="'Przejdź do następnej'"
                        :label-page="'Przejdź do'"
                        :label-prev-page="'Przejdź do poprzedniej'"
                        :label-last-page="'Przejdź do ostatniej'"
                        :label-first-page="'Przjdź do pierwszej'"
                        first-class="cis-chevron-double-left-alt custom"
                        last-class="cis-chevron-double-right-alt custom"
                        prev-class="cis-chevron-left-alt custom"
                        next-class="cis-chevron-right-alt custom"
                        :limit="10"
                        align="center"
                        :pills="true"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-modal
      ref="fmodal"
      id="modal-filters"
      title="Filtrowanie i sortowanie"
      size="lg"
      centered
      hide-footer
      style="z-index: 130000"
      v-if="filters"
      :content-class="isTv ? 'ai-pop' : ''"
    >
      <div class="aifilters__spinner" :class="{ show: showFilterSpinner }">
        <b-spinner></b-spinner>
      </div>
      <div class="mx-auto aifilters">
        <div class="text-center my-4 buttons-mobile">
          <b-button
            class="mr-1 mobile-btn"
            variant="secondary"
            type="button"
            @click="clearFilters()"
            :aria-label="model.translations['searchPage.Clear']"
            >{{ model.translations["searchPage.Clear"] }}
          </b-button>
          <b-button
            class="ml-1 mobile-btn"
            :class="{ filterAnim: filterAnim }"
            variant="primary"
            type="button"
            @click="loadRecords()"
            :aria-label="model.translations['searchPage.Filter']"
            >{{ model.translations["searchPage.Filter"] }}
          </b-button>
        </div>
        <div class="aifilters__section strike" v-if="kinds">
          <span>{{ kinds.name }}</span>
        </div>
        <b-form-checkbox-group
          button-variant="outline-primary"
          v-model="kindSelected"
          v-if="kinds"
        >
          <b-form-checkbox
            v-for="(item, index) in kinds.value"
            :key="index"
            :value="item.key"
            @change="_filterChange(true, item)"
          >
            {{ item.value }}
          </b-form-checkbox>
        </b-form-checkbox-group>
        <div class="aifilters__section mt-4" v-if="sorts">
          <span>{{ sorts.name }}</span>
        </div>
        <b-form-radio-group
          button-variant="outline-primary"
          v-model="sortSelectedKey"
          v-if="sorts"
        >
          <b-form-radio
            v-for="(item, index) in sorts.value"
            :key="index"
            :value="item.key"
          >
            {{ item.value }}
          </b-form-radio>
        </b-form-radio-group>
        <div :key="rebuildFiltersVar">
          <div v-for="(item, index) in filters.slice(2)" :key="index">
            <div v-if="index > 0" class="aifilters__fdivider"></div>
            <accordion
              class="acordionAI"
              :tabId="item.field + 'M'"
              v-if="item.type == 2"
              :collapsed="!isTv"
              :expandedByDefault="true"
            >
              <template v-slot:header>
                <span class="aifilters__fname">{{ item.name }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <b-form-checkbox
                  v-for="(fitem, findex) in item.value.slice(0, getFilterItemCount(item))"
                  v-model="fitem.selected"
                  :key="findex"
                  @change="_filterChange(false, item)"
                >
                  {{ fitem.value }}
                </b-form-checkbox>
                <a
                  class="cursor-pointer aifilters__show-more focus-smart"
                  @click="expandFilter(item)"
                  v-show="item.value.length > getFilterItemCount(item)"
                >
                  {{ model.translations["general.ShowMore"] }}
                </a>
              </template>
            </accordion>
            <div v-if="item.type == 4">
              <div class="aifilters__fname mb-2">{{ item.name }}</div>
              <label :for="'rangeFrom' + index" class="mr-1"
                >{{ model.translations["general.From"] }}:</label
              >
              <b-form-input
                class="range-from mr-3"
                maxlength="4"
                :formatter="formatterInt"
                :name="'rangeFrom' + index"
                :id="'rangeFrom' + index"
                v-model="item.value.value[0]"
                @change="filterChange(false)"
                :min="item.value.min"
                :max="item.value.value[1]"
              ></b-form-input>
              <label for="rangeFrom" class="mr-2"
                >{{ model.translations["general.To"] }}:</label
              >
              <b-form-input
                class="range-to"
                maxlength="4"
                :formatter="formatterInt"
                :name="'rangeTo' + index"
                :id="'rangeTo' + index"
                v-model="item.value.value[1]"
                @change="filterChange(false)"
                :min="item.value.value[0]"
                :max="item.value.max"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import HomeSections from "../components/HomePage/HomeSections.vue";
import Accordion from "../components/ListingView/Accordion.vue";
import SearchAIResultRow from "../components/common/SearchAIResultRow.vue";
import axios from "axios";
import { EventBus } from "@/services/event-bus";
const ASKFOR_LIST = 1;
const ASKFOR_FILTERS = 2;
export default {
  name: "SearchAI",
  components: {
    //----------------
    //Akordeon dla rozwijalnych filtrów
    //----------------
    Accordion,

    //----------------
    //Slidery 'polecane' dla strony bez frazy wyszukiwania
    //----------------
    HomeSections,

    //----------------
    //Wiersz na liscie znalezionych pozycji
    //----------------
    SearchAIResultRow,
  },
  props: {
    //----------------
    //Model backend: SearchAIViewModel
    //----------------
    model: Object,
  },
  data() {
    return {
      //znalezione pozycje opakowane w strukturę do stronicowania
      pagination: {
        pageNumber: 1,
        totalItems: 0,
        pageCount: 1,
        itemsPerPage: 20,
        records: null,
      },
      //aktualnie fraza wyszukiwania w input[text]
      currentSearchPhrase: null,

      //fraza wyszukiwania ale aktualnych wyników
      lastSearchPhrase: null,

      //stan walidacji frazy wyszukiwania
      isValid: true,

      //boolean, czy wersja z wynikami wyszukiwania czy wersja z 'polecane'
      isSearchDone: false,

      //timer uruchamiany po zmianie filtrów, opoznienie przez zapytaniem API
      filterChangeTimer: null,

      //zmienna wymusze przebudowanie filtrów, tam gdzie vue nie robi tego domyslnie
      rebuildFiltersVar: 0,

      //timer, opoznienie przez zapytaniem o podpowiedzi do frazy wyszukiwania
      searchHintTimer: null,

      //podpowiedzie względem frazy wyszukiwania
      hints: null,

      //pokaza/ukryj podpowiedzi
      showHints: false,

      //lista filtrów
      filters: null,

      //pokaż/ukryj spinner nad lista filtrów (przy przeładowaniu)
      showFilterSpinner: false,

      //liczba pozycji filtra przed 'pokaz wiecej'
      filterShowCount: 12,

      //minimalna ilość znaków przy zapytaniem api
      minSearchPhrase: 3,

      //pokaz/ukryj animacje na 'filtru'
      filterAnim: false,

      //czy klienta to aplikacja telewizyjna
      isTv: this.$isTizen(this.$browserDetect),
    };
  },
  mounted() {
    this.$root.$on("bv::modal::show", this.setNavLock);
    this.$root.$on("bv::modal::hide", this.hideModalEvent);
    EventBus.$on("closeNavEvent", this.hideModalEvent);
    this.filters = this.model.filters;
    this.setupFiltersByQS();
    this.currentSearchPhrase = this.$route.query.phrase;
    this.lastSearchPhrase = this.$route.query.phrase;
    this.isSearchDone = this.model.searchResults ? true : false;
    if (this.model.searchResults) {
      this.pagination.pageNumber = this.model.searchResults.pageNumber;
      this.pagination.totalItems = this.model.searchResults.totalItems;
      this.pagination.pageCount = this.model.searchResults.pageCount;
      this.pagination.records = this.model.searchResults.records;
    }
  },
  computed: {
    kinds: {
      get() {
        return this.filters ? this.filters[0] : null;
      },
    },
    kindSelected: {
      get() {
        var value;
        if (this.kinds.value.filter((x) => x.selected)[0]?.key)
          value = this.kinds.value.filter((x) => x.selected)[0].key;
        return [value];
      },
      set(v) {
        this.kinds.value.forEach((x) => (x.selected = v[v.length - 1] == x.key));
      },
    },
    sorts: {
      get() {
        return this.filters ? this.filters[1] : null;
      },
    },
    sortSelectedKey: {
      get() {
        return this.sorts.value.filter((x) => x.selected)[0]?.key;
      },
      set(v) {
        this.sorts.value.forEach((x) => (x.selected = v == x.key));
      },
    },
    sortSelected: {
      get() {
        return this.sorts.value.filter((x) => x.selected)[0];
      },
      set(v) {
        this.sorts.value.forEach((x) => (x.selected = v.key == x.key));
      },
    },
  },
  methods: {
    getFilterItemCount(f) {
      return f.showCount ? f.showCount : this.filterShowCount;
    },
    setupFiltersByQS() {
      var page = this.$route.query["page"];
      this.pagination.pageNumber = page ?? 1;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.filters.forEach((filter) => {
        if (filter.visible) {
          let qsVal = params[`${filter.field}.${filter.type}`];
          if (qsVal) {
            switch (filter.type) {
              case 3:
                filter.value = qsVal;
                break;
              case 4:
                filter.value.value = qsVal.split(",");
                break;
              case 1:
              case 2:
              case 5:
                filter.value.forEach((e) => {
                  if (e.key == qsVal) {
                    this.selectFilter(filter, e);
                  }
                });
                break;
            }
          }
        }
      });
    },
    selectFilter(filter, selectedValue) {
      this.pagination.pageNumber = 1;
      let isMultiple = filter.type === 2;
      if (!isMultiple) {
        filter.value = filter.value.map((value) => {
          return {
            key: value.key,
            value: value.value,
            selected: false,
          };
        });
      }

      let value = filter.value.filter((value) => value.key === selectedValue.key)[0];

      if (!isMultiple) {
        value.selected = true;
        filter.placeholder = selectedValue.value;
      } else {
        value.selected = !value.selected;
        let selectedValues = filter.value.filter((value) => value.selected == true);
        filter.placeholder = `${filter.name}${
          selectedValues.length > 0 ? " (" + selectedValues.length + ")" : ""
        }`;
      }
    },
    expandFilter(f) {
      f.showCount = this.getFilterItemCount(f) + this.filterShowCount;
      let x = f.field;
      f.field = "";
      f.field = x;
    },
    closeHints() {
      this.showHints = false;
    },
    apllyHint(s) {
      this.showHints = false;
      this.currentSearchPhrase = s;
      this.submit();
    },
    gotoPage(page) {
      this.pagination.pageNumber = page;
      this.doSearch(ASKFOR_LIST);
    },
    loadRecords() {
      this.filterAnim = false;
      this.$refs["fmodal"].hide();
      this.pagination.pageNumber = 1;
      this.doSearch(ASKFOR_LIST);
    },
    showFiltersModal() {
      this.$refs["fmodal"].show();
      this.rebuildFiltersVar++;
    },
    addHashToLocation(params) {
      history.pushState(
        {},
        null,
        `${this.$route.path}?phrase=${this.lastSearchPhrase}&page=${
          this.pagination.pageNumber
        }&${this.serialize(params)}`
      );
    },
    serialize(params) {
      if (params) {
        var queryString = Object.keys(params)
          .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
          })
          .join("&");
        return queryString;
      }
      return "";
    },
    doSearch(ask) {
      let self = this;
      self.showFilterSpinner = true;

      if (ask & ASKFOR_LIST) self.$setBusy(true);
      var filtersToSend = this.getFiltersJson();
      let filtersToSendQS = filtersToSend.map((filter) => {
        return `"${filter.field}.${filter.type}":"${filter.values}"`;
      });
      var filtersReady = JSON.parse(`{${filtersToSendQS.join()}}`);
      this.addHashToLocation(filtersReady);
      axios
        .get(process.env.VUE_APP_BASE_API_URL + this.model.searchUrlAI, {
          params: {
            ask: ask,
            limit: self.pagination.itemsPerPage,
          },
        })
        .then(function (response) {
          self.isSearchDone = true;
          self.$scrollTo(self.$refs.scroll, 1000, { offset: -120 });
          if (response.data.filters) {
            self.reloadFilters(response.data.filters);
          }
          if (response.data.searchResults) {
            self.pagination.pageNumber = response.data.searchResults.pageNumber;
            self.pagination.pageCount = response.data.searchResults.pageCount;
            self.pagination.totalItems = response.data.searchResults.totalItems;
            self.pagination.records = response.data.searchResults.records;
          }
          self.showFilterSpinner = false;
          if (ask & ASKFOR_LIST) self.$setBusy(false);
        })
        .catch(function (e) {
          console.log(e);
          self.isSearchDone = true;
          if (ask == ASKFOR_FILTERS) self.showFilterSpinner = false;
          else self.$setBusy(false);
        });
    },
    reloadFilters(f) {
      f.forEach((x) => {
        let old = this.filters.filter((o) => o.field == x.field)[0];
        if (old) {
          if (x.type == 1 || x.type == 2 || x.type == 5) {
            let oldSelected = old.value
              .filter((o) => o.selected == true)
              .map((o) => o.key);
            x.value.forEach((v) => (v.selected = oldSelected.includes(v.key)));
          } else if (x.type == 4) {
            x.value.value[0] = old.value?.value[0];
            x.value.value[1] = old.value?.value[1];
          }
        }
      });
      f.unshift(this.filters[1]);
      f.unshift(this.filters[0]);
      this.filters = f;
    },
    getFiltersJson() {
      let filtersToSend = this.filters
        .filter(
          (f) =>
            ((f.type == 2 || f.type == 1 || f.type == 5) &&
              f.value.filter((i) => i.selected == true).length > 0) ||
            (f.type == 4 && f.value && (f.value.value[0] || f.value?.value[1]))
        )
        .map((f) => {
          if (f.type == 2 || f.type == 1 || f.type == 5)
            return {
              field: f.field,
              type: f.type,
              values: f.value
                .filter((i) => i.selected == true)
                .map((i) => i.key)
                .join(","),
            };
          else if (f.type == 4)
            return {
              field: f.field,
              type: f.type,
              values: f.value?.value,
            };
        });
      return filtersToSend;
    },
    clearFilters() {
      this.filters.slice(2).map((f) => {
        if (f.type == 2 || f.type == 1)
          f.value.map((i) => {
            i.selected = false;
          });
        else if (f.type == 4) f.value.value = [f.value.min, f.value.max];
      });
      this.doSearch(ASKFOR_FILTERS);
    },
    _filterChange(bReloadResults, selectedValue) {
      this.sendAnalytics(
        (selectedValue.selected ? "Włączył " : "Wyłączył ") + "filtr",
        selectedValue.value,
        false
      );
      this.filterChange(bReloadResults);
    },
    filterChange(bReloadResults) {
      this.filterAnim = !bReloadResults;
      clearTimeout(this.filterChangeTimer);
      this.filterChangeTimer = setTimeout(() => {
        this.doSearch(bReloadResults ? ASKFOR_FILTERS | ASKFOR_LIST : ASKFOR_FILTERS);
      }, 1000);
    },
    formatterInt(value) {
      return value.replace(/\D/g, "");
    },
    keyd(e) {
      if (e.key === "Select") this.submit();
    },
    keyup() {
      if (this.currentSearchPhrase.length < 3) return;
      clearTimeout(this.searchHintTimer);
      this.searchHintTimer = setTimeout(() => {
        this.askForHints();
      }, 1000);
    },
    askForHints() {
      let self = this;
      axios
        .get(process.env.VUE_APP_BASE_API_URL + this.model.searchHintUrlAI, {
          params: {
            phrase: self.currentSearchPhrase,
            limit: 10,
          },
        })
        .then(function (response) {
          self.hints = response.data;
          if (self.hints && self.hints.length > 0) {
            self.showHints = true;
          } else {
            self.showHints = false;
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    submit() {
      if (
        this.currentSearchPhrase &&
        this.currentSearchPhrase.length > 0 &&
        this.currentSearchPhrase.length < 3
      ) {
        return;
      }
      if (this.currentSearchPhrase && this.currentSearchPhrase.length >= 3) {
        this.$router.push({
          path: this.$route.path,
          query: { phrase: this.currentSearchPhrase },
        });
      } else {
        this.$router.push({
          path: this.$route.path,
        });
      }
    },
    setNavLock() {
      this.$setNavigationLock(true);
    },
    hideModalEvent() {
      this.$setNavigationLock(false);
      this.$refs["fmodal"].hide();
    },
    sendAnalytics(action, label, interaction) {
      this.$setGAFormCustom("Sekcja: Wyniki wyszukiwania AI", action, label, interaction);
    },
  },
  watch: {
    currentSearchPhrase() {
      this.isValid =
        this.currentSearchPhrase &&
        (this.currentSearchPhrase.length == 0 ||
          this.currentSearchPhrase.length >= this.minSearchPhrase);
    },
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.setNavLock);
    this.$root.$off("bv::modal::hide", this.hideModalEvent);
    EventBus.$off("closeNavEvent", this.hideModalEvent);
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.clear-both {
  clear: both;
}
.cursor-pointer {
  cursor: pointer;
}
.grayed {
  color: gray;
}
.searchai {
  &__mobilehdr {
    .results {
      font-weight: bold;
      color: $color-light-gray;
      .phrase {
        color: $color-main;
      }
    }
    .mobile-kinds {
      padding: 1em 0;
      border-top: 1px solid $color-dark-gray;
      a {
        cursor: pointer;
        display: inline-block;
        padding: 0.5em 0;
        margin: 0 1em 0 0;
        color: $color-light-gray;
        &.selected {
          border-bottom: 2px solid $color-main;
        }
      }
    }
    .mobile-hamburger {
      display: inline-block;
      padding: 0.5em;
      border: 1px solid $color-dark-gray;
      span {
        display: block;
        background-color: $color-light-gray;
        width: 26px;
        height: 2px;
        margin: 4px auto;
        transition: all 0.3s;
        &:nth-child(1) {
          width: 29px;
        }
        &:nth-child(2) {
          width: 21px;
        }
        &:nth-child(3) {
          width: 13px;
        }
        &:nth-child(4) {
          width: 5px;
        }
      }
      transition: all 0.3s;
      &:hover {
        border-color: $color-light-gray;
        span {
          background-color: $color-main;
        }
      }
    }
  }

  &__hdr {
    .row {
      height: 9em;
      align-items: center;
    }

    label {
      font-size: inherit;
      margin-right: 1em;
      > i {
        font-size: 1.4em;
        margin-right: 1em;
      }
    }

    &__input {
      background-color: $color-black !important;
      border-bottom: 1px solid gray;
      height: 2.8em;
      margin-right: 1em;
      width: 25em !important;
    }

    &__outerinput {
      position: relative;
    }

    &__hints {
      position: absolute;
      z-index: 200;
      left: 0;
      top: 3.5em;
      width: 25em;
      border: 1px solid $color-main;
      background-color: $color-black;
      padding: 0.5em;
      &__hint {
        cursor: pointer;
      }
    }

    .invalid-feedback {
      position: absolute;
    }

    &__button {
      i,
      span {
        vertical-align: middle;
      }
    }

    &__c1 {
      min-width: 800px;
      @include media-breakpoint-down(sm) {
        min-width: 600px;
      }
      @include media-breakpoint-down(xs) {
        min-width: unset;
      }
    }

    &__viewc {
      text-align: right;
      max-width: 554px;
      @include media-breakpoint-down(xs) {
        text-align: left;
      }
      a {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(xs) {
      &__form .form-inline {
        width: 100%;
      }
      .row {
        flex-direction: column;
      }
      &__outerinput {
        flex-grow: 1;
      }
      label {
        display: none;
      }
      &__viewc {
        order: 1;
        padding: 1em 0;
        flex-grow: unset;
      }
      &__c1 {
        order: 2;
      }
      &__button {
        padding-left: 0;
        padding-right: 0;
        span {
          display: none;
        }
        i {
          margin-left: 0.4em !important;
          margin-right: 0.4em !important;
        }
      }
      &__input {
        padding-left: 0px;
        padding-right: 0px;
        width: 98% !important;
      }
    }
  }
}

.aifilters {
  color: $color-main;
  max-width: 300px;
  min-width: 190px;
  flex: 0 0 30%;
  .buttons-mobile {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $body-bg;
  }

  .mobile-btn {
    width: 10em;
  }

  &__show-more {
    font-size: 0.75em;
    float: right;
    &:hover {
      text-decoration: underline;
    }
  }

  &__spinner {
    display: none;
    position: absolute;
    left: -5px;
    top: -10px;
    bottom: 0;
    right: 0;
    background-color: rgba(29, 29, 29, 0.8);
    z-index: 2;
    text-align: center;
    &.show {
      display: block;
    }
    > span {
      position: relative;
      top: 50%;
      color: white;
    }
  }

  &__section {
    &.strike {
      border-bottom: 1px solid $color-dark-gray;
    }
    line-height: 0.1em;
    font-size: 0.8em;
    color: $color-text-inactive;
    margin-bottom: 2em;
    span:nth-child(1) {
      background-color: $body-bg;
      padding-right: 1em;
    }
    span:nth-child(2) {
      display: inline-block;
      float: right;
      background-color: $body-bg;
    }
    button {
      position: relative;
      top: -1em;
      padding-left: 1em;
      padding-right: 1em;
      margin-left: 1em;
    }
  }
  &__header {
    padding: 0.1em 0;
    position: sticky;
    top: calc(67px);
    background: red;
    z-index: 100;
    background-color: #1d1d1d;
    margin-bottom: 0.4em;
    .aifilters__section {
      display: flex;
      justify-content: space-between;
      line-height: 100%;
      border: none;
      span:nth-child(1) {
        display: flex;
        align-items: center;
      }
      span:nth-child(2) {
        float: none;
        .btn {
          position: relative;
        }
      }
      &:before {
        content: " ";
        height: 1px;
        background: $color-dark-gray;
        position: absolute;
        width: 100%;
        z-index: -1;
        top: 50%;
      }
    }
  }
  &__info {
    color: $color-light-gray;
    font-size: 0.8em;
  }
  &__fdivider {
    border-bottom: 1px solid $color-dark-gray;
    margin: 1em 0;
  }
  &__fname {
    font-weight: bold;
    font-size: 0.8em;
  }
  .custom-control-inline {
    display: flex;
    margin: 0.5em 0;
    label {
      line-height: 2;
    }
  }
  .range-from {
    display: inline-block;
    width: 5em;
  }
  .range-to {
    display: inline-block;
    width: 5em;
  }
  .clear {
    text-decoration: underline;
    cursor: pointer;
  }
}

.records {
  width: 300px;
  .sortdropdown > .btn {
    min-width: 260px;
    &:after {
      margin-left: 0.75em;
    }
  }
  .record {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .records-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .dropdown {
      .btn-secondary {
        font-family: "knile";
        font-style: normal;
        font-weight: 600;
        font-size: em(16);
        line-height: 24px;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
        flex: none;
        text-transform: none;
        padding: em(16) em(24);
        background: transparent;
        &:hover {
          border-color: white;
        }
      }
      .dropdown-menu {
        width: 100%;
        background: black;
        padding: 16px;
        .dropdown-item {
          color: white;
          padding: 12px 8px;
          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
  .resultBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .results {
    font-weight: bold;
    color: $color-text-inactive;
    font-size: 1.2em;
    .phrase {
      color: $color-main;
    }
  }
  .kind-divider {
    @include media-breakpoint-up(sm) {
      font-size: 0.8em;
    }
    border-bottom: 1px solid $color-dark-gray;
    line-height: 0.1em;
    color: $color-text-inactive;
    margin: 3em 0 2em 0;
    span:nth-child(1) {
      background-color: $body-bg;
      padding-right: 1em;
    }
  }
  .button {
    background-color: inherit;
    text-transform: none;
    font-weight: normal;
  }
}

.pagination {
  .page-item {
    position: relative;
    &[role="separator"] {
      display: none;
    }
    &:before {
      pointer-events: none;
      position: absolute;
      color: white;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    &:hover {
      &:before {
        color: #d9d9d9;
      }
    }
    .page-link {
      height: 35px;
      background: transparent;
      border: 2px solid;
      border-color: transparent;
      padding: 0rem 0.75rem;
    }
    &.active {
      .page-link {
        border-color: $color-main;
      }
    }
    &.custom {
      .page-link {
        color: transparent !important;
      }
    }
    &.disabled {
      &:before {
        color: #6c757d;
      }
    }
  }
}
.filterAnim {
  animation-name: filterAnim;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
@keyframes filterAnim {
  0% {
    background: #fff;
    border-color: #fff;
    color: #212529;
  }
  50% {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  100% {
    background: #fff;
    border-color: #fff;
    color: #212529;
  }
}
.Tizen {
  .searchai__hdr__input {
    width: 18em !important;
  }
  .searchai__mobilehdr {
    display: block !important;
    .py-1 .focus-smart {
      display: block;
    }
    .mobile-hamburger {
      &::before {
        bottom: 5px;
      }
    }
  }
  .aifilters {
    display: none !important;
  }
}
.ai-pop {
  font-size: 1.83em;
  .aifilters {
    max-width: 90% !important;
  }
  .aifilters__show-more {
    float: none;
  }
  .custom-control {
    position: relative;
    label {
      &::before,
      &:after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:has(.smarttv-focused) {
      &:before {
        content: " ";
        position: absolute;
        border: 2px solid white;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
      }
    }
  }
  .smarttv-focused:not(label) {
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      border: 2px solid white;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
    }
  }
  input {
    border: 1px solid transparent;

    &.smarttv-focused {
      border-color: white;
    }
  }
  .buttons-mobile {
    position: static;
    display: flex;
    justify-content: space-between;
  }
  .close {
    font-size: 1.5em;
  }
}
</style>

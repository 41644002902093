<!------------------------------------------
Komonent z metadanymi filmu i filtrami, sekcja
po prawej (w szerokościach desktop) na stronie 
wyszukiwania AI w filmie.
-------------------------------------------->
<template>
  <b-container fluid class="meta">
    <b-row>
      <movie-meta-title :model="model" class="d-none d-md-block" />
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <accordion
          v-for="(item, index) in model.filters"
          :key="index"
          :tabId="item.code + index"
          :accordionName="item.code + index"
          class="acordionAI meta__filter py-3 mb-2"
          :collapsed="!accordionExpanded"
          :expandedByDefault="true"
        >
          <template v-slot:header>
            <span class="meta__filter__name"
              >{{ item.caption }} ({{ item.values.length }})</span
            >
            <i class="cil-chevron-bottom-alt"></i>
            <i class="cil-chevron-left-alt"></i>
          </template>
          <template v-slot:main>
            <div v-if="item.values.some(x=>x.withImage == true)" class="cast-filter">
              <carousel-container
                :model="castSliderOptions"
                :translations="model.translations"
                :itemList="item.values"  
                class="d-lgx-none"              
              >                
                <template v-slot:main>
                  <splide-slide
                    v-for="(fitem, findex) in getFilterValuePairs(item.values)"
                    :key="findex"
                  >
                  
                    <div                                         
                      class="meta__filter__postercheck focus-smart"                                    
                      @click="fitem[0].isSelected = !fitem[0].isSelected;filterChange()"
                      >
                        <div 
                          class="poster d-inline-block align-middle cursor-pointer"
                          :style="'background-image: url(' + getPoster(fitem[0].imageUrl) + ')'"                    
                        >
                          <div>
                            <b-form-checkbox 
                            class="disable"
                              v-model="fitem[0].isSelected"                                  
                            >
                            </b-form-checkbox>
                          </div>
                        </div>
                        <div 
                          class="plabel d-inline-block align-middle cursor-pointer"               
                        >
                        <div>{{ formatCast(fitem[0].name) }} ({{ fitem[0].counter }})</div>                  
                      </div>                              
                    </div>  

                    <div                                         
                      v-if="fitem[1]"
                      class="meta__filter__postercheck focus-smart"                                    
                      @click="fitem[1].isSelected = !fitem[1].isSelected;filterChange()"
                      >
                        <div 
                          class="poster d-inline-block align-middle cursor-pointer"
                          :style="'background-image: url(' + getPoster(fitem[1].imageUrl) + ')'"                    
                        >
                          <div>
                            <b-form-checkbox 
                            class="disable"
                              v-model="fitem[1].isSelected"                                  
                            >
                            </b-form-checkbox>
                          </div>
                        </div>
                        <div 
                          class="plabel d-inline-block align-middle cursor-pointer"               
                        >
                        <div>{{ formatCast(fitem[1].name) }} ({{ fitem[1].counter }})</div>                  
                      </div>                              
                    </div>                      
                  </splide-slide>
                </template>
              </carousel-container>
              <div class="d-none d-lgx-block">
                <div               
                v-for="(fitem, findex) in item.values.slice(0, getFilterItemCount(true,item))"                
                class="meta__filter__postercheck focus-smart"              
                :key="findex"              
                @click="fitem.isSelected = !fitem.isSelected;filterChange()"
                >
                  <div 
                    class="poster d-inline-block align-middle cursor-pointer"
                    :style="'background-image: url(' + getPoster(fitem.imageUrl) + ')'"                    
                  >
                    <div>
                      <b-form-checkbox 
                      class="disable"
                        v-model="fitem.isSelected"                                  
                      >
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div 
                    class="plabel d-inline-block align-middle cursor-pointer"               
                  >
                    <div>{{ formatCast(fitem.name) }} ({{ fitem.counter }})</div>                  
                  </div>                              
                </div>  
                <a
                  class="cursor-pointer meta__filter__show-more posters focus-smart"
                  @click="expandFilter(true,item)"
                  v-show="item.values.length > getFilterItemCount(true,item)"
                >
                  {{ model.translations["general.ShowMore"] }}
                </a>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(fitem, findex) in item.values.slice(0, getFilterItemCount(false,item))"
                class="meta__filter__check"                
                :key="findex"
              >
                <b-form-checkbox 
                  v-model="fitem.isSelected"
                  @change="filterChange"
                >
                  {{ fitem.name }} ({{ fitem.counter }})
                </b-form-checkbox>
              </div>
              <a
                class="cursor-pointer meta__filter__show-more focus-smart"
                @click="expandFilter(false,item)"
                v-show="item.values.length > getFilterItemCount(false,item)"
              >
                {{ model.translations["general.ShowMore"] }}
              </a>            
            </div>
          </template>
        </accordion>
      </b-col>
    </b-row>
    <b-row v-if="model.tags">
      <b-col class="mt-4 meta__tags p-3">
        <div class="color-dim meta__tags__hdr mb-2">
          {{ model.translations["moviePage.Tags"] }}
        </div>
        <span>{{ model.tags.join(", ") }}</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import Accordion from "../ListingView/Accordion.vue";
import MovieMetaTitle from "./MovieMetaTitle.vue";
import CarouselContainer from "../ListingView/CarouselContainer.vue";
import { SplideSlide } from "@splidejs/vue-splide";
export default {
  name: "MovieMeta",
  components: {
    //----------------    
    //Akordeon dla rozwijalnych filtrów
    //----------------
    Accordion,

    //----------------    
    //Komponent z nagłowkiem filmu (tytuł, rezyser, fraza wyszukiwania itd)
    //----------------
    MovieMetaTitle,

    //----------------
    //Części slidera z filtrem obsady
    //----------------    
    SplideSlide,    
    CarouselContainer,
  },
  props: {
    //----------------
    //Model backend: AssetViewModelAI
    //----------------
    model: Object,
  },
  data() {
    return {
      //liczna pozycji filtrów przed rozwinięciem 'pokaz wiecej'
      filterShowCount: 12,

      //liczna pozycji filtrów przed rozwinięciem 'pokaz wiecej' - dla filtra obsady
      filterCastShowCount: 6,

      //timer, opoznienie po zmianie filtrów, przez zapytaniem api
      filterChangeTimer: null,

      //boolean: akordeony w wersji rozwiniętej (dla app tv)
      accordionExpanded: false,

      //opcje slidera z filtrem obsady
      castSliderOptions: {
        rewind: false,
        pagination: false,
        type: "posters",
        autoplay: false,
        pauseOnHover: false,
        interval: 5000,
      },
    };
  },
  mounted() {
    this.accordionExpanded = this.$isTizen(this.$browserDetect);
  },
  methods: {
    getFilterValuePairs(values) {
      return values.reduce(function (rows, key, index) { 
                return (index % 2 == 0 ? rows.push([key]) 
                  : rows[rows.length-1].push(key)) && rows;
              }, []);
    },
    formatCast(s) {      
      return s.trim().replace(/ /, '\n');
    },
    getPoster(url) {
      return url ? url : "/assets/placeholder_ai.png";
    },
    getFilterItemCount(isCastFilter, f) {
      return f.showCount ? f.showCount : (isCastFilter ? this.filterCastShowCount:this.filterShowCount);
    },
    expandFilter(isCastFilter, f) {
      f.showCount = this.getFilterItemCount(isCastFilter, f) + (isCastFilter ? this.filterCastShowCount:this.filterShowCount);
      let x = f.caption;
      f.caption = "";
      f.caption = x;
    },
    clearSearchPhrase() {
      let qry = Object.assign({}, this.$route.query);
      delete qry.search;
      this.$router.push({ path: this.$route.path, query: qry });
    },
    getFiltersJson() {
      let filtersToSend = this.model.filters
        .filter((f) => f.values.filter((i) => i.isSelected == true).length > 0)
        .map((f) => {
          return {
            id: f.code,
            filter: f.values.filter((i) => i.isSelected == true).map((i) => i.key),
          };
        });
      return filtersToSend;
    },
    filterChange() {
      clearTimeout(this.filterChangeTimer);
      this.filterChangeTimer = setTimeout(() => {
        this.reload();
      }, 1000);
    },
    reload() {
      let self = this;
      let _filters = this.getFiltersJson();
      let _phrase = self.model.searchPhrase
        ? encodeURIComponent(self.model.searchPhrase)
        : "";
      self.$emit("set-busy", true);
      axios
        .post(
          `${process.env.VUE_APP_BASE_API_URL}${this.model.movieSearchUrl}/?id=${self.model.id}&phrase=${_phrase}&kind=${self.model.kind}`,
          {
            filters: _filters,
          }
        )
        .then(function (response) {
          self.$emit("on-data", response);
          self.$emit("set-busy", false);
        })
        .catch(function (e) {
          console.log(e);
          self.$emit("set-busy", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.meta {  
  .d-lgx-block {
    @media (min-width: 1400px) {
      display: block !important;
    }
  }

  .d-lgx-none {
    @media (min-width: 1400px) {
      display: none !important;
    }
  }

  .border-btm {
    border-bottom: 1px solid $color-gray;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  &__filter {
    border-bottom: 1px solid $color-dark-gray;
    border-radius: 0;
    &__name {
      font-weight: bold;
    }
    &__check {
      display: inline-block;
      text-align: left;
      padding: 0.4em 0.6em;
      margin: 1em 1em 1em 0;
      background-color: $color-dark-gray;
      border-radius: 3px;
      border: 1px solid $color-dark-gray;
      transition: all 0.3s;
      &:hover {
        border-color: white;
      }      
      label {        
        text-align: left;
        font-size: 1em;
        &::before {
          border: 1px solid $color-divider;
          background-color: unset;
        }
        &::after, &::before {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &__show-more {
      display: inline-block;
      padding: 0.4em 0.6em;
      &:hover {
        text-decoration: underline;
      }
      &.posters {    
        padding: 0;                  
        min-width: 250px;
        margin: 1em 0;
      }
    }
    &__postercheck {
      position: relative;
      display: inline-block;
      margin: 1em 1.3em 1em 0;

      .poster {
        width: 4em;
        height: 5em;
        border: 1px solid $color-gray;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 100% auto;
        position: relative;
        >div {
          position: absolute;
          bottom: -1px;
          left: -1px;  
          width: 1.4em;
          height: 1.4em;
          background-color: $body-bg;
          border-radius: 3px;
          pointer-events: none;
          
          .custom-checkbox {
            position: static !important;
            label {
              position: static !important;              
              top: 1.2em;
              left: -1px;
              &:before {
                border: 1px solid $color-divider;
              }
              &:before, &::after {
                top: unset !important;
                bottom: 0;
                transform: none !important;
              }              
            }          
          }          
        }
      }
      .plabel {
        white-space: pre-line;
        margin-left: 0.5em;                
        width: 125px;
        @include media-breakpoint-down(sm) {
          width: 100px;          
        }
      }
    }    
  }
  &__tags {
    border-radius: 2px;
    background-color: $color-dark-gray;
    &__hdr {
      font-weight: bold;
    }
  }

  .cast-filter .carousel {    
    .splide {
      display: block;
    }
    .splide__list {
      padding: 0 !important;
    }
  }
}
</style>

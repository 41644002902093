export default {
    methods: {
        alertUpdated(selector) {            
            var content = this.$el.querySelector(selector);
            if (content) {                
                var body = document.body;
                content.parentNode.removeChild(content);
                body.appendChild(content);
            }
        },
        alertDestroy(selector) {            
            var content = document.body.querySelector(selector);
            if (content) {
                document.body.removeChild(content);
            }
        },
    }
}
<template>
  <b-card no-body>
    <b-card-header header-tag="header" v-if="haveHeader">
      <b-button
        block
        v-b-toggle="localTabId"
        tabindex="0"
        class="accordion-btn"
        v-if="collapsed"
      >
        <slot name="header"></slot>
      </b-button>
      <p block tabindex="0" class="accordion-btn not-collapsed" v-else>
        <slot name="header"></slot>
      </p>
    </b-card-header>
    <b-collapse
      :id="localTabId"
      :accordion="accordionName"
      v-if="this.$slots['main']"
      :visible="expandedByDefault"
    >
      <b-card-body>
        <b-card-text>
          <slot name="main"></slot>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  props: {
    accordionName: String,
    tabId: String,
    collapsed: { default: true, type: Boolean },
    expandedByDefault: Boolean(false),
  },
  data() {
    return {
      show: true,
      haveHeader: false,
    };
  },
  mounted() {
    this.haveHeader = this.$slots.header ? true : false;
  },
  updated() {
    this.haveHeader = this.$slots.header ? true : false;
  },
  computed: {
    localTabId() {
      return this.tabId.replace(/ /g, '#');
    }
  }
};
</script>

<style lang="scss">
.acordionAI {
  &.card {
    background-color: unset;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: none;
    .card-header {
      border-radius: unset;
      padding: 0;
      margin: 0;
      border: none;
      background-color: unset;
      i {
        float: right;
        margin-top: 0.2em;
      }
      .accordion-btn {
        text-transform: unset;
        padding: unset;
        text-align: unset;
        background-color: unset;
        border: none;
      }

      .collapsed .cil-chevron-bottom-alt {
        display: none;
      }
      .not-collapsed .cil-chevron-left-alt {
        display: none;
      }

      button {
        font-family: unset;
        font-weight: unset;
      }
    }
    .card-body {
      padding: 0.8em 0 0 0;
    }
  }
}
</style>

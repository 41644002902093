<template>
  <grid :className="model.options.type">
    <template v-slot:header>
      <h2 v-if="model.header" :class="{ 'sr-only': model.hideTitle }">
        <b-link
          v-if="model.headerUrl && canRoute()"
          :to="model.headerUrl"
          tabindex="0"
          @click.native="sendAnalytics('Pokaż więcej')"
          class="focus-smart"
        >
          <div class="nameSection">{{ model.header }}</div>
          <div>
            <div class="showAll">{{ translations["general.ShowMore"] }}</div>
            <i class="cil-chevron-right-alt"></i>
          </div>
        </b-link>
        <div v-else>
          <div class="nameSection">{{ model.header }}</div>
        </div>
      </h2>
      <p class="descriptionItems" v-if="model.description" v-html="model.description"></p>
    </template>
    <template v-slot:main>
      <list-item
        :model="item"
        :box-view="true"
        v-for="(item, index) in model.items"
        :key="index"
        :section="'Sekcja: ' + model.header"
        :alternative="true"
        displayType="grid"
        :translations="translations"
      ></list-item>
    </template>
  </grid>
</template>

<script>
import ListItem from "@/components/Lists/ListItem";
import Grid from "@/components/ListingView/Grid";
export default {
  name: "HomeModuleX2",
  components: {
    ListItem,
    Grid,
  },
  props: {
    model: Object,
    translations: Object,
  },
  methods: {
    canRoute() {
      return !(
        this.$route.fullPath == this.model.headerUrl ||
        this.$route.fullPath == this.model.headerUrl + "/#"
      );
    },
  },
};
</script>

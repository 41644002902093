<template>
  <div class="add-playlist" :id="'a' + episodeId">
    <modal
      :show="showAdd && !showCreate"
      header="playlistadd"
      :title="translations['soundLibrary.AddToPlaylist']"
      closeText="translations['general.Close']"
      :hideClose="true"
      @hidePop="close"
      :no-fade="true"
      v-if="mobileMode || forceModal"
    >
      <template v-slot:main>
        <div class="add-playlist__list">
          <b-button
            class="add-playlist__playlist"
            v-for="(item, index) in playlists"
            :key="index"
            @click="addItem(item.id, episodeId)"
          >
            <div
              class="left"
              :style="
                'background-image: url(' +
                (item.imageUrl ? item.imageUrl : '/assets/placeholder.png') +
                ')'
              "
            >
              <span>{{ firstLetter(item) }}</span>
            </div>
            <p>{{ item.name }}</p>
          </b-button>
        </div>
      </template>
      <template v-slot:btn>
        <div class="flex-buttons">
          <b-button @click="close">{{ translations["general.Close"] }}</b-button>
          <b-button variant="primary" @click="showCreate = true">{{
            translations["soundLibrary.Add"]
          }}</b-button>
        </div>
      </template>
    </modal>
    <div class="add-playlist__list full" v-else>
      <h3>{{ translations["soundLibrary.AddToPlaylist"] }}</h3>
      <b-button
        class="add-playlist__playlist"
        v-for="(item, index) in playlists"
        :key="index"
        @click="addItem(item.id, episodeId)"
        :id="'b' + item.id"
      >
        <div
          class="left"
          :style="
            'background-image: url(' +
            (item.imageUrl ? item.imageUrl : '/assets/placeholder.png') +
            ')'
          "
        >
          <span>{{ firstLetter(item) }}</span>
        </div>
        <p>{{ item.name }}</p>
      </b-button>
      <b-button @click="close">{{ translations["general.Close"] }}</b-button>
      <b-button :id="'b' + episodeId" variant="primary" @click="showCreate = true">{{
        translations["soundLibrary.CreatePlaylist"]
      }}</b-button>
    </div>
    <create-playlist
      v-if="showCreate"
      :translations="translations"
      @close="refresh"
    ></create-playlist>
  </div>
</template>
<script>
import modal from "./modal.vue";
import PlaylistService from "../../services/playlistService";
import CreatePlaylist from "./CreatePlaylist.vue";
import { EventBus } from "@/services/event-bus";
export default {
  components: { modal, CreatePlaylist },
  props: {
    episodeId: Number,
    translations: Object,
    forceModal: Boolean,
  },
  data() {
    return {
      showAdd: false,
      playlists: [],
      showCreate: false,
      mobileMode: window.innerWidth < 1199.5,
    };
  },
  mounted() {
    this.getPlaylist();
    this.showAdd = true;
  },
  methods: {
    addItem(playlistId, nodeId) {
      PlaylistService.add(playlistId, nodeId).then((x) => {
        if (x == true) {
          this.close();
          EventBus.$emit("pl-added", playlistId);
        } else alert(x);
      });
    },
    close() {
      this.$emit("close");
      this.showAdd = false;
    },
    getPlaylist(name) {
      const name1 = name;
      PlaylistService.getAll().then((response) => {
        this.playlists = response;
        var item;
        if (this.playlists.length > 0) {
          this.playlists?.forEach((element) => {
            element.id = element.id.replaceAll("-", "");
          });
          if (name) item = this.playlists.find((x) => x.name == name1)?.id;
          if (!item) item = this.playlists[0]?.id;
        } else item = { id: this.episodeId };
        var selector = "#a" + this.episodeId + " #b" + item;
        setTimeout(() => {
          window.RedgalaxyNav.focusElement(selector);
        }, 100);
      });
    },
    firstLetter(item) {
      return item.name[0];
    },
    refresh(name) {
      this.showCreate = false;
      this.getPlaylist(name);
    },
  },
};
</script>
<style lang="scss">
.add-playlist__list {
  margin-bottom: 24px;
  .add-playlist__playlist {
    display: flex;
    padding: 8px;
    margin: 0;
    height: unset;
    min-height: unset;
    background: #252525;
    border: none;
    margin-bottom: 8px;
    .left {
      position: relative;
      margin-right: 16px;
      width: 71px;
      height: 40px;
      background: center / cover no-repeat;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    p {
      margin: 0px;
      flex-grow: 1;
      text-align: left;
      font-family: "Knile";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;
      padding: 9px 0;
    }
  }
  &.full {
    margin: 24px 16px;
    h3 {
      margin-bottom: 16px;
    }
    .add-playlist__playlist {
      width: 100%;
      background: rgba(255, 255, 255, 0.1);
      margin: 4px 0;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .btn {
      &:last-child,
      &:nth-last-child(2) {
        margin-top: 16px;
      }
      &:last-child {
        margin-left: 16px;
      }
    }
  }
}
</style>

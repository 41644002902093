<template>  
  <b-container class="color--basic lgcode mt-5">
    <b-row>
      <b-col class="text-center">
        <h1 class="mt-5">
          {{ model.translations["lgCode.PageTitle"] }}
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span v-html="model.info"></span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form @submit.stop.prevent="submit">
          <b-container>
            <b-row class="my-4">
                <b-col>
                  <b-form-input
                      name="code_ch1"
                      id="code_ch1" 
                      class="code-input"                            
                      maxlength="1"
                      @keyup="gotoNextCtrl"
                      v-model="$v.state.code_ch1.$model"                                    
                      aria-describedby="error-lgcodechar"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                      name="code_ch2"
                      id="code_ch2"
                      ref="code_ch2"
                      class="code-input"
                      maxlength="1"
                      @keyup="gotoNextCtrl"
                      v-model="$v.state.code_ch2.$model"                      
                      aria-describedby="error-lgcodechar"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                      name="code_ch3"
                      id="code_ch3"
                      ref="code_ch3"
                      class="code-input"
                      maxlength="1"
                      @keyup="gotoNextCtrl"
                      v-model="$v.state.code_ch3.$model"
                      aria-describedby="error-lgcodechar"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                      name="code_ch4"
                      id="code_ch4"
                      ref="code_ch4"
                      class="code-input"
                      maxlength="1"
                      @keyup="gotoNextCtrl"
                      v-model="$v.state.code_ch4.$model"                      
                      aria-describedby="error-lgcodechar"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                      name="code_ch5"
                      id="code_ch5"
                      ref="code_ch5"
                      class="code-input"
                      maxlength="1"
                      @keyup="gotoNextCtrl"
                      v-model="$v.state.code_ch5.$model"                      
                      aria-describedby="error-lgcodechar"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                      name="code_ch6"
                      id="code_ch6"
                      ref="code_ch6"
                      class="code-input"
                      maxlength="1"
                      @keyup="gotoNextCtrl"
                      v-model="$v.state.code_ch6.$model"
                      aria-describedby="error-lgcodechar"
                  ></b-form-input>
                </b-col>                                                                
            </b-row>            
            <b-row>
              <b-col class=" mt-5 text-center">
                <b-button
                    type="submit"
                    variant="primary"
                    class="button--large"
                    ref="savebtn"                   
                    :disabled="!isCodeEntered()"
                    >{{ model.translations["general.Save"] }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </b-col>
    </b-row>    
    <modal
      header="profile"
      :title="getSendResults()"
      :show="showSendResults"
      :closeText='model.translations["general.Close"]'
      @hidePop="closeModal"
    >
    </modal>        
  </b-container>  
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import modal from "../components/common/modal.vue";
import axios from "axios";
export default {
  name: "LgcodePage",
  mixins: [ValidationMixin],
  components: { 
    modal,  
  },
  props: {
    model: Object,
  },
   data() {    
    return {
      sendResults: 0,
      showSendResults: false,
      state: {
        code_ch1: null,
        code_ch2: null,
        code_ch3: null,
        code_ch4: null,
        code_ch5: null,
        code_ch6: null,        
      }
    }
  },
  mounted() {
  },
  methods: {
    gotoNextCtrl(e) {
      let elemId = parseInt(e.srcElement.id[e.srcElement.id.length - 1]);      
      if (e.key && e.key.length==1 && e.srcElement.value) {
        if (elemId < 6) {
          this.$refs["code_ch" + (parseInt(elemId) + 1)].focus();
        }
        else if (this.isCodeEntered()) {                                    
          this.$refs["savebtn"].focus();
        }
      }
   
    },
    isCodeEntered () {
      return this.state.code_ch1
              && this.state.code_ch2
              && this.state.code_ch3
              && this.state.code_ch4
              && this.state.code_ch5
              && this.state.code_ch6;      
    },
    getSendResults() {
      switch(this.sendResults) {
          case 1:
            return this.model.translations["lgCode.Success"];            
          case -1:
            return this.model.translations["lgCode.Error"];            
          case -2:
            return this.model.translations["lgCode.Error400"];                      
          default:
            return null;
      }      
    },      
    closeModal() {
      this.showSendResults = false;
    },
    submit() {      
      this.showSendResults = false;
      if (this.isCodeEntered())
      {
          let toSend = this.state.code_ch1
              + this.state.code_ch2
              + this.state.code_ch3
              + this.state.code_ch4
              + this.state.code_ch5
              + this.state.code_ch6;                    
          let self = this;
          axios
            .put(process.env.VUE_APP_BASE_API_URL + this.model.deviceCodeApi, {code: toSend})
            .then(function (response) {                            
              self.showSendResults = true;              
              if (response.status == 200 || response.status == 204) {                
                self.sendResults = 1;
              }
              else if (response.status == 400) {
                self.sendResults = -2;
              }
              else {
                self.sendResults = -1;
              }
            })
            .catch(() => {                  
              this.showSendResults = true;
              this.sendResults = -1;
            });

      }
    }
  },
  validations: {
    state: {
      code_ch1: {  },
      code_ch2: {  },
      code_ch3: {  },
      code_ch4: {  },
      code_ch5: {  },
      code_ch6: {  },
    }
  }
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.lgcode {  
  width: 90%;
  max-width: 60em;
  .code-input {
    margin: 0 auto;
    font-size: 1.5em;
    width: 90%;
    max-width: 5em;
    text-align: center;
  }  
}
</style> 
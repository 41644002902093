<template>
  <div>
    <!-- typ licencji -->
    <radio-buttons
      :header="model.translations['proOrder.TypeLicense']"
      :itemList="model.frameLicense"
      :select="currentLicense.licenseType"
      :readonly="readonly"
      name="licenseType"
      @ChangeValue="getValue"
    ></radio-buttons>
    <!-- terrytorium -->
    <transition name="slide-fade">
      <territory
        v-show="currentLicense.licenseType"
        :header="model.translations['proOrder.Territory']"
        :translations="model.translations"
        :countriesList="model.countries"
        :continentsList="model.continents"
        :selectedContinents="currentLicense.continent"
        :selectedCountries="currentLicense.countries"
        :selectedCheck="currentLicense.checkedFiled"
        :readonly="readonly"
        @ChangeValue="getValue"
      >
      </territory>
    </transition>
    <div v-if="currentLicense.licenseType == 'usecase.internet'">
      <transition name="slide-fade">
        <radio-buttons
          v-show="isTerritorySelected"
          :itemList="model.publishSubject"
          name="publishSubject"
          :header="model.translations['proOrder.PublishSubject']"
          :select="currentLicense.publishSubject"
          :input="currentLicense.publishSubjectInput"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons></transition
      ><transition name="slide-fade">
        <radio-buttons
          v-show="
            currentLicense.publishSubject &&
            currentLicense.publishSubject.length > 0
          "
          :itemList="model.partPublishingType"
          name="publishingType"
          :header="model.translations['proOrder.PartPublishingType']"
          :select="currentLicense.publishingType"
          :input="currentLicense.publishingTypeInput"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.publishingType"
          :itemList="model.publishPlace"
          name="publishPlace"
          :header="model.translations['proOrder.PublishPlace']"
          :select="currentLicense.publishPlace"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
          <inputs
            v-if="!readonly"
            v-show="currentLicense.publishPlace"
            :disabled="!currentLicense.publishPlace"
            :label="model.translations['proOrder.UrlAdress']"
            :placeholder="model.translations['proOrder.EnterAdress']"
            name="urls"
            @ChangeValue="getValue"
            :selected="currentLicense.urls"
            :buttonText="model.translations['proOrder.AddNext']"
          ></inputs>
          <div v-if="readonly">
            <div v-for="(item, index) in currentLicense.urls" :key="index">
              <span class="pro-readonly-value tags">{{ item }}</span>
            </div>
          </div>
        </radio-buttons>
      </transition>
      <transition name="slide-fade">
        <radio-buttons
          v-show="
            currentLicense.publishPlace &&
            currentLicense.urls &&
            currentLicense.urls.find((x) => x)
          "
          :itemList="model.accessType"
          name="accessType"
          :header="model.translations['proOrder.Access']"
          :select="currentLicense.accessType"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
    </div>
    <div v-if="currentLicense.licenseType == 'usecase.paper'">
      <transition name="slide-fade">
        <tag-filter
          v-show="isTerritorySelected"
          :tagList="model.lanugages"
          :header="model.translations['proOrder.PublicationLanguage']"
          :translations="model.translations"
          @ChangeValue="getValue"
          :selectedTag="currentLicense.language"
          :readonly="readonly"
          name="language"
        ></tag-filter>
      </transition>
      <transition name="slide-fade">
        <input-pro
          v-show="currentLicense.language && currentLicense.language.length > 0"
          :header="model.translations['proOrder.Circulation']"
          name="count"
          :placeholder="model.translations['proOrder.EnterNumber']"
          type="number"
          :input="currentLicense.count"
          :readonly="readonly"
          @ChangeValue="getValue"
        ></input-pro>
      </transition>
      <transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.count"
          :header="model.translations['proOrder.PublishType']"
          :itemList="model.framePublishType"
          :select="currentLicense.publishType"
          :input="currentLicense.publishTypeInput"
          :readonly="readonly"
          name="publishType"
          @ChangeValue="getValue"
        ></radio-buttons>
      </transition>
      <transition name="slide-fade">
        <radio-buttons-extended
          v-show="currentLicense.publishType"
          :header="model.translations['proOrder.Distribution']"
          :itemList="model.frameDistribution"
          :select="currentLicense.distribution"
          :readonly="readonly"
          name="distribution"
          @ChangeValue="getValue"
        ></radio-buttons-extended>
      </transition>
      <transition name="slide-fade">
        <radio-buttons-extended
          v-show="
            currentLicense.distribution &&
            currentLicense.distribution.length > 0
          "
          :header="model.translations['proOrder.PublishSubject']"
          :itemList="model.framePublishSubject"
          :select="currentLicense.publishSubject"
          :input="currentLicense.publishSubjectInput"
          :readonly="readonly"
          name="publishSubject"
          @ChangeValue="getValue"
        ></radio-buttons-extended>
      </transition>
    </div>
    <div v-if="currentLicense.licenseType == 'usecase.poster'">
      <transition name="slide-fade">
        <tag-filter
          v-show="isTerritorySelected"
          :tagList="model.lanugages"
          :header="model.translations['proOrder.PublicationLanguage']"
          :translations="model.translations"
          :readonly="readonly"
          @ChangeValue="getValue"
          :selectedTag="currentLicense.language"
          name="language"
        ></tag-filter>
      </transition>
      <transition name="slide-fade">
        <input-pro
          v-show="currentLicense.language && currentLicense.language.length > 0"
          :header="model.translations['proOrder.Circulation']"
          name="count"
          :placeholder="model.translations['proOrder.EnterNumber']"
          type="number"
          :input="currentLicense.count"
          @ChangeValue="getValue"
        ></input-pro> </transition
      ><transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.count"
          :itemList="model.framePrintType"
          name="printType"
          :header="model.translations['proOrder.TypeOfPrinting']"
          :select="currentLicense.printType"
          :readonly="readonly"
          @ChangeValue="getValue"
        >
        </radio-buttons> </transition
      ><transition name="slide-fade">
        <radio-buttons
          v-show="currentLicense.printType"
          :itemList="model.destyny"
          name="destyny"
          :header="model.translations['proOrder.Purpose']"
          :select="currentLicense.destyny"
          @ChangeValue="getValue"
        >
        </radio-buttons>
      </transition>
    </div>
    <transition name="slide-fade">
      <section-header
        :header="model.translations['proOrder.Pending']"
        v-show="
          !readonly &&
          !(
            (currentLicense.licenseType == 'usecase.internet' &&
              currentLicense.accessType) ||
            (currentLicense.licenseType == 'usecase.paper' &&
              currentLicense.publishSubject) ||
            (currentLicense.licenseType == 'usecase.poster' &&
              currentLicense.destyny)
          )
        "
      >
      </section-header>
    </transition>
    <transition name="slide-fade" v-if="readonly">
      <div>
        <section-header
          :header="model.translations['proOrder.LicenseNotes']"
        ></section-header>
        <b-container>
          <b-row class="progressBar m40 text-area">
            <b-col :sm="10">
              <div class="notes-margin">
                <b-form-group>
                  <label :for="currentLicense.notes" class="col-form-label">{{
                    model.translations["proOrder.EnterNotes"]
                  }}</label>
                  <b-form-textarea
                    v-model="currentLicense.notes"
                    size="lg"
                    maxlength="1000"
                  ></b-form-textarea>
                  <p class="chars-status text-right">
                    {{
                      currentLicense.notes ? currentLicense.notes.length : 0
                    }}
                    / 1000
                  </p>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </transition>
  </div>
</template>
<script>
import RadioButtons from "./RadioButtons.vue";
import Inputs from "./ProInput.vue";
import Territory from "./Territory.vue";
import RadioButtonsExtended from "./RadioButtonsExtended.vue";
import TagFilter from "./TagFilter.vue";
import InputPro from "./InputPro.vue";
import SectionHeader from "./SectionHeader.vue";
export default {
  components: {
    Territory,
    Inputs,
    RadioButtons,
    RadioButtonsExtended,
    TagFilter,
    InputPro,
    SectionHeader,
  },
  props: {
    model: Object,
    current: Object,
    readonly: Boolean,
  },
  data() {
    return {
      currentLicense: {
        licenseType: null,
        countries: [],
        continent: [],
        checkedFiled: [],
        accessType: null,
        publishingType: null,
        publishingTypeInput: null,
        publishPlace: null,
        publishSubject: null,
        publishSubjectInput: null,
        urls: [null],
        language: [],
        count: null,
        distribution: [],
        publishType: null,
        publishTypeInput: null,
        printType: null,
        destyny: null,
        filesFormat: [],
        notes: null,
      },
      fileFormat: [],
    };
  },
  methods: {
    getValue(value, type) {
      switch (type) {
        case "licenseType":
          this.currentLicense.licenseType = value;
          break;
        case "continent":
          this.currentLicense.continent = value;
          break;
        case "countries":
          this.currentLicense.countries = value;
          break;
        case "territory":
          this.currentLicense.checkedFiled = value;
          break;
        case "accessType":
          this.currentLicense.accessType = value;
          break;
        case "publishingType":
          this.currentLicense.publishingType = value;
          break;
        case "publishingTypeinput":
          this.currentLicense.publishingTypeInput = value;
          break;
        case "publishPlace":
          this.currentLicense.publishPlace = value;
          break;
        case "publishSubjectinput":
          this.currentLicense.publishSubjectInput = value;
          break;
        case "publishSubject":
          this.currentLicense.publishSubject = value;
          break;
        case "urls":
          this.currentLicense.urls = value;
          break;
        case "language":
          this.currentLicense.language = value;
          break;
        case "count":
          this.currentLicense.count = value;
          break;
        case "distribution":
          this.currentLicense.distribution = value;
          break;
        case "publishType":
          this.currentLicense.publishType = value;
          break;
        case "publishTypeinput":
          this.currentLicense.publishTypeInput = value;
          break;
        case "printType":
          this.currentLicense.printType = value;
          break;
        case "destyny":
          this.currentLicense.destyny = value;
          break;
        case "filesFormat":
          this.currentLicense.filesFormat = value;
      }
      this.$emit("ChangeValue", this.currentLicense, "frame");
    },
  },
  watch: {
    current: {
      handler(newValue) {
        if (newValue) {
          this.currentLicense.licenseType = newValue.licenseType;
          this.currentLicense.countries = newValue.countries;
          this.currentLicense.continent = newValue.continent;
          this.currentLicense.checkedFiled = newValue.checkedFiled;
          this.currentLicense.accessType = newValue.accessType;
          this.currentLicense.publishingType = newValue.publishingType;
          this.currentLicense.publishingTypeInput =
            newValue.publishingTypeInput;
          this.currentLicense.publishPlace = newValue.publishPlace;
          this.currentLicense.publishSubject = newValue.publishSubject;
          this.currentLicense.publishSubjectInput =
            newValue.publishSubjectInput;
          this.currentLicense.urls = newValue.urls;
          this.currentLicense.language = newValue.language;
          this.currentLicense.count = newValue.count;
          this.currentLicense.distribution = newValue.distribution;
          this.currentLicense.publishType = newValue.publishType;
          this.currentLicense.publishTypeInput = newValue.publishTypeInput;
          this.currentLicense.printType = newValue.printType;
          this.currentLicense.destyny = newValue.destyny;
          this.currentLicense.filesFormat = newValue.filesFormat;
          this.currentLicense.notes = newValue.notes;
        } else {
          this.currentLicense.licenseType = null;
          this.currentLicense.countries = [];
          this.currentLicense.continent = [];
          this.currentLicense.checkedFiled = [];
          this.currentLicense.accessType = null;
          this.currentLicense.publishingType = null;
          this.currentLicense.publishingTypeInput = null;
          this.currentLicense.publishPlace = null;
          this.currentLicense.publishSubject = null;
          this.currentLicense.publishSubjectInput = null;
          this.currentLicense.urls = [null];
          this.currentLicense.language = [];
          this.currentLicense.count = null;
          this.currentLicense.distribution = [];
          this.currentLicense.publishType = null;
          this.currentLicense.publishTypeInput = null;
          this.currentLicense.printType = null;
          this.currentLicense.destyny = null;
          this.currentLicense.filesFormat = [];
          this.currentLicense.notes = null;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.current) this.currentLicense = this.current;
  },
  computed: {
    isTerritorySelected: function () {
      return (
        this.currentLicense.checkedFiled &&
        (this.currentLicense.checkedFiled.find((x) => x == "C") ||
          (this.currentLicense.countries &&
            this.currentLicense.countries.length > 0) ||
          (this.currentLicense.continent &&
            this.currentLicense.continent.length > 0))
      );
    },
  },
};
</script>
<template>
  <div class="favorites color--basic">
    <grid-container
      v-if="model.hasFavorites"
      :model="model.listViewModel"
      header="Profil użytkownika"
    ></grid-container>
    <b-container v-else>
      <b-row class="mt-5">
        <b-col cols="12">
          <text-block :text="model.emptyItemDescription"></text-block>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TextBlock from "@/components/common/TextBlock";
import GridContainer from "@/components/Lists/GridContainer";

export default {
  name: "FavoritesPage",
  components: {
    TextBlock,
    GridContainer,
  },
  props: {
    model: Object,
  },
};
</script>

<style lang="scss">
.favorites {
  min-height: 50vh;
  .grid-container {
    > div {
      .row {
        margin: 0;
      }
    }
  }
}
</style>
<!------------------------------------------------------
Linki do filmu w starej wersji i wersji wyszukiwania AI, 
nagłówek stron filmu
-------------------------------------------------------->
<template>
  <b-container fluid class="header-tab">
    <b-row>
      <b-col>
        <b-container fluid class="containerFixed">
          <b-row>
            <b-col class="tab" :class="{ active: !isSearchInMovie }">
              <b-link class="align-middle focus-smart" :to="urlMovie">{{
                model.translations["moviePage.MoviePage"]
              }}</b-link>
            </b-col>
            <b-col class="tab" :class="{ active: isSearchInMovie }">
              <b-link :to="urlMovieAI" class="focus-smart">
                <span class="align-middle">{{
                  model.translations["moviePage.SearchInMovie"]
                }}</span></b-link
              >
              <i
                v-if="!isTv"
                v-b-tooltip.hover="{ customClass: 'advsearch-tooltip' }"
                class="grayed cil-info-circle ml-2 align-middle"
                :title="model.translations['searchPage.AdvSearchDecription']"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "MovieTabs",
  props: {
    //----------------
    //Model backend: AssetViewMapperAI albo AssetViewMapper
    //----------------
    model: Object,
  },
  data() {
    return {
      //czy klient to app tv
      isTv: this.$isTizen(this.$browserDetect),
    };
  },
  computed: {
    isSearchInMovie() {
      return this.$route.query.ai ? true : false;
    },
    urlMovie() {
      let _search = this.$route.query.search ? "&search=" + this.$route.query.search : "";
      return `${this.$route.path}?b=ai${_search}`;
    },
    urlMovieAI() {
      let _search = this.$route.query.search ? "&search=" + this.$route.query.search : "";
      return `${this.$route.path}?ai=1${_search}`;
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.header-tab {
  border-bottom: 1px solid $color-gray;
  font-family: $font-family-wotfard;
  .tab {
    flex-grow: 0;
    padding: 2em 0;
    margin-right: 2em;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    line-height: 1.5em;
    > a {
      color: $color-text-inactive;
    }
    i {
      color: $color-text-inactive;
      font-size: 1.5em;
    }
    &.active {
      > a {
        color: $color-main;
      }
      border-bottom: 3px solid white;
    }
    a {
      transition: all 0.3s;
      &:hover {
        color: $color-main !important;
      }
    }
  }
}
</style>

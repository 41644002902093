<template>
  <div class="lesson-result color--basic">
    <b-container>
      <b-row class="mt-5">
        <b-col>
          <h1 class="text-center">{{ model.title }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="vd_list-group mb-5 md"
            :key="index"
            v-for="(quiz, index) in quizzes"
          >
            <span class="vd_text-small">{{ quiz.name }}</span>
            <div
              class="vd_list-item"
              :key="index"
              v-for="(result, index) in quiz.results"
            >
              {{ result.email }}
              <div class="vd_list-badge">
                {{ result.result }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <bottom-button :model="generatedLessonLink"></bottom-button>
  </div>
</template>

<script>
import BottomButton from "@/components/common/BottomButton";
import axios from "axios";

export default {
    name: "LessonResultPage",
    components: {
        BottomButton
  },
  props: {
    model: Object,
  },
  data() {
    return {
      quizzes: [],
      lessonLink: "",
    };
  },
  computed: {
    generatedLessonLink() {
      return {
        name: this.model.translations["lessons.Result.BackToLesson"],
        url: this.lessonLink,
      };
    },
  },
  mounted() {
    let self = this;
    self.$setBusy(true);
    let url = this.model.loadResultEndpoint.replace(
      "{lessonId}",
      this.$route.query.key
    );
    axios
      .get(process.env.VUE_APP_BASE_API_URL + url, {})
      .then(function (response) {
        self.quizzes = response.data.results;
        self.lessonLink = response.data.lessonLink;
        self.$setBusy(false);
      })
      .catch(function () {
        self.$setBusy(false);
      });
  },
};
</script>

<style lang="scss">
</style>
<template>
  <div class="avaliables" :class="{ 'out-vod': altView }" v-if="item">
    <p class="avaliable" :style="{ background: item.color, color: item.textColor }">
      <i v-if="item.showBasket" class="cil-cart"></i>{{ item.text }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    price: Object,
    avaliable: Object,
    showOutVod: Boolean,
    translations: Object,
  },
  data() {
    return {
      item: null,
      altView: false,
    };
  },
  mounted() {
    if (this.showOutVod) {
      this.item = {
        text: this.translations["moviePage.OutVOD"],
      };
      this.altView = true;
    } else if (this.price) {
      this.item = this.price;
    } else if (this.avaliable) {
      this.item = this.avaliable;
    } else {
      this.item = null;
    }
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.avaliable {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  font-family: Knile;
  font-size: em(14, 16);
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 171.429% */
  padding: 4px 8px;
  border-radius: 4px;

  box-shadow: 0px 0px 18px 0px rgba(255, 255, 255, 0.24);
}

.modulex2 .vd_list-item .avaliable {
  font-size: 1.15em;
}
.avaliable i {
  padding-right: 10px;
  position: relative;
  top: 5px;
  font-size: 1.6em !important;
}
.avaliables {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 3%;
  left: 3%;
  width: 100%;
  pointer-events: none;
  height: unset !important;
  &.out-vod {
    top: 0;
    left: 0;
    .avaliable {
      background: rgb(159, 200, 240);
      color: black;
      text-shadow: none;
    }
  }
}
</style>

<template>
  <div class="vd-custom-dropdown">
    <b-dropdown
      v-if="showDropdown"
      v-model="isDropdownOpen"
      class="vd-custom-dropdown"
      :class="{ open: isDropdownOpen }"
      @toggle="toggleDropdown"
      variant="link"
      right
    >
      <!-- Button trigger for dropdown -->
      <template #button-content>
        <span class="vd_dropdown-label">
          <i :class="selectedViewIcon()" class="icon-large"></i>
        </span>
      </template>

      <!-- Dropdown items -->
      <b-dropdown-item
        v-for="(view, index) in viewOptions"
        :key="index"
        @click="changeView(view)"
        class="vd_dropdown-list-item"
      >
        <i :class="view.icon" class="icon-large"></i>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  name: "ViewSelector",
  props: {
    showDropdown: {
      type: Boolean,
      default: true,
    },
    translations: Object,
    displayType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      viewOptions: [
        { label: "List View", value: "list", icon: "cil-storage" },
        { label: "Grid View", value: "grid", icon: "cil-grid" },
      ],
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    changeView(view) {
      this.$emit("displayTypeUpdate", view.value);
      this.isDropdownOpen = false;
    },
    selectedViewIcon() {
              return this.viewOptions.find((option) => option.value === this.displayType)?.icon;
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.vd-custom-dropdown {
  .vd-custom-dropdown {
    width: 100%;
    padding-bottom: 1em;
  }
  .dropdown-menu {
    background: black !important;
    // min-width: 1.5em;
    .dropdown-item {
      color: white;
      padding: 1em;
      text-align: center;
      font-size: 1.5em;
      &:hover {
        color: black;
      }
    }
  }
  .dropdown-toggle {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 1.5em;
  }
  .btn {
    text-decoration: none !important;
  }
  @include media-breakpoint-up(md) {
    .vd-custom-dropdown {
      padding-bottom: 0;
    }
    .dropdown-menu {
      min-width: 1.5em;
      .dropdown-item {
        font-size: 1em;
        padding: 0.5em 1em;
      }
    }
    .dropdown-toggle {
      font-size: 1em;
    }
  }
}
</style>

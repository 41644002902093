<template>
  <img class="image__item" v-lazy="url" />
</template>
<script>
export default {
  name: "ImageItem",
  props: {
    source: {
      type: String,
    },
  },  
  beforeMount() {
    this.useParentCalc = (
      this.$parent.$parent.$parent.$props &&
      this.$parent.$parent.$parent.$props.options &&
      this.$parent.$parent.$parent.$props.options.width == "98vw");    
  },
  data() {
    return {
      useParentCalc: false,
    }
  },
  computed: {
    url() {
      return this.useParentCalc ?
        this.source.substring(0, this.source.indexOf("?"))
        : this.source;
    }
  }
};
</script>
<style lang="scss">
</style>
<template>
    <div class="quiz__question__result">
        <div class="vd_bg-dark mt-5 pt-4 pb-4">
            <b-container>
                <b-row>
                    <b-col cols="12" class="text-left" v-html="model.text"></b-col>
                    <b-col cols="12" class="text-left">
                        <div class="vd_list-group sm mt-5">
                            <div class="vd_list-item">
                                {{ translations['quizzes.Result.Percent'] }}
                                <div class="vd_list-badge">
                                    {{ model.percent }}%
                                </div>
                            </div>
                            <div class="vd_list-item">
                                {{ translations['quizzes.Result.Points'] }}
                                <div class="vd_list-badge">
                                    {{ model.points }}
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="text-center">
            <b-button class="mt-5" variant="primary" :to="model.redirectLink.url">
                {{ translations['quizzes.Questions.Buttons.End'] }}
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "QuizResult",
    props: {
        model: Object,
        translations: Object
    }
}
</script>
<!------------------------------------------
Tytuł, metadany, fraza - nagłówek na stronie wyszukiwania w filmie
-------------------------------------------->
<template>
  <b-container fluid class="metatitle">
    <b-row class="align-items-center pb-4 mb-4 border-btm metatitle__firstrow">
      <b-col class="metatitle__hdrleft mr-2">
        <h1 class="metatitle__hdrleft__title">{{ model.title }}</h1>
        <div class="metatitle__hdrleft__category color-dim">
          {{ model.mainCategory ? model.mainCategory.join(" ") : null }}
        </div>
        <div class="metatitle__hdrleft__director color-dim">
          {{ model.showOnBox.roleName }}: {{ model.showOnBox.persons.join(", ") }}
        </div>
      </b-col>
      <b-col class="metatitle__hdrright flex-grow-0 my-2">
        <b-button variant="primary" class="button--watch" @click="gotoMoviePage">
          <span class="d-none d-md-inline">{{ model.translations["moviePageAI.WatchMovie"] }}</span>
        </b-button>
        <b-button variant="secondary" class="button--aihelp mt-1" :to="model.aIhelpPage.url">
          <span class="d-none d-md-inline align-middle mr-2">{{ model.translations["moviePageAI.HelpAboutAI"]}}</span>
          <i class="cil-info-circle align-middle"></i>
        </b-button>        
      </b-col>
    </b-row>
    <b-row v-if="model.searchPhrase">
      <b-col class="metatitle__phrase">
        <div class="metatitle__phrase__clear d-inline-block py-2 px-3">
          <span class="lbl align-middle color-dim" >{{ model.translations["searchPage.Phrase"]}}:</span>
          <span class="txt align-middle pl-2">{{ model.searchPhrase }}</span>
          <a
            v-b-tooltip.hover
            @click="clearSearchPhrase()"
            class="align-middle icon link ml-3"
            :title="model.translations['moviePageAI.DeletePhraseTip']"
            tabindex="0"
            ><i class="cil-x"
          /></a>
        </div>
        <div class="metatitle__phrase__info mt-3 color-dim">
          {{ model.translations["moviePageAI.DeletePhraseInfo"] }}
        </div>
      </b-col>
    </b-row>      
  </b-container>
</template>

<script>
export default {
  name: "MovieMetaTitle",        
  props: {
    //----------------
    //Model backend: AssetViewModelAI
    //----------------
    model: Object,
  },  
  methods: {    
    clearSearchPhrase() {
      let qry = Object.assign({}, this.$route.query);
      delete qry.search;
      this.$router.push({ path: this.$route.path, query: qry });
    }, 
    gotoMoviePage() {      
      this.$router.push({ 
        path: this.$route.path,       
        query: { 
          "search": this.$route.query.search,
          "b": "ai"
        }});
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.metatitle {  
  &__firstrow {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0 !important;
    }
  }
  &__hdrleft {
    &__title {
      text-transform: uppercase;
      font-size: 2.5em !important;
      font-weight: bold;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0.1em;
      }
    }
  }
  &__hdrright {
    @include media-breakpoint-down(sm) {
      align-self: baseline;
    }
  }
  &__phrase {
    &__clear {
      background-color: $color-dark-gray;
      border-radius: 1.5em;
      line-height: 100%;      
      .lbl {
        line-height: 1.5em;
        height: 1.5em;
        display: inline-block;
        font-size: 1.3em;                
      }
      .txt {
        line-height: 1.5em;
        height: 1.5em;
        display: inline-block;
        font-size: 1.3em;
        font-weight: bold;
      }
      .icon {
        height: 1.5em;
        line-height: 1.5em;
        display: inline-block;
        >i {
          font-size: 1.5em;
        }
        &.link {
          cursor: pointer;
        }
      }
      transition: all 0.3s;
      &:hover {
        background-color: $color-gray;
      }
    }
  }
  
  .button--watch,
  .button--aihelp
  {             
    font-family: $font-family-wotfard;
    display: block;    
    position: relative;    
    text-transform: none;
    height: auto;        
    white-space: nowrap;
    z-index: 1;    
    border: none;  
    width: 166px;      
    @include media-breakpoint-down(sm) {
      width: 4em;
      height: 4em;                 
    }
  }
}

 .button--aihelp {
    background: transparent;
    border: 3px solid white !important;        
    padding-left: 0;
    padding-right: 0;
    >i {
      color: white;
      font-size: 1.5em;
      line-height: 1.6;
      @include media-breakpoint-down(sm) {     
        font-size: 2em;
        line-height: 1.8;
      }
    }

    @include media-breakpoint-down(sm) {
       padding: 0 !important;
    }
 }

.button--watch {
  background: white;    
  padding: 1em;
  @include media-breakpoint-up(md) {
      padding-right: 3.375em;      
  }  
  &:after {
    content: "";          
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: 2em;
    line-height: 1.5em;
    margin-left: 0.46875em;      
    position: absolute;
    width: em(12);
    height: em(23);
    background-image: url("/assets/35mm_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
  }
  @include media-breakpoint-down(sm) {
    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: none;        
      margin-left: 0;
      background-size: 75%;
    }    
  }
}
</style>

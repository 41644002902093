<template>
  <div class="vd_list" :class="{ triple: isTriple }">
    <div class="vd_list-grid" :class="{ 'only-box-view': isGrid }">
      <audio-playlist
        ref="aplaylist"
        :translations="translations"
        :episodes="items"
        :headerText="null"
        :showIndex="false"
        :showLoop="false"
        :singlePlay="true"
        v-if="displayType == 'audio'"
      ></audio-playlist>

      <!-- List items for non-audio display types -->
      <list-item
        v-for="(item, index) in displayType == 'audio' ? null : items"
        :key="index"
        :model="item"
        :box-view="isGrid"
        :section="section"
        :alternative="isGrid"
        :displayType="displayType"
        :translations="translations"
      ></list-item>
      <div v-if="!isTriple" class="vd_list-item save-grid"></div>
      <div v-if="!isTriple" class="vd_list-item save-grid"></div>
      <div v-if="!isTriple" class="vd_list-item save-grid"></div>
    </div>

    <!-- See more link -->
    <div class="vd_text-more text-right" v-if="showMore">
      <b-link class="vd_text-more text-right focus-smart" :to="'/'">zobacz więcej</b-link>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/Lists/ListItem";
import AudioPlaylist from "@/components/common/AudioPlaylist.vue"; // Imported

export default {
  name: "ListContainer",
  components: {
    ListItem,
    AudioPlaylist,
  },
  computed: {
    isGrid() {
      return this.displayType === "grid";
    },
  },
  props: {
    items: Array,
    section: String,
    showMore: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String,
      default: "list",
    },
    translations: Object,
    isTriple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.wcag-contrast
  .vd_list-grid.only-box-view
  .vd_list-item
  .vd_list-description
  .vd_list-description-wrapper,
.wcag-contrast .vd_list-grid .vd_list-item.item-video .vd_play-icon-wrapper {
  background: $color-black !important;
}

/*  dropdown  */
.vd_dropdown {
  display: inline-block;
  min-width: em(240);
  position: relative;
  padding: em(15) 0;
  z-index: 2;
  margin-bottom: em(20);

  .vd_dropdown-label-warpper {
    display: flex;
    align-items: center;
    padding: 0 em(20);
    cursor: pointer;

    .vd_dropdown-label {
      font-size: em(16);
      font-weight: bold;
    }

    .vd_dropdown-arrow {
      border: 0.75em solid transparent;
      border-right-color: white;
      width: 0;
      height: 0;
      margin-left: auto;
      transition: 0.23s;
      transform-origin: 75% 50%;
      transform: rotate(-90deg);
    }
  }

  .vd_dropdown-list {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 0.23s;

    .vd_dropdown-list-item {
      background: $color-light-gray;
      color: black;
      font-size: em(16);
      font-weight: bold;
      padding: em(10) em(30);
      cursor: pointer;

      &:first-child {
        padding-top: em(20);
      }

      &:last-child {
        padding-bottom: em(20);
      }

      span {
        position: relative;

        &::before {
          content: "";
          height: 2px;
          width: 0;
          position: absolute;
          background: black;
          top: 100%;
          transition: 0.3s;
        }
      }

      &:hover span::before {
        width: 100%;
      }
    }
  }

  &.open {
    .vd_dropdown-arrow {
      transform: rotate(0deg);
    }

    .vd_dropdown-list {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.vd_list-grid .vd_list-item .vd_list-description .boxSocial {
  width: 100%;
  top: auto;
  backface-visibility: hidden;
  bottom: 0;
  color: $color-main;

  .social {
    a,
    span {
      display: inline-block;

      > * {
        vertical-align: middle;
      }

      img,
      svg {
        height: 100%;
      }
    }

    a,
    span {
      margin-right: 20px;

      &:last-child {
        @media (max-width: map-get($grid-breakpoints, "lg") - 1) {
          margin-right: 5px;
        }
      }
    }

    i {
      position: relative;
    }
  }
}

.hover-social {
  //   .vd_list-cover,
  .vd_list-description {
    transition: all 0.4s;
    pointer-events: none;
    transition-delay: 0.2s;
    // transform: translateY(50px);
    transition-timing-function: ease-out;

    h4 {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }

    .boxSocial {
      transition: 1s all;
      height: 0;
      padding: 0;
      overflow: hidden;

      @media (min-width: map-get($grid-breakpoints, "lg")) {
        // padding: 1.25em 20px 10px !important;
      }

      .social {
        a,
        span {
          transition: all 0.2s;

          i {
            font-size: em(15);
            position: relative;
            top: -4px;
          }

          &:hover {
            animation-name: pulsing;
            animation-duration: 1.5s;
            animation-timing-function: ease;
            animation-delay: 0.2s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;
            text-decoration: none;
          }

          img {
            position: relative;
            top: -4px;
          }
        }

        .dissolve svg {
          opacity: 0;
          transition-delay: 0.2s;
          transition-duration: 1s;
          transition-timing-function: ease-in-out;
          transition-property: all;
        }
      }

      .vd_play-icon-wrapper {
        display: none;
      }
    }

    .vd_list-description-wrapper {
      background: rgba(0, 0, 0, 0.4) !important;
    }

    @media (min-width: map-get($grid-breakpoints, "md")) {
      //   pointer-events: none !important;
    }
  }

  &:hover,
  &:active,
  &.selected,
  &:focus-within {
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      .vd_list-description {
        .boxSocial {
          height: 50px;
          padding-top: 1.25em;
        }
        .social .dissolve svg {
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    .vd_list-description {
      .vd_list-description-wrapper {
        .lead {
          height: em(90);
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    .vd_list-description {
      // transform: translateY(50px);
    }
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    .vd_list-description {
      // transform: translateY(50px);

      .boxSocial {
        .social {
          a,
          span {
            height: em(23);

            i {
              font-size: em(23);
              position: relative;
              top: -4px;
            }
          }
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    .vd_list-description {
      // transform: translateY(50px);
    }
  }
}
.triple .vd_list-grid.only-box-view {
  padding: 0 10%;
  justify-content: center;
  .vd_list-item {
    margin: 8px;
    flex: 0 0 calc(33% - 16px);
    img {
      aspect-ratio: 16 / 9 !important;
    }
  }
  @keyframes pulsing {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.6);
    }

    100% {
      transform: scale(1);
    }
  }
}

.carousel {
  .vd_list-item {
    top: 2px;
  }
}

.modulex2 {
  > div {
    padding: 23px 0;
  }

  .vd_list-item {
    // margin-bottom: 0;
    .vd_list-description {
      .vd_list-description-wrapper {
        .lead {
          display: -webkit-box;
        }
      }

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        .vd_list-title {
          font-size: em(16, 12);
        }

        h4 {
          font-size: em(13, 12);
        }

        .lead {
          font-size: em(12, 12);
        }
      }
    }
  }

  .hover-social {
    .vd_list-description {
      @media (min-width: map-get($grid-breakpoints, "xl")) {
        // transform: translateY(46px);
      }
    }
  }
}

.Tizen {
  .vd_list-grid .vd_list-item .vd_list-description {
    h4 {
      @media (min-width: map-get($grid-breakpoints, "lg")) {
        font-size: em(16, 16);
      }
    }

    .vd_list-title-wrapper .vd_list-title h3 {
      font-size: em(20);
    }
  }
}
</style>

<template>
    <div class="quiz__questions__question__multiple text-left">
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col cols="12" md="10" lg="8">
                    <b-form-group>
                        <b-form-checkbox-group
                            stacked
                            buttons
                            button-variant="outline-primary"
                            v-model="selected"
                            :options="answers"
                            class="quiz__questions__question__answers--item"
                            value-field="key"
                            text-field="text"
                            @change="change"
                        ></b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "MultipleAnswers",
    mounted() {
        this.selected = this.userSelected;
    },
    data() {
        return {
            selected: []
        }
    },
    props: {
        answers: Array,
        userSelected: Array
    },
    methods:{
        change: function(){
            this.$emit("values", this.selected);
        }
    }
}
</script>

<style scoped>

</style>
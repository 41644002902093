<template>
  <div v-click-outside="closeDialog">
    <b-navbar-nav
      class="lang-nav-bar d-none d-lg-block"
      v-if="$isTizen($browserDetect)"
    >
      <b-nav-item
        @click="showLangList = !showLangList"
        class="lang-nav-item second-group-spacing mx-3"
        :class="[showLangList ? 'lang-nav-item--close' : 'zoom-icon']"
        :title="langSetting.label"
      >
        <template>
          <span
            :title="langSetting.label"
            role="img"
            :class="{ 'd-none': showLangList }"
            class="lang-nav-item__btn"
            >{{ langSetting.current.iso }}</span
          >
          <span v-if="showLangList" :title="langSetting.label" role="img"
            ><i class="header-cicon cil-x"
          /></span>
        </template>
      </b-nav-item>
      <b-navbar-nav
        :class="{ expand: showLangList }"
        class="lang-nav-item__menu"
      >
        <b-nav-item
          v-for="lang in langAvailable"
          :key="lang.id"
          :to="lang.url"
          @click="sendAnalitycs(lang.name[0])"
          :link-attrs="{
            role: 'img',
            title: lang.name[0],
            lang: lang.iso,
          }"
        >
          <span
            aria-hidden="true"
            class="lang-nav-item__name"
            v-for="(name, i) in lang.name"
            :key="i"
            >{{ name }}</span
          >
          <span class="lang-nav-item__iso" aria-hidden="true">{{
            lang.iso
          }}</span>
          <!-- <span class="wcag_hide">{{ lang.name[0] }}</span>   -->
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar-nav>
    <b-navbar-nav
      class="lang-nav-bar d-none d-lg-block"
      v-else-if="!isCollapsedVersion"
    >
      <b-nav-item
        @click="showLangList = !showLangList"
        class="lang-nav-item second-group-spacing mx-3"
        :class="[showLangList ? 'lang-nav-item--close' : 'zoom-icon']"
        :title="langSetting.label"
      >
        <template>
          <span
            :title="langSetting.label"
            role="img"
            :class="{ 'd-none': showLangList }"
            class="lang-nav-item__btn"
            >{{ langSetting.current.iso }}</span
          >
          <span v-if="showLangList" :title="langSetting.label" role="img"
            ><i class="header-cicon cil-x"
          /></span>
        </template>
        <transition name="slide" mode="out-in">
          <b-navbar-nav v-if="showLangList" class="lang-nav-item__menu">
            <b-nav-item
              v-for="lang in langAvailable"
              :key="lang.id"
              :to="lang.url"
              @click="sendAnalitycs(lang.name[0])"
              :link-attrs="{
                role: 'img',
                title: lang.name[0],
                lang: lang.iso,
              }"
            >
              <span
                aria-hidden="true"
                class="lang-nav-item__name"
                v-for="(name, i) in lang.name"
                :key="i"
                >{{ name }}</span
              >
              <span class="lang-nav-item__iso" aria-hidden="true">{{
                lang.iso
              }}</span>
              <!-- <span class="wcag_hide">{{ lang.name[0] }}</span>   -->
            </b-nav-item>
          </b-navbar-nav>
        </transition>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav v-if="isCollapsedVersion" class="d-lg-none lang-collapsed">
      <b-nav-item
        v-for="lang in langSetting.available"
        :key="lang.id"
        :to="lang.url"
        :title="lang.name"
        class="navitem-hover-left-right"
        @click="sendAnalitycs(lang.name[0])"
      >
        {{ lang.iso }}
      </b-nav-item>
    </b-navbar-nav>
  </div>
</template>
<script>
import { EventBus } from "@/services/event-bus";
export default {
  props: ["langSetting", "isCollapsedVersion"],
  data() {
    return {
      showLangList: false,
    };
  },
  mounted() {
    EventBus.$on("closeNavEvent", this.closeDialog);
  },
  computed: {
    langAvailable() {
      let listLang = JSON.parse(JSON.stringify(this.langSetting.available));
      listLang.map((lang) => {
        return (lang.name = lang.name.split(" ", 1));
      });
      return listLang;
    },
  },
  methods: {
    closeDialog() {
      this.showLangList = false;
    },
    sendAnalitycs(lang) {
      this.$setGAFormNavigation("Zmień język", lang, false);
    },
  },
  beforeDestroy() {
    EventBus.$off("closeNavEvent", this.closeDialog);
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.lang-nav-bar {
  position: relative;
  // margin-left: 2em;
  .lang-nav-item {
    // position: absolute;
    // top: -27px;
    // left: -2.9em;
    // width: 52px;
    // height: 66px;
    // padding-top: 6px;
    // z-index: 4;
    // text-align: center;
    margin: 0 !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    &__btn {
      border: 2px solid white;
      border-radius: 2px;
      padding: 2px 6px;
      font-size: 0.75em;
      font-weight: bold;
      text-transform: uppercase;
      transition: font-size 0.2s;
      position: relative;
      top: -5px;
      //  &:hover {
      //      font-size: 0.9em;
      //   }
    }

    &__name {
      text-transform: capitalize;
    }
    &__iso {
      border: 2px solid $color-main;
      border-radius: 2px;
      padding: 2px 6px;
      font-size: 0.75em;
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: middle;
    }

    &--close {
      background-color: $color-nero;
    }
    &__menu {
      background-color: $color-nero;
      position: absolute;
      top: 61px;
      height: auto;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      padding: 10px 10px 10px 4px;
      z-index: 4;
      transform: translateX(-50%);

      .nav-item {
        width: 9.375em;
        text-align: end;
        position: relative;
        .lang-nav-item__name {
          margin-right: em(29);
        }
        .lang-nav-item__iso {
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          position: absolute;
        }
      }
    }
  }
}

.lang-collapsed {
  flex-direction: row;
  .nav-item:not(:last-child) {
    &:after {
      content: "|";
      margin: 0 30px;
      color: $color-main;
    }
    .nav-link {
      display: inline-block;
    }
  }
}

.wcag-fontboost2 {
  .lang-nav-bar {
    .nav-link {
      height: 2.5em;
    }
  }
}
.wcag-fontboost1 {
  .lang-nav-bar {
    .nav-link {
      height: 2.75em;
    }
  }
}
</style>
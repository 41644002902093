<template>
  <div class="error-page color--basic">
    <b-container fluid class="containerFixed">
      <b-row align-h="center">
        <b-col cols="12" md="6" class="mt-5 text-center">
          <h1>{{this.statusCode}}</h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="containerFixed">
      <b-row align-h="center">
        <b-col cols="12" md="6" class="mt-5 text-center">
          <h3>{{ model.translations[descriptionKey] }}</h3>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="mt-4 text-center">
          <b-button type="button" variant="primary" to="/">
            {{ model.translations["errors.GoHome"] }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  computed: {
    descriptionKey() {
      return "errors." + this.statusCode + ".Description";
    },
    errorImg() {
      return "/assets/" + this.statusCode + ".png";
    },
  },
  props: {
    statusCode: Number,
    model: Object,
  },
};
</script>
<style lang="scss" scoped>
h1{
  font-size:8em;
  margin-bottom: 0.1em;
}
</style>
<template>
  <div
    class="ListViewer"
    v-if="items"
    :alt="translations['altText.GaleryTitle'] + ': ' + title"
  >
    <carousel-container
      :model="options"
      :translations="translations"
      :itemList="items"
      galleryId="Gallery"
      section="Sekcje: galeria zdjęć"
      :alternative="true"
      :header="translations['moviePage.Gallery']"
    >
      <template v-slot:header>
        <h2>{{ translations["moviePage.Gallery"] }}</h2>
      </template>
      <template v-slot:main>
        <splide-slide v-for="(item, index) in items" :key="index">
          <image-box
            :model="item"
            galleryId="Gallery"
            :elementId="index"
            :title="title"
            :textOpen="translations['general.ExpendImage']"
          ></image-box>
        </splide-slide>
      </template>
    </carousel-container>
  </div>
</template>
<script>
import { SplideSlide } from "@splidejs/vue-splide";
import CarouselContainer from "../ListingView/CarouselContainer.vue";
import ImageBox from "../ListingView/ImageBox.vue";
export default {
  components: {
    ImageBox,
    CarouselContainer,
    SplideSlide,
  },
  props: {
    translations: Object,
    options: Object,
    items: Array,
    title: String,
  },
};
</script>
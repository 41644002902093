<template>
    <div class="quiz__questions__question__answers quiz__questions__question__single text-left">
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col cols="12" md="10" lg="8">
                    <b-form-radio-group
                        stacked
                        buttons
                        button-variant="outline-primary"
                        v-model="selected"
                        :options="answers"
                        class="quiz__questions__question__answers--item"
                        value-field="key"
                        text-field="text"
                        @change="change"
                    ></b-form-radio-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "SingleAnswer",
    mounted() {
        this.selected = this.userSelected.length > 0 ? this.userSelected[0] : '';
    },
    data() {
        return {
            selected: ''
        }
    },
    props: {
        answers: Array,
        userSelected: Array
    },
    methods: {
        change: function () {
            let items = [];
            items.push(this.selected);

            this.$emit("values", items);
        }
    }
}
</script>
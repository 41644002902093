var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isCollapsedVersion)?_c('div',{staticClass:"d-none d-lg-flex social-uncollapsed share"},[_c('b-link',{staticClass:"showShare focus-smart",class:{ 'showShare--active': _vm.isShare },attrs:{"aria-label":_vm.translation['navigation.Share']},on:{"keyup":function($event){_vm.isShare = !_vm.isShare}}},[_c('i',{staticClass:"cil-share-alt",attrs:{"role":"img","title":_vm.translation['navigation.Share']}})]),_c('b-nav',{class:[_vm.isShare ? 'share__social--keyup' : 'share__social']},[_vm._l((_vm.social),function(link,index){return _c('b-nav-item',{key:index,staticClass:"share__social__item",attrs:{"href":link.url,"target":"_blank","title":link.label,"link-attrs":{
          'aria-label': link.label,
        }},on:{"click":function($event){return _vm.sendAnalitycs(link.name)}}},[_c('i',{class:'cib-' + link.icon,attrs:{"title":link.label,"role":"img"}}),_c('span',{staticClass:"wcag_hide"},[_vm._v(_vm._s(link.label))])])}),_c('b-nav-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-shared-mail",modifiers:{"modal-shared-mail":true}}],staticClass:"share__social__item",attrs:{"link-attrs":{
          'aria-label': _vm.translation['general.Share.Email'],
        }},on:{"click":function($event){return _vm.shareByMail()}}},[_c('i',{staticClass:"cil-mail",attrs:{"aria-hidden":"true","title":_vm.translation['general.Share.Email']}})])],2),_c('share-by-mail',{attrs:{"shareSubmitUrl":_vm.shareSubmitUrl,"translation":_vm.translation}})],1):_vm._e(),(_vm.isCollapsedVersion)?_c('b-nav',{staticClass:"social-collapsed d-lg-none"},[_vm._l((_vm.social),function(link,index){return _c('b-nav-item',{key:index,staticClass:"social-collapsed__link",attrs:{"href":link.url,"target":"_blank","title":link.label,"link-attrs":{
        'aria-label': link.label,
      }},on:{"click":function($event){return _vm.sendAnalitycs(link.name)}}},[_c('i',{staticClass:"social-collapsed__icon header-cicon",class:'cib-' + link.icon,attrs:{"title":link.label,"role":"img"}})])}),_c('b-nav-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-shared-mail",modifiers:{"modal-shared-mail":true}}],staticClass:"social-collapsed__link",attrs:{"title":"ShareByEmail","link-attrs":{
        'aria-label': _vm.translation['general.Share.Email'],
      }}},[_c('i',{staticClass:"social-collapsed__icon cil-mail header-cicon",attrs:{"aria-hidden":"true","title":_vm.translation['general.Share.Email']}})])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <modal
    :title="translations['soundLibrary.CreatePlaylist']"
    :hideClose="true"
    header="addPlaylist"
    :show="show"
    @hidePop="close"
    :no-fade="true"
  >
    <template v-slot:main
      ><div class="text-left create-playlist">
        <label for="playlistName">{{ translations["soundLibrary.Title"] }}</label>
        <b-input
          id="playlistName"
          name="playlistName"
          v-model="name"
          @keydown.enter="create"
        ></b-input>
      </div>
    </template>
    <template v-slot:btn>
      <div class="flex-buttons">
        <b-button @click="close">{{ translations["general.Close"] }}</b-button>
        <b-button variant="primary" @click="create">{{
          translations["soundLibrary.Add"]
        }}</b-button>
      </div>
    </template>
  </modal>
</template>
<script>
import modal from "./modal.vue";
import PlaylistService from "../../services/playlistService";
export default {
  components: { modal },
  props: {
    translations: Object,
  },
  data() {
    return { show: false, name: "" };
  },
  mounted() {
    this.show = true;
    setTimeout(() => {
      window.RedgalaxyNav.focusElement("#playlistName");
    }, 100);            
  },
  methods: {
    close() {
      this.$emit("close", this.name);
    },
    create() {
      PlaylistService.create(this.name).then(() => {
        this.close();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.create-playlist {
  margin-bottom: 24px;
  label {
    font-family: "Wotfard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
}
</style>

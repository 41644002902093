export default {
    install: function (Vue, options) {
        Vue.prototype.$setBusy = function (option) {
            if (option) {
                this.$root.$emit('bv::hide::tooltip');
                document.body.classList.add(options.className);
            } else {
                document.body.classList.remove(options.className);
                document.body.classList.remove("busyStart");
            }
        };
    }
}
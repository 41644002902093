<template>
  <div
    class="boxSocial"
    v-if="showSocial && !this.$browserDetect.isApp && model.favoriteModel"
  >
    <div class="social">
      <favorites-control
        :title="model.title"
        :model="model.favoriteModel"
        css-class="vd_like-btn"
        :section="section"
      ></favorites-control>
      <a
        :href="facebookUrl"
        target="_blank"
        tabindex="0"
        :aria-label="altFacebook"
        @click="
          blockNavigation();
          sendAnalytics('Kliknięcie w ikonkę', 'Udostępnij na facebooku');
          return false;
        "
      >
        <i class="cib-facebook icon" :title="altFacebook" role="img"></i>
      </a>
      <a
        :aria-label="altEmail"
        class=""
        href="#"
        @click="
          blockNavigation();
          shareByMail();
          return false;
        "
        tabindex="0"
      >
        <i :title="altEmail" class="cil-mail" aria-hidden="true"></i>
      </a>
      <span class="float-right dissolve">
        <img src="/assets/35mm.svg" :alt="model.watch" class="icon" />
      </span>
    </div>
  </div>
</template>
<script>
import FavoritesControl from "@/components/common/FavoritesControl";
import { EventBus } from "@/services/event-bus.js";
export default {
  components: {
    FavoritesControl,
  },
  props: {
    model: Object,
    showSocial: Boolean,
    section: String,
    translations: Object,
  },
  data() {
    return {
      altFacebook: String,
      altEmail: String,
    };
  },
  computed: {
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${location.origin}${this.model.url}`;
    },
  },
  mounted() {
    this.makeAlt();
  },
  methods: {
    blockNavigation() {
      EventBus.$emit("lockScroll");
    },
    sendAnalytics(gAction, gLabel) {
      var name = "";
      switch (this.model.type) {
        case "Asset":
          name = "filmu";
          break;
        case "Article":
          name = "Artykułu";
          break;
        case "Quiz":
          name = "Quizu";
          break;
        case "Lesson":
          name = "Lekcji";
          break;
      }

      this.$setGAFormCustom(
        this.section,
        gAction ?? "przejdź na stornę " + name,
        gLabel ?? this.model.title,
        false
      );
    },
    shareByMail: function () {
      this.$bvModal.show("modal-shared-mail");
      this.sendAnalytics("Kliknięcie w ikonkę", "Udostępnij przez E-mail");
      EventBus.$emit("share-content", `${location.origin}${this.model.url}`);
    },
    makeAlt() {
      this.altFacebook = `${this.translations["general.Share.Facebook"]} - ${this.model.title}`;
      this.altEmail = `${this.translations["general.Share.Email"]} - ${this.model.title}`;
    },
  },
};
</script>

<template>
  <div class="mx-auto mt-5 color--basic regpanel">
    <b-container class="mb-5" v-if="done">
      <b-row class="pt-5">
        <b-col class="text-center">
          {{ model.translations["user.RegistrationExternalSucceeded"] }}
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col class="mx-4 text-center">
          <b-button
            type="submit"
            variant="primary"
            class="button--large button--reg"
            :href="getExternalLoginLink()"
            >{{ model.translations["general.Login"] }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-form @submit.stop.prevent="submit" v-if="!done">
      <b-container>
        <b-row>
          <b-col class="mx-4 text-center text-uppercase">
            <h1 v-html="model.translations['general.Register']"></h1>
          </b-col>
        </b-row>
        <b-row class="pt-2" v-if="state.provider">
          <b-col class="text-center text--smaller"
            >{{ this.loginIfRegistered[0] }}
            <b-link
              :to="model.login.url"
              class="focus-smart"
              :title="model.login.name"
              >{{ this.loginIfRegistered[1] }}</b-link
            >
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col>
            <label for="email">{{ model.translations["general.Email"] }}</label>
            <b-form-input
              name="email"
              id="email"
              v-model="$v.state.email.$model"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col>
            <label for="firstname">{{
              model.translations["general.FirstName"]
            }}</label>
            <b-form-input
              name="firstname"
              id="firstname"
              v-model="$v.state.firstname.$model"
              :state="validationState($v.state.firstname)"
              aria-describedby="error-firstname"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-firstname"
              v-if="$v.state.firstname.$dirty && !$v.state.firstname.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col>
            <label for="lastname">{{
              model.translations["general.LastName"]
            }}</label>
            <b-form-input
              name="lastname"
              id="lastname"
              v-model="$v.state.lastname.$model"
              :state="validationState($v.state.lastname)"
              aria-describedby="error-lastname"
            ></b-form-input>
            <b-form-invalid-feedback
              id="error-lastname"
              v-if="$v.state.lastname.$dirty && !$v.state.lastname.required"
              >{{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <!-- <b-row class="pt-3">
          <b-col>
            <b-form-checkbox
              id="check-standard"
              v-model="checkStandard"
              name="check-standard"
              value="on"
              unchecked-value="off"              
              >{{ model.translations["user.AccountStandard"] }}
            </b-form-checkbox>
          </b-col>
        </b-row> 
        <b-row class="pt-3">
          <b-col>
            <b-form-checkbox
              id="check-pro"
              v-model="checkPro"
              name="check-pro"
              value="on"
              unchecked-value="off"              
              >{{ model.translations["user.AccountPro"] }}
            </b-form-checkbox>
          </b-col>
        </b-row>  
        <b-row class="pt-3" v-show="state.isPro">
          <b-col class="pl-5 pr-3">
            <b-row>
              <b-col>
                <label for="institutionName">{{
                  model.translations["user.InstitutionName"]
                }}</label>
                <b-form-input
                  name="institutionName"
                  id="institutionName"
                  v-model="$v.state.institutionName.$model"
                  :state="validationState($v.state.institutionName)"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.state.institutionName.$dirty &&
                    !$v.state.institutionName.required
                  "
                  >{{ model.translations["general.Errors.Required"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="pt-3">
              <b-col>
                <label for="institutionPhone">{{
                  model.translations["user.InstitutionPhone"]
                }}</label>
                <b-form-input
                  name="institutionPhone"
                  id="institutionPhone"
                  v-model="$v.state.institutionPhone.$model"
                  :state="validationState($v.state.institutionPhone)"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.state.institutionPhone.$dirty &&
                    !$v.state.institutionPhone.required
                  "
                  >{{ model.translations["general.Errors.Required"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="pt-3">
              <b-col>
                <label for="institutionNIP">{{
                  model.translations["user.NIP"]
                }}</label>
                <b-form-input
                  name="institutionNIP"
                  id="institutionNIP"
                  v-model="$v.state.institutionNIP.$model"
                  :state="validationState($v.state.institutionNIP)"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.state.institutionNIP.$dirty &&
                    !$v.state.institutionNIP.required
                  "
                  >{{ model.translations["general.Errors.Required"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="pt-3">
              <b-col>
                <label for="institutionRIK">{{
                  model.translations["user.RIK"]
                }}</label>
                <b-form-input
                  name="institutionRIK"
                  id="institutionRIK"
                  v-model="$v.state.institutionRIK.$model"
                  :state="validationState($v.state.institutionRIK)"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.state.institutionRIK.$dirty &&
                    !$v.state.institutionRIK.required
                  "
                  >{{ model.translations["general.Errors.Required"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>            
          </b-col>
        </b-row> -->
        <b-row class="pt-5">
          <b-col>
            <b-form-checkbox
              v-model="$v.state.acceptedCheck.$model"
              name="acceptedCheck"
              value="on"
              unchecked-value=""
              class="accept"
              :state="validationState($v.state.acceptedCheck)"
              aria-describedby="error-acceptedCheck"
            >
              {{ this.acceptance[0] }}
              <b-link
                :to="model.rules.url"
                class="focus-smart"
                :title="model.rules.name"
                >{{ this.acceptance[1] }}</b-link
              >
              {{ this.acceptance[2] }}
              <b-link
                :to="model.privacy.url"
                class="focus-smart"
                :title="model.privacy.name"
                >{{ this.acceptance[3] }}</b-link
              >{{ this.acceptance[4] }}
            </b-form-checkbox>
            <b-form-invalid-feedback
              :state="!$v.state.acceptedCheck.$error"
              id="error-acceptedCheck"
            >
              {{ model.translations["general.Errors.Required"] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="pt-2" v-if="false">
          <b-col>
            <b-form-checkbox
              v-model="$v.state.newsletter.$model"
              name="check-newsl"
              value="on"
              unchecked-value="off"
              >{{ model.translations["user.OptionsNewsletter"] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="pt-5">
          <b-col class="mx-4 text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large button--reg"
              >{{ model.translations["general.Register"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <modal
      header="register"
      :title="registrationError"
      :show="showModal"
      :closeText="closeText"
      @hidePop="hideModal"
    >
    </modal>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import authService from "@/services/authService";
import { load } from "recaptcha-v3";
import modal from "../components/common/modal.vue";

export default {
  components: { modal },
  name: "RegisterExternalPage",
  mixins: [ValidationMixin],
  props: {
    model: Object,
    translations: Object,
    closeText: String,
  },
  data() {
    return {
      done: false,
      acceptance: [],
      loginIfRegistered: [],
      registrationError: null,
      state: {
        provider: null,
        providerKey: null,
        email: null,
        firstname: null,
        lastname: null,
        isPro: false,
        acceptedCheck: null,
        institutionName: null,
        institutionPhone: null,
        institutionNIP: null,
        institutionRIK: null,
        newsletter: null,
        token: null,
      },
      showModal: Boolean,
    };
  },
  computed: {
    checkPro: {
      get() {
        return this.state.isPro ? "on" : "off";
      },
      set(value) {
        this.state.isPro = value == "on";
      },
    },
    checkStandard: {
      get() {
        return this.state.isPro ? "off" : "on";
      },
      set(value) {
        this.state.isPro = value == "off";
      },
    },
  },
  mounted() {
    this.acceptance = this.model.translations["user.Acceptance"].split(/{|}/);
    this.loginIfRegistered =
      this.model.translations["user.LoginIfRegistered"].split(/{|}/);
    load(this.model.reCaptchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
    if (this.$route.query.provider) {
      this.state.email = this.$route.query.email;
      this.state.firstname = this.$route.query.firstname;
      this.state.lastname = this.$route.query.lastname;
      this.state.provider = this.$route.query.provider;
      this.state.providerKey = this.$route.query.providerKey;
    } else {
      var data = authService.getProfile(true);
      if (data) {
        this.state.email = data.email;
        this.state.firstname = data.given_name;
        this.state.lastname = data.family_name;
        this.state.provider = null;
        this.state.providerKey = null;
      }
    }
  },
  validations: {
    state: {
      email: {},
      firstname: {},
      lastname: {},
      acceptedCheck: {
        required,
      },
      newsletter: {},
      institutionName: {
        required: requiredIf(function () {
          return this.state.isPro;
        }),
      },
      institutionPhone: {
        required: requiredIf(function () {
          return this.state.isPro;
        }),
      },
      institutionNIP: {},
      institutionRIK: {},
    },
  },
  methods: {
    getExternalLoginLink() {
      return authService.getExternalLink(
        this.state.provider,
        window.location.href
      );
    },
    hideModal() {
      this.showModal = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.registrationError = this.model.translations["user.RegisterError"];
        this.show = true;
        return;
      }
      this.$setBusy(true);
      this.recaptcha.execute("register").then((token) => {
        this.state.token = token;
        (this.state.provider
          ? authService.registerExt(this.state)
          : authService.saveProfile(this.state)
        )
          .then(() => {
            if (this.state.provider) {
              this.done = true;
              this.$setBusy(false);
            } else {
              authService.remoteLogin(this);
              var self = this;
              setTimeout(() => {
                self.$router.push("/");
                this.$setBusy(false);
              }, 1000);
            }
          })
          .catch((error_code) => {
            let error =
              error_code &&
              this.model.translations["user.SSOErrors." + error_code]
                ? this.model.translations["user.SSOErrors." + error_code]
                : this.model.translations["user.RegisterError"];
            this.registrationError = error;
            this.showModal = true;
            this.$setBusy(false);
            window.scrollTo(0, 0);
          });
      });
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.regpanel {
  width: 100%;
  max-width: 500px;
  font-family: $font-family-wotfard;

  .text {
    &--smaller {
      font-size: $font-size-smaller;
    }
  }

  .divider {
    border-top: 1px solid $color-gray;
  }

  &__opt-col {
    border-radius: 2px;
    background-color: $color-gray;
    height: 40px;
    .custom-control-label {
      vertical-align: unset;
    }
  }

  &__opt-teacher {
    height: 40px;
    .custom-control-label {
      vertical-align: unset;
    }
  }

  &__collapsible {
    .custom-control {
      margin: 5px 0;
    }
    &.teacher {
      margin-bottom: 20px;
    }
  }

  .button {
    &--reg {
      text-transform: uppercase;
      font-size: $font-size-bigger;
    }
    &--toggle {
      background-color: inherit;
      border: none;
      height: 40px;
      width: 40px;
      padding: 0 10px;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &:active {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
      }

      .media-aside {
        min-width: 30px;
      }

      &.collapsed {
        .arrow {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $color-main;
        }
      }

      &.not-collapsed {
        .arrow {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $color-main;
          padding-left: 5px;
        }
      }
    }
    &--fb,
    &--google {
      border: none;
      font-weight: $font-weight-semi-bold;
      width: 100%;
      max-width: 360px;
    }
    &--google {
      background-color: $color-google;
    }
    &--fb {
      background-color: $color-fb;
    }
  }
  .force-white-text label {
    color: white !important;
  }
  .force-darker label {
    color: #999 !important;
  }
  .accept {
    a {
      text-decoration: underline;
    }
  }
  > form {
    > .container {
      > .row {
        margin: 0;
      }
    }
  }
}
</style>
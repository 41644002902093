<template>
  <div class="quiz__questions__question">
    <div class="vd_bg-dark pb-5 mb-5">
      <question-content v-if="model" :model="model"></question-content>
      <div class="quiz__question__question__answers pt-5">
        <multiple-answers
          v-if="model && model.type === 'Checkbox'"
          :answers="model.answers"
          :user-selected="answer"
          @values="selected"
        ></multiple-answers>
        <single-answer
          v-if="model && model.type === 'Radio'"
          :answers="model.answers"
          @values="selected"
          :user-selected="answer"
        ></single-answer>
      </div>
    </div>

    <div class="quiz__question__question__navigation">
      <b-container>
        <b-row align-h="between">
          <b-col cols="6" md="4">
            <b-button
              v-if="showPrev && !submitted"
              @click="prev"
              variant="primary"
            >
              {{ translations["quizzes.Questions.Buttons.Prev"] }}
            </b-button>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              v-if="showNext && !submitted"
              @click="next"
              variant="primary"
            >
              {{ translations["quizzes.Questions.Buttons.Next"] }}
            </b-button>
            <b-button
              v-if="showPrev && !showNext && !submitted"
              @click="submit"
              variant="primary"
            >
              {{ translations["quizzes.Questions.Buttons.End"] }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import QuestionContent from "@/components/Quiz/QuestionContent";
import MultipleAnswers from "@/components/Quiz/MultipleAnswers";
import SingleAnswer from "@/components/Quiz/SingleAnswer";
export default {
  name: "Question",
  components: {
        QuestionContent,
        MultipleAnswers,
        SingleAnswer
  },
  data() {
    return {
      submitted: false,
      userAnswer: null,
    };
  },
  props: {
    model: Object,
    showPrev: Boolean,
    showNext: Boolean,
    answer: Array,
    translations: Object,
  },
  methods: {
    selected(items) {
      this.userAnswer = { key: this.model.key, answers: items };
    },
    next() {
      this.emitValues();
      this.$emit("next");
    },
    prev() {
      if (this.showPrev && !this.showNext) {
        this.emitValues();
      }
      this.$emit("prev");
    },
    submit() {
      this.submitted = true;
      this.emitValues();
      this.$emit("submit");
    },
    emitValues() {
      if (!this.userAnswer) {
        this.userAnswer = {
          key: this.model.key,
          answers: [],
        };
      }

      this.$emit("values", this.userAnswer);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.quiz {
  &__questions {
    &__question {
      &__answers {
        &--item {
          width: 100%;

          .btn {
            background: $color-gray;
            border: 0;
            border-top: 2px solid $color-main;
            padding: em(13);
            max-width: em(320);
            margin: auto;
            font-size: em(16);
            font-weight: $font-weight-semi-bold;
            color: $color-main;

            &:hover {
              background: transparentize($color-gray, 0.4);
            }

            &:not(:last-child) {
              margin-bottom: em(20);
            }
          }
        }
      }
    }
  }
}
</style>
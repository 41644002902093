<template>
  <div>
    <b-modal
      id="modal-shared-mail"
      :title="translation['navigation.Share']"
      size="md"
      centered
      hide-footer
      style="z-index: 13000"
    >
      <div class="mx-auto">
        <b-form
          @submit.stop.prevent="submit"
          class="newsletter color--basic"
          v-if="!isFormSubmitted"
        >
          <b-container>
            <transition name="fade">
              <b-row v-if="hasValidationError">
                <b-col class="text-center">
                  <b-alert show variant="danger">{{
                    translation["navigation.Share.EmailWasNotSent"]
                  }}</b-alert>
                </b-col>
              </b-row>
            </transition>
            <b-row>
              <b-col>
                <label class="color--basic" for="name">
                  {{ translation["general.FirstName"] }}
                </label>
                <b-form-input
                  name="name"
                  v-model="$v.state.name.$model"
                  :state="validationState($v.state.name)"
                  aria-describedby="error-name"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="error-name"
                  v-if="$v.state.name.$dirty && !$v.state.name.required"
                >
                  {{ translation["general.Errors.Required"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="pt-4">
              <b-col>
                <label class="color--basic" for="email">
                  {{ translation["navigation.Share.YourEmailAddress"] }}
                </label>
                <b-form-input
                  name="email"
                  v-model="$v.state.emailFrom.$model"
                  :state="validationState($v.state.emailFrom)"
                  aria-describedby="error-email"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.state.emailFrom.$dirty && !$v.state.emailFrom.required
                  "
                  id="error-email"
                  >{{ translation["general.Errors.Required"] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-else-if="
                    $v.state.emailFrom.$dirty && !$v.state.emailFrom.email
                  "
                  id="error-email"
                  >{{ translation["general.Errors.InvalidEmail"] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="pt-4">
              <b-col>
                <label class="color--basic">{{
                  translation["navigation.Share.AdditionalEmails"]
                }}</label>
                <b-row class="newsletter__emailsTo_container">
                  <b-col
                    cols="12"
                    :key="index"
                    v-for="(v, index) in $v.state.emails.$each.$iter"
                    class="newsletter__emailsTo_container__item"
                  >
                    <div class="newsletter__emailsTo_container__item--input">
                      <b-form-input
                        v-model="v.email.$model"
                        :state="validationState(v.email)"
                        aria-describedby="error-zxc"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="error-zxc"
                        v-if="v.email.$dirty && !v.email.required"
                        >{{ translation["general.Errors.Required"] }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        id="error-zxc"
                        v-else-if="v.email.$dirty && !v.email.email"
                        >{{ translation["general.Errors.InvalidEmail"] }}
                      </b-form-invalid-feedback>
                    </div>
                    <div
                      class="newsletter__emailsTo_container__item--icon"
                      v-if="state.emails.length > 1"
                    >
                      <i
                        aria-hidden="true"
                        class="cil-trash-x"
                        @click="removeEmail(index)"
                      />
                    </div>
                  </b-col>
                  <b-col cols="12" class="text-center mt-3">
                    <b-button
                      variant="primary"
                      @click="state.emails.push({ email: '' })"
                      >{{ translation["lessons.Form.EmailsAdd"] }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="pt-5 pb-3">
              <b-col class="mx-4 text-center">
                <b-button type="submit" variant="primary">
                  {{ translation["navigation.Share.Send"] }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
        <div v-if="isFormSubmitted">
          <b-container>
            <b-row>
              <b-col cols="12" class="mt-4">
                <div class="color--basic text-center">
                  {{ translation["navigation.Share.EmailWasSent"] }}
                </div>
              </b-col>
            </b-row>
            <b-row class="pt-5 pb-3">
              <b-col class="mx-4 text-center">
                <b-link
                  @click="hideModal"
                  class="text-uppercase btn btn-primary focus-smart"
                  >{{ translation["general.Close"] }}
                </b-link>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import { EventBus } from "@/services/event-bus.js";

export default {
  name: "ShareByMail",
  mixins: [ValidationMixin],
  props: {
    shareSubmitUrl: String,
    translation: Object,
  },
  mounted() {
    this.state.emails.push({ email: "" });
    EventBus.$on("share-content", this.setContentUrl);
  },
  data() {
    return {
      isFormSubmitted: false,
      hasValidationError: false,
      confirmationText: "",
      state: {
        contentUrl: window.location.origin + this.$route.fullPath,
        name: null,
        title: document.title,
        emailFrom: null,
        emails: [],
      },
    };
  },
  validations: {
    state: {
      name: { required },
      emailFrom: { required, email },
      emails: {
        required,
        $each: {
          email: {
            required,
            email,
          },
        },
      },
    },
  },
  methods: {
    removeEmail(index) {
      this.state.emails.splice(index, 1);
    },
    submit() {
      this.hasValidationError = false;
      this.$v.$touch();

      if (this.$v.$anyError) {
        this.hasValidationError = true;
        return;
      }
      this.$setBusy(true);
      axios({
        method: "POST",
        url: process.env.VUE_APP_BASE_API_URL + this.shareSubmitUrl,
        data: { ...this.state },
      })
        .then((response) => {
          if (response.status == 200) {
            this.isFormSubmitted = true;
          } else {
            this.hasValidationError = true;
          }
          this.$setBusy(false);
        })
        .catch(() => {
          this.hasValidationError = true;
          this.$setBusy(false);
        });
    },
    hideModal() {
      this.$bvModal.hide("modal-shared-mail");
    },
    setContentUrl(contentUrl) {
      this.isFormSubmitted = false;
      this.state.contentUrl = contentUrl
        ? contentUrl
        : window.location.origin + this.$route.fullPath;
    },
  },
  beforeDestroy() {
    EventBus.$off("share-content", this.setContentUrl);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.button {
  &--next-email {
    background-color: $color-main;
    color: $color-black;
    font-size: em(14);
    font-weight: $font-weight-regular;
  }
}
.newsletter {
  &__emailsTo_container {
    margin: 0;
    &__item {
      display: flex;
      padding-top: 10px;

      &--input {
        width: calc(100% - 35px);
      }

      &--icon {
        width: 35px;
        height: 40px;
        font-size: 1.25em;
        display: inline-block;
        padding-top: 10px;
        text-align: center;
        background-color: #3f3f3f;
        cursor: pointer;
      }
    }
    &__item:first-child {
      padding-top: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
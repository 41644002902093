<template>
  <div v-if="model.movieType" class="vd_list-item hover-outline item-video fake">
    <player
      :assetId="model.atdId"
      :jumpTime="model.umpTime"
      :translations="translations"
      :imageUrl="model.image.url"
      :lang="model.lang"
    >
    </player>
  </div>
  <div
    class="vd_list-item hover-outline item-video"
    :class="{
      'hover-scale': boxView,
      'hover-social': showSocial,
      selected: isSelected,
    }"
    v-else-if="alternative"
  >
    <div
      tabindex="0"
      class="link focus-smart"
      :aria-label="model.watch + ' ' + model.title"
      role="button"
      @click="clickItem"
      @keypress.enter="clickItem"
    >
    <avaliable
      :avaliable="model.avaliable"
      :showOutVod="model.showOutVod"
      :price="priceItem"
      :translations="translations"
    ></avaliable>
      <div
        aria-hidden="true"
        class="vd_list-cover vd_list-carousel"
        :class="{ 'background--nero': model.defaultIcon }"
        :style="backgroundDefaultIcon"
      >
        <div class="vd_play-icon-wrapper" v-if="!showSocial">
          <div class="vd_video-icon" v-if="model.type == 'Asset'">
            <svg width="21" height="22" viewBox="0 0 21 22">
              <path
                id="Union_5"
                data-name="Union 5"
                d="M1,22a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM1,14a1,1,0,0,1-1-1V10A1,1,0,0,1,1,9H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM9,9A1,1,0,0,1,8,8V5A1,1,0,0,1,9,4h3a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1ZM1,5A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H4A1,1,0,0,1,5,1V4A1,1,0,0,1,4,5Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div :aria-label="model.image ? model.image.title : ''" tabindex="-1">
          <lazy-image
            v-if="model.image"
            :source="model.image.url"
            :alt="model.title"
            style="aspect-ratio: 4/3"
          ></lazy-image>
          <img
            v-if="!model.image && !model.defaultIcon"
            src="/assets/placeholder.png"
            :alt="model.title"
          />
        </div>
      </div>
    </div>
    <div
      role="button"
      :aria-label="model.watch + ' ' + model.title"
      class="vd_list-description"
    >
      <div
        aria-hidden="true"
        v-if="model.defaultIcon"
        class="vd_list-description-icon vd_icon-link"
        tabindex="-1"
      >
        <i :class="!model.image && model.defaultIcon"></i>
      </div>
      <div class="vd_list-description-wrapper">
        <div class="vd_list-title-wrapper">
          <div class="vd_list-title">
            <h3>
              <div class="hover-left-right" tabindex="-1">
                {{ ctitle }}
              </div>
            </h3>
          </div>
        </div>
        <h4 v-if="model.showOnBox">
          <span class="showbox">{{ model.showOnBox.roleName }}:</span>&nbsp;<span
            class="showbox"
            v-for="(item, index) in model.showOnBox.persons"
            :key="index"
            >{{ getName(item, model.showOnBox.persons.length == 1)
            }}<span class="showbox" v-if="index < model.showOnBox.persons.length - 1"
              >,&nbsp;</span
            ></span
          >
        </h4>
        <h4 v-if="model.subtitle">{{ model.subtitle }}</h4>
        <div
          class="vd_list-description--text"
          v-if="model.description"
          v-html="model.description"
        ></div>
        <div v-if="model.lead" v-html="model.lead" class="lead"></div>
        <box-social
          :model="model"
          :showSocial="showSocial"
          :section="section"
          :translations="translations"
        ></box-social>
      </div>
    </div>
  </div>
  <div
    v-else
    tabindex="0"
    class="vd_list-item hover-outline item-video focus-smart"
    :class="{
      'hover-scale': boxView,
      'hover-social': showSocial,
      selected: isSelected,
    }"
    role="button"
    @click="clickItem"
    @keypress.enter="clickItem"
  >
    <avaliable
      :avaliable="model.avaliable"
      :showOutVod="model.showOutVod"
      :price="priceItem"
      :translations="translations"
    ></avaliable>
    <div
      class="vd_list-cover vd_list-carousel"
      :class="{ 'background--nero': model.defaultIcon }"
      :style="backgroundDefaultIcon"
    >
      <div class="vd_play-icon-wrapper" v-if="!showSocial">
        <div class="vd_video-icon" v-if="model.type == 'Asset'">
          <svg width="21" height="22" viewBox="0 0 21 22">
            <path
              id="Union_5"
              data-name="Union 5"
              d="M1,22a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm8-4a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM1,14a1,1,0,0,1-1-1V10A1,1,0,0,1,1,9H4a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1ZM9,9A1,1,0,0,1,8,8V5A1,1,0,0,1,9,4h3a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1ZM1,5A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H4A1,1,0,0,1,5,1V4A1,1,0,0,1,4,5Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div :aria-label="model.image ? model.image.title : ''" tabindex="-1">
        <lazy-image
          v-if="model.image"
          :source="model.image.url"
          :alt="model.title"
          style="aspect-ratio: 4/3"
        ></lazy-image>
        <img
          v-if="!model.image && !model.defaultIcon"
          src="/assets/placeholder.png"
          :alt="model.title"
        />
      </div>
    </div>
    <div class="vd_list-description">
      <div
        v-if="model.defaultIcon"
        class="vd_list-description-icon vd_icon-link"
        tabindex="-1"
      >
        <i :class="!model.image && model.defaultIcon"></i>
      </div>
      <div class="vd_list-description-wrapper">
        <div class="vd_list-title-wrapper">
          <div class="vd_list-title">
            <h3>
              <div class="hover-left-right" tabindex="-1">
                {{ ctitle }}
              </div>
            </h3>
          </div>
        </div>
        <h4 v-if="model.showOnBox">
          <span class="showbox">{{ model.showOnBox.roleName }}:</span>&nbsp;<span
            class="showbox"
            v-for="(item, index) in model.showOnBox.persons"
            :key="index"
            >{{ getName(item, model.showOnBox.persons.length == 1)
            }}<span class="showbox" v-if="index < model.showOnBox.persons.length - 1"
              >,&nbsp;</span
            ></span
          >
        </h4>
        <h4 v-if="model.subtitle">{{ model.subtitle }}</h4>
        <div
          class="vd_list-description--text"
          v-if="model.description"
          v-html="model.description"
        ></div>
        <div v-if="model.lead" v-html="model.lead" class="lead"></div>

        <box-social
          :model="model"
          :showSocial="showSocial"
          :section="section"
          :translations="translations"
        ></box-social>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/services/event-bus.js";
import BoxSocial from "../common/BoxSocial.vue";
import LazyImage from "../common/LazyImage.vue";
import Avaliable from "../common/Avaliable.vue";
import Player from "@/components/common/Player.vue";

export default {
  components: {
    BoxSocial,
    LazyImage,
    Avaliable,
    Player,
  },
  props: {
    model: Object,
    boxView: Boolean,
    isSelected: Boolean,
    section: String,
    alternative: Boolean,
    displayType: String,
    translations: Object,
  },
  data() {
    return {
      domain: location.origin,
    };
  },
  computed: {
    backgroundDefaultIcon() {
      if (this.model.defaultIcon && !this.model.image) {
        return (
          "background-image: url('/assets/placeholder_" +
          this.model.type.toLowerCase() +
          ".png')"
        );
      }
      return "";
    },
    priceItem() {
      if (this.model.prices)
        return {
          color: "rgba(0, 0, 0, 0.4) !important",
          text: `${this.model.prices.name} ${this.model.prices.price}`,
          textColor: "white",
          showBasket: true,
        };
      return null;
    },
    showSocial() {
      if (this.displayType == "grid") {
        return this.model.type == "Asset";
      }
      return false;
    },
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.domain}${this.model.url}`;
    },
    ctitle() {
      return this.model.additionalInfo
        ? this.model.title + " | " + this.model.additionalInfo
        : this.model.title;
    },
  },
  methods: {
    clickItem() {
      this.sendAnalytics();
      this.$router.push({
        path: this.model.url,
        query: this.$route.query,
      });
    },
    shareByMail: function () {
      this.$bvModal.show("modal-shared-mail");
      this.sendAnalytics("Kliknięcie w ikonkę", "Udostępnij przez E-mail");
      EventBus.$emit("share-content", `${this.domain}${this.model.url}`);
    },
    sendAnalytics(gAction, gLabel) {
      var name = "";
      switch (this.model.type) {
        case "Asset":
          name = "filmu";
          break;
        case "Article":
          name = "Artykułu";
          break;
        case "Quiz":
          name = "Quizu";
          break;
        case "Lesson":
          name = "Lekcji";
          break;
      }
      this.$setGAFormCustom(this.section, gAction + name, gLabel, false);
    },
    getName(text, full) {
      if (full) return text;
      let splited = text.split(" ");
      return splited[0].substring(0, 1) + ". " + splited[splited.length - 1];
    },
  },
};
</script>
<style lang="scss">
.vd_list-item {
  color: white;
}
.only-box-view {
  .link {
    width: 100%;
  }
}
.showbox {
  padding: 0 !important;
}
.vd_list-grid.only-box-view .vd_list-item {
  .link {
    height: 100%;
    .vd_list-carousel {
      padding: 0 !important;
      div {
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="vd_list-item item-video hover-social hover-outline gallery-hover">
    <i class="cil-images"></i>
    <div class="vd_list-cover p-0 galleryBtn" @click="openModal(index)" tabindex="0">
      <lazy-image
        :source="item.value.images[0].url"
        :alt="item.value.images[0].alt"
      ></lazy-image>
    </div>
    <div class="vd_list-description">
      <div class="vd_list-description-wrapper">
        <h4>Zobacz wszystkie: {{ item.value.images.length }}</h4>
      </div>
    </div>
    <carousel-container
      :model="galleryOptions"
      :translations="translations"
      :itemList="item.value.images"
      :galleryId="'modal_gallery_' + index"
      :section="'Sekcja: ' + title"
    >
      <template v-slot:main>
        <splide-slide v-for="(image, imageIndex) in item.value.images" :key="imageIndex">
          <image-box
            :model="image"
            :galleryId="'modal_gallery_' + index"
            :elementId="imageIndex"
            @click="sendAnalytics('Wyświetl galerię zdjęć')"
            :textOpen="translations['general.ExpendImage']"
          ></image-box>
        </splide-slide>
      </template>
    </carousel-container>
  </div>
</template>
<script>
import { EventBus } from "@/services/event-bus";
import { SplideSlide } from "@splidejs/vue-splide";
import CarouselContainer from "@/components/ListingView/CarouselContainer";
import LazyImage from "./LazyImage.vue";
import ImageBox from "@/components/ListingView/ImageBox";
export default {
  props: {
    item: Object,
    translations: Object,
    title: String,
    index: Number,
  },
  components: {
    CarouselContainer,
    ImageBox,
    LazyImage,
    SplideSlide,
  },
  data() {
    return {
      galleryOptions: {
        autoplay: false,
        interval: 5000,
        pagination: false,
        pauseOnHover: false,
        rewind: false,
        type: "loop",
      },
    };
  },
  methods: {
    sendAnalytics(gAction) {
      this.$setGAFormCustom("Sekcja: " + this.title, gAction, "", false);
    },
    openModal(id) {
      EventBus.$emit("expand", {
        value: true,
        id: "modal_gallery_" + id,
        elementId: 0,
      });
    },
  },
};
</script>

const VueOsDetect = {
    install: Vue => {
        let ua = window.navigator.userAgent;
        let pt = window.navigator.platform;
        let osObj = {};

        osObj.isIOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
        osObj.isAndroid = /Android/.test(ua);
        osObj.isBlackBerry = /BlackBerry/.test(pt);
        osObj.isMac = /Mac/.test(pt);
        osObj.isTizen = /SMART-TV/.test(navigator.userAgent) || /Tizen/.test(navigator.userAgent);
        // osObj.isTizen = true;
        osObj.isWindows = /Win/.test(pt);
        osObj.isLinux = /Linux/.test(pt) && !osObj.isAndroid && !osObj.isTizen;

        osObj.os =
            (osObj.isIOS ? 'iOS' :
                (osObj.isAndroid ? 'Android' :
                    (osObj.isBlackBerry ? 'BlackBerry' :
                        (osObj.isMac ? 'Mac' :
                            (osObj.isWindows ? 'Windows' :
                                (osObj.isLinux ? 'Linux' :
                                    null))))));

        osObj.version = null;

        if (osObj.isAndroid) {
            const splitUserAgent = ua.split('Android ');
            if (splitUserAgent.length > 1) {
                osObj.version = splitUserAgent[1].split(';')[0];
            }
        }
        else if (osObj.isWindows) {
            const splitUserAgent = ua.split('Windows NT ');
            if (splitUserAgent.length > 1) {
                const versionSubStr = splitUserAgent[1];

                if (versionSubStr.startsWith('5.0')) {
                    osObj.version = '2000';
                } else if (versionSubStr.startsWith('5.1')) {
                    osObj.version = 'XP';
                } else if (versionSubStr.startsWith('5.2')) {
                    osObj.version = 'Server';
                } else if (versionSubStr.startsWith('6.0')) {
                    osObj.version = 'Vista';
                } else if (versionSubStr.startsWith('6.1')) {
                    osObj.version = '7';
                } else if (versionSubStr.startsWith('6.2')) {
                    osObj.version = '8';
                } else if (versionSubStr.startsWith('6.3')) {
                    osObj.version = '8.1';
                } else if (versionSubStr.startsWith('10.0')) {
                    osObj.version = '10';
                }
            }
        }
        else if (osObj.isIOS) {
            const splitUserAgent = ua.split('OS ');
            if (splitUserAgent.length > 1) {
                const unformattedVersion = splitUserAgent[1].split(' ')[0];
                osObj.version = unformattedVersion.split('_').join('.');
            }
        }
        else if (osObj.isMac) {
            const splitUserAgent = ua.split('Mac OS X ');
            if (splitUserAgent.length > 1) {
                let endOfVersion = splitUserAgent[1].indexOf(';');
                const firstParantheses = splitUserAgent[1].indexOf(')');
                if (firstParantheses > -1 && (firstParantheses < endOfVersion || endOfVersion == -1)) {
                    endOfVersion = firstParantheses;
                }

                const unformattedVersion = splitUserAgent[1].substring(0, endOfVersion);
                osObj.version = unformattedVersion.split('_').join('.');
            }
        }
        else if (osObj.isBlackBerry) {
            let splitUserAgent = ua.split('BlackBerry9700/');
            if (splitUserAgent.length > 1) {
                osObj.version = splitUserAgent[1].split(' ')[0];
            } else {
                splitUserAgent = ua.split('Version/');
                if (splitUserAgent.length > 1) {
                    osObj.version = splitUserAgent[1].split(' ')[0];
                }
            }
        }
        Vue.prototype.$osDetect = osObj;
    }
};

export default VueOsDetect;

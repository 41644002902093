<template>
  <div class="Prosummary">
    <!-- start license? -->
    <div
      v-for="(currentLicense, index) in submitValue.licenseList"
      :key="index"
    >
      <b-row>
        <b-col>
          <div class="white-two m30">
            <b-container>
              <b-row class="progressBar">
                <b-col :sm="10">
                  <h2>
                    {{ translations["proOrder.License"] }} {{ index + 1 }}
                  </h2>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="form over">
            <b-row class="movie-selector-row">
              <b-col class="col-sm-10">
                <accordion
                  :tabId="'name' + index"
                  :accordionName="'Movies' + index"
                  v-for="(item, index) in currentLicense.assets"
                  :key="index"
                  role="tab"
                  class="movie-selector"
                >
                  <template v-slot:header>
                    <span>{{ item.title }}</span>
                    <i class="cil-chevron-bottom-alt"></i>
                    <i class="cil-chevron-left-alt"></i>
                  </template>
                  <template v-slot:main>
                    <div class="info">
                      <p>{{ item.category }}</p>
                      <p
                        class="person"
                        v-if="
                          item.showOnBox != null &&
                          item.showOnBox.person &&
                          item.showOnBox.person.length > 0
                        "
                      >
                        <span>{{ item.showOnBox.roleName }}:</span
                        ><span>{{ item.showOnBox.person[0] }}</span>
                      </p>
                      <p class="split">
                        <span>{{ item.country }}</span
                        ><span>{{ item.year }}</span>
                      </p>
                    </div>
                    <p class="desc">
                      {{ item.lead }}
                    </p>
                  </template>
                </accordion>
              </b-col>
            </b-row>
            <radio-buttons
              :header="model.translations['proOrder.IOrder']"
              :itemList="model.orderType"
              :select="currentLicense.orderType"
              :readonly="true"
              :name="'orderType' + index"
            ></radio-buttons>

            <transition name="slide-fade">
              <full-movie
                v-if="currentLicense.orderType == 'asset.full'"
                :readonly="true"
                :model="model"
                @ChangeValue="getValue"
                :current="currentLicense.full"
              ></full-movie>
              <part-movie
                v-else-if="currentLicense.orderType == 'asset.segments'"
                :readonly="true"
                :model="model"
                @ChangeValue="getValue"
                :current="currentLicense.part"
              ></part-movie>
              <frame-movie
                v-else-if="currentLicense.orderType == 'asset.frames'"
                :readonly="true"
                :model="model"
                @ChangeValue="getValue"
                :current="currentLicense.frame"
              ></frame-movie>
              ></transition
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="progressBar">
        <b-col :sm="12" class="text-center">
          <b-button
            v-show="submitValue.licenseList.length > 1"
            variant="primary"
            class="button--large mx-1"
            @click="delLicense(index)"
            >{{ translations["proOrder.DeleteLicense"] }}</b-button
          >
          <b-button
            variant="primary"
            class="button--large mx-1"
            @click="openLicense(index)"
            >{{ translations["proOrder.ChangeLicense"] }}</b-button
          >
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div class="form">
          <b-container>
            <b-row class="progressBar buttons mt-5">
              <b-col :sm="4" class="text-center">
                <!-- <b-button
                  variant="primary"
                  @click="save"
                  class="button--large button--summary"
                >
                  {{ model.translations["proOrder.SaveOrder"] }}</b-button
                > -->
                <b-button
                  variant="primary"
                  class="button--summary"
                  @click="sendOrder"
                  >{{ model.translations["proOrder.SendOrder"] }}</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import Accordion from "@/components/ListingView/Accordion.vue";
import RadioButtons from "@/components/ProForm/RadioButtons.vue";
import FullMovie from "@/components/ProForm/FullMovie.vue";
import PartMovie from "@/components/ProForm/PartMovie.vue";
import FrameMovie from "@/components/ProForm/FrameMovie.vue";
import saveOrder from "@/services/saveOrder";
export default {
  components: {
    Accordion,
    RadioButtons,
    FrameMovie,
    PartMovie,
    FullMovie,
  },
  props: {
    model: Object,
    submitValue: Object,
    translations: Object,
  },
  methods: {
    openLicense: function (index) {
      this.$emit("ChangeValue", index, "progress");
    },
    delLicense: function (index) {
      this.$emit("ChangeValue", index, "delete");
    },
    getValue: function () {},
    send(payload) {
      axios
        .post(`${process.env.VUE_APP_PRO_URL}/order`, payload)
        .then(() => {
          this.$setBusy(false);
          this.$emit("sendOk");
        })
        .catch(() => {
          this.$setBusy(false);
          this.$emit("sendFailed");
        });
    },
    async sendOrder() {
      this.$setBusy(true);
      var result = await saveOrder.prePare(this.submitValue);
      this.send(result);
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.movie-selector-row {
  justify-content: flex-end;
}
.movie-selector {
  max-width: 80%;
  border: none !important;
}
.Prosummary {
  .form {
    &.over {
      pointer-events: none;
      .text-area {
        pointer-events: all;
      }
      .custom-control-label {
        &::before {
          display: none;
        }
      }
    }
  }
}
</style>
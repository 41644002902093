<template>
  <router-link
    v-if="logo.url"
    :tabindex="$browserDetect.isApp ? -1 : 1"
    :to="logo.url"
    :title="logo.name"
    class="eulink"
    :class="{ eutopmenu: !isCollapsedVersion }"
  >
    <span class="eulogo-txt">{{ logo.name }}</span>
    <img :src="logo.urlImg" :alt="logo.alt" class="eulogo-img" />
  </router-link>
  <div
    v-else
    :title="logo.name"
    class="eulink"
    :class="{ eutopmenu: !isCollapsedVersion }"
  >
    <span class="eulogo-txt">{{ logo.name }}</span>
    <lazy-image class="eulogo-img" :source="logo.urlImg" :alt="logo.alt" />
  </div>
</template>
<script>
import LazyImage from "../common/LazyImage.vue";
export default {
  props: ["logo", "isCollapsedVersion"],
  components: { LazyImage },
  beforeDestroy() {
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
.eulink {
  line-height: 1;
  text-transform: none;
  font-weight: $font-weight-regular;
  display: flex;
  align-items: center;
  &.eutopmenu {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
.eulink:hover {
  text-decoration: none;
}
.eulogo-txt {
  font-size: 0.75em;
  min-width: 75px;
  text-align: right;
  @include media-breakpoint-between(md, xl) {
    font-size: em(12);
  }
}
.eulogo-img {
  border: 1px solid white;

  @include media-breakpoint-down(sm) {
    height: 40px;
    margin: 0 7px 0 0;
  }

  @include media-breakpoint-between(md, md) {
    height: 40px;

    margin: 0 11px 0 0;
  }

  @include media-breakpoint-up(lg) {
    height: 40px;
    margin: 0 0 0 12px;
  }
}
</style>


<template>
    <div class="quiz__questions__title">
        <b-container>
            <b-row>
                <b-col v-if="model.title" cols="12" class="text-left">
                    <p class="vd_text-small">{{ model.title }}</p>
                </b-col>
                <b-col v-if="model.text" cols="12" class="text-left vd_text-small" v-html="model.text"></b-col>
                <b-col v-if="model.images" cols="12" class="text-left">
                    <div class="vd_image-grid">
                        <div class="vd_image-grid-item text-center" :key="index" v-for="(image,index) in model.images">
                            <img :src="image.url" :alt="image.title">
                            <p class="pt-2">{{image.title}}</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "QuestionContent",
    props: {
        model: Object
    }

}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.vd_image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3em;
    margin-left: -8px;
    margin-right: -8px;
}

.vd_image-grid-item {
    flex-basis: calc(25% - 16px);
    margin: 8px;
    /*flex-grow: 1;*/
}

.vd_image-grid-item img {
    max-width: 100%;
}

@media (max-width: map-get($grid-breakpoints, "md")) {
    .vd_image-grid-item {
        flex-basis: calc(50% - 16px);
    }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
    .vd_image-grid-item {
        flex-basis: calc(100% - 16px);
    }
}
</style>
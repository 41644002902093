<template>
  <div>
    <movie-tabs :model="model" v-if="this.$route.query.b == 'ai'" />
    <div class="moviePage">
      <b-container fluid class="disableFlex containerFixed">
        <b-row class="top-row-spacer">
          <player
            ref="player"
            :assetId="canWatch ? model.atdId : null"
            :imageUrl="model.image.url"
            :loginUrl="model.loginUrl"
            :overlay="model.overlay"
            :showPreviousButton="
              model.seasonsModel && model.seasonsModel.previousEpisodeUrl ? true : false
            "
            :lang="model.lang"
            :showNextButton="
              model.seasonsModel && model.seasonsModel.nextEpisodeUrl ? true : false
            "
            :avaliable="model.avaliable"
            :jumpTime="model.jumpTime"
            :translations="model.translations"
            @player-status="onPlayerStatus"
            :title="title"
            :aditionalInfo="model.additionalInfo"
            :aditionalColor="model.colorInfo"
            :showContinuePlaying="model.isFonoteka ? false : true"
            :showOutVod="model.showOutVod"
          >
            <template v-if="!canWatch">
              <b-card
                v-if="islogged"
                :title="model.translations['moviePage.Adult.Header']"
                tag="div"
                class="mb-2"
              >
                <b-card-text>
                  {{ model.translations["moviePage.Adult.BodyConfirmAge"] }}
                </b-card-text>
                <div class="buttons">
                  <b-button @click="Confirm" href="" variant="primary">
                    {{ model.translations["moviePage.Adult.ConfirmAge"] }}</b-button
                  >
                  <b-form-checkbox
                    id="remember"
                    v-model="remeber"
                    name="remember"
                    value="1"
                    unchecked-value="0"
                  >
                    {{ model.translations["moviePage.Adult.Remember"] }}
                  </b-form-checkbox>
                </div>
              </b-card>
              <b-card
                v-else
                :title="model.translations['moviePage.Adult.Header']"
                tag="div"
                class="mb-2 text-center"
              >
                <b-card-text>
                  {{ model.translations["moviePage.Adult.BodyLogin"] }}
                </b-card-text>
                <div class="buttons center">
                  <b-button :to="model.loginUrl + currentUrl" variant="primary">
                    {{ model.translations["general.Login"] }}</b-button
                  >
                </div>
              </b-card>
            </template>
          </player>
          <div class="movieInfo">
            <div class="descriptionBox">
              <div class="movieHeader focus-smart">
                <div class="description">
                  <h1>{{ model.title }}</h1>
                  <p>
                    <span v-if="model.genre" class="category">
                      {{ getStringFromArray(model.genre, 2) }}
                    </span>
                    <br v-if="!model.isPackage" />
                    <span
                      v-if="model.showOnBox.persons && model.showOnBox.persons.length > 0"
                      class="Director"
                    >
                      <span class="showbox">{{ model.showOnBox.roleName }}:</span
                      >&nbsp;<span
                        class="showbox"
                        v-for="(item, index) in model.showOnBox.persons"
                        :key="index"
                        >{{ getName(item, model.showOnBox.persons.length == 1)
                        }}<span
                          class="showbox"
                          v-if="index < model.showOnBox.persons.length - 1"
                          >,&nbsp;</span
                        ></span
                      >
                    </span>
                    <br v-if="!model.isPackage" />
                    <span v-if="model.country" class="country"> {{ model.country }} </span
                    >&nbsp;
                    <span v-if="model.year" class="year">{{ model.year }}</span>
                  </p>
                </div>
                <div v-if="!model.isPackage">
                  <div class="moreInfo" v-if="!this.$browserDetect.isApp">
                    <div
                      v-if="model.quality && model.quality.img"
                      class="additional"
                      role="img"
                      :title="model.quality.name"
                    >
                      <img
                        aria-hidden="true"
                        style="icon"
                        :src="model.quality.img"
                        :alt="model.quality.name"
                        v-b-tooltip.hover
                        class="icon"
                      />
                    </div>
                    <div v-if="model.duration" class="additional">
                      <div
                        v-b-tooltip.hover
                        :title="
                          model.translations['moviePage.DurationTitle'] +
                          ' ' +
                          model.duration +
                          ' ' +
                          model.translations['carousel.Min']
                        "
                        role="img"
                      >
                        <img
                          aria-hidden="true"
                          class="icon"
                          src="/assets/clock.svg"
                          :alt="model.translations['moviePage.DurationTitle']"
                        />
                        <span class="time">
                          {{ model.duration }}
                          {{ model.translations["carousel.Min"] }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="model.language.sound && model.language.sound.length > 0"
                      class="additional"
                    >
                      <div
                        v-b-tooltip.hover
                        :title="getLanguage(model.language.sound, true, true)"
                        role="img"
                      >
                        <img
                          aria-hidden="true"
                          class="icon"
                          src="/assets/audio.svg"
                          :alt="model.translations['moviePage.AviableSound']"
                        />
                        <span aria-hidden="true" class="text">{{
                          getLanguage(model.language.sound, false, true)
                        }}</span>
                      </div>
                    </div>
                    <div v-if="model.language.subtitle" class="additional">
                      <div
                        v-b-tooltip.hover
                        :title="getLanguage(model.language.subtitle, true, false)"
                        role="img"
                      >
                        <img
                          aria-hidden="true"
                          class="icon"
                          src="/assets/subtitles.svg"
                          :alt="model.translations['moviePage.AviableSubtitles']"
                        />
                        <span aria-hidden="true" class="text">{{
                          getLanguage(model.language.subtitle, false, false)
                        }}</span>
                      </div>
                    </div>
                    <div
                      v-if="model.categoryAge && model.categoryAge.img"
                      class="categoryAge additional"
                    >
                      <img
                        class="icon"
                        :src="model.categoryAge.img"
                        v-b-tooltip.hover
                        :title="model.categoryAge.name"
                      />
                      <img
                        v-for="(item, index) in model.warning"
                        :key="index"
                        class="icon"
                        :src="item.img"
                        v-b-tooltip.hover
                        :title="item.name"
                      />
                    </div>
                    <div class="additional" v-if="model.language.audioDescription">
                      <img
                        class="icon"
                        src="/assets/AD.svg"
                        v-b-tooltip.hover
                        :title="model.translations['moviePage.AudioDescription']"
                      />
                    </div>
                    <div class="additional" v-if="model.language.deaf">
                      <img
                        class="icon"
                        src="/assets/n.svg"
                        v-b-tooltip.hover
                        :title="model.translations['moviePage.Deaf']"
                      />
                    </div>
                    <div v-if="model.isFonoteka" class="additional audiobtn">
                      <b-button @click="loop = !loop" :pressed="loop" variant="secondary">
                        <i class="cil-loop"></i>
                        {{ model.translations["soundLibrary.DoLoop"] }}
                      </b-button>
                    </div>
                  </div>
                  <div class="moreInfo" v-else>
                    <div v-if="model.quality && model.quality.img" class="additional">
                      <img
                        :tabindex="this.$getTabIndex()"
                        style="icon"
                        :src="model.quality.img"
                        :title="model.quality.name"
                        v-b-tooltip.hover
                        class="icon"
                      />
                    </div>
                    <div v-if="model.duration" class="additional">
                      <div
                        :tabindex="this.$getTabIndex()"
                        v-b-tooltip.hover
                        :title="
                          model.translations['moviePage.DurationTitle'] +
                          ' ' +
                          model.duration +
                          ' ' +
                          model.translations['carousel.Min']
                        "
                      >
                        <img class="icon" src="/assets/clock.svg" />
                        <span class="time">
                          {{ model.duration }}
                          {{ model.translations["carousel.Min"] }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="model.language.sound && model.language.sound.length > 0"
                      class="additional"
                    >
                      <div
                        :tabindex="this.$getTabIndex()"
                        v-b-tooltip.hover
                        :title="getLanguage(model.language.sound, true, true)"
                      >
                        <img class="icon" src="/assets/audio.svg" />
                        <span class="text">{{
                          getLanguage(model.language.sound, false, true)
                        }}</span>
                      </div>
                    </div>
                    <div v-if="model.language.subtitle" class="additional">
                      <div
                        :tabindex="this.$getTabIndex()"
                        v-b-tooltip.hover
                        :title="getLanguage(model.language.subtitle, true, false)"
                      >
                        <img class="icon" src="/assets/subtitles.svg" />
                        <span class="text">{{
                          getLanguage(model.language.subtitle, false, false)
                        }}</span>
                      </div>
                    </div>
                    <div
                      v-if="model.categoryAge && model.categoryAge.img"
                      class="categoryAge additional"
                    >
                      <img
                        :tabindex="this.$getTabIndex()"
                        class="icon"
                        :src="model.categoryAge.img"
                        v-b-tooltip.hover
                        :title="model.categoryAge.name"
                      />
                      <img
                        v-for="(item, index) in model.warning"
                        :key="index"
                        class="icon"
                        :src="item.img"
                        v-b-tooltip.hover
                        :title="item.name"
                      />
                    </div>
                    <div class="additional" v-if="model.language.audioDescription">
                      <img
                        :tabindex="this.$getTabIndex()"
                        class="icon"
                        src="/assets/AD.svg"
                        v-b-tooltip.hover
                        :title="model.translations['moviePage.AudioDescription']"
                      />
                    </div>
                    <div class="additional" v-if="model.language.deaf">
                      <img
                        :tabindex="this.$getTabIndex()"
                        class="icon"
                        src="/assets/n.svg"
                        v-b-tooltip.hover
                        :title="model.translations['moviePage.Deaf']"
                      />
                    </div>
                    <div v-if="model.isFonoteka" class="additional audiobtn">
                      <b-button
                        @click="loop = !loop"
                        :pressed="loop"
                        v-if="model.isFonoteka"
                        variant="secondary"
                      >
                        <i class="cil-loop"></i>
                        {{ model.translations["soundLibrary.LoopPlaylist"] }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="lead focus-smart" v-if="model.lead">
              <p>
                {{ model.lead }}
              </p>
            </div>
            <div class="longDescription lead focus-smart" v-if="model.description">
              <accordion
                accordionName="Description"
                ref="Descr"
                tabId="Descr"
                :collapsed="!expended"
              >
                <template v-slot:header v-if="!expended">
                  <div class="descriptionHeader extra">
                    <span>{{
                      model.translations["moviePage.LongDescription.Show"]
                    }}</span>
                    <div class="showAll">
                      {{ model.translations["general.ShowMore"] }}
                    </div>
                    <i class="cil-chevron-bottom-alt"></i>
                  </div>
                  <div class="slim descriptionHeader">
                    <span>{{
                      model.translations["moviePage.LongDescription.Hide"]
                    }}</span>
                    <div class="showAll">
                      {{ model.translations["general.ShowLess"] }}
                    </div>
                    <i class="cil-chevron-top-alt"></i>
                  </div>
                </template>
                <template v-slot:main>
                  <div v-html="model.description"></div>
                </template>
              </accordion>
            </div>
            <div v-if="model.seasonsModel && model.seasonsModel.seasons">
              <div
                class="ListViewer vd_list-grid only-box-view"
                v-for="(season, index) in model.seasonsModel.seasons"
                :key="index"
              >
                <carousel-container
                  :model="model.seasonsModel.carouselOptions"
                  :translations="model.translations"
                  :itemList="season.episodes"
                  :header="season.title"
                >
                  <template v-slot:header>
                    <h3>{{ season.title }}</h3>
                  </template>
                  <template v-slot:main>
                    <splide-slide
                      v-for="(episode, index) in season.episodes"
                      :key="index"
                    >
                      <carousel-item
                        :model="episode"
                        :box-view="true"
                        :isSelected="episode.selected"
                        :section="'Sekcja odcinki:' + model.title"
                        :alternative="true"
                        displayType="grid"
                        :translations="model.translations"
                      ></carousel-item>
                    </splide-slide>
                  </template>
                </carousel-container>
              </div>
            </div>
            <share-control
              :translations="model.translations"
              :favorite-model="model.favoriteModel"
              :title="model.title"
              :useAddToPlaylist="model.isFonoteka && isLoggedIn"
              section="Sekcje: podstawowe informacje o filmie"
              v-if="!model.isPackage"
            ></share-control>
            <div class="basicInfo" v-if="!model.isPackage">
              <div class="cast">
                <div class="fe-item section second" v-if="model.distribution">
                  <span class="fe-key who">{{
                    model.translations["moviePage.Distributor"]
                  }}</span>
                  <span class="fe-value"
                    ><span v-for="(item, index) in model.distribution" :key="index"
                      ><b-link
                        :to="
                          model.searchUrl + '?searchPhrase.3=' + encodeURIComponent(item)
                        "
                        class="hover-left-right focus-smart"
                        @click="
                          sendAnalytics(model.translations['moviePage.Distributor'], item)
                        "
                        >{{ item }}</b-link
                      >
                      <span v-if="index < model.distribution.length - 1"
                        >,&nbsp;</span
                      ></span
                    ></span
                  >
                </div>
                <div
                  class="fe-item section second"
                  v-for="(item, index) in getHonorde(model.honoreds)"
                  :key="index"
                >
                  <span class="fe-key who">{{ item.label }}:</span>
                  <span class="fe-value">
                    <span v-for="(p, index) in item.persons" :key="index">
                      <span v-if="index > 0"></span>
                      <b-link
                        :to="model.searchUrl + '?searchPhrase.3=' + encodeURIComponent(p)"
                        :key="index"
                        class="hover-left-right focus-smart"
                        @click="sendAnalytics(item.label, p)"
                        >{{ p }}
                      </b-link>
                      <span v-if="index < item.persons.length - 1">,&nbsp;</span>
                    </span>
                  </span>
                </div>
                <div class="fe-item section second" v-if="model.genre">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Genre"] }}:
                  </span>
                  <span class="fe-value"> {{ getStringFromArray(model.genre, 1) }}</span>
                </div>
                <div class="fe-item section second" v-if="model.musicGenre">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.MusicStyle"] }}:
                  </span>
                  <span class="fe-value">
                    {{ getStringFromArray(model.musicGenre, 1) }}</span
                  >
                </div>
                <div class="fe-item section second" v-if="model.premier">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Premier"] }}:
                  </span>
                  <span class="fe-value"> {{ model.premier }}</span>
                </div>
                <!-- <div class="fe-item section second" v-if="model.signature">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Signature"] }}:
                  </span>
                  <span class="fe-value"> {{ model.signature }}</span>
                </div> -->
                <div class="fe-item section second" v-if="model.usedIn">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.UsedIn"] }}:
                  </span>
                  <span class="fe-value"> {{ model.usedIn }}</span>
                </div>
                <div class="fe-item section second" v-if="model.soundSource">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.SoundSource"] }}:
                  </span>
                  <span class="fe-value"> {{ model.soundSource }}</span>
                </div>
                <div class="fe-item section second" v-if="model.tags">
                  <span class="fe-key who">
                    {{ model.translations["moviePage.Tags"] }}:
                  </span>
                  <span class="fe-value">
                    <span v-for="(item, index) in model.tags" :key="index"
                      >{{ index > 0 ? ", " : "" }}
                      <b-link
                        :to="
                          model.searchUrl + '?searchPhrase.3=' + encodeURIComponent(item)
                        "
                        class="hover-left-right focus-smart"
                        @click="sendAnalytics(model.translations['moviePage.Tags'], item)"
                        >{{ item }}</b-link
                      >
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <gallery-alternative
            v-if="
              !model.isFonoteka && canWatch && model.images && model.images.length > 0
            "
            :options="model.galeryOptions"
            :translations="model.translations"
            :items="model.images"
            :title="model.title"
          ></gallery-alternative>
          <div
            class="ListViewer vd_list-grid only-box-view"
            v-else-if="model.isPackage && model.avaliableMovie"
          >
            <home-grid
              :model="avaliableModel"
              :translations="model.translations"
            ></home-grid>
          </div>
          <div class="movieMoreInfo">
            <accordion
              :collapsed="!expended"
              tabId="cast"
              ref="cast"
              accordionName="main"
              v-if="model.cast && !model.isPackage"
            >
              <template v-slot:header>
                <span>{{ creditsHeader }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <div class="cast">
                  <div
                    class="fe-item section"
                    v-for="(item, index) in model.cast"
                    :key="index"
                  >
                    <span class="fe-key who"> {{ item.roleName }}:</span>
                    <span class="fe-value" v-if="item.isCast">
                      <div class="cast-row">
                        <b-col cols="12" lg="6">
                          <span
                            v-for="(castItem, cindex) in item.castItems.slice(
                              0,
                              item.castItems.length / 2
                            )"
                            :key="cindex"
                            class="cast-item"
                          >
                            <b-link
                              :to="
                                model.searchUrl +
                                '?searchPhrase.3=' +
                                encodeURIComponent(castItem.person)
                              "
                              @click="sendAnalytics(item.roleName, castItem.person)"
                              class="hover-left-right-black focus-smart"
                              >{{ castItem.person }}
                            </b-link>
                            <span class="cast-role" v-if="castItem.role ? true : false">
                              - {{ castItem.role }}</span
                            >
                          </span>
                        </b-col>
                        <b-col cols="12" lg="6">
                          <span
                            v-for="(castItem, cindex) in item.castItems.slice(
                              item.castItems.length / 2 + 1
                            )"
                            :key="cindex"
                            class="cast-item"
                          >
                            <b-link
                              :to="
                                model.searchUrl +
                                '?searchPhrase.3=' +
                                encodeURIComponent(castItem.person)
                              "
                              @click="sendAnalytics(item.roleName, castItem.person)"
                              class="hover-left-right-black focus-smart"
                              >{{ castItem.person }}
                            </b-link>
                            <span class="cast-role" v-if="castItem.role ? true : false">
                              - {{ castItem.role }}</span
                            >
                          </span>
                        </b-col>
                      </div>
                    </span>
                    <span class="fe-value" v-if="!item.isCast">
                      <span v-for="(p, index) in item.persons" :key="'c' + index">
                        <b-link
                          :to="
                            model.searchUrl + '?searchPhrase.3=' + encodeURIComponent(p)
                          "
                          :key="index"
                          class="hover-left-right-black focus-smart"
                          @click="sendAnalytics(item.roleName, item.person)"
                          >{{ p }}
                        </b-link>
                        <span v-if="index < item.persons.length - 1">,&nbsp;</span>
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </accordion>
            <accordion
              tabId="aword"
              ref="aword"
              accordionName="main"
              v-if="model.awords && model.awords.length > 0"
              :collapsed="!expended"
            >
              <template v-slot:header>
                <span> {{ model.translations["moviePage.Awords"] }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <div class="focus-smart customText">
                  <p v-for="(item, index) in model.awords" :key="index">
                    {{ item }}
                  </p>
                </div>
              </template>
            </accordion>
            <accordion
              tabId="AdditionalMaterial"
              ref="AdditionalMaterial"
              accordionName="main"
              v-if="model.additionalMaterials && model.additionalMaterials.length > 0"
              :collapsed="!expended"
              initInside
            >
              <template v-slot:header>
                <span> {{ model.translations["moviePage.AdditionalMaterial"] }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <b-col
                  cols="12"
                  :sm="getColumnSize(section.alias)"
                  :key="index"
                  v-for="(section, index) in model.additionalMaterials"
                >
                  <div
                    class="ListViewer"
                    v-if="section.alias == 'aditionalMaterialGallery'"
                  >
                    <grid :className="section.value.options.type + ' wrap'">
                      <template v-slot:main>
                        <gallery-item-extension
                          :model="item"
                          v-for="(item, index) in section.value.images"
                          :key="index"
                        >
                        </gallery-item-extension>
                      </template>
                    </grid>
                  </div>
                  <text-block
                    v-if="section.alias == 'textSection'"
                    :text="section.value.text"
                  >
                  </text-block>
                  <text-block-with-title
                    v-if="section.alias == 'textWithTitleSection'"
                    :model="section.value"
                  >
                  </text-block-with-title>
                  <article-image
                    v-if="section.alias == 'imageSection'"
                    :model="section.value"
                  >
                  </article-image>
                  <download-control
                    v-if="section.alias == 'downloadSection'"
                    :model="section.value"
                  >
                  </download-control>
                </b-col>
              </template>
            </accordion>
            <accordion
              v-for="(item, index) in getHonorde(model.accordions)"
              :key="index"
              :tabId="item.name"
              accordionName="main"
              :ref="item.name"
              :collapsed="!expended"
            >
              <template v-slot:header>
                <span> {{ item.name }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <div class="focus-smart customText" v-if="'accordion' == item.alias">
                  <p v-html="item.text"></p>
                </div>
                <list-container
                  v-else-if="item.movies && item.movies.length > 0"
                  :items="item.movies"
                  display-type="grid"
                  :section="'Sekcja: ' + model.name"
                  :translations="model.translations"
                  :key="index"
                ></list-container>
              </template>
            </accordion>
            <accordion
              tabId="ProfessionalInfoAccordion"
              accordionName="movieDetails"
              :collapsed="!expended"
              v-if="
                isPro &&
                (model.storage &&
                model.storage.length > 0)||
                (model.language.subtitle &&
                model.language.subtitle.length > 0)
              "
            >
              <template v-slot:header>
                <span>{{ model.translations["moviePage.ProInfo.Accordion"] }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>

              <template v-slot:main>
                <!-- Dostępne Nośniki (Available Media) Section -->
                <div v-if="isPro && model.storage && model.storage.length > 0">
                  <h5>{{ model.translations["moviePage.AvailableMedia"] }}</h5>
                  <ul>
                    <li v-for="(item, index) in model.storage" :key="index">
                      {{ item }}
                    </li>
                  </ul>
                </div>

                <!-- Napisy (Subtitles) Section -->
                <div v-if="model.language.subtitle && model.language.subtitle.length > 0">
                  <h5>{{ model.translations["moviePage.Subtitles"] }}</h5>
                  <ul>
                    <li v-for="(subtitle, index) in model.language.subtitle" :key="index">
                      {{ subtitle.fullName }}
                    </li>
                  </ul>
                </div>
              </template>
            </accordion>

            <!-- <accordion tab-id="proForm" accordion-name="proForm">
            <template v-slot:header>
              <span>
                {{ model.translations["moviePage.ProForm.SectionTitle"] }}</span
              >
              <i class="cil-chevron-bottom-alt"></i>
              <i class="cil-chevron-left-alt"></i>
            </template>
            <template v-slot:main>
              <pro-form :model="model.proFormViewModel"></pro-form>
            </template>
          </accordion> -->
            <accordion
              v-if="model.subjects != null && model.subjects.length > 0"
              accordionName="main"
              tabId="Tematy"
              :collapsed="!expended"
            >
              <template v-slot:header>
                <span> {{ model.translations["moviePage.Subjects"] }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>

              <template v-slot:main>
                <table class="subjects">
                  <tr class="subject">
                    <th>{{ model.translations["moviePage.Subject.Title"] }}</th>
                    <th>{{ model.translations["moviePage.Subject.Start"] }}</th>
                    <th>{{ model.translations["moviePage.Subject.End"] }}</th>
                  </tr>
                  <tr
                    class="subject"
                    v-for="(item, index) in model.subjects"
                    :key="index"
                  >
                    <td>
                      <div>{{ item.title }}</div>
                    </td>
                    <td>
                      <a
                        @click.prevent="
                          goTo(item.startCode);
                          subjectAnalytics(item.title, true);
                        "
                        class="tclink focus-smart"
                        ><i class="cis-media-play-circle"></i>
                        <span class="short">{{ item.startCode.substring(0, 8) }}</span>
                        <span class="full">{{ item.startCode }}</span></a
                      >
                    </td>
                    <td>
                      <a
                        @click.prevent="
                          goTo(item.endCode);
                          subjectAnalytics(item.title, false);
                        "
                        class="tclink focus-smart"
                      >
                        <span class="short">{{ item.endCode.substring(0, 8) }}</span>
                        <span class="full">{{ item.endCode }}</span></a
                      >
                    </td>
                  </tr>
                </table>
              </template>
            </accordion>
            <accordion
              :collapsed="!expended"
              tabId="proInfo"
              ref="proInfo"
              accordionName="main"
              v-if="model.proInfo"
            >
              <template v-slot:header>
                <span>{{ model.translations["moviePage.ProInfo"] }}</span>
                <i class="cil-chevron-bottom-alt"></i>
                <i class="cil-chevron-left-alt"></i>
              </template>
              <template v-slot:main>
                <div class="cast focus-smart">
                  <div
                    class="fe-item section"
                    v-for="(item, index) in model.proInfo"
                    :key="index"
                  >
                    <span class="fe-key who"> {{ item.key }}:</span>

                    <span
                      class="fe-value"
                      v-if="
                        item.type == 1 && (item.value[0].person || item.value[0].role)
                      "
                    >
                      <div class="cast-row">
                        <b-col
                          cols="12"
                          lg="6"
                          v-if="item.value.slice(0, item.value.length / 2).length > 0"
                        >
                          <span
                            v-for="(castItem, cindex) in item.value.slice(
                              0,
                              item.value.length / 2
                            )"
                            :key="cindex"
                            class="cast-item"
                          >
                            <b-link
                              :to="
                                model.searchUrl +
                                '?searchPhrase.3=' +
                                encodeURIComponent(castItem.person)
                              "
                              @click="sendAnalytics(item.roleName, castItem.person)"
                              class="hover-left-right-black focus-smart"
                              >{{ castItem.person }}
                            </b-link>
                            <span class="cast-role" v-if="castItem.role ? true : false">
                              - {{ castItem.role }}</span
                            >
                          </span>
                        </b-col>
                        <b-col cols="12" lg="6">
                          <span
                            v-for="(castItem, cindex) in item.value.slice(
                              item.value.length / 2
                            )"
                            :key="cindex"
                            class="cast-item"
                          >
                            <b-link
                              :to="
                                model.searchUrl +
                                '?searchPhrase.3=' +
                                encodeURIComponent(castItem.person)
                              "
                              @click="sendAnalytics(item.roleName, castItem.person)"
                              class="hover-left-right-black focus-smart"
                              >{{ castItem.person }}
                            </b-link>
                            <span class="cast-role" v-if="castItem.role ? true : false">
                              - {{ castItem.role }}</span
                            >
                          </span>
                        </b-col>
                      </div>
                    </span>

                    <span class="fe-value" v-else-if="item.type == 1">
                      <span
                        class="cast-item"
                        v-for="(pitem, pIndex) in item.value"
                        :key="pIndex"
                      >
                        <span>{{ pitem }}</span>
                        <span v-if="item.value.length - 1 > pIndex">,&nbsp;</span>
                      </span>
                    </span>
                    <span class="fe-value" v-if="item.type == 0">
                      {{ item.value }}
                    </span>
                  </div>
                </div>
              </template></accordion
            >
          </div>
          <div
            class="ListViewer vd_list-grid only-box-view"
            v-if="model.reletedFromCategory && model.reletedFromCategory.items"
          >
            <home-grid
              v-if="model.reletedFromCategory"
              :model="model.reletedFromCategory"
              :translations="model.translations"
              class="grid releated-grid mb-4"
            ></home-grid>
          </div>
          <div
            class="ListViewer vd_list-grid only-box-view"
            v-if="model.reletedContent && model.reletedContent.items"
          >
            <carousel-container
              :model="model.reletedContent.options"
              :translations="model.translations"
              :itemList="model.reletedContent.items"
              :header="
                model.isPackage
                  ? model.translations['moviePage.ReletedPackage']
                  : model.reletedContent.header
              "
            >
              <template v-slot:header>
                <h2 v-if="model.isPackage">
                  {{ model.translations["moviePage.ReletedPackage"] }}
                </h2>
                <h2 v-else>{{ model.reletedContent.header }}</h2>
              </template>
              <template v-slot:main>
                <splide-slide
                  v-for="(item, index) in model.reletedContent.items"
                  :key="index"
                >
                  <carousel-item
                    :alternative="true"
                    :model="item"
                    :box-view="true"
                    section="Sekcje: podobne filmy"
                    displayType="grid"
                    :translations="model.translations"
                  ></carousel-item>
                </splide-slide>
              </template>
            </carousel-container>
          </div>
        </b-row>
      </b-container>
      <b-container> </b-container>
    </div>
  </div>
</template>
<script>
import MovieTabs from "../components/common/MovieTabs.vue";
import { SplideSlide } from "@splidejs/vue-splide";
import Player from "@/components/common/Player.vue";
import { PlayerStatus } from "@/components/common/Player.vue";
import Accordion from "../components/ListingView/Accordion.vue";
import GalleryItemExtension from "../components/ListingView/GalleryItemExtension.vue";
import TextBlock from "@/components/common/TextBlock";
import TextBlockWithTitle from "@/components/common/TextBlockWithTitle";
import DownloadControl from "@/components/common/DownloadControl";
import ShareControl from "../components/common/ShareControl.vue";
import CarouselContainer from "../components/ListingView/CarouselContainer.vue";
import Grid from "../components/ListingView/Grid.vue";
import CarouselItem from "../components/ListingView/CarouselItem.vue";
import GalleryAlternative from "../components/common/GalleryAlternative.vue";
import authService from "@/services/authService";
import { getCookie, setCookie } from "tiny-cookie";
import HomeGrid from "../components/HomePage/HomeGrid.vue";
import ListContainer from "@/components/Lists/ListContainer";
// import ProForm from "@/components/ListingView/ProForm";

export default {
  components: {
    MovieTabs,
    SplideSlide,
    Accordion,
    Player,
    GalleryItemExtension,
    TextBlock,
    TextBlockWithTitle,
    DownloadControl,
    ShareControl,
    CarouselContainer,
    Grid,
    CarouselItem,
    GalleryAlternative,
    HomeGrid,
    ListContainer,
  },
  data() {
    return {
      isPro: false,
      events: [],
      expended: false,
      canWatch: false,
      islogged: false,
      remeber: "0",
      currentUrl: "",
      avaliableModel: {},
      title: "",
      loop: false,
    };
  },
  props: {
    model: Object,
  },
  updated() {
    if (this.$route.query.s) {
      let seconds = parseInt(this.$route.query.s);
      if (!isNaN(seconds)) {
        this.$nextTick(function () {
          setTimeout(() => this.$refs.player.goTo(seconds), 1000);
        });
      }
    }
  },
  methods: {
    Confirm() {
      this.canWatch = true;
      if (this.remeber) {
        const now = new Date();
        now.setDate(now.getDate() + 360);
        setCookie("Adult", this.remeber, {
          expires: now.toGMTString(),
        });
      }
      setTimeout(this.checkCanFocus, 100);
    },
    checkCanFocus() {
      var item = document.getElementsByClassName("rgp-widget__button");
      if (item.length > 0) window.RedgalaxyNav.focusElement(".rgp-widget__button");
      else setTimeout(this.checkCanFocus, 500);
    },
    isCollapsed() {
      this.expended = this.$isTizen(this.$browserDetect) || this.model.isPackage;
    },
    getName(text, full) {
      if (full) return text;
      let splited = text.split(" ");
      return splited[0].substring(0, 1) + ". " + splited[splited.length - 1];
    },
    onPlayerStatus(status) {
      if (status == PlayerStatus.NEXT) {
        this.$router.push({
          path: this.model.seasonsModel.nextEpisodeUrl,
          query: { a: true },
        });
      } else if (status == PlayerStatus.PREVIOUS) {
        this.$router.push({
          path: this.model.seasonsModel.previousEpisodeUrl,
          query: { a: true },
        });
      } else if (status == PlayerStatus.ENDED) {
        if (this.loop) {
          this.$refs.player.startPlayer(0);
        }
      }
    },
    getStringFromArray: function (array, type) {
      if (array) {
        if (type == 0) {
          var value = array.slice(0, 2).join(" ");
          if (array.length > 2) {
            value = value + " +" + (array.length - 2);
          }
          return value;
        } else if (type == 1) return array.join(", ");
        else if (type == 2) return array.slice(0, 1).join(" ");
      }
    },
    getSearchLinked: function (array) {
      return array.join(", ");
    },
    getHonorde: function (array) {
      if (array && array.length > 0) return array;
      return [];
    },
    getColumnSize(alias) {
      if (
        alias == "textSection" ||
        alias == "textWithTitleSection" ||
        alias == "aditionalMaterialGallery"
      ) {
        return 12;
      }
      if (alias == "imageSection") {
        return 8;
      }
      if (alias == "downloadSection") {
        return 6;
      }
    },
    getLanguage: function (array, GetFull, IsSound) {
      var value = [];
      for (var i = 0; i < array.length; i++) {
        if (GetFull) {
          value.push(array[i].fullName);
        } else {
          value.push(array[i].shortName);
        }
      }
      if (!GetFull) {
        if (array.length > 2) {
          value = value.slice(0, 2);
        }
        return value.join(" ");
      } else {
        if (IsSound)
          return (
            this.model.translations["moviePage.AviableSound"] +
            " " +
            value.join(" ") +
            (this.model.audioChannels
              ? ", " + this.model.audioChannels.toLowerCase()
              : "")
          );
        else
          return (
            this.model.translations["moviePage.AviableSubtitles"] + " " + value.join(" ")
          );
      }
    },
    goTo: function (time) {
      var test = time.split(":");
      var seconds = +test[0] * 60 * 60 + +test[1] * 60 + +test[2];
      this.$refs.player.goTo(seconds);
      // this.addHashToLocation({ s: seconds });
    },
    subjectAnalytics(subject, start) {
      this.$setGAFormCustom(
        "Sekcje: tematy",
        "Kliknął tytuł tematu: " + subject,
        start ? "start" : "koniec"
      );
    },
    getTCLink(time) {
      var test = time.split(":");
      var seconds = +test[0] * 60 * 60 + +test[1] * 60 + +test[2];
      return this.$route.path + "?s=" + seconds;
    },
    sendAnalytics(gAction, gLabel) {
      this.$setGAFormCustom(
        "Sekcja: podstawowe informacje o filmie",
        "Kliknął element: " + gAction,
        gLabel,
        false
      );
    },
    serialize(params) {
      if (params) {
        var queryString = Object.keys(params)
          .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
          })
          .join("&");
        return queryString;
      }
      return "";
    },
    addHashToLocation(params) {
      history.pushState({}, null, this.$route.path + this.serialize(params));
    },
    collapseState(collapseId, isJustShown) {
      if (this.events.includes(collapseId)) {
        if (this.$refs.Descr.tabId == collapseId) {
          this.$setGAFormCustom(
            "Sekcje: podstawowe informacje o filmie",
            isJustShown ? "Rozwiń" : "Zwiń",
            "",
            false
          );
        } else if (this.$refs.cast?.tabId == collapseId) {
          this.$setGAFormCustom(
            "Sekcje: obsada i twórcy filmu",
            isJustShown ? "Rozwiń" : "Zwiń",
            "",
            false
          );
        } else if (this.$refs.AdditionalMaterial?.tabId == collapseId) {
          this.$setGAFormCustom(
            "Sekcje: Materiały dodatkowe",
            isJustShown ? "Rozwiń" : "Zwiń",
            "",
            false
          );
        } else if (this.$refs.aword?.tabId == collapseId) {
          this.$setGAFormCustom(
            "Sekcje: Nagrody",
            isJustShown ? "Rozwiń" : "Zwiń",
            "",
            false
          );
        } else if ("nav-collapse-main" == collapseId) {
          return;
        } else {
          this.$setGAFormCustom(
            "Sekcje: " + collapseId,
            isJustShown ? "Rozwiń" : "Zwiń",
            "",
            false
          );
        }
      } else this.events.push(collapseId);
    },
  },
  computed: {
    creditsHeader() {
      switch (this.model.headerType) {
        case 0:
        default:
          return this.model.translations["moviePage.CastAndCredits"];
        case 1:
          return this.model.translations["moviePage.Credits"];
        case 2:
          return this.model.translations["moviePage.Authors"];
      }
    },
    isLoggedIn() {
      return authService.getUser() ? true : false;
    },
  },
  mounted() {
    this.isPro = authService.getProfile()?.is_pro ?? false;
    this.currentUrl = "?returnUrl=" + location.pathname;
    this.$root.$on("bv::collapse::state", this.collapseState);
    this.isCollapsed();
    if (this.model.isPackage) {
      this.avaliableModel = {
        header: this.model.translations["moviePage.PackageMovies"],
        items: this.model.avaliableMovie,
      };
    }
  },
  beforeMount() {
    if (this.model.categoryAge.value == 18) {
      if (authService.getUser()) {
        this.islogged = true;
        var cookieAdult = getCookie("Adult");
        if (cookieAdult && cookieAdult == "1") {
          this.canWatch = true;
        } else {
          this.canWatch = false;
        }
      } else {
        this.canWatch = false;
      }
    } else {
      this.canWatch = true;
    }
  },
  beforeDestroy() {
    this.$root.$off("bv::collapse::state", this.collapseState);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.top-row-spacer {
  > .movieInfo {
    margin-top: 2em;
  }
}
.disableFlex {
  .row {
    display: block !important;

    .splide {
      display: block;
    }
  }
}

h3 {
  font-size: em(26);
}
.subject {
  display: table-row;
  * > {
    display: table-cell;
  }
  .tclink {
    white-space: nowrap !important;
    color: inherit !important;
    > i,
    > span {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      margin-right: 3px;
    }
    .full {
      display: none !important;
    }
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      .short {
        display: none !important;
      }
      .full {
        display: inline-block !important;
      }
    }
  }
}
.card-text {
  font-family: $font-family-wotfard;
  .subjects {
    border-collapse: collapse;
    width: 100%;
    margin-left: 0;
    a {
      cursor: pointer;
    }
  }
  .Player {
    aspect-ratio: 4/3;
  }
}
.Player {
  .card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: em(500);
    transform: translate(-50%, -50%);
    z-index: 2002;
    // background: black;
    &:before {
      content: " ";
      background: black;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    .btn {
      margin: auto;
    }
    .buttons {
      display: flex;
      .btn {
        margin-right: 0;
      }
      &.center {
        .btn {
          margin: 2px auto;
        }
      }
      .custom-control {
        margin: auto;
        margin-left: 0;
        .custom-control-label {
          margin-left: 3.5em !important;
          position: relative;
        }
      }
    }
  }
}
.moreInfo .additional.audiobtn {
  .btn {
    font-weight: normal;
  }
  i {
    font-size: 1.2em;
    position: relative;
    top: 0.2em;
  }
  .active {
    background-color: $color-dark-gray;
    i {
      font-weight: 800;
    }
  }
}
.releated-grid {
  .vd_list-grid {
    width: 100%;
  }
}
</style>

<template>
  <div class="favorites color--basic" v-if="$isTizen($browserDetect)">
    <b-container class="shopping-list mt-5">
      <h1 class="text-center mt-5">
        {{ model.translations["order.PageTitle"] }}
      </h1>
      <tv-qr-code :message="model.translations['general.QrCodeInfo']"></tv-qr-code>
    </b-container>
  </div>
  <div class="favorites color--basic moviePage" v-else>
    <b-container class="shopping-list mt-5">
      <h1 class="text-center mt-5">
        {{ model.translations["order.PageTitle"] }}
      </h1>
      <b-row v-if="!model.items || model.items.length <= 0">
        <b-col>
          {{ model.translations["order.emptyList"] }}
        </b-col>
      </b-row>
      <b-row v-if="model.items && model.items.length > 0">
        <b-col>
          <accordion
            v-for="(item, index) in model.items"
            :key="index"
            :accordionName="'Description' + index"
            :tabId="'Descr' + index"
          >
            <template v-slot:header>
              <div
                tabindex="0"
                class="shopping-list__button"
                :class="{
                  'shopping-list__button--expired': !isStatusActive(item.status),
                }"
              >
                <div>
                  <i class="cil-chevron-bottom-alt"></i>
                  <i class="cil-chevron-left-alt"></i>
                  <span class="hover-left-right">
                    {{ getItemKindStr(item.kind) }}
                    <!-- <span class="title">"{{ item.title }}"</span> -->
                  </span>
                </div>
                <div class="shopping-list__icon">
                  <span v-if="item.paymentStatus == PaymentStatus.CONFIRM"
                    >{{ model.translations["order.Status.Active"] }}
                    <i class="icoStatus cil-check-alt"></i
                  ></span>
                  <span v-else-if="item.paymentStatus == PaymentStatus.CANCELLED"
                    >{{ model.translations["order.Status.Blocked"]
                    }}<i class="icoStatus cil-x"></i
                  ></span>
                  <span v-else-if="item.paymentStatus == PaymentStatus.BLOCKED"
                    >{{ model.translations["order.Status.Cancelled"]
                    }}<i class="icoStatus cil-x"></i
                  ></span>
                  <span v-else-if="item.paymentStatus == PaymentStatus.WAITING"
                    >{{ model.translations["order.Status.Waiting"]
                    }}<i class="icoStatus cil-clock"></i
                  ></span>
                  <span v-else-if="item.paymentStatus == PaymentStatus.TIMEDOUT"
                    >{{ model.translations["order.Status.Timedout"]
                    }}<i class="icoStatus cil-x"></i
                  ></span>
                  <span v-else-if="item.paymentStatus == PaymentStatus.REJECTED"
                    >{{ model.translations["order.Status.Rejected"]
                    }}<i class="icoStatus cil-x"></i
                  ></span>
                  <span v-else-if="item.paymentStatus == PaymentStatus.NOT_EXTENDING"
                    >{{ model.translations["order.Status.NotExtending"] }}
                    {{ formatDate(item.till) }}<i class="icoStatus cil-check-alt"></i
                  ></span>
                </div>
              </div>
            </template>
            <template v-slot:main>
              <div class="LicList payments">
                <h3>{{ model.translations["order.Details"] }}</h3>
                <div
                  class="licItem inactive"
                  :class="{
                    active: item.status == OrderStatus.ACTIVE,
                  }"
                >
                  <div class="header">
                    <i class="cil-exclamation-circle" aria-hidden="true"></i>
                    {{ model.translations["order.Access"] }}
                  </div>
                  <div
                    class="value"
                    v-if="
                      item.kind == OrderKind.SUBSCRIPTION &&
                      item.status == OrderStatus.NOT_EXTENDING
                    "
                  >
                    {{ model.translations["order.Expires"] }} ({{ getValidTime(item) }})
                  </div>
                  <div class="value active" v-else-if="isStatusActive(item.status)">
                    {{ model.translations["order.Active"] }}
                    <span v-if="item.kind == OrderKind.ONETIMEPAYMENT"
                      >({{ getValidTime(item) }})</span
                    >
                  </div>
                  <div class="value inactive" v-else>
                    {{ model.translations["order.Inactive"] }}
                  </div>
                </div>
                <div class="licItem">
                  <div class="header">
                    {{ model.translations["order.BoughtProduct"] }}
                  </div>
                  <div class="value">
                    <b-link
                      :to="item.articleUrl"
                      class="card-body__title hover-left-right-black focus-smart"
                    >
                      {{ item.title }}
                    </b-link>
                  </div>
                </div>
                <div class="licItem">
                  <div class="header">
                    {{ model.translations["order.ValidBy"] }}
                  </div>
                  <div class="value">{{ getDuration(item) }}</div>
                </div>
                <div class="licItem">
                  <div class="header">
                    {{ model.translations["order.Price"] }}
                  </div>
                  <div class="value">
                    {{ (item.price / 100).toFixed(2) }}
                    {{ model.translations["order.Currency"] }}
                  </div>
                </div>
                <!-- <div class="licItem">
                  <div
                    class="header"
                    v-if="item.kind == OrderKind.ONETIMEPAYMENT"
                  >
                    Wygaśnie za:
                  </div>
                  <div class="header" v-else>Odnowienie:</div>
                  <div class="value">{{ getValidTime(item) }}</div>
                </div> -->
                <div class="licItem">
                  <div class="header">
                    {{ model.translations["order.PaymentMethod"] }}
                  </div>
                  <div class="value">{{ item.system }}</div>
                </div>
                <div class="licItem">
                  <div class="header">
                    {{ model.translations["order.AccessFrom"] }}
                  </div>
                  <div class="value">
                    {{ formatDate(item.confirmedAt) }}
                  </div>
                </div>
                <div class="licItem">
                  <div
                    class="header"
                    v-if="
                      item.kind == OrderKind.SUBSCRIPTION && isSubscriptionActive(item)
                    "
                  >
                    {{ model.translations["order.RenewalDate"] }}
                  </div>
                  <div class="header" v-else>
                    {{ model.translations["order.AccessTo"] }}
                  </div>
                  <div class="value">
                    {{ item.confirmedAt ? formatDate(item.till) : null }}
                  </div>
                </div>
                <div class="buttons" v-if="isSubscriptionActive(item)">
                  <b-button @click="deleteSubscription(item.subscriptionId)">
                    {{ model.translations["order.AbortSubscription"] }}</b-button
                  >
                </div>
                <div class="fake"></div>
              </div>
            </template>
          </accordion>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="shopping-list mt-5" v-if="proOrders && proOrders.length > 0">
      <h1 class="text-center mt-5">
        {{ model.translations["order"] }}
      </h1>
      <b-row>
        <b-col>
          <accordion
            v-for="(item, index) in proOrders"
            :key="index"
            :accordionName="'Description' + index"
            :tabId="'Descr' + index"
          >
            <template v-slot:header>
              <div
                @click="gerOrderDetails(item, 'Descr' + index)"
                tabindex="0"
                class="shopping-list__button"
              >
                <span class="hover-left-right">
                  {{ getItemKindStr(item.kind) }}
                  <span class="title"
                    >{{ model.translations["order.License"] }}: {{ item.title }}</span
                  >
                  <span>{{ model.translations["order.From"] }}</span>
                  <span>&nbsp;{{ formatDate(item.confirmedAt) }}</span>
                </span>
                <div class="shopping-list__icon">
                  <span v-if="item.proStatus && item.proStatus.labelKey">{{
                    model.proTranslation[item.proStatus.labelKey].text
                  }}</span>
                  <i class="cil-chevron-bottom-alt"></i>
                  <i class="cil-chevron-left-alt"></i>
                </div>
              </div>
            </template>
            <template v-slot:main v-if="item.show">
              <div>
                <div v-if="item.license && item.license.properties" class="LicList">
                  <h3>{{ model.translations["order.Pro.LicenseDetails"] }}</h3>
                  <licensse-item
                    :type="4"
                    v-if="item.articles && item.articles.length > 0"
                    :attachments="item.articles"
                    :model="model.translations"
                    :translations="model.proTranslation"
                  ></licensse-item>
                  <licensse-item
                    v-for="(licItem, IndexL) in item.license.properties"
                    :key="IndexL"
                    :model="licItem"
                    :translations="model.proTranslation"
                    :type="0"
                  ></licensse-item>

                  <licensse-item
                    :type="2"
                    :attachments="item.attachments"
                    :model="model.translations"
                    v-if="item.attachments && item.attachments.length > 0"
                  >
                  </licensse-item>

                  <licensse-item
                    v-if="item.createdAt"
                    :translations="model.translations"
                    :type="6"
                    :date="formatDate(item.createdAt)"
                    :title="model.translations['order.CreateDate']"
                  ></licensse-item>

                  <licensse-item
                    v-if="item.modificationDate"
                    :translations="model.translations"
                    :type="6"
                    :date="formatDate(item.modificationDate)"
                    :title="model.translations['order.ModificationDate']"
                  ></licensse-item>
                </div>
                <div v-if="item.notes && item.notes.length > 0" class="LicList">
                  <h3>{{ model.translations["order.Pro.Notice"] }}</h3>
                  <licensse-item
                    :type="1"
                    v-for="(noteItem, indexN) in item.notes"
                    :key="indexN"
                    :model="noteItem"
                  ></licensse-item>
                </div>
                <div
                  class="buttons"
                  v-if="
                    item.proStatus &&
                    item.proStatus.labelKey &&
                    item.proStatus.labelKey === 'status.request'
                  "
                >
                  <b-button @click="changeStatus(item.id)">{{
                    model.translations["order.Cancel"]
                  }}</b-button>
                </div>
              </div>
              <div class="busy" v-if="item.waiting">
                <busy-indicator></busy-indicator>
              </div>
            </template>
          </accordion> </b-col
      ></b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Accordion from "../components/ListingView/Accordion.vue";
import moment from "moment";
import "moment/locale/pl";
import LicensseItem from "../components/ProForm/licensseItem.vue";
import BusyIndicator from "../components/common/BusyIndicator.vue";
import TvQrCode from "../components/common/TvQrCode.vue";

export default {
  name: "OrdersPage",
  components: {
    Accordion,
    LicensseItem,
    BusyIndicator,
    TvQrCode,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      proOrders: [],
      OrderStatus: {
        ACTIVE: 1,
        BLOCKED: 2,
        CANCELLED: 3,
        WAITING: 4,
        TIMEDOUT: 5,
        REJECTED: 6,
        NOT_EXTENDING: 7,
      },
      OrderKind: {
        SUBSCRIPTION: 1,
        ONETIMEPAYMENT: 2,
      },
      PaymentStatus: {
        CONFIRM: 0,
        BLOCKED: 1,
        REJECTED: 2,
        WAITING: 3,
      },
    };
  },
  mounted() {
    moment.locale(localStorage.getItem("x-language").substr(0, 2));
    this.proOrders = this.model.proOrder;
  },
  methods: {
    getDuration(item) {
      if (!(item.till && item.confirmedAt)) {
        return null;
      }
      let endDate = moment(String(item.till));
      let startDate = moment(String(item.confirmedAt));
      let duration = moment.duration(Math.abs(endDate.diff(startDate)));
      let time = duration.asYears();
      if (time >= 1) {
        time = time.toFixed(0);
        if (time == 1) return time + " " + this.model.translations["order.Year"];
        else return time + " " + this.model.translations["order.Years"];
      }
      time = duration.asMonths();
      if (time >= 1) {
        time = time.toFixed(0);
        if (time == 1) return time + " " + this.model.translations["order.Month"];
        else return time + " " + this.model.translations["order.Months"];
      }
      time = duration.asDays();
      if (time >= 1) {
        time = time.toFixed(0);
        if (time == 1) return time + " " + this.model.translations["order.Day"];
        else return time + " " + this.model.translations["order.Days"];
      }
      time = duration.asHours().toFixed(0);
      if (time == 1) return time + " " + this.model.translations["order.Hour"];
      else return time + " " + this.model.translations["order.Hours"];
    },
    getValidTime(item) {
      if (this.isStatusActive(item.status)) {
        var endDate = moment(String(item.till));
        var startDate = moment();
        let duration = moment.duration(Math.abs(endDate.diff(startDate)));
        let time = duration.asYears();
        if (time >= 1) {
          time = time.toFixed(0);
          if (time == 1) return time + " " + this.model.translations["order.Year"];
          else return time + " " + this.model.translations["order.Years"];
        }
        time = duration.asMonths();
        if (time >= 1) {
          time = time.toFixed(0);
          if (time == 1) return time + " " + this.model.translations["order.Month"];
          else return time + " " + this.model.translations["order.Months"];
        }
        // time = duration.asWeeks();
        // if (time >= 1) return time.toFixed(0) + " Tygodnie";
        time = duration.asDays();
        if (time >= 1) {
          time = time.toFixed(0);
          if (time == 1) return time + " " + this.model.translations["order.Day"];
          else return time + " " + this.model.translations["order.Days"];
        }
        time = duration.asHours();
        if (time >= 1) {
          time = time.toFixed(0);
          if (time == 1) return time + " " + this.model.translations["order.Hour"];
          else return time + " " + this.model.translations["order.Hours"];
        }
        return this.model.translations["order.LessHour"];
      }
      return "Wygasł";
    },
    getItemKindStr(kind) {
      switch (kind) {
        case this.OrderKind.SUBSCRIPTION:
          return this.model.translations["order.Kind.Subscription"];
        case this.OrderKind.ONETIMEPAYMENT:
          return this.model.translations["order.Kind.OneTimePayment"];
        default:
          return null;
      }
    },
    isSubscriptionActive(item) {
      return (
        item.status == this.OrderStatus.ACTIVE && item.kind == this.OrderKind.SUBSCRIPTION
      );
    },
    deleteSubscription(id) {
      this.$setBusy(true);
      axios
        .delete(process.env.VUE_APP_WIDGET_API_URL + "/deletesubscription/?id=" + id)
        .then(() => {
          this.$setBusy(false);
          this.model.items.forEach((x) => {
            if (x.subscriptionId == id) {
              x.status = this.OrderStatus.NOT_EXTENDING;
            }
            return x;
          });
        });
    },
    isStatusActive(status) {
      return (
        status == this.OrderStatus.ACTIVE || status == this.OrderStatus.NOT_EXTENDING
      );
    },
    formatDate(dt) {
      return dt ? moment(String(dt)).format("LLL") : '';
    },
    isTillVisible(item) {
      return (
        item.status != this.OrderStatus.ACTIVE || item.kind != this.OrderKind.SUBSCRIPTION
      );
    },
    gerOrderDetails(item, id) {
      item.waiting = true;
      var currentId = id;
      var self = this;
      if (!item.show) {
        axios({
          method: "GET",
          url: process.env.VUE_APP_WIDGET_API_URL + "pro-form/order?id=" + item.id,
        })
          .then((response) => {
            var map = self.proOrders.map((x) => {
              if (x.id === item.id) {
                x.modificationDate = response.data.modificationDate;
                x.articles = response.data.positions;
                x.license = response.data.licenseRequest;
                x.notes = response.data.notes;
                x.show = true;
                x.attachments = response.data.attachments.map((z) => {
                  z.orderId = item.id;
                  return z;
                });
              }
              return x;
            });
            item.waiting = false;
            self.proOrders = map;
            setTimeout(() => {
              this.$root.$emit("bv::toggle::collapse", currentId);
            }, 0);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    changeStatus(id) {
      var self = this;
      axios({
        method: "POST",
        url: process.env.VUE_APP_WIDGET_API_URL + "/pro-form/status?orderId=" + id,
      })
        .then(() => {
          var map = self.proOrders.map((x) => {
            if (x.id === id) {
              x.proStatus.labelKey = "status.canceled";
            }
            return x;
          });
          self.proOrders = map;
        })
        .catch((error) => {
          // this.waiting = false;
          console.error(error);
        });
    },
    redirectTo(item) {
      this.$router.push({ path: this.model.proUrl, query: { id: item.id } });
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.favorites {
  min-height: 50vh;
}
.shopping-list {
  max-width: 1200px;
  margin: auto;
  .card {
    border-top: 3px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-content: stretch;
    padding: 0 30px;
    .title {
      text-transform: none;
    }
  }

  &__button--expired {
    color: #a1a1a1;
  }

  .card-body {
    background: #373737;
    color: white;
    display: flex;
    flex-direction: column;
    &__title {
      color: white;
      font-weight: $font-weight-medium;
      font-size: em(18);
      margin-bottom: em(21);
    }

    &__access {
      padding: 0 10px 30px;
      font-size: em(16);
      font-weight: $font-weight-regular;
      span {
        padding-right: em(40);
      }
    }
  }
  .btn-secondary {
    background-color: $color-black;
    border-color: $color-black;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    .icoStatus {
      position: relative;
      top: em(3);
      margin-left: 10px !important;
      &.cil-check-alt {
        color: #40daa3;
      }
      &.cil-x {
        color: red;
      }
      &.cil-clock {
        color: skyblue;
      }
    }
  }
  .card-header {
    button {
      background: #373737 !important;
      .cil-chevron-bottom-alt,
      .cil-chevron-left-alt {
        top: em(3);
        position: relative;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
}
.card-body {
  position: relative;
  .card-text {
    .busy {
      min-height: 200px;
      .busy-indicator {
        background: #dedede;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          color: black;
        }
      }
    }
  }
}
.buttons {
  .btn {
    background: white;
    color: black;
    border-color: white;
  }
}
.shopping-list {
  .LicList {
    &.payments {
      gap: 5%;
      flex-direction: row;
      justify-content: center;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      h3 {
        flex: 0 0 95%;
        border-bottom: 1px solid;
        padding-bottom: 10px;
        margin-bottom: 0;
      }
      .licItem,
      .buttons {
        border-top: none;
        border-bottom: 1px solid;
        flex: 0 0 45%;

        .header,
        .value {
          width: auto !important;
          flex: 0 0 50%;
          .cil-exclamation-circle {
            margin-right: em(10);
          }
          span {
            display: contents;
          }
        }
        &.inactive {
          i,
          .value {
            color: #d4a42a;
          }
        }
        &.active {
          i,
          .value {
            color: #40daa3;
          }
        }
        // &.ending {
        //   i,
        //   .value {
        //     color: #d4a42a;
        //   }
        // }
      }
      .buttons {
        padding: 10px;
        text-align: right;
        button {
          margin: auto;
        }
      }
      .fake {
        flex: 0 0 45%;
      }
    }
    a {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <div>
    <b-row class="progressBar details-header" v-if="header">
      <b-col :sm="10" class="">
        <b-container>
          <h3>{{ header }}</h3>
        </b-container>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="progressBar m40">
        <b-col :sm="10">
          <b-form-group>
            <b-form-checkbox-group
              class="radios"
              v-model="selectedValue[0]"
              :name="name"
              :class="{ selected: selectedValue[0] }"
            >
              <div
                v-for="(item, index) in items"
                :key="index"
                :class="{
                  active:
                    selectedValue[0] && selectedValue[0].includes(item.value),
                }"
              >
                <b-form-checkbox :value="item.value">
                  {{ item.text }}
                </b-form-checkbox>
                <p
                  class="hint"
                  v-if="!readonly && item.hint"
                  v-show="
                    selectedValue[0] && selectedValue[0].includes(item.value)
                  "
                >
                  {{ item.hint }}
                </p>
                <transition name="slide-fade">
                  <div
                    class="short"
                    v-if="
                      item.haveInput &&
                      selectedValue[0] &&
                      selectedValue[0].includes(item.value)
                    "
                  >
                    <b-form-group :label="item.inputName">
                      <b-input
                        :placeholder="item.inputPlaceHolder"
                        :disabled="item.value != selectedValue[0]"
                        :type="item.inputType"
                        v-model="inputValue[item.value]"
                      ></b-input>
                    </b-form-group>
                  </div>
                  <div
                    class="short"
                    v-if="
                      item.haveText &&
                      selectedValue[0] &&
                      selectedValue[0].includes(item.value)
                    "
                  >
                    <b-form-group :label="item.inputName">
                      <label :for="item.inputName" class="col-form-label">{{
                        item.inputPlaceHolder
                      }}</label>
                      <b-form-textarea
                        :disabled="
                          !(
                            selectedValue[0] &&
                            selectedValue[0].includes(item.value)
                          )
                        "
                        v-model="inputValue[item.value]"
                        size="lg"
                        maxlength="1000"
                      ></b-form-textarea>
                      <p class="chars-status text-right">
                        {{
                          inputValue[item.value]
                            ? inputValue[item.value].length
                            : 0
                        }}
                        / 1000
                      </p>
                    </b-form-group>
                  </div>
                  <div
                    v-else-if="
                      item.haveCheckBox &&
                      selectedValue[0] &&
                      selectedValue[0].includes(item.value)
                    "
                  >
                    <b-form-checkbox-group
                      class="radios"
                      :name="name + index"
                      v-model="selectedValue[1]"
                    >
                      <div
                        v-for="(item2, index2) in item.radioValues.filter(
                          (x) =>
                            !readonly ||
                            selectedValue[1].includes(item.value + x.value)
                        )"
                        :key="index2"
                      >
                        <b-form-checkbox
                          :value="item.value + ';' + item2.value"
                        >
                          {{ item2.text }}
                        </b-form-checkbox>
                        <transition name="slide-fade">
                          <div
                            class="short"
                            v-if="
                              item2.haveInput &&
                              selectedValue[1] &&
                              selectedValue[1].includes(
                                item.value + ';' + item2.value
                              )
                            "
                          >
                            <b-form-group :label="item2.inputName">
                              <b-input
                                :placeholder="item2.inputPlaceHolder"
                                :type="item2.inputType"
                                v-model="
                                  inputValue[item.value + ';' + item2.value]
                                "
                              ></b-input>
                            </b-form-group>
                          </div>
                          <div
                            class="short"
                            v-if="
                              !readonly &&
                              item2.haveFile &&
                              selectedValue[1] &&
                              selectedValue[1].includes(
                                item.value + ';' + item2.value
                              )
                            "
                          >
                            <b-form-group :label="item2.inputName">
                              <b-form-file
                                v-model="
                                  inputValue[item.value + ';' + item2.value]
                                "
                                :state="
                                  chechFile(
                                    inputValue[item.value + ';' + item2.value]
                                  )
                                "
                                :browse-text="
                                  translations['proOrder.FileBrowseButton']
                                "
                                :placeholder="
                                  translations['proOrder.FilePlaceholder']
                                "
                                :drop-placeholder="
                                  translations['proOrder.FileDropPlaceholder']
                                "
                              ></b-form-file>
                              <span
                                v-if="
                                  toBig &&
                                  inputValue[item.value + ';' + item2.value]
                                "
                                >plik jest za duży</span
                              >
                            </b-form-group>
                            <div v-if="readonly">
                              <span class="pro-readonly-value">{{
                                inputValue[item.value + ";" + item2.value].name
                              }}</span>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </b-form-checkbox-group>
                  </div>
                  <div
                    v-else-if="
                      item.haveRadio &&
                      selectedValue[0] &&
                      selectedValue[0].includes(item.value)
                    "
                  >
                    <b-form-checkbox-group
                      class="radios"
                      :name="name + index"
                      v-model="selectedValue[1]"
                    >
                      <div
                        v-for="(item2, index2) in item.radioValues.filter(
                          (x) =>
                            !readonly ||
                            selectedValue[1].includes(item.value + x.value)
                        )"
                        :key="index2"
                      >
                        <b-form-checkbox
                          :value="item.value + ';' + item2.value"
                          @click.native="
                            filterValues(item.value + ';' + item2.value)
                          "
                        >
                          {{ item2.text }}
                        </b-form-checkbox>
                        <transition name="slide-fade">
                          <div
                            class="short"
                            v-if="
                              item2.haveInput &&
                              selectedValue[1] &&
                              selectedValue[1].includes(
                                item.value + ';' + item2.value
                              )
                            "
                          >
                            <b-form-group :label="item2.inputName">
                              <b-input
                                :placeholder="item2.inputPlaceHolder"
                                :type="item2.inputType"
                                v-model="
                                  inputValue[item.value + ';' + item2.value]
                                "
                              ></b-input>
                            </b-form-group>
                          </div>
                          <div
                            class="short"
                            v-if="
                              !readonly &&
                              item2.haveFile &&
                              selectedValue[1] &&
                              selectedValue[1].includes(
                                item.value + ';' + item2.value
                              )
                            "
                          >
                            <b-form-group :label="item2.inputName">
                              <b-form-file
                                v-model="
                                  inputValue[item.value + ';' + item2.value]
                                "
                                :state="
                                  chechFile(
                                    inputValue[item.value + ';' + item2.value]
                                  )
                                "
                                :browse-text="
                                  translations['proOrder.FileBrowseButton']
                                "
                                :placeholder="
                                  translations['proOrder.FilePlaceholder']
                                "
                                :drop-placeholder="
                                  translations['proOrder.FileDropPlaceholder']
                                "
                              ></b-form-file><span v-if="toBig">plik jest za duży</span>
                            </b-form-group>
                            <div v-if="readonly">
                              <span class="pro-readonly-value">{{
                                inputValue[item.value + ";" + item2.value].name
                              }}</span>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </b-form-checkbox-group>
                  </div>
                </transition>
              </div>
            </b-form-checkbox-group>
            <slot></slot>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    header: String,
    itemList: Array,
    select: null,
    input: null,
    name: String,
    translations: null,
    readonly: Boolean,
  },
  data() {
    return {
      selectedValue: [],
      inputValue: [],
      toBig: "",
    };
  },
  computed: {
    items: function () {
      if (!this.readonly) return this.itemList;
      else
        return this.itemList.filter(
          (x) =>
            this.selectedValue[0] && this.selectedValue[0].includes(x.value)
        );
    },
  },
  watch: {
    selectedValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, this.name);
      },
      deep: true,
    },
    select: {
      handler(newValue) {
        if (newValue != this.selectedValue) this.selectedValue = newValue;
      },
      deep: true,
    },
    inputValue: {
      handler(newValue) {
        this.$emit("ChangeValue", newValue, this.name + "input");
      },
    },

    input: {
      handler(newValue) {
        if (this.inputValue != newValue) this.inputValue = newValue;
      },
    },
  },
  mounted() {
    if (this.select) this.selectedValue = this.select;
    if (this.input) this.inputValue = this.input;
  },
  methods: {
    chechFile(model) {
      if (model) {
        if (model.size > 5242880) {
          this.toBig = true;
          return false;
        }
      }
      return true;
    },
    filterValues(newValue) {
      var start = newValue.split(";");
      var self = this;
      if (self.selectedValue[1])
        for (var i = 0; i < self.selectedValue[1].length; i++) {
          var item = self.selectedValue[1][i];
          if (item.includes(start[0])) {
            self.$delete(self.selectedValue[1], i);
          }
        }
    },
  },
};
</script>
<style lang="scss">
.radios {
  .radios {
    margin-left: 20px;
  }
}
</style>
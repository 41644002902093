<template>
  <div :class="className">
    <slot name="header"></slot>
    <div>
      <slot name="main"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    className:String
  }
};
</script>
<template>
  <b-link
    class="highlighted"
    :to="model.url"
    @click.native="analyticsSend()"
    tabindex="-1"
  >
    <div class="highlighted-background">
      <p
        class="aditional-info"
        :style="'color:' + model.colorInfo"
        v-if="model.additionalInfo"
      >
        {{ model.additionalInfo }}
      </p>
      <lazy-image :source="model.image.url" aria-hidden="true"></lazy-image>

      <avaliable
        :avaliable="model.avaliable"
        :showOutVod="model.showOutVod"
        :price="priceItem"
        :translations="translations"
      ></avaliable>
    </div>
    <div class="descriptionBox">
      <b-container fluid class="Fixed containerFixed">
        <b-row>
          <div class="description">
            <b-link
              class="focus-smart"
              ref="focusStart"
              :to="model.url"
              :aria-label="model.title"
              tabindex="0"
            >
              <h1 class="hover-left-right">{{ ctitle }}</h1>
            </b-link>
            <p>
              <span v-if="model.category" class="category">
                {{ model.category }}
              </span>
              <br />
              <span
                v-if="
                  model.showOnBox &&
                  model.showOnBox.persons &&
                  model.showOnBox.persons.length > 0
                "
                class="Director"
              >
                <span class="showbox">{{ model.showOnBox.roleName }}:</span>&nbsp;<span
                  class="showbox"
                  v-for="(item, index) in model.showOnBox.persons"
                  :key="index"
                  >{{ getName(item, model.showOnBox.persons.length == 1)
                  }}<span
                    class="showbox"
                    v-if="index < model.showOnBox.persons.length - 1"
                    >,&nbsp;</span
                  ></span
                >
              </span>
              <br />
              <span v-if="model.country" class="country"> {{ model.country }} </span
              >&nbsp;
              <span v-if="model.year" class="year">{{ model.year }}</span>
            </p>
          </div>
          <div class="moreInfo" v-if="!this.$browserDetect.isApp">
            <div v-if="model.quality" class="additional hiding2">
              <div :title="model.quality.name" v-b-tooltip.hover role="img">
                <img
                  aria-hidden="true"
                  class="icon"
                  :alt="model.quality.name"
                  :src="model.quality.img"
                />
              </div>
            </div>
            <div v-if="model.duration" class="additional hiding2">
              <div
                v-b-tooltip.hover
                :title="
                  translations['moviePage.DurationTitle'] +
                  ' ' +
                  model.duration +
                  ' ' +
                  translations['carousel.Min']
                "
                role="img"
              >
                <img
                  aria-hidden="true"
                  class="icon"
                  src="/assets/clock.svg"
                  :alt="translations['moviePage.DurationTitle']"
                />
                <span class="time" aria-hidden="true">
                  {{ model.duration }} {{ translations["carousel.Min"] }}
                </span>
              </div>
            </div>
            <div v-if="model.language.sound" class="additional hiding">
              <div
                :title="getLanguage(model.language.sound, true, true)"
                v-b-tooltip.hover
                role="img"
              >
                <img
                  aria-hidden="true"
                  class="icon"
                  src="/assets/audio.svg"
                  :alt="getLanguage(model.language.sound, true, true)"
                />
                <span class="text" aria-hidden="true">{{
                  getLanguage(model.language.sound, false, true)
                }}</span>
              </div>
            </div>
            <div v-if="model.language.subtitle" class="additional hiding">
              <div
                :title="getLanguage(model.language.subtitle, true, false)"
                v-b-tooltip.hover
                role="img"
              >
                <img
                  aria-hidden="true"
                  class="icon"
                  src="/assets/subtitles.svg"
                  :alt="getLanguage(model.language.subtitle, true, false)"
                />
                <span class="text" aria-hidden="true">{{
                  getLanguage(model.language.subtitle, false, false)
                }}</span>
              </div>
            </div>

            <div
              v-if="model.categoryAge && model.categoryAge.img"
              class="categoryAge additional"
            >
              <img
                class="icon"
                :src="model.categoryAge.img"
                v-b-tooltip.hover
                :title="model.categoryAge.name"
              />
              <img
                v-for="(item, index) in model.warning"
                :key="index"
                class="icon"
                :src="item.img"
                v-b-tooltip.hover
                :title="item.name"
              />
            </div>
            <div class="additional" v-if="model.language.audioDescription">
              <div
                v-b-tooltip.hover
                :title="translations['moviePage.AudioDescription']"
                role="img"
              >
                <img
                  class="icon"
                  src="/assets/AD.svg"
                  :alt="translations['moviePage.AudioDescription']"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div
              class="additional"
              v-if="model.language.deaf"
              v-b-tooltip.hover
              :title="translations['moviePage.Deaf']"
              role="img"
            >
              <img
                class="icon"
                src="/assets/n.svg"
                :alt="translations['moviePage.Deaf']"
                aria-hidden="true"
              />
            </div>
          </div>
          <div class="moreInfo" v-else>
            <div
              v-if="model.quality"
              :tabindex="this.$getTabIndex()"
              class="additional hiding2"
              v-b-tooltip.hover
              :title="model.quality.name"
            >
              <img class="icon" :src="model.quality.img" :alt="model.quality.name" />
            </div>
            <div
              v-if="model.duration"
              :tabindex="this.$getTabIndex()"
              class="additional hiding2"
              v-b-tooltip.hover
              :title="
                translations['moviePage.DurationTitle'] +
                ' ' +
                model.duration +
                ' ' +
                translations['carousel.Min']
              "
            >
              <img
                class="icon"
                src="/assets/clock.svg"
                :alt="translations['moviePage.DurationTitle']"
              />
              <span class="time">
                {{ model.duration }} {{ translations["carousel.Min"] }}
              </span>
            </div>
            <div
              v-if="model.language.sound"
              :tabindex="this.$getTabIndex()"
              class="additional hiding"
              :title="getLanguage(model.language.sound, true, true)"
              v-b-tooltip.hover
            >
              <img
                class="icon"
                src="/assets/audio.svg"
                :alt="getLanguage(model.language.sound, true, true)"
              />
              <span class="text">{{
                getLanguage(model.language.sound, false, true)
              }}</span>
            </div>
            <div
              v-if="model.language.subtitle"
              :tabindex="this.$getTabIndex()"
              class="additional hiding"
              v-b-tooltip.hover
              :title="getLanguage(model.language.subtitle, true, false)"
            >
              <img
                class="icon"
                src="/assets/subtitles.svg"
                :alt="getLanguage(model.language.subtitle, true, false)"
              />
              <span class="text">{{
                getLanguage(model.language.subtitle, false, false)
              }}</span>
            </div>
            <div
              v-if="model.categoryAge"
              :tabindex="this.$getTabIndex()"
              class="categoryAge additional"
              :title="model.categoryAge.name"
              v-b-tooltip.hover
            >
              <img
                class="icon"
                :src="model.categoryAge.img"
                :alt="model.categoryAge.name"
              />
            </div>
            <div
              class="additional"
              :tabindex="this.$getTabIndex()"
              v-if="model.language.audioDescription"
              v-b-tooltip.hover
              :title="translations['moviePage.AudioDescription']"
            >
              <img
                class="icon"
                src="/assets/AD.svg"
                :alt="translations['moviePage.AudioDescription']"
              />
            </div>
            <div
              :tabindex="this.$getTabIndex()"
              class="additional"
              v-if="model.language.deaf"
              v-b-tooltip.hover
              :title="translations['moviePage.Deaf']"
            >
              <img
                class="icon"
                src="/assets/n.svg"
                :alt="translations['moviePage.Deaf']"
              />
            </div>
          </div>
        </b-row>
      </b-container>
      <a aria-label="scroll" href="scrollHere" @click="scrollIntoView" class="bodyScroll">
        <div class="scroll">
          <div class="chevron"></div>
          <div class="chevron"></div>
          <div class="chevron"></div>
        </div>
      </a>
    </div>
    <div class="scrollHere"></div>
  </b-link>
</template>
<script>
import Avaliable from "../common/Avaliable.vue";
import LazyImage from "../common/LazyImage.vue";
export default {
  components: { LazyImage, Avaliable },
  props: {
    model: Object,
    translations: Object,
  },
  methods: {
    getLanguage: function (array, GetFull, IsSound) {
      var value = [];
      for (var i = 0; i < array.length; i++) {
        if (GetFull) {
          value.push(array[i].fullName);
        } else {
          value.push(array[i].shortName);
        }
      }
      if (!GetFull) {
        if (array.length > 2) {
          value = value.slice(0, 2);
        }
        return value.join(" ");
      } else {
        if (IsSound)
          return this.translations["moviePage.AviableSound"] + " " + value.join(" ");
        else
          return this.translations["moviePage.AviableSubtitles"] + " " + value.join(" ");
      }
    },
    scrollIntoView(event) {
      var href = event.target.parentNode.parentNode.getAttribute("href");
      event.preventDefault();
      var element = this.$el.getElementsByClassName(href)[0];
      if (element != undefined) {
        this.$scrollTo(element, 700, { offset: 0 });
      }
    },
    analyticsSend() {
      this.$setGAFormCustom(
        "Wyróżniony Film",
        "Przejdź na stronę filmu",
        this.model.title
      );
    },
    getName(text, full) {
      if (full) return text;
      let splited = text.split(" ");
      return splited[0].substring(0, 1) + ". " + splited[splited.length - 1];
    },
  },
  computed: {
    ctitle() {
      return this.model.additionalInfo
        ? this.model.title + " | " + this.model.additionalInfo
        : this.model.title;
    },
    priceItem() {
      if (this.model.prices)
        return {
          color: "rgba(0, 0, 0, 0.4) !important",
          text: `${this.model.prices.name} ${this.model.prices.price}`,
          textColor: "white",
          showBasket: true,
        };
      return null;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.highlighted {
  .description {
    a {
      text-decoration: none;
    }
  }
  .aditional-info {
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 9999;
    font-size: 2.5em;
  }
  .bodyScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 150px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      bottom: 225px;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      display: none;
    }
  }
  .Fixed {
    position: relative;
  }
  .scroll {
    margin: 10px;
    position: relative;
    width: 17px;
    height: 60px;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      width: 20px;
    }
  }

  .chevron {
    position: absolute;
    width: 17px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      width: 20px;
    }
  }

  .chevron:first-child {
    animation: move 3s ease-out 1s infinite;
  }

  .chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }

  .chevron:before,
  .chevron:after {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
  }

  .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }

  .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }

  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }

  @keyframes pulse {
    to {
      opacity: 1;
    }
  }
  .scrollHere {
    position: absolute;
    bottom: 0;
  }
  &-background {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 100%;
      min-width: 100%;
      max-width: unset !important;
    }
  }
}
</style>

<template>
  <modal
    :title="translations['soundLibrary.DeletePlaylist']"
    :hideClose="true"
    header="addPlaylist"
    :show="show"
    @hidePop="close(false)"
  >
    <template v-slot:main
      ><div class="text-left delete-playlist">
        {{translations["soundLibrary.ConfirmDelete"]}}
      </div>
    </template>
    <template v-slot:btn>
      <div class="flex-buttons">
        <b-button @click="close(false)">{{ translations["general.Close"] }}</b-button>
        <b-button variant="primary" @click="deletePlaylist">{{
          translations["soundLibrary.Delete"]
        }}</b-button>
      </div>
    </template>
  </modal>
</template>
<script>
import modal from "./modal.vue";
import PlaylistService from "../../services/playlistService";
export default {
  components: { modal },
  props: {
    translations: Object,
    deleteId: String,
  },
  data() {
    return { show: false, name: "" };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    close(isDeleted) {
      this.$emit("close", isDeleted);
    },
    deletePlaylist() {
      PlaylistService.delete(this.deleteId).then(() => {
        this.close(true);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-playlist {
  margin-bottom: 24px;
  label {
    font-family: "Wotfard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
}
</style>

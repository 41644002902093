<template>
  <div id="home-page" style="width: 100%">
    <div class="player-audio">
      <player
        ref="player"
        v-if="usePlayer"
        :assetId="1"
        :lang="model.listView.lang"
        :loginUrl="model.listView.loginUrl"
        :showPreviousButton="false"
        :showNextButton="false"
        :translations="model.listView.translations"
        :showContinuePlaying="false"
      >
      </player>
    </div>
    <b-container fluid v-if="model.highlighted && model.highlighted.url">
      <b-row>
        <highlighted
          :model="model.highlighted"
          :translations="model.translations"
        ></highlighted>
      </b-row>
    </b-container>
    <b-container fluid v-else class="categoryHeader disableFlex containerFixed sr-only">
      <b-row class="mt-5">
        <h1 class="sr-only">{{ model.sectionName }}</h1>
      </b-row>
    </b-container>
    <home-sections
      v-for="(item, index) in model.items"
      :key="index"
      :item="item"
      :index="index"
      :translations="model.translations"
      :isFluid="model.highlighted && model.highlighted.url !== null"
    ></home-sections>

    <b-container
      v-if="model.listView && model.items.length == 0"
      fluid
      class="disableFlex containerFixed blank"
    >
    </b-container>
    <b-container
      fluid
      class="disableFlex containerFixed"
      v-if="model.listView"
    >
      <b-row>
        <grid-container
          v-if="model.listView"
          :model="model.listView"
          :header="model.sectionName"
          :isFluid="model.highlighted && model.highlighted.url !== null"
        ></grid-container></b-row
    ></b-container>
  </div>
</template>

<script>
import HomeSections from "../components/HomePage/HomeSections.vue";
import Highlighted from "../components/ListingView/Highlighted.vue";
import GridContainer from "../components/Lists/GridContainer.vue";
import Player from "@/components/common/Player.vue";
import { EventBus } from "@/services/event-bus";

export default {
  name: "HomePage",
  components: {
    Highlighted,
    GridContainer,
    HomeSections,
    Player,
  },
  props: {
    model: Object,
  },
  data() {
    return {      
      usePlayer: false,
    };
  },
  mounted() {
    EventBus.$on("need-player", () => {
      this.usePlayer = true;
    });
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.categoryHeader {
  color: $color-main !important;
  margin: auto !important;
  padding: 0 !important;
  .row {
    margin: 0;
    padding: 0;
    h1 {
      margin-top: 0.3em;
      margin-bottom: 0.3em;
      font-size: em(45);
    }
  }
}
.content {
  width: 100%;
}

h2 {
  color: $color-main;
}
.ListViewer {
  h2,
  h3 {
    a {
      color: white;

      div {
        display: inline-block;
      }

      .showAll {
        margin: 0px;
        max-width: 0;
        overflow: hidden;
        transition: all 1s;
        font-size: em(8);
        white-space: nowrap;
        font-weight: normal;
        text-transform: lowercase;
      }

      i {
        opacity: 0;
        transition: all 0.5s;
        position: relative;
        top: 0.05em;
      }

      .nameSection {
        margin-right: 5px;
        font-weight: bold;
        font-family: $font-family-knile;
        @media (min-width: map-get($grid-breakpoints, "md")) {
          font-size: em(32, 26);
        }
      }
    }

    :hover {
      .showAll {
        margin: 0 8px;
        max-width: 300px;
      }
    }
  }

  .vd_list {
    padding: 23px 0;
  }
  :hover {
    h2,
    h3 {
      i {
        opacity: 1;
      }
    }
  }
}

.ListViewer,
.home__collection {
  h2,
  h3 {
    .nameSection {
      margin-right: 5px;
      font-weight: bold;
      font-family: $font-family-knile;
      @media (min-width: map-get($grid-breakpoints, "md")) {
        font-size: em(32, 26);
      }
    }
  }
}
#home-page {
  .container,
  .container-fluid {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.player-audio {
  position: fixed;
  top: -2000px;
  z-index: 2;
}
</style>

<template>
    <div class="collapse-item" :class="cssClass">
        <b-row class="justify-content-md-center custom">
            <b-col cols="12" lg="12" class="text-center">
                <b-button
                    role="tab"
                    class="collapse-item__button w-100"
                    v-b-toggle="model.key"
                >
                    <b-media right-align vertical-align="center">
                        <template #aside>
                            <div class="arrow"></div>
                        </template>
                        <p v-if="model.title" class="mb-0 mr-3">{{ model.title }} </p>
                        <p v-else class="mb-0 mr-3">{{ defaultTitle }} </p>
                    </b-media>

                </b-button>
            </b-col>
            <b-col cols="12" lg="12">
                <b-collapse class="text-content__body" :id="model.key" v-bind="collapseModel">
                    <slot></slot>
                </b-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "CollapseItem",
    props: {
        model: Object,
        accordionName: String,
        cssClass: String,
        defaultTitle: {
            type: String,
            default: ""
        }
    },
    computed: {
        collapseModel() {
            if (this.accordionName) {
                return {
                    accordion: this.accordionName,
                    role: "tabpanel"
                };
            }
            return {};
        }
    }
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.collapse-item {

    &__button {
        height: auto;
        background-color: $color-gray;
        border: 0;
        border-radius: 2px;
        font-weight: $font-weight-semi-bold;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-top: 0.625rem;

        .media-aside {
            min-width: 30px;
        }

        &.collapsed {
            .arrow {
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid $color-main;
            }
        }

        &.not-collapsed {
            .arrow {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 10px solid $color-main;
                padding-left: 5px;
            }
        }
    }

    &__button:active,
    &__button:focus,
    &__button:hover,
    &__button:visited,
    &__button:focus-visible {
        background-color: $color-gray;
        border-color: inherit;
        box-shadow: none;
    }

    .text-content {
        &__body {
            background-color: $color-light-gray;

            .text-block {
                padding: 15px 20px;
            }
        }
    }

    &__secondary {
        background-color: $color-light-gray;

        .media-aside {
            min-width: 26px;
        }

        .collapse-item__button {
            background-color: $color-light-gray;
            color: $color-black;

            &.collapsed {
                .arrow {
                    border-top: solid $color-black;
                    border-width: 7px;
                }
            }

            &.not-collapsed {
                .arrow {
                    border-color: transparent;
                    border-right: solid $color-black;
                    border-width: 7px;
                }
            }
        }
    }
    .custom{
        margin: 0;
    }
}
</style>
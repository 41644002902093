<template>
  <section class="main-nav-bar">
    <div class="main-nav-bar__first-level">
      <b-container fluid class="containerFixed">
        <b-row>
          <div class="row m-0 w-100">
            <b-navbar-nav class="main-nav ml-0 mr-0" id="TVScroll" ref="scroll">
              <template v-for="(main, index) in mainLinks">
                <b-nav-item
                  :key="index"
                  :id="'nav' + index"
                  class="navitem-hover-left-right"
                  v-if="!main.subPages"
                  :to="main.url"
                  exact-active-class="active-link"
                  exact
                  @click="sendAnalitycs(main.name)"
                  @focus="focus"
                  >{{ main.name }}</b-nav-item
                >
                <b-nav-item
                  :key="index"
                  v-else-if="!$isTizen($browserDetect)"
                  exact-active-class="active-link"
                  class="main-nav__dropdown"
                  :class="[openSubmenuIndex == index ? 'active' : '']"
                  @click.stop="showSubmenu(index)"
                >
                  <span class="submenu-toggle-text">{{ main.name }}</span
                  ><i
                    class="main-nav__icon"
                    :class="[
                      openSubmenuIndex != index
                        ? 'cil-chevron-bottom-alt'
                        : 'cil-chevron-left-alt',
                    ]"
                  ></i>
                  <transition name="fade" mode="out-in">
                    <b-navbar-nav
                      v-if="openSubmenuIndex == index"
                      class="submenu"
                    >
                      <b-navbar-nav v-if="!user">
                        <b-nav-item
                          class="submenu-hover-left-right"
                          exact-active-class="active-link-submenu"
                          v-for="(subPage, i) in main.subPages.filter(
                            (subPage) => !subPage.requireAuthorization
                          )"
                          :key="i"
                          :to="subPage.url"
                          @click="sendAnalitycs(subPage.name)"
                          >{{ subPage.name }}</b-nav-item
                        >
                      </b-navbar-nav>

                      <b-navbar-nav v-if="user">
                        <b-nav-item
                          class="submenu-hover-left-right"
                          exact-active-class="active-link-submenu"
                          v-for="(subPage, i) in main.subPages"
                          @click="sendAnalitycs(subPage.name)"
                          :key="i"
                          :to="subPage.url"
                          >{{ subPage.name }}</b-nav-item
                        >
                      </b-navbar-nav>
                    </b-navbar-nav>
                  </transition>
                </b-nav-item>
                <b-navbar-nav
                  :key="index"
                  class="submenu"
                  :class="[openSubmenuIndex == index ? 'active' : '']"
                  v-else
                >
                  <b-nav-item
                    exact-active-class="active-link"
                    class="main-nav__dropdown"
                    @click.stop="showSubmenu(index)"
                    @focus="focus"
                  >
                    <span class="submenu-toggle-text">{{ main.name }}</span
                    ><i
                      class="main-nav__icon"
                      :class="[
                        openSubmenuIndex != index
                          ? 'cil-chevron-bottom-alt'
                          : 'cil-chevron-left-alt',
                      ]"
                    ></i> </b-nav-item
                  ><b-navbar-nav v-if="!user">
                    <b-nav-item
                      class="submenu-hover-left-right"
                      exact-active-class="active-link-submenu"
                      v-for="(subPage, i) in main.subPages.filter(
                        (subPage) => !subPage.requireAuthorization
                      )"
                      :key="i"
                      :to="subPage.url"
                      @click="sendAnalitycs(subPage.name)"
                      @focus="focus"
                      >{{ subPage.name }}</b-nav-item
                    >
                  </b-navbar-nav>

                  <b-navbar-nav v-else>
                    <b-nav-item
                      class="submenu-hover-left-right"
                      exact-active-class="active-link-submenu"
                      v-for="(subPage, i) in main.subPages"
                      @click="sendAnalitycs(subPage.name)"
                      @focus="focus"
                      :key="i"
                      :to="subPage.url"
                      >{{ subPage.name }}</b-nav-item
                    >
                  </b-navbar-nav>
                </b-navbar-nav>
              </template>
            </b-navbar-nav>
          </div>
        </b-row>
      </b-container>
    </div>
  </section>
</template>
<script>
import authService from "@/services/authService";
import { EventBus } from "@/services/event-bus";
export default {
  props: ["mainLinks"],
  data() {
    return {
      isOpen: false,
      openSubmenuIndex: null,
      isSubmenuActive: false,
      user: null,
    };
  },
  methods: {
    focus(a) {
      if (this.$isTizen(this.$browserDetect)) {
        this.$scrollTo(a.srcElement, 500, {
          container: "#TVScroll",
          x: true,
          y: false,
          offset: -1000,
        });
      }
    },
    showSubmenu(index) {
      if (this.openSubmenuIndex == index) {
        this.openSubmenuIndex = null;
        return;
      }
      this.openSubmenuIndex = index;
    },
    sendAnalitycs(label) {
      this.$setGAFormNavigation("Kliknięcie menu", label, false);
    },
    CloseSubmenu() {
      this.isSubmenuActive = false;
    },
  },
  mounted() {
    if (window.RedgalaxyNav) {
      window.RedgalaxyNav.focusElement("#nav0 .nav-link");
    }
    this.user = authService.getUser();
    let currentPage = this.$route.path.split("/");
    let menuName = this.mainLinks.map((el) => el.name.toLowerCase());
    this.openSubmenuIndex = menuName.findIndex(function (name) {
      return currentPage[1] == name;
    });
    if (this.openSubmenuIndex && this.openSubmenuIndex > -1) {
      this.isSubmenuActive = true;
    }
    EventBus.$on("closeNavEvent", this.CloseSubmenu);
  },
  beforeDestroy() {
    EventBus.$off("closeNavEvent", this.CloseSubmenu);
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";

.main-nav-bar {
  width: 100%;
  position: relative;
  &__first-level {
    .containerFixed {
      .row {
        width: 100%;
      }
    }
  }
}

.main-nav {
  width: 100%;
  margin: 11px 20px;
  flex-wrap: wrap;
  position: relative;

  &:first-child .nav-item .nav-link {
    padding-left: 0;
    > a {
      padding-bottom: 1px;
    }
  }
  .nav-item {
    padding-right: 0 !important;
    min-height: 36px;
    padding-left: 20px;
    > a.nav-link {
      padding-bottom: 0.0625em;
      padding-top: 4px;
      font-weight: $font-weight-semi-bold;
      @include media-breakpoint-down(md) {
        font-weight: $font-weight-regular;
        font-size: em(15, 12);
      }
    }
    &.active .submenu {
      display: flex;
    }
  }
  &__icon {
    margin-left: 10px;
    display: inline-block;

    &.down {
      background-image: url("/assets/arrow_down.svg");
      background-repeat: no-repeat;
      background-size: 50%;
      background-position-x: center;
      background-position-y: top;
      width: 21px;
      @include media-breakpoint-up(lg) {
        width: 25px;
      }
    }
    &.left {
      background-image: url("/assets/arrow_left.svg");
      background-repeat: no-repeat;
      background-size: 28%;
      background-position-x: center;
      background-position-y: center;
      width: 20px;
      padding-bottom: 4px;

      @include media-breakpoint-up(lg) {
        background-image: url("/assets/arrow_down.svg");
        background-repeat: no-repeat;
        background-size: 50%;
        background-position-x: center;
        background-position-y: top;
        width: 25px;
        padding-bottom: 0;
      }
    }
  }

  &__dropdown {
    .submenu-toggle-text {
      @include hover-slider-bottom(#fff);
      padding-bottom: 0.0625em;
      display: inline-block;
    }
    .submenu {
      position: relative;
      background-color: #1d1d1d !important;
      margin-left: -20px;
      padding-left: 30px;
      overflow-x: hidden;
      overflow-y: hidden;

      .submenu-hover-left-right {
        > a {
          display: inline-block;
          @include hover-slider-bottom($color-main);
        }
      }

      .nav-item > a {
        color: $color-main !important;
        font-size: 1em;
        line-height: 1.2;
        font-weight: $font-weight-bold;
        margin: 5px 0;
        padding-top: 2px;
        @include media-breakpoint-up(lg) {
          font-size: 0.9em;
          padding-top: 7.7px;
        }
      }

      @include media-breakpoint-up(lg) {
        position: fixed;
        background-color: $color-light-gray;
        padding: 0.2em 1000em; /* trick from css-tricks comments */
        margin: 0 -1000em; /* trick from css-tricks comments */
        z-index: 2;
        color: black;
        overflow-x: hidden;
        overflow-y: hidden;
        width: auto;

        .nav-item {
        }
        .nav-item > a {
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .nav-item {
      padding: 0 3.9125rem 0 0;
      font-size: $font-size-desktop-mainmenu;
      margin-right: em(45);
      margin-bottom: 0.2em;
    }
  }
}
.slide1-enter-active {
  animation: showHide 0.2s;
}
.slide1-leave-active {
  animation: showHide 0.2s reverse;
}
@keyframes showHide {
  from {
    transform: scaley(0) translate3d(0, -100%, 0);
    height: 0;
  }
  to {
    transform: scaley(1) translate3d(0, 0, 0);
  }
}
.active-link {
  text-decoration: none;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 2px, 100% 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}
.active-link-submenu {
  text-decoration: none;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#000, #000);
  background-size: 0 2px, 100% 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}
.wcag-fontboost2 {
  .main-nav {
    .nav-item {
      // margin-right: 2rem;
    }
  }
}
</style>
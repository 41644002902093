<template>
  <div class="audio-playlist" v-click-outside="clickOutside">
    <b-container fluid class="audio-playlist__header" v-if="headerText || showSort">
      <b-row>
        <b-col class="audio-playlist__header__caption align-self-center">
          {{ headerText }}
        </b-col>
        <b-col class="audio-playlist__header__sort" v-if="localShowSort">
          <b-dropdown
            class="mb-3"
            no-flip
            v-model="sortSelected.key"
            :text="translations['searchPage.Sort'] + ': ' + sortSelected.value"
            @change="sortChanged"
          >
            <b-dropdown-item
              v-for="(item, index) in sortValues"
              :key="index"
              :value="item.key"
              @click="sortChanged(item)"
            >
              {{ item.value }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="audio-playlist__list">
      <b-row
        v-for="(episode, index) in this.localEpisodes"
        :key="index"
        class="audio-playlist__episode-row align-items-center"
      >
        <b-col class="index text-center px-0" v-if="showIndex">
          {{ index + 1 }}
        </b-col>
        <b-col class="btnplay" :class="{ 'hide-mobile': false, 'px-0': showIndex }">
          <div
            v-if="isPlaylistItem(episode)"
            class="play-episode"
            :style="
              'background-image: url(' +
              (episode.categoryImage
                ? episode.categoryImage.url
                : episode.image
                ? episode.image.url
                : '') +
              ')'
            "
          ></div>
          <b-button
            v-else
            role="button"
            variant="secondary"
            class="play-episode"
            :class="{ current: player && currentIndex == index }"
            :style="
              'background-image: url(' +
              (episode.categoryImage
                ? episode.categoryImage.url
                : episode.image
                ? episode.image.url
                : '') +
              ')'
            "
            @click="togglePlayEpisode(index, false, true)"
          >
            <i
              :class="{
                'cil-media-play': isEpisodePlaying[index] == false,
                'cil-media-pause': isEpisodePlaying[index] == true,
              }"
            ></i>
          </b-button>
        </b-col>
        <b-col class="px-0">
          <b-link :to="episode.url" class="d-block episode-title focus-smart"
            ><span></span>{{ episode.title }}</b-link
          >
          <span class="episode-author" v-if="getAuthor(episode)"
            >{{ getAuthor(episode) }}
          </span>
        </b-col>
        <b-col class="buttons">
          <span class="btncol signature hide4mobile">
            {{ episode.signature }}
          </span>
          <span class="btncol duration">
            <i class="cil-clock align-bottom"></i>
            <span class="pl-1 align-bottom">{{ getDurationStr(episode.duration) }}</span>
          </span>

          <b-link
            v-if="!$browserDetect.isApp && !showLoop"
            :title="translations['soundLibrary.DoLoop']"
            @click.native="toggleLoop(index)"
            class="btncol ibtn loop hide4mobile focus-smart"
            :class="{ show: localEpisodes[index].loop, hide: !showLoop }"
            v-model="loopRefresher"
            ><i class="cil-loop"></i>
          </b-link>

          <b-link
            :id="'addbtn' + episode.favoriteModel.id"
            :title="translations['soundLibrary.AddToPlaylist']"
            @click.native="addToPlaylist(episode)"
            class="btncol ibtn playlist hide4mobile focus-smart"
            :class="{ 'force-hide': isPlaylistItem(episode) }"
            v-if="isLoggedIn"
            ><i class="cil-playlist-add"></i>
          </b-link>

          <favorites-control
            v-if="!$browserDetect.isApp"
            :model="episode.favoriteModel"
            section="Playlist"
            :title="episode.title"
            css-class="btncol ibtn hide4mobile"
            :class="{ 'force-hide': isPlaylistItem(episode) }"
          ></favorites-control>

          <share-control-v2
            v-if="!$browserDetect.isApp"
            css-class="btncol ibtn hide4mobile"
            :translations="translations"
            section="Playlist"
            :title="episode.title"
          ></share-control-v2>

          <b-link
            v-if="showTrash"
            :title="translations['soundLibrary.DeleteFromPlaylist']"
            @click.native="deleteFromPlaylist(index)"
            class="btncol ibtn hide4mobile focus-smart"
            ><i class="cil-trash"></i>
          </b-link>

          <b-link
            v-if="showMove"
            :class="{ 'force-hide': index <= 0 }"
            :title="translations['soundLibrary.MoveUp']"
            @click.native="moveUp(index)"
            class="btncol ibtn hide4mobile focus-smart"
            ><i class="cil-chevron-top-alt"></i>
          </b-link>

          <b-link
            v-if="showMove"
            :class="{ 'force-hide': localEpisodes && index >= localEpisodes.length - 1 }"
            :title="translations['soundLibrary.MoveDown']"
            @click.native="moveDown(index)"
            class="btncol ibtn hide4mobile focus-smart"
            ><i class="cil-chevron-bottom-alt"></i>
          </b-link>

          <div class="btncol ibtn show4mobile">
            <b-link
              :title="translations['general.ShowMore']"
              v-b-toggle="mobileCollapsePrefix + index"
              @click="closeCollapses(mobileCollapsePrefix + index)"
              ><i class="cis-options-alt" aria-hidden="true"></i>
              <span class="wcag_hide">{{ translations["general.ShowMore"] }}</span>
            </b-link>
            <b-collapse
              :ref="mobileCollapsePrefix + index"
              :id="mobileCollapsePrefix + index"
              class="collapse-mobile-more"
            >
              <b-card>
                <b-link
                  @click.native="toggleLoop(index)"
                  class="collapse-lnk loop"
                  :class="{ show: localEpisodes[index].loop }"
                  v-model="loopRefresher"
                  ><i class="cil-loop"></i>{{ translations["soundLibrary.DoLoop"] }}
                </b-link>

                <b-link
                  :id="'addbtn' + episode.favoriteModel.id"
                  @click.native="addToPlaylist(episode)"
                  class="collapse-lnk playlist"
                  v-if="isLoggedIn && !isPlaylistItem(episode)"
                  ><i class="cil-playlist-add"></i
                  >{{ translations["soundLibrary.AddToPlaylist"] }}
                </b-link>

                <favorites-control
                  :model="episode.favoriteModel"
                  section="Playlist"
                  :title="episode.title"
                  css-class="collapse-lnk"
                  v-if="!$browserDetect.isApp && isLoggedIn && !isPlaylistItem(episode)"
                  >{{ translations["general.Favorite.Add"] }}</favorites-control
                >

                <share-control-v2
                  v-if="!$browserDetect.isApp"
                  css-class="collapse-lnk mshare"
                  :translations="translations"
                  section="Playlist"
                  :title="episode.title"
                  >{{ translations["general.Share"] }}</share-control-v2
                >

                <b-link
                  v-if="showTrash"
                  @click.native="deleteFromPlaylist(index)"
                  class="collapse-lnk"
                  ><i class="cil-trash"></i
                  >{{ translations["soundLibrary.DeleteFromPlaylist"] }}
                </b-link>

                <b-link
                  v-if="showMove && index > 0"
                  @click.native="moveUp(index)"
                  class="collapse-lnk"
                  ><i class="cil-chevron-top-alt"></i
                  >{{ translations["soundLibrary.MoveUp"] }}
                </b-link>

                <b-link
                  v-if="showMove && localEpisodes && index < localEpisodes.length - 1"
                  @click.native="moveDown(index)"
                  class="collapse-lnk"
                  ><i class="cil-chevron-bottom-alt"></i
                  >{{ translations["soundLibrary.MoveDown"] }}
                </b-link>

                <b-button role="button" variant="secondary" class="w-100">
                  {{ translations["general.Close"] }}
                </b-button>
              </b-card>
            </b-collapse>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="addpl"
          :class="{
            show:
              !hideAddToPl && episode.favoriteModel && episode.favoriteModel.id == idAdd,
          }"
        >
          <add-playlist
            v-if="
              episode.favoriteModel &&
              (episode.favoriteModel.id == idAdd || episode.favoriteModel.id == idAddPrev)
            "
            :episodeId="episode.favoriteModel.id"
            @close="onAddPlaylistClose(episode.favoriteModel.id)"
            :translations="translations"
          ></add-playlist>
        </b-col>
        <div class="track" :style="trackSize(index)"></div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { PlayerStatus } from "@/components/common/Player.vue";
import FavoritesControl from "@/components/common/FavoritesControl.vue";
import ShareControlV2 from "@/components/common/ShareControlV2.vue";
import AddPlaylist from "./AddPlaylist.vue";
import PlaylistService from "../../services/playlistService";
import { EventBus } from "@/services/event-bus";
import authService from "@/services/authService";
export default {
  components: {
    FavoritesControl,
    ShareControlV2,
    AddPlaylist,
  },
  name: "AudioPlaylist",
  props: {
    translations: Object,
    shuffle: Boolean,
    loopPlaylist: Boolean,
    episodes: null,
    showSort: {
      type: Boolean,
      default: false,
    },
    headerText: String,
    showLoop: {
      type: Boolean,
      default: true,
    },
    showIndex: Boolean,
    singlePlay: Boolean,
    showTrash: Boolean,
    showMove: Boolean,
    playlistId: String,
    isUserPlaylist: Boolean,
  },
  data() {
    return {
      player: null,
      currentIndex: 0,
      currentStatus: null,
      currentPosition: null,
      trackPosition: null,
      localShowSort: false,
      sortSelected: null,
      sortValues: [],
      loopRefresher: 0,
      localEpisodes: null,
      lastMobileCollapse: null,
      shuffleArray: [],
      idAdd: -1,
      idAddPrev: -1,
      hideAddToPl: false,
      mobileCollapsePrefix: "collapse-mobile-",
      isLoggedIn: false,
      skipEpisodeWatch: false,
    };
  },
  beforeDestroy() {
    if (this.player) {
      this.player.$off("player-status", this.onPlayerStatus);
    }
    this.stopTrackPosition();
  },
  methods: {
    isPlaylistItem(e) {
      return e.type === "Playlist";
    },
    getPlayer() {
      let p = this.$parent;
      while (p && !p.$refs.player) {
        p = p.$parent;
      }
      return p && p.$refs.player ? p.$refs.player : null;
    },
    onAddPlaylistClose(id) {
      const id1 = id;
      this.hideAddToPl = true;
      setTimeout(() => {
        this.idAdd = -1;
        window.RedgalaxyNav.focusElement("#addbtn" + id1);
      }, 1000);
    },
    onPlayerStatus(status, time) {
      if (
        status == PlayerStatus.NEXT ||
        status == PlayerStatus.PREVIOUS ||
        status == PlayerStatus.ENDED
      ) {
        let gotoIndex;
        let manualNextPrev = false;
        if (status == PlayerStatus.ENDED) {
          if (this.localEpisodes[this.currentIndex].loop) {
            gotoIndex = this.currentIndex;
          } else if (this.singlePlay) {
            gotoIndex = -1;
          } else if (this.shuffle) {
            gotoIndex = this.takeNextShuffleIdx();
            if (gotoIndex < 0 && this.loopPlaylist) {
              this.buildShuffleArray();
              gotoIndex = this.takeNextShuffleIdx();
            }
          } else {
            gotoIndex = this.currentIndex + 1;
          }
        } else if (status == PlayerStatus.NEXT) {
          manualNextPrev = true;
          gotoIndex = this.currentIndex + 1;
        } else if (status == PlayerStatus.PREVIOUS) {
          manualNextPrev = true;
          gotoIndex = this.currentIndex - 1;
        }

        if (gotoIndex >= this.localEpisodes.length && this.loopPlaylist) {
          gotoIndex = 0;
        }
        if (gotoIndex >= 0 && gotoIndex < this.localEpisodes.length) {
          this.currentStatus = PlayerStatus.STOPPED;
          this.currentPosition = null;
          this.togglePlayEpisode(gotoIndex, true, manualNextPrev);
        } else {
          this.shuffleArray = null;
        }
      } else {
        if (this.shuffle && !this.shuffleArray) {
          this.buildShuffleArray();
        }
        this.currentStatus = status;
        if (status == PlayerStatus.PLAYING) {
          this.startTrackPosition();
        } else if (
          status == PlayerStatus.STOPPED ||
          status == PlayerStatus.ENDED ||
          status == PlayerStatus.ERROR
        ) {
          this.stopTrackPosition();
        } else if (
          this.currentPosition &&
          (status == PlayerStatus.SEEKED || status == PlayerStatus.PAUSED)
        ) {
          this.currentPosition.time = time;
        }
      }
    },
    buildShuffleArray() {
      let idxarray = this.localEpisodes.map((x, index) => index);
      let curIndex = idxarray.length,
        randomIndex;
      while (curIndex != 0) {
        randomIndex = Math.floor(Math.random() * curIndex);
        curIndex--;
        [idxarray[curIndex], idxarray[randomIndex]] = [
          idxarray[randomIndex],
          idxarray[curIndex],
        ];
      }
      let firstEl = idxarray[0];
      while (firstEl != this.currentIndex) {
        idxarray.push(idxarray.shift());
        firstEl = idxarray[0];
      }
      this.shuffleArray = idxarray;
      this.currentShuffleIndex = 0;
    },
    takeNextShuffleIdx() {
      if (++this.currentShuffleIndex >= this.shuffleArray.length) return -1;
      else return this.shuffleArray[this.currentShuffleIndex];
    },
    clickOutside() {
      if (this.lastMobileCollapse) {
        this.$root.$emit("bv::toggle::collapse", this.lastMobileCollapse);
      }
    },
    closeCollapses(collapseName) {
      if (this.lastMobileCollapse && collapseName !== this.lastMobileCollapse) {
        this.$root.$emit("bv::toggle::collapse", this.lastMobileCollapse);
      }
    },
    trackSize(index) {
      return this.currentIndex == index && this.currentPosition
        ? {
            width: `${
              (100 * this.currentPosition.time) / this.currentPosition.duration
            }%`,
          }
        : null;
    },
    startTrackPosition() {
      if (!this.trackPosition) {
        this.trackPosition = setInterval(() => this.updatePlayerPosition(), 1000);
      }
    },
    stopTrackPosition() {
      if (this.trackPosition) {
        clearInterval(this.trackPosition);
        this.trackPosition = null;
      }
    },
    updatePlayerPosition() {
      this.currentPosition = this.player.getCurrentTime();
    },
    togglePlayEpisode(episodeIndex, forceReload, manual) {
      if (!this.player) {
        this.player = this.getPlayer();
      }
      if (!this.player) {
        this.$router.push(this.localEpisodes[episodeIndex].url);
      } else {
        EventBus.$emit("player-takeover", this._uid);
        let assetId = this.localEpisodes[episodeIndex].atdId;
        if (
          this.currentIndex == episodeIndex &&
          !forceReload &&
          this.player.getProductId() == assetId
        ) {
          this.player.togglePlay(this.currentStatus != PlayerStatus.PLAYING);
        } else {
          this.currentIndex = episodeIndex;
          this.$emit("updateTitle", {
            title: this.localEpisodes[this.currentIndex].title,
            position: this.currentIndex,
          });
          this.stopTrackPosition();
          this.currentPosition = null;
          this.player.setProductID(assetId);
          this.player.setNextVisible(
            !this.singlePlay && this.currentIndex < this.localEpisodes.length - 1
          );
          this.player.setPrevVisible(!this.singlePlay && this.currentIndex > 0);
          if (this.shuffle && manual) {
            this.buildShuffleArray();
          }
        }
      }
    },
    toggleLoop(index) {
      this.localEpisodes[index].loop = !this.localEpisodes[index].loop;
      this.loopRefresher++;
    },
    addToPlaylist(episode) {
      if (episode.favoriteModel) {
        this.hideAddToPl = false;
        this.idAddPrev = this.idAdd;
        this.idAdd = episode.favoriteModel.id;
      }
    },
    getDurationStr: function (duration) {
      var totalSeconds = duration;
      var hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      var minutes = Math.floor(totalSeconds / 60);
      var seconds = totalSeconds % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },
    deleteFromPlaylist(idx) {
      let deleteId = this.localEpisodes[idx].favoriteModel.id;
      PlaylistService.deleteItem(this.playlistId, deleteId);
      let bCurrentDeleted = idx == this.currentIndex;
      this.localEpisodes.splice(idx, 1);
      if (this.localEpisodes.length <= 0) {
        this.currentIndex = -1;
      } else if (idx <= this.currentIndex) {
        this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : 0;
      }
      if (bCurrentDeleted && this.currentIndex >= 0) {
        this.togglePlayEpisode(this.currentIndex, true);
      }
    },
    moveUp(idx) {
      let temp = this.localEpisodes[idx];
      this.localEpisodes.splice(idx, 1);
      this.localEpisodes.splice(idx - 1, 0, temp);
      if (idx == this.currentIndex) {
        this.currentIndex--;
      } else if (idx == this.currentIndex + 1) {
        this.currentIndex++;
      }
      let ids = this.localEpisodes.map((x) => x.favoriteModel.id);
      PlaylistService.update(this.playlistId, ids);
      this.sortSelected = this.sortValues[0];
      this.saveOrder();
    },
    moveDown(idx) {
      let temp = this.localEpisodes[idx];
      this.localEpisodes.splice(idx, 1);
      this.localEpisodes.splice(idx + 1, 0, temp);
      if (idx == this.currentIndex) {
        this.currentIndex++;
      } else if (idx == this.currentIndex - 1) {
        this.currentIndex--;
      }
      let ids = this.localEpisodes.map((x) => x.favoriteModel.id);
      PlaylistService.update(this.playlistId, ids);
      this.sortSelected = this.sortValues[0];
      this.saveOrder();
    },
    sortChanged(item) {
      this.sortSelected = item;
      let currentId = this.localEpisodes[this.currentIndex].favoriteModel.id;
      let cmpFnc;
      switch (item.key) {
        case 1:
          cmpFnc = function (a, b) {
            return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
          };
          break;
        case 2:
          cmpFnc = function (a, b) {
            return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
          };
          break;
        case 3:
          cmpFnc = function (a, b) {
            return -1 * (a.title > b.title ? 1 : a.title < b.title ? -1 : 0);
          };
          break;
        case 4:
          cmpFnc = function (a, b) {
            return a.duration > b.duration ? 1 : a.duration < b.duration ? -1 : 0;
          };
          break;
        case 5:
          cmpFnc = function (a, b) {
            return -1 * (a.duration > b.duration ? 1 : a.duration < b.duration ? -1 : 0);
          };
          break;
      }
      this.skipEpisodeWatch = true;
      this.localEpisodes.sort(cmpFnc);
      this.currentIndex = this.localEpisodes.findIndex(function (el) {
        return el.favoriteModel.id == currentId;
      });
    },
    saveOrder() {
      this.localEpisodes.forEach((x, index) => (x.order = index));
    },
    getAuthor(episode) {
      return episode.showOnBox != null &&
        episode.showOnBox.persons != null &&
        episode.showOnBox.persons.length > 0
        ? episode.showOnBox.persons[0]
        : null;
    },
    onPlayerTakeover(uid) {
      if (uid != this._uid) {
        if (this.player) {
          this.player.$off("player-status", this.onPlayerStatus);
          this.stopTrackPosition();
          this.currentIndex = -1;
          this.currentPosition = null;
          this.player = null;
        }
      }
    },
  },
  computed: {
    isEpisodePlaying() {
      return this.localEpisodes.map((e, index) => {
        return index == this.currentIndex && this.currentStatus == PlayerStatus.PLAYING;
      });
    },
  },
  watch: {
    shuffle(newValue) {
      if (newValue) {
        this.buildShuffleArray();
      }
    },
    player(ply) {
      if (ply) {
        ply.$on("player-status", this.onPlayerStatus);
      }
    },
    episodes(e) {
      this.localEpisodes = e;
      if (!this.skipEpisodeWatch) this.saveOrder();
    },
  },
  mounted() {
    this.isLoggedIn = authService.getUser() ? true : false;
    EventBus.$on("player-takeover", this.onPlayerTakeover);
    EventBus.$emit("need-player");
    this.sortValues = [
      {
        key: 1,
        value: this.translations[
          this.isUserPlaylist ? "soundLibrary.SortCustom" : "soundLibrary.SortDefault"
        ],
      },
      { key: 2, value: this.translations["searchPage.Sort.Ascending"] },
      { key: 3, value: this.translations["searchPage.Sort.Descending"] },
      { key: 4, value: this.translations["searchPage.Sort.Longest"] },
      { key: 5, value: this.translations["searchPage.Sort.Shortest"] },
    ];
    this.sortSelected = this.sortValues[0];
    this.localShowSort = this.showSort;
    this.localEpisodes = this.episodes;
    this.$emit("updateTitle", {
      title: this.localEpisodes[this.currentIndex].title,
      position: this.currentIndex,
    });
    this.saveOrder();
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId && collapseId.includes(this.mobileCollapsePrefix)) {
        if (isJustShown) {
          this.lastMobileCollapse = collapseId;
        } else if (collapseId === this.lastMobileCollapse) {
          this.lastMobileCollapse = null;
        }
      }
    });
    if (this.shuffle) {
      this.buildShuffleArray();
    }
    if (!this.singlePlay) {
      setTimeout(() => {
        this.player = this.getPlayer();
      }, 100);
    }
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

$font-size-smaller: 0.8em;
$font-size-small: 0.6em;

.disableFlex {
  .row {
    display: block !important;

    .splide {
      display: block;
    }
  }
  .audio-playlist .row {
    display: flex !important;
    & > * {
      margin-bottom: 0 !important;
    }
  }
}

.audio-playlist {
  color: $color-main;
  font-family: $font-family-wotfard;
  width: 100%;
  &__header {
    &__caption {
      font-weight: 600;
      font-size: 1em;
      text-transform: uppercase;
      min-height: 2em;
    }
    &__sort {
      flex: 0 0 1px;
      @media (max-width: 460px) {
        flex: 0 0 100%;
      }
      .dropdown {
        float: right;
        .btn-secondary {
          font-family: "knile";
          font-style: normal;
          font-weight: 600;
          font-size: em(16);
          line-height: 24px;
          display: flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.7);
          flex: none;
          text-transform: none;
          padding: em(16) em(24);
          background: transparent;
          &:hover {
            border-color: white;
          }
        }
        .dropdown-menu {
          width: 100%;
          background: black;
          padding: 16px;
          .dropdown-item {
            color: white;
            padding: 12px 8px;
            &:hover {
              background: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }

  &__list {
    padding-left: 0;
    padding-right: 0;
  }

  &__episode-row {
    position: relative;
    margin: 0.5em auto;
    background-color: #242424;
    padding: 0.9em 0;
    transition: all 0.2s;
    min-height: 5em;
    &:hover,
    &:focus-within {
      background-color: $color-dark-gray-alt;
      box-shadow: 0 0 0 2px white;
      .btncol {
        visibility: visible !important;
        &.force-hide {
          visibility: hidden !important;
        }
      }
      .play-episode {
        > i {
          opacity: 1;
        }
      }
      .index-mobile {
        &__play {
          display: block !important;
        }
        &__digit {
          display: none !important;
        }
      }
    }

    .index {
      flex: 0 0 3.2em;
    }

    .index-mobile {
      flex: 0 0 4em;
      display: none;
      padding: 0;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .btnplay {
      flex: 0 0 4em;
      @include media-breakpoint-down(sm) {
        &.hide-mobile {
          display: none;
        }
      }
    }

    .play-episode {
      width: 3em;
      height: 3em;
      padding: 0;
      border: none;
      > i {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        font-size: 2.5em;
        line-height: 1.2;
        background-color: rgba(55, 55, 55, 0.75);
        opacity: 0;
        @include media-breakpoint-down(sm) {
          opacity: 0.5;
        }
      }
      background: center / cover no-repeat;
      &.current {
        > i {
          opacity: 1;
        }
      }
    }

    .buttons {
      flex: 0 0 1%;
      white-space: nowrap;
      .btncol {
        line-height: 1;
        margin: 0 0.7em;
        vertical-align: middle;
        display: inline-block;
        visibility: hidden;
        &.show {
          visibility: visible !important;
          &.loop i {
            font-weight: bold !important;
          }
        }
        &.hide {
          display: none;
        }
        &.show4mobile {
          display: none;
        }
        @include media-breakpoint-down(sm) {
          &.hide4mobile {
            display: none;
          }
          &.show4mobile {
            display: inline-block;
            visibility: visible !important;
          }
        }
      }

      .ibtn {
        vertical-align: middle;
        i {
          font-size: 1.2em;
        }
        &.playlist > i {
          font-size: 1.8em;
        }
      }

      .duration {
        visibility: visible !important;
      }

      .collapse-mobile-more {
        position: absolute;
        right: 0;
        z-index: 1;
        background-color: $body-bg;
        min-width: 200px;
        > .card {
          background-color: transparent !important;
        }

        .collapse-lnk {
          display: block;
          margin: 0 0 2.5em 0;
          text-transform: uppercase;
          i {
            font-size: 1.2em;
            width: 1.5em;
            display: inline-block;
          }
          &.loop.show {
            font-weight: bold;
            i {
              font-weight: bold;
            }
          }
          &.mshare .medialist {
            left: 1em;
            i {
              width: unset;
            }
          }
        }
      }
    }

    .episode-title {
      font-family: $font-family-knile;
    }

    .episode-author {
      font-size: 0.8em;
      min-height: 1em;
      display: inline-block;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .track {
      position: absolute;
      z-index: 1;
      height: 3px;
      background-color: white;
      left: 0;
      bottom: 0;
      width: 0;
    }
  }

  .addpl {
    transition: all 0.5s;
    opacity: 0;
    max-height: 0;
    &.show {
      overflow: auto;
      max-height: 500px;
      opacity: 1;
    }
  }
}
</style>

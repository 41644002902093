<!------------------------------------------
Komonent z playerem i stopklatkami dla strony 
wyszukiwania AI w filmie
-------------------------------------------->
<template>
  <div class="leftSide">
    <player
      ref="player"
      :assetId="model.atdId"
      :imageUrl="model.image.url"
      :loginUrl="model.loginUrl"
      :overlay="model.overlay"
      :jumpTime="model.jumpTime"
      :translations="model.translations"
      :showContinuePlaying="false"
    >
    </player>
    <div class="timeline" v-if="pagination.totalItems > 0">
      <div class="spinner" :class="{ show: showSpinner }">
        <b-spinner></b-spinner>
      </div>
      <div class="progressbar" v-if="!isTv">
        <div class="progressbar__main">
          <div
            class="progressbar__item"
            v-for="(fitem, findex) in frames"
            :key="findex"
            :style="getStyle(fitem)"
            :class="{ active: hoverItem == fitem.id }"
            @mouseover="hoverItem = fitem.id"
            @mouseleave="hoverItem = null"
          ></div>
        </div>
        <div class="progressbar__start">00:00</div>
        <div class="progressbar__end">{{ convertSecond(model.duration) }}</div>
      </div>
      <div class="movie__search__result">
        <h3 v-if="!isSearch">{{ model.translations["moviePageAI.SelectedScenes"] }}</h3>
        <h3 v-else>
          {{
            model.translations["moviePageAI.FindedScenes"].replace(
              "{count}",
              pagination.totalItems
            )
          }}
        </h3>
        <div class="frames">
          <b-button
            class="frame focus-smart"
            v-for="(fitem, findex) in pagination.items"
            :key="findex"
            @mouseover="hoverItem = fitem.id"
            @mouseleave="hoverItem = null"
            @click="goTo(fitem.startTime)"
            :class="{
              active: hoverItem == fitem.id,
            }"
          >
            <lazy-image
              :source="fitem.imageUrl ? fitem.imageUrl : '/assets/35mm.svg'"
              :alt="fitem.frameDescription"
              class="fimg"
              :key="pagination.pageNumber"
            />
            <span class="tc">{{ fitem.frameDescription }}</span>
          </b-button>
        </div>
        <b-pagination
          v-if="pagination.totalItems > pagination.itemsPerPage"
          :value="pagination.pageNumber"
          :total-rows="pagination.totalItems"
          :per-page="pagination.itemsPerPage"
          :aria-label="'Stronicowanie'"
          :label-next-page="'Przejdź do następnej'"
          :label-page="'Przejdź do'"
          :label-prev-page="'Przejdź do poprzedniej'"
          :label-last-page="'Przejdź do ostatniej'"
          :label-first-page="'Przjdź do pierwszej'"
          first-class="cis-chevron-double-left-alt custom"
          last-class="cis-chevron-double-right-alt custom"
          prev-class="cis-chevron-left-alt custom"
          next-class="cis-chevron-right-alt custom"
          :pills="true"
          @change="GotoPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import LazyImage from "./LazyImage.vue";
import Player from "./Player.vue";
export default {
  components: {
    //----------------
    //OBVS
    //----------------
    Player,

    //----------------
    //Komponent dla obrazów lazy-loading
    //----------------
    LazyImage,
  },
  props: {
    //----------------
    //Model backend: AssetViewModelAI
    //----------------
    model: Object,

    //----------------
    //Klatki filmu
    //----------------
    frames: Array,

    //----------------
    //Pokaż spinner nad klatkamia (w czasie ladowania)
    //----------------
    showSpinner: Boolean(false),
  },
  data() {
    return {
      //OBVS
      isTv: this.$isTizen(this.$browserDetect),

      //lista klatek zapakowana do struktury stronicowania
      pagination: {
        pageNumber: 1,
        totalItems: 0,
        itemsPerPage: 18,
        count: 0,
        items: [],
      },

      //aktualna klatka z 'hover'
      hoverItem: null,
    };
  },
  methods: {
    GotoPage(page) {
      this.pagination.pageNumber = page;
      this.updateFrames();
    },
    updateFrames() {
      this.pagination.totalItems = this.frames.length;
      this.pagination.items = this.frames.slice(
        (this.pagination.pageNumber - 1) * this.pagination.itemsPerPage,
        this.pagination.pageNumber * this.pagination.itemsPerPage
      );
      this.pagination.count = frames.length / this.pagination.itemsPerPage;
    },
    convertSecond(second) {
      const date = new Date(null);
      date.setSeconds(second);
      return date.toISOString().slice(11, 19);
    },
    getStyle(item) {
      const start = ((item.startTime / this.model.duration) * 100).toString();
      const end = (
        (item.endTime / this.model.duration) * 100 -
        (item.startTime / this.model.duration) * 100
      ).toString();
      return {
        left: start + "%",
        width: end + "%",
      };
    },
    goTo(time) {
      this.$refs.player.goTo(time);
    },
  },
  watch: {
    frames() {
      this.updateFrames();
    },
  },
  computed: {
    isSearch() {
      return this.model.searchPhrase ? true : false;
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/_variables.scss";
.leftSide {
  .spinner {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(29, 29, 29, 0.8);
    z-index: 2;
    text-align: center;
    &.show {
      display: block;
    }
    > span {
      position: relative;
      top: 50%;
      color: white;
    }
  }

  .Player {
  }
  .timeline {
    position: relative;
    .progressbar {
      margin-top: em(16, 12);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__main {
        height: 17px;
        background: #303030;
        position: relative;
        width: 100%;
      }
      &__item {
        position: absolute;
        height: 10px;
        margin: 4px 0 3px;
        width: 10px;
        background: #414141;
        &.active {
          background: #d9d9d9;
          z-index: 10000;
          min-width: 0.5px;
        }
      }
    }
    .movie__search__result {
      margin-top: em(16, 12);
    }
    h3 {
      margin-bottom: 1em;
      font-family: "Knile";
      font-style: normal;
      font-weight: 600;
      font-size: em(15, 12);
      line-height: em(24, 12);
    }
    .frames {
      color: $color-light-gray;
      display: flex;
      flex-wrap: wrap;
      margin-right: -8px;

      .frame {
        vertical-align: middle;
        flex-basis: calc(33.333% - 8px);
        min-width: 70px;
        margin-right: 8px;
        margin-bottom: 14px;
        text-align: center;
        padding: 0;
        background: transparent;
        border-color: transparent;
        box-shadow: none;
        border: none;
        font-weight: normal;
        .fimg {
          display: block;
          border: 2px solid transparent;
          margin-bottom: 0.5em;
          border-radius: 3px;
          aspect-ratio: 1.6 / 1;
          max-width: 100%;
        }
        .tc {
          font-size: 0.7em;
        }
        &.active {
          .fimg {
            border: 2px solid #ffffff;
          }
        }
      }
      .frames-left {
        font-size: 1.5em;
      }
    }
    .pagination .page-item:before {
      left: 50%;
    }

    @include media-breakpoint-up(md) {
      .progressbar {
        &__main {
          height: em(33);
        }
        &__item {
          height: 19px;
          margin: 7px 0;
        }
      }
      .frames {
        .frame {
          flex-basis: calc(33.333% - 16px);
          margin-right: 16px;
          margin-bottom: 27px;
          .tc {
            font-size: em(11);
          }
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .frames .frame {
        flex-basis: calc(16.666% - 16px);
      }
    }
  }
}
</style>

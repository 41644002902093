<template>
  <div class="my-lessons color--basic">
    <b-container fluid class="containerFixed">
      <b-row class="mt-5">
        <b-col cols="12" class="fix">
          <h1>{{ model.title }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="fix">
          <text-block
            v-if="!hasAvailableLessons"
            :text="model.noLessonText"
          ></text-block>
          <list-container
            v-if="hasAvailableLessons"
            :items="lessons"
          ></list-container>
        </b-col>
      </b-row>
    </b-container>
    <bottom-button :model="model.createLessonLink"></bottom-button>
  </div>
</template>

<script>
import ListContainer from "@/components/Lists/ListContainer";
import TextBlock from "@/components/common/TextBlock";
import BottomButton from "@/components/common/BottomButton";
import axios from "axios";

export default {
  name: "MyLessons",
  components: {
    ListContainer,
    TextBlock,
    BottomButton,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      lessons: [],
    };
  },
  computed: {
    hasAvailableLessons() {
      return this.lessons.length > 0;
    },
  },
  mounted() {
    let self = this;
    self.$setBusy(true);
    axios
      .get(process.env.VUE_APP_BASE_API_URL + this.model.lessonsApiEndpoint, {})
      .then(function (response) {
        self.lessons = response.data;
        self.$setBusy(false);
      })
      .catch(function () {
        self.$setBusy(false);
      });
  },
};
</script>
<template>
  <div class="share-control">
    <add-playlist
      v-if="useAddToPlaylist && showAddToPlaylist"
      :episodeId="favoriteModel.id"
      :translations="translations"
      :forceModal="true"
      :showAdd="useAddToPlaylist && showAddToPlaylist"
      @close="close"
    ></add-playlist>
    <b-row>
      <b-col cols="12">
        <div class="share-control__icons">
          <b-link
            v-if="useAddToPlaylist"
            :id="'addbtn' + favoriteModel.id"
            class="share-control__icons__icon focus-smart"
            @click="showAddToPlaylist = true"
          >
            <div role="img" :title="translations['soundLibrary.AddToPlaylist']">
              <i class="cil-playlist-add"> </i>
              <p>{{ translations["soundLibrary.AddToPlaylist"] }}</p>
            </div>
          </b-link>
          <favorites-control
            :model="favoriteModel"
            :section="section"
            :title="title"
            css-class="share-control__icons__icon"
          >
            <p aria-hidden="true">{{ translations["general.Favorite.Add"] }}</p>
          </favorites-control>
          <a
            class="share-control__icons__icon"
            v-if="!this.$browserDetect.isApp"
            tabindex="0"
          >
            <div role="img" :title="translations['general.Share']">
              <i class="cil-share-alt"> </i>
              <p>{{ translations["general.Share"] }}</p>
            </div>
            <ul class="medialist">
              <li>
                <a
                  :href="facebookUrl"
                  target="_blank"
                  @click="sendAnalytics('Udostępnij na facebooku')"
                >
                  <i
                    role="img"
                    :title="
                      translations['general.Share.Facebook'] +
                      ' ' +
                      title +
                      ' ' +
                      translations['general.OpenNewTab']
                    "
                    class="cib-facebook"
                  ></i>
                  <span class="wcag_hide"
                    >Facebook {{ translations["general.OpenNewTab"] }}</span
                  >
                </a>
              </li>
              <li>
                <a tabindex="0" class="" @click="shareByMail()">
                  <i
                    :title="translations['general.Share.Email'] + ' ' + title"
                    class="cil-mail"
                    aria-hidden="true"
                  ></i>
                  <span class="wcag_hide">Email</span>
                </a>
              </li>
            </ul>
          </a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FavoritesControl from "@/components/common/FavoritesControl";
import { EventBus } from "@/services/event-bus.js";
import AddPlaylist from "./AddPlaylist.vue";
export default {
  name: "ShareControl",
  components: {
    FavoritesControl,
    AddPlaylist,
  },
  props: {
    translations: Object,
    favoriteModel: Object,
    section: String,
    title: String,
    useAddToPlaylist: Boolean,
  },
  data() {
    return {
      showAddToPlaylist: false,
    };
  },
  computed: {
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    },
  },
  methods: {
    shareByMail: function () {
      this.$bvModal.show("modal-shared-mail");
      this.sendAnalytics("Udostępnij przez E-mail");
      EventBus.$emit("share-content", null);
    },
    sendAnalytics(gLabel) {
      this.$setGAFormCustom(this.section, "Kliknięcie w ikonkę", gLabel, false);
    },
    close() {
      this.showAddToPlaylist = false;
      window.RedgalaxyNav.focusElement('#addbtn' + this.favoriteModel.id);
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";

.share-control {
  &__icons {
    margin-top: 60px;
    margin-bottom: 60px;
    font-family: $font-family-wotfard;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      text-align: center;
      &__icon {
        margin: 0 15px !important;
      }
    }
    &__icon {
      display: grid;
      grid-template-columns: 35px auto;
      margin-bottom: 10px;
      line-height: 25px;
      margin-right: 70px;
      vertical-align: top;
      position: relative;
      display: inline-block;

      i {
        font-size: em(30);
      }

      p {
        font-size: em(13);
        text-transform: uppercase;
        margin-bottom: 0;
      }
      &:first-child {
        margin-bottom: 20px;
      }

      .medialist {
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 40px;
        z-index: 1;
        left: -30px;
        transition: all 0.3s;
        opacity: 0;
        li {
          display: list-item;
          padding: 0;
          opacity: 1;
          background-color: $body-bg;
          list-style-type: none;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 56px;
            color: $color-black !important;
            background: #fff;
            &:hover {
              background-color: rgba(255, 255, 255, 0.7) !important;
            }
            transition: background-color 0.3s;

            i {
              padding-top: 15px;
            }
          }
        }
      }
      &:hover,
      &:focus,
      &:focus-within {
        .medialist {
          opacity: 1;
          max-height: 200px;
        }
      }
    }
  }
  .col-12 {
    padding: 0;
  }
  .row {
    border-bottom: 2px solid;
    border-top: 2px solid;
  }
  @media (min-width: map-get($grid-breakpoints, "sm" )) {
    &__icons {
      display: inline-block;
      &__icon {
        width: auto;
        display: inline-block;
        text-align: center;
        margin-right: 20px;
        margin-bottom: 0;
        &:first-child {
          margin-bottom: 0px;
        }
        i {
          padding-bottom: 15px;
        }
        i,
        p {
          display: block;
        }
      }
    }
  }
}
</style>

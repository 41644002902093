import axios from 'axios'

export default {
    getContent: function (to) {

        var data = {
            headers: {
                "x-origin-url": to
            }
        };
        let url = process.env.VUE_APP_CONTENT_URL;
        return axios.get(url, data).then(function (response) {
            return response.data;
        }).catch(function () {
            return "error";
        });
    },
    getSeo: function (to) {
        var data = { headers: { "x-origin-url": to } };
        let url = `${process.env.VUE_APP_BASE_API_URL}umbraco/api/content/Meta`;
        return axios.get(url, data).then(function (response) {
            return response.data;
        }).catch(function () {
            return "error";
        });
    }
}